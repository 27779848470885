import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as gruposUserActions from '../../../actions/modulos/inwork/grupos_user.actions';
import * as rolesUsuarioActions from '../../../actions/modulos/inwork/roles_usuarios.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { GruposService } from 'src/app/services/inwork/grupos.service';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

@Injectable()

export class gruposUserInworkEffects {
    constructor(
        private actions$: Actions,
        private gruposService: GruposService,
    ) { }
    @Effect()
    postGruposUserInwork$ = this.actions$.ofType(gruposUserActions.POST_GRUPOS_USER)
        .pipe(
            switchMap((action: gruposUserActions.PostGruposUserAction) => {
                return this.gruposService.postGruposUser(action.listGruposUsuario)
                    .pipe(
                        map((rolesUsuarios: RolesUsuarios[]) => new rolesUsuarioActions.GetRolesUsuariosActionSuccess(rolesUsuarios)),
                        catchError(error => of(new gruposUserActions.PostGruposUserActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putGruposUserInwork$ = this.actions$.ofType(gruposUserActions.PUT_GRUPOS_USER)
        .pipe(
            switchMap((action: gruposUserActions.PutGruposUserAction) => {
                return this.gruposService.putGruposUser(action.listGruposUsuario)
                    .pipe(
                        map((rolesUsuarios: RolesUsuarios[]) => new rolesUsuarioActions.GetRolesUsuariosActionSuccess(rolesUsuarios)),
                        catchError(error => of(new gruposUserActions.PutGruposUserActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteGruposUserInwork$ = this.actions$.ofType(gruposUserActions.DELETE_GRUPOS_USER)
        .pipe(
            switchMap((action: gruposUserActions.DeleteGruposUserAction) => {
                return this.gruposService.deleteGruposUser(action.user)
                    .pipe(
                        map((rolesUsuarios: RolesUsuarios[]) => new rolesUsuarioActions.GetRolesUsuariosActionSuccess((rolesUsuarios) ? rolesUsuarios : rolesUsuarios = [])),
                        catchError(error => of(new gruposUserActions.DeleteGruposUserActionFAIL(error)))
                    )
            })
        );
};
