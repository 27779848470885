import { Component, EventEmitter, OnInit, ViewChild, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Usuario } from '../../../../models/balcax/usuario.model';

@Component({
  selector: 'app-rpassword',
  templateUrl: './rpassword.component.html',
  styleUrls: ['./rpassword.component.css']
})
export class RpasswordComponent implements OnInit {

  @ViewChild('myForm', { static: false, }) formValues;
  form: FormGroup;
  submitted = false;

  loading: boolean = false;
  BbtnValidar: boolean = false;

  @Output() dataUser = new EventEmitter<Usuario>();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      correo: new FormControl('', [Validators.required, Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]),
    });
    this.BbtnValidar = false;
  }

  get f() { return JSON.stringify(this.form.controls); }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    if (this.form.invalid) {
      this.loading = false
      return;
    }
    else {
      this.dataUser.emit(new Usuario(null, null, null, this.form.value.correo, null, null, null, null, null, null, null));
      this.submitted = false;

      setTimeout(() => {
        this.BbtnValidar = true;
        this.loading = false
      }, 1750);
    }
  }

}
