import { Action } from '@ngrx/store';
import { Campos } from 'src/app/shared/models/Inwork/catalogos.model';


//CATALOGOS
export const GET_CAMPOS_PT = '[INWORK] Get Campos PT Inwork';
export const GET_CAMPOS_PT_FAIL = '[INWORK] Get Campos PT Inwork FAIL';
export const GET_CAMPOS_PT_SUCCESS = '[INWORK] Get Campos PT Inwork SUCCESS';

export const POST_CAMPO_PT = '[INWORK] Post Campo PT Inwork';
export const POST_CAMPO_PT_FAIL = '[INWORK] Post Campo PT Inwork FAIL';
export const POST_CAMPO_PT_SUCCESS = '[INWORK] Post Campo PT Inwork SUCCESS';

export const PUT_CAMPO_PT = '[INWORK] Put Campo PT Inwork';
export const PUT_CAMPO_PT_FAIL = '[INWORK] Put Campo PT Inwork FAIL';
export const PUT_CAMPO_PT_SUCCESS = '[INWORK] Put Campo PT Inwork SUCCESS';

export const DELETE_CAMPO_PT = '[INWORK] Delete Campo PT Inwork';
export const DELETE_CAMPO_PT_FAIL = '[INWORK] Delete Campo PT Inwork FAIL';
export const DELETE_CAMPO_PT_SUCCESS = '[INWORK] Delete Campo PT Inwork SUCCESS';

//#region GET_CAMPOS_PT
export class GetCamposPTAction implements Action {
    readonly type = GET_CAMPOS_PT;
    constructor(public IdAtributosPT: number){}
};

export class GetCamposPTActionFail implements Action {
    readonly type = GET_CAMPOS_PT_FAIL;
    constructor(public payload: any) { }
};

export class GetCamposPTActionSuccess implements Action {
    readonly type = GET_CAMPOS_PT_SUCCESS;
    constructor(public campos: Campos[]) { }
};
//#endregion

//#region  POST_CAMPO_PT
export class PostCampoPTAction implements Action {
    readonly type = POST_CAMPO_PT;
    constructor(public campo: Campos) { }
};
export class PostCampoPTActionFAIL implements Action {
    readonly type = POST_CAMPO_PT_FAIL;
    constructor(public payload: any) { }
};
export class PostCampoPTActionSUCCESS implements Action {
    readonly type = POST_CAMPO_PT_SUCCESS;
    constructor(public newCampo: Campos) { }
};
//#endregion

//#region  PUT_CAMPO_PT
export class PutCampoPTAction implements Action {
    readonly type = PUT_CAMPO_PT;
    constructor(public campo: Campos) { }
};
export class PutCampoPTActionFAIL implements Action {
    readonly type = PUT_CAMPO_PT_FAIL;
    constructor(public payload: any) { }
};
export class PutCampoPTActionSUCCESS implements Action {
    readonly type = PUT_CAMPO_PT_SUCCESS;
    constructor(public updateCampo: Campos) { }
};
//#endregion

//#region  DELETE_CAMPO_PT
export class DeleteCampoPTAction implements Action {
    readonly type = DELETE_CAMPO_PT;
    constructor(public campo: Campos) { }
};
export class DeleteCampoPTActionFAIL implements Action {
    readonly type = DELETE_CAMPO_PT_FAIL;
    constructor(public payload: any) { }
};
export class DeleteCampoPTActionSUCCESS implements Action {
    readonly type = DELETE_CAMPO_PT_SUCCESS;
    constructor(public deleteCampo: Campos) { }
};
//#endregion

export type camposPTInworkActions =
GetCamposPTAction |
GetCamposPTActionFail |
GetCamposPTActionSuccess |

PostCampoPTAction |
PostCampoPTActionFAIL |
PostCampoPTActionSUCCESS |

PutCampoPTAction |
PutCampoPTActionFAIL |
PutCampoPTActionSUCCESS |

DeleteCampoPTAction |
DeleteCampoPTActionFAIL |
DeleteCampoPTActionSUCCESS;
