import * as fromActions from '../../../actions/modulos/indicadoresSustentabilidad/indexSus';
import * as fromIndicadores from '../../../actions/modulos/indicadoresSustentabilidad/indicadoresSus.actions';

import { CatalogosState, catalogosReducerSus } from './catalogosSus.reducer';
import { AreasState, areasReducerSus } from './areasSus.reducer';
import { AreasUsuarioState, areasUsuarioReducerSus } from './areasusuarioSus.reducer';
import { MesConfigState, mesConfiguracionReducerSus } from './mesconfiguracionSus.reducer';
import { DashboardsState, dashboardsReducerSus } from './dashboardsSus.reducer';
import { configProgramaTrabajoState, configProgramaTrabajoReducerSus } from './config_programatrabajoSus.reducer';
import { EstrategicoState, estrategicosReducerSus } from './estrategicosSus.reducer';
import { EspecificoState, especificosReducerSus } from './especificosSus.reducer';
import { EstrategiasState, estrategiasReducerSus } from './estrategiasSus.reducer';
// import { IndicadoresState } from '../indicadores/indicadores.reducer';

export interface IndicadoresSusState {
    dashboards: DashboardsState;
    areas: AreasState;
    areas_usuario: AreasUsuarioState
    estrategicos: EstrategicoState;
    especificos: EspecificoState;
    catalogos: CatalogosState;
    estrategias: EstrategiasState;
    mesConfiguracion: MesConfigState;
    configProgramaTrabajo: configProgramaTrabajoState
};

const initState: IndicadoresSusState = {
    dashboards: {
        dashboard: null,
        listCardsActivities: [],
        resultAvanceEspecifico: null,
        resultAvanceIndicadores: null,
        resultAvanceEstandares: null,
        detallesAvanceIndicadores: null,
        listAvancePorArea: [],
        listRecentActivities: [],
        loaded: false,
        loading: false,
        error: null
    },
    areas: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    areas_usuario: {
        list: [],
        areaUsuario: null,
        loaded: false,
        loading: false,
        error: null
    },
    estrategicos: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    especificos: {
        list: [],
        reportIndicadores: [],
        loaded: false,
        loading: false,
        error: null
    },
    catalogos: {
        frecuencias: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        tipos_indicadores: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        periodos: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        estandares: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
    },
    estrategias: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    mesConfiguracion: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    configProgramaTrabajo: {
        configuracionPrograma: null,
        configuracionAvance: [],
        loaded: false,
        loading: false,
        error: null
    }
};

export function indicadoresReducerSus(state = initState, action: fromIndicadores.indicadoresActions_SUS): IndicadoresSusState {
    if (action.type == fromActions.GET_DASHBOARD_SUS ||
        action.type == fromActions.GET_DASHBOARD_SUS_FAIL ||
        action.type == fromActions.GET_DASHBOARD_SUS_SUCCESS ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES_SUS ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES_SUS_FAIL ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES_SUS_SUCCESS ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO_SUS ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO_SUS_FAIL ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO_SUS_SUCCESS ||
        action.type == fromActions.GET_AVANCE_INDICADORES_SUS ||
        action.type == fromActions.GET_AVANCE_INDICADORES_SUS_FAIL ||
        action.type == fromActions.GET_AVANCE_INDICADORES_SUS_SUCCESS ||
        action.type == fromActions.GET_AVANCE_ESTANDARES_SUS ||
        action.type == fromActions.GET_AVANCE_ESTANDARES_SUS_FAIL ||
        action.type == fromActions.GET_AVANCE_ESTANDARES_SUS_SUCCESS ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES_SUS ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES_SUS_FAIL ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES_SUS_SUCCESS ||
        action.type == fromActions.GET_AVANCE_POR_AREA_SUS ||
        action.type == fromActions.GET_AVANCE_POR_AREA_SUS_FAIL ||
        action.type == fromActions.GET_AVANCE_POR_AREA_SUS_SUCCESS ||
        action.type == fromActions.GET_RECENT_ACTIVITIES_SUS ||
        action.type == fromActions.GET_RECENT_ACTIVITIES_SUS_FAIL ||
        action.type == fromActions.GET_RECENT_ACTIVITIES_SUS_SUCCESS) {
        return {
            ...state,
            dashboards: dashboardsReducerSus(state.dashboards, action)
        };

    } else if (action.type == fromActions.GET_AREAS_SUS ||
        action.type == fromActions.GET_AREAS_SUS_FAIL ||
        action.type == fromActions.GET_AREAS_SUS_SUCCESS ||
        action.type == fromActions.PUT_AREAS_SUS ||
        action.type == fromActions.PUT_AREAS_SUS_FAIL ||
        action.type == fromActions.PUT_AREAS_SUS_SUCCESS ||
        action.type == fromActions.PUT_STATE_AREAS_SUS ||
        action.type == fromActions.PUT_STATE_AREAS_SUS_FAIL ||
        action.type == fromActions.PUT_STATE_AREAS_SUS_SUCCESS ||
        action.type == fromActions.POST_AREAS_SUS ||
        action.type == fromActions.POST_AREAS_SUS_FAIL ||
        action.type == fromActions.POST_AREAS_SUS_SUCCESS ||
        action.type == fromActions.DELETE_AREA_SUS ||
        action.type == fromActions.DELETE_AREA_SUS_FAIL ||
        action.type == fromActions.DELETE_AREA_SUS_SUCCESS) {
        return {
            ...state,
            areas: areasReducerSus(state.areas, action)
        };

    } else if (action.type == fromActions.GET_AREAS_USUARIO_SUS_BY_CORREO ||
        action.type == fromActions.GET_AREAS_USUARIO_SUS_BY_CORREO_FAIL ||
        action.type == fromActions.GET_AREAS_USUARIO_SUS_BY_CORREO_SUCCESS ||
        action.type == fromActions.GET_AREAS_USUARIO_SUS ||
        action.type == fromActions.GET_AREAS_USUARIO_SUS_FAIL ||
        action.type == fromActions.GET_AREAS_USUARIO_SUS_SUCCESS ||
        action.type == fromActions.PUT_AREAS_USUARIO_SUS ||
        action.type == fromActions.PUT_AREAS_USUARIO_SUS_FAIL ||
        action.type == fromActions.PUT_AREAS_USUARIO_SUS_SUCCESS ||
        action.type == fromActions.POST_AREAS_USUARIO_SUS ||
        action.type == fromActions.POST_AREAS_USUARIO_SUS_FAIL ||
        action.type == fromActions.POST_AREAS_USUARIO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_AREA_USUARIO_SUS ||
        action.type == fromActions.DELETE_AREA_USUARIO_SUS_FAIL ||
        action.type == fromActions.DELETE_AREA_USUARIO_SUS_SUCCESS) {
        return {
            ...state,
            areas_usuario: areasUsuarioReducerSus(state.areas_usuario, action)
        };

    } else if (action.type == fromActions.GET_ESTRATEGICOS_SUS ||
        action.type == fromActions.GET_ESTRATEGICOS_SUS_FAIL ||
        action.type == fromActions.GET_ESTRATEGICOS_SUS_SUCCESS ||
        action.type == fromActions.PUT_STATE_ESTRATEGICO_SUS ||
        action.type == fromActions.PUT_STATE_ESTRATEGICO_SUS_FAIL ||
        action.type == fromActions.PUT_STATE_ESTRATEGICO_SUS_SUCCESS ||
        action.type == fromActions.PUT_ESTRATEGICO_SUS ||
        action.type == fromActions.PUT_ESTRATEGICO_SUS_FAIL ||
        action.type == fromActions.PUT_ESTRATEGICO_SUS_SUCCESS ||
        action.type == fromActions.POST_ESTRATEGICO_SUS ||
        action.type == fromActions.POST_ESTRATEGICO_SUS_FAIL ||
        action.type == fromActions.POST_ESTRATEGICO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_ESTRATEGICO_SUS ||
        action.type == fromActions.DELETE_ESTRATEGICO_SUS_FAIL ||
        action.type == fromActions.DELETE_ESTRATEGICO_SUS_SUCCESS) {
        return {
            ...state,
            estrategicos: estrategicosReducerSus(state.estrategicos, action)
        };
    }
    else if (action.type == fromActions.GET_ESPECIFICOS_SUS ||
        action.type == fromActions.GET_ESPECIFICOS_SUS_FAIL ||
        action.type == fromActions.GET_ESPECIFICOS_SUS_SUCCESS ||
        action.type == fromActions.GET_REPORTE_ESPECIFICOS_SUS ||
        action.type == fromActions.GET_REPORTE_ESPECIFICOS_SUS_FAIL ||
        action.type == fromActions.GET_REPORTE_ESPECIFICOS_SUS_SUCCESS ||
        action.type == fromActions.PUT_ESPECIFICO_SUS ||
        action.type == fromActions.PUT_ESPECIFICO_SUS_FAIL ||
        action.type == fromActions.PUT_ESPECIFICO_SUS_SUCCESS ||
        action.type == fromActions.PUT_STATE_ESPECIFICO_SUS ||
        action.type == fromActions.PUT_STATE_ESPECIFICO_SUS_FAIL ||
        action.type == fromActions.PUT_STATE_ESPECIFICO_SUS_SUCCESS ||
        action.type == fromActions.POST_ESPECIFICO_SUS ||
        action.type == fromActions.POST_ESPECIFICO_SUS_FAIL ||
        action.type == fromActions.POST_ESPECIFICO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_ESPECIFICO_SUS ||
        action.type == fromActions.DELETE_ESPECIFICO_SUS_FAIL ||
        action.type == fromActions.DELETE_ESPECIFICO_SUS_SUCCESS) {
        return {
            ...state,
            especificos: especificosReducerSus(state.especificos, action)
        };
    }
    else if (action.type == fromActions.GET_FRECUENCIAS_SUS ||
        action.type == fromActions.GET_FRECUENCIAS_SUS_FAIL ||
        action.type == fromActions.GET_FRECUENCIAS_SUS_SUCCESS ||
        action.type == fromActions.POST_FRECUENCIA_SUS ||
        action.type == fromActions.POST_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.POST_FRECUENCIA_SUS_SUCCESS ||
        action.type == fromActions.PUT_FRECUENCIA_SUS ||
        action.type == fromActions.PUT_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.PUT_FRECUENCIA_SUS_SUCCESS ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS_SUCCESS ||

        action.type == fromActions.GET_TIPOS_INDICADOR_SUS ||
        action.type == fromActions.GET_TIPOS_INDICADOR_SUS_FAIL ||
        action.type == fromActions.GET_TIPOS_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS_SUCCESS ||

        action.type == fromActions.GET_PERIODOS_SUS ||
        action.type == fromActions.GET_PERIODOS_SUS_FAIL ||
        action.type == fromActions.GET_PERIODOS_SUS_SUCCESS ||
        action.type == fromActions.POST_PERIODO_SUS ||
        action.type == fromActions.POST_PERIODO_SUS_FAIL ||
        action.type == fromActions.POST_PERIODO_SUS_SUCCESS ||
        action.type == fromActions.PUT_PERIODO_SUS ||
        action.type == fromActions.PUT_PERIODO_SUS_FAIL ||
        action.type == fromActions.PUT_PERIODO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_PERIODO_SUS ||
        action.type == fromActions.DELETE_PERIODO_SUS_FAIL ||
        action.type == fromActions.DELETE_PERIODO_SUS_SUCCESS ||

        action.type == fromActions.GET_ESTANDARES_SUS ||
        action.type == fromActions.GET_ESTANDARES_SUS_FAIL ||
        action.type == fromActions.GET_ESTANDARES_SUS_SUCCESS ||
        action.type == fromActions.POST_ESTANDAR_SUS ||
        action.type == fromActions.POST_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.POST_ESTANDAR_SUS_SUCCESS ||
        action.type == fromActions.PUT_ESTANDAR_SUS ||
        action.type == fromActions.PUT_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.PUT_ESTANDAR_SUS_SUCCESS ||
        action.type == fromActions.DELETE_ESTANDAR_SUS ||
        action.type == fromActions.DELETE_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.DELETE_ESTANDAR_SUS_SUCCESS) {
        return {
            ...state,
            catalogos: catalogosReducerSus(state.catalogos, action)
        };
    }
    else if (action.type == fromActions.GET_ESTRATEGIAS_SUS ||
        action.type == fromActions.GET_ESTRATEGIAS_SUS_FAIL ||
        action.type == fromActions.GET_ESTRATEGIAS_SUS_SUCCESS ||
        action.type == fromActions.POST_ESTRATEGIA_SUS ||
        action.type == fromActions.POST_ESTRATEGIA_SUS_FAIL ||
        action.type == fromActions.POST_ESTRATEGIA_SUS_SUCCESS ||
        action.type == fromActions.PUT_ESTRATEGIAS_SUS ||
        action.type == fromActions.PUT_ESTRATEGIAS_SUS_FAIL ||
        action.type == fromActions.PUT_ESTRATEGIAS_SUS_SUCCESS ||
        action.type == fromActions.DELETE_ESTRATEGIA_SUS ||
        action.type == fromActions.DELETE_ESTRATEGIA_SUS_FAIL ||
        action.type == fromActions.DELETE_ESTRATEGIA_SUS_SUCCESS) {
        return {
            ...state,
            estrategias: estrategiasReducerSus(state.estrategias, action)
        };
    }
    else if (action.type == fromActions.GET_MES_CONFIGURACION_SUS ||
        action.type == fromActions.GET_MES_CONFIGURACION_SUS_FAIL ||
        action.type == fromActions.GET_MES_CONFIGURACION_SUS_SUCCESS ||
        action.type == fromActions.POST_MES_CONFIGURACION_SUS ||
        action.type == fromActions.POST_MES_CONFIGURACION_SUS_FAIL ||
        action.type == fromActions.POST_MES_CONFIGURACION_SUS_SUCCESS ||
        action.type == fromActions.PUT_MES_CONFIGURACION_SUS ||
        action.type == fromActions.PUT_MES_CONFIGURACION_SUS_FAIL ||
        action.type == fromActions.PUT_MES_CONFIGURACION_SUS_SUCCESS ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO_SUS ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO_SUS_FAIL ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_MES_CONFIGURACION_SUS ||
        action.type == fromActions.DELETE_MES_CONFIGURACION_SUS_FAIL ||
        action.type == fromActions.DELETE_MES_CONFIGURACION_SUS_SUCCESS) {
        return {
            ...state,
            mesConfiguracion: mesConfiguracionReducerSus(state.mesConfiguracion, action)
        };
    } else if (action.type == fromActions.GET_CONFIGURACION_PROGRAMA_SUS ||
        action.type == fromActions.GET_CONFIGURACION_PROGRAMA_SUS_FAIL ||
        action.type == fromActions.GET_CONFIGURACION_PROGRAMA_SUS_SUCCESS ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE_SUS ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE_SUS_FAIL ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE_SUS_SUCCESS ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA_SUS ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA_SUS_FAIL ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA_SUS_SUCCESS ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE_SUS ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE_SUS_FAIL ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE_SUS_SUCCESS ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA_SUS ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA_SUS_FAIL ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA_SUS_SUCCESS ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE_SUS ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE_SUS_FAIL ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE_SUS_SUCCESS ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA_SUS ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA_SUS_FAIL ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA_SUS_SUCCESS ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE_SUS ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE_SUS_FAIL ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE_SUS_SUCCESS) {
        return {
            ...state,
            configProgramaTrabajo: configProgramaTrabajoReducerSus(state.configProgramaTrabajo, action)
        };
    }
    return state;
}