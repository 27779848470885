import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as areasActions from '../../../actions/modulos/indicadores/areas.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { AreasService } from 'src/app/services/indicadores/areas.service';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';

@Injectable()

export class areasEffects {
    constructor(
        private actions$: Actions,
        private areasService: AreasService,
    ) { }

    @Effect()
    cargarAreas$ = this.actions$.ofType(areasActions.GET_AREAS)
        .pipe(
            switchMap(() => {
                return this.areasService.getAreas()
                    .pipe(
                        map((response: any) => new areasActions.GetAreasActionSuccess(response)),
                        catchError(error => of(new areasActions.GetAreasActionFail(error)))
                    )
            })
        );

    @Effect()
    changeEditArea$ = this.actions$.ofType(areasActions.PUT_AREAS)
        .pipe(
            switchMap((action: areasActions.PutAreasAction) => {
                const areas = new AreaEmpresa();
                areas.id = action.areas.id;
                areas.descripcion = action.areas.descripcion;
                areas.dependencia = action.areas.dependencia;
                areas.nivel = action.areas.nivel;
                areas.permiso = action.areas.permiso;
                areas.idEmpresa = action.areas.idEmpresa;

                return this.areasService.putArea(areas)
                    .pipe(
                        map(() => new areasActions.PutAreasActionSuccess(areas)),
                        catchError(error => of(new areasActions.PutAreasActionFail(error)))
                    )
            })
        );

    @Effect()
    changeStateArea$ = this.actions$.ofType(areasActions.PUT_STATE_AREAS)
        .pipe(
            switchMap((action: areasActions.PutStateAreasAction) => {
                const area = new AreaEmpresa();
                area.id = action.idArea;
                return this.areasService.putAreaPermiso(area)
                    .pipe(
                        map(() => new areasActions.PutStateAreasActionSuccess(area.id)),
                        catchError(error => of(new areasActions.PutStateAreasActionFail(error)))
                    )
            })
        );
        
    @Effect()
    postArea$ = this.actions$.ofType(areasActions.POST_AREAS)
        .pipe(
            switchMap((action: areasActions.PostAreasAction) => {
                return this.areasService.postArea(action.area)
                    .pipe(
                        map((area: AreaEmpresa) => new areasActions.PostAreasActionSUCCESS(area)),
                        catchError(error => of(new areasActions.PostAreasActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteArea$ = this.actions$.ofType(areasActions.DELETE_AREA)
        .pipe(
            switchMap((action: areasActions.DeleteAreaAction) => {
                return this.areasService.deleteArea(action.area)
                    .pipe(
                        map(() => new areasActions.DeleteAreaActionSUCCESS(action.area)),
                        catchError(error => of(new areasActions.DeleteAreaActionFAIL(error)))
                    )
            })
        );
};
