import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { loginEffects } from 'src/app/store/effects';
import { environment } from 'src/environments/environment';
//import { UsersService } from 'src/app/services/balcax/users.service';
//import { AuthService } from 'src/app/services/balcax/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  baseUrl: string;
  headers = new BehaviorSubject(null);

  constructor(

  ) {
    this.baseUrl = environment.apiBalcan;

  }

  setLocal() {
    let headers = { 'Content-Type': 'application/json' };
    let token = this.token;
    if (token)
      headers['Authorization'] = `Bearer ${token}`;
    this.headers.next(headers);
  }

  get token() {

    return localStorage.d;
  }

//   @HostListener('window:unload', ['$event'])
// beforeunloadHandler(event) {
//     console.log("entra al evento.")
//     // this.auth.logoff();
//     //this.usersService.cancelSubsctiptions();
// }
@HostListener('window:beforeunload')
  onUnload() {
    const data = new FormData();
    console.log("se cierra. ")
localStorage.clear();
    return false;
  }
}
