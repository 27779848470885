import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';
import { HttpClientService } from '../../implementation/http-client.service';
import { ExceptionsService } from '../../exceptions.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogoPVService {


    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region PUT
    putProducto(codigo: CodigoPVMicroMedicion) {

        let _code: CodigoPVMicroMedicion = {
            id: codigo.id,
            tipo: codigo.tipo,
            codigo: codigo.codigo,
            marca: codigo.marca,
            diametro: codigo.diametro,
            milimetros: codigo.milimetros,
            cantidad: codigo.cantidad,
            precio: codigo.precio,
            stock: codigo.stock,
            iva: codigo.iva,
            ivaActivo: codigo.ivaActivo
        };

        return this.http.post(`${environment.apiAlmacen}shop/product`, { _code })
            .pipe(
                map((response: CodigoPVMicroMedicion) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )));
    }
    //#endregion
}