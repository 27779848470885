import { Estados } from './estados.model';

export class DataFlujoTrabajo{
    constructor(
        public id?: number,
        public nombre?: string,
        public creadoPor?: string,
        public dataEstadosPrimarios?: DataEstadosPrimarios[]
    ){}
}

export class DataEstadosPrimarios{
    constructor(
        public id?: number,
        public nombre?: string,
        public listEstados?: Estados[]
    ){}
}

export class Propietario{
    constructor(
        public id?: number,
        public idCarpetaProyecto?: number,
        public usuario?: string
    ){}
}

export class Compartido{
    constructor(
        public id?: number,
        public idCarpetaProyecto?: number,
        public usuario?: string,
        public grupo?: string
    ){}
}

