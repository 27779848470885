import { Action } from '@ngrx/store';
import { ObjetivoEstrategico } from 'src/app/shared/models/Indicadores/obj_estrategico.model';

export const GET_ESTRATEGICOS_SUS = '[ESTRATEGICOS_SUS] Get Estrategicos_SUS';
export const GET_ESTRATEGICOS_SUS_FAIL = '[ESTRATEGICOS_SUS] Get Estrategicos_SUS FAIL';
export const GET_ESTRATEGICOS_SUS_SUCCESS = '[ESTRATEGICOS_SUS] Get Estrategicos_SUS SUCCESS';

export const PUT_STATE_ESTRATEGICO_SUS = '[ESTRATEGICOS_SUS] Put State_SUS Estrategicos';
export const PUT_STATE_ESTRATEGICO_SUS_FAIL = '[ESTRATEGICOS_SUS] Put State_SUS Estrategicos FAIL';
export const PUT_STATE_ESTRATEGICO_SUS_SUCCESS = '[ESTRATEGICOS_SUS] Put State_SUS Estrategicos SUCCESS';

export const PUT_ESTRATEGICO_SUS = '[ESTRATEGICOS_SUS] Put Estrategico_SUS';
export const PUT_ESTRATEGICO_SUS_FAIL = '[ESTRATEGICOS_SUS] Put Estrategico_SUS FAIL';
export const PUT_ESTRATEGICO_SUS_SUCCESS = '[ESTRATEGICOS_SUS] Put Estrategico_SUS SUCCESS';

export const POST_ESTRATEGICO_SUS = '[ESTRATEGICOS_SUS] Post Estrategicos_SUS';
export const POST_ESTRATEGICO_SUS_FAIL = '[ESTRATEGICOS_SUS] Post Estrategicos_SUS FAIL';
export const POST_ESTRATEGICO_SUS_SUCCESS = '[ESTRATEGICOS_SUS] Post Estrategicos_SUS SUCCESS';

export const DELETE_ESTRATEGICO_SUS = '[ESTRATEGICOS_SUS] Delete Estrategicos_SUS';
export const DELETE_ESTRATEGICO_SUS_FAIL = '[ESTRATEGICOS_SUS] Delete Estrategicos_SUS FAIL';
export const DELETE_ESTRATEGICO_SUS_SUCCESS = '[ESTRATEGICOS_SUS] Delete Estrategicos_SUS SUCCESS';
//#region GET_ESTRATEGICOS
export class GetEstrategicosAction_SUS implements Action {
    readonly type = GET_ESTRATEGICOS_SUS;
    constructor(public tipoIndicador: string) { }
};

export class GetEstrategicosActionFail_SUS implements Action {
    readonly type = GET_ESTRATEGICOS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstrategicosActionSuccess_SUS implements Action {
    readonly type = GET_ESTRATEGICOS_SUS_SUCCESS;
    constructor(public objetivosEstrategicos: ObjetivoEstrategico[]) { }
};
//#endregion

//#region PUT_STATE_ESTRATEGICO
export class PutStateEstrategicoAction_SUS implements Action {
    readonly type = PUT_STATE_ESTRATEGICO_SUS;
    constructor(public idEstrategico: number) { }
};

export class PutStateEstrategicoActionFail_SUS implements Action {
    readonly type = PUT_STATE_ESTRATEGICO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutStateEstrategicoActionSuccess_SUS implements Action {
    readonly type = PUT_STATE_ESTRATEGICO_SUS_SUCCESS;
    constructor(public idEstrategico: number) { }
};
//#endregion

//#region PUT_ESTRATEGICO
export class PutEstrategicoAction_SUS implements Action {
    readonly type = PUT_ESTRATEGICO_SUS;
    constructor(public estrategico: ObjetivoEstrategico) { }
};

export class PutEstrategicoActionFail_SUS implements Action {
    readonly type = PUT_ESTRATEGICO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutEstrategicoActionSuccess_SUS implements Action {
    readonly type = PUT_ESTRATEGICO_SUS_SUCCESS;
    constructor(public editEstrategico: ObjetivoEstrategico) { }
};
//#endregion

//#region  POST_ESTRATEGICO
export class PostEstrategicoAction_SUS implements Action {
    readonly type = POST_ESTRATEGICO_SUS;
    constructor(public estrategico: ObjetivoEstrategico) { }
};
export class PostEstrategicoActionFAIL_SUS implements Action {
    readonly type = POST_ESTRATEGICO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostEstrategicoActionSUCCESS_SUS implements Action {
    readonly type = POST_ESTRATEGICO_SUS_SUCCESS;
    constructor(public newEstrategico: ObjetivoEstrategico) { }
};
//#endregion

//#region  DELETE_ESTRATEGICO
export class DeleteEstrategicoAction_SUS implements Action {
    readonly type = DELETE_ESTRATEGICO_SUS;
    constructor(public estrategico: ObjetivoEstrategico) { }
};
export class DeleteEstrategicoActionFAIL_SUS implements Action {
    readonly type = DELETE_ESTRATEGICO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstrategicoActionSUCCESS_SUS implements Action {
    readonly type = DELETE_ESTRATEGICO_SUS_SUCCESS;
    constructor(public deleteEstrategico: ObjetivoEstrategico) { }
};
//#endregion


export type estrategicosActions_SUS =
    GetEstrategicosAction_SUS |
    GetEstrategicosActionFail_SUS |
    GetEstrategicosActionSuccess_SUS |

    PutStateEstrategicoAction_SUS |
    PutStateEstrategicoActionFail_SUS |
    PutStateEstrategicoActionSuccess_SUS |

    PutEstrategicoAction_SUS |
    PutEstrategicoActionFail_SUS |
    PutEstrategicoActionSuccess_SUS |

    PostEstrategicoAction_SUS |
    PostEstrategicoActionFAIL_SUS |
    PostEstrategicoActionSUCCESS_SUS |

    DeleteEstrategicoAction_SUS |
    DeleteEstrategicoActionFAIL_SUS |
    DeleteEstrategicoActionSUCCESS_SUS;