import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as carpetasProyectosActions from '../../../actions/modulos/inwork/carpetas_proyectos.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { CarpetaProyectoService } from 'src/app/services/inwork/carpetasProyectos.service';
import { CarpetaProyecto } from 'src/app/shared/models/Inwork/carpeta_proyecto.model';

@Injectable()

export class carpetasProyectosInworkEffects {
    constructor(
        private actions$: Actions,
        private carpetaProyectoService: CarpetaProyectoService,
    ) { }

    @Effect()
    getCarpetasProyectosInwork$ = this.actions$.ofType(carpetasProyectosActions.GET_CARPETASPROYECTOS)
        .pipe(
            switchMap(() => {
                return this.carpetaProyectoService.getCarpetasProyectos()
                    .pipe(
                        map((carpetasP: CarpetaProyecto[]) => new carpetasProyectosActions.GetCarpetaProyectoActionSuccess(carpetasP)),
                        catchError(error => of(new carpetasProyectosActions.GetCarpetaProyectoActionFail(error)))
                    )
            })
        );
    
        @Effect()
    getCarpetasProyectosByIdInwork$ = this.actions$.ofType(carpetasProyectosActions.GET_CARPETASPROYECTOS_BY_ID)
        .pipe(
            switchMap((action: carpetasProyectosActions.GetCarpetaProyectoByIdAction) => {
                return this.carpetaProyectoService.getCarpetasProyectosById(action.idCarpetaP)
                    .pipe(
                        map((carpetasP: CarpetaProyecto[]) => new carpetasProyectosActions.GetCarpetaProyectoByIdActionSuccess(carpetasP)),
                        catchError(error => of(new carpetasProyectosActions.GetCarpetaProyectoByIdActionFail(error)))
                    )
            })
        );

    @Effect()
    postCarpetaProyectoInwork$ = this.actions$.ofType(carpetasProyectosActions.POST_CARPETAPROYECTO)
        .pipe(
            switchMap((action: carpetasProyectosActions.PostCarpetaProyectoAction) => {
                return this.carpetaProyectoService.postCarpetaProyecto(action.carpetaProyecto)
                    .pipe(
                        map((carpetasP: CarpetaProyecto[]) => new carpetasProyectosActions.GetCarpetaProyectoByIdActionSuccess(carpetasP)),
                        catchError(error => of(new carpetasProyectosActions.PostCarpetaProyectoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putCarpetaProyectoInwork$ = this.actions$.ofType(carpetasProyectosActions.PUT_CARPETAPROYECTO)
        .pipe(
            switchMap((action: carpetasProyectosActions.PutCarpetaProyectoAction) => {
                const cp = new CarpetaProyecto();
                cp.id = action.carpetaProyecto.id;
                cp.nombre = action.carpetaProyecto.nombre;
                cp.descripcion = action.carpetaProyecto.descripcion;
                cp.dependencia = action.carpetaProyecto.dependencia;
                cp.tipo = action.carpetaProyecto.tipo;
                cp.visible = action.carpetaProyecto.visible;
                cp.atributosPT = action.carpetaProyecto.atributosPT;
                cp.usersCompartir = action.carpetaProyecto.usersCompartir;
                cp.usersPropietario = action.carpetaProyecto.usersPropietario;
                
                return this.carpetaProyectoService.putCarpetaProyecto(cp)
                    .pipe(
                        map((carpetasP: CarpetaProyecto[]) => new carpetasProyectosActions.GetCarpetaProyectoByIdActionSuccess(carpetasP)),
                        catchError(error => of(new carpetasProyectosActions.PutCarpetaProyectoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteCarpetaProyectoInwork$ = this.actions$.ofType(carpetasProyectosActions.DELETE_CARPETAPROYECTO)
        .pipe(
            switchMap((action: carpetasProyectosActions.DeleteCarpetaProyectoAction) => {
                return this.carpetaProyectoService.deleteCarpetaProyecto(action.carpetaProyecto)
                    .pipe(
                        map((response) => new carpetasProyectosActions.DeleteCarpetaProyectoActionSUCCESS((response) ? action.carpetaProyecto : new CarpetaProyecto(0, "", "",0, "", false,null, null, null))),
                        catchError(error => of(new carpetasProyectosActions.DeleteCarpetaProyectoActionFAIL(error)))
                    )
            })
        );
};
