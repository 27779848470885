import * as fromEspecifico from '../../../actions/modulos/indicadoresSustentabilidad/especificosSus.actions';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';
import { ReporteIndicadores } from 'src/app/shared/models/Indicadores/reporte_indicadores.model';

export class EspecificoState {
    list: ObjetivoEspecifico[];
    reportIndicadores: ReporteIndicadores[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: EspecificoState = {
    list: [],
    reportIndicadores: [],
    loaded: false,
    loading: false,
    error: null
};

export function especificosReducerSus(state = initState, action: fromEspecifico.especificosActions_SUS): EspecificoState {
    switch (action.type) {
        //#region GET_ESPECIFICOS
        case fromEspecifico.GET_ESPECIFICOS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEspecifico.GET_ESPECIFICOS_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.objetivosEspecificos.map(especifico => {
                        return {
                            ...especifico
                        }
                    })
                ]
            };
        case fromEspecifico.GET_ESPECIFICOS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_REPORTE_ESPECIFICOS
        case fromEspecifico.GET_REPORTE_ESPECIFICOS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEspecifico.GET_REPORTE_ESPECIFICOS_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reportIndicadores: [
                    ...action.report.map(rep => {
                        return {
                            ...rep
                        }
                    })
                ]
            };
        case fromEspecifico.GET_REPORTE_ESPECIFICOS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_ESPECIFICOS
        case fromEspecifico.PUT_ESPECIFICO_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEspecifico.PUT_ESPECIFICO_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(especificoEditar => {
                        if (especificoEditar.id === action.editEspecifico.id) {
                            return {
                                ...especificoEditar,
                                nombre: action.editEspecifico.nombre,
                                descripcion: action.editEspecifico.descripcion,
                                indicador: action.editEspecifico.indicador,
                                tipoIndicador: action.editEspecifico.tipoIndicador,
                                frecuencia: action.editEspecifico.frecuencia,
                                formula: action.editEspecifico.formula,
                                lineaBase: action.editEspecifico.lineaBase,
                                metaProgramada: action.editEspecifico.metaProgramada,
                                responsable: action.editEspecifico.responsable,
                                entregable: action.editEspecifico.entregable,
                                unidadMedida: action.editEspecifico.unidadMedida,
                                sustancial: action.editEspecifico.sustancial,
                                area: action.editEspecifico.area,
                                fechaCreacion: action.editEspecifico.fechaCreacion,
                                usuario: action.editEspecifico.usuario,
                                observaciones: action.editEspecifico.observaciones,
                                autorizado: action.editEspecifico.autorizado,
                                programado: action.editEspecifico.programado,
                                periodo: action.editEspecifico.periodo
                            };
                        } else {
                            return especificoEditar;
                        }
                    })
                ]
            };
        case fromEspecifico.PUT_ESPECIFICO_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_STATE_ESPECIFICO
        case fromEspecifico.PUT_STATE_ESPECIFICO_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEspecifico.PUT_STATE_ESPECIFICO_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(especificoEditar => {
                        if (especificoEditar.id === action.id) {
                            return {
                                ...especificoEditar,
                                autorizado: !especificoEditar.autorizado
                            };
                        } else {
                            return especificoEditar;
                        }
                    })
                ]
            };
        //#region POST_ESPECIFICOS
        case fromEspecifico.POST_ESPECIFICO_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromEspecifico.POST_ESPECIFICO_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.newEspecifico],
                loading: false,
                loaded: true,
                error: null
            };
        case fromEspecifico.POST_ESPECIFICO_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region DELETE_ESPECIFICO
        case fromEspecifico.DELETE_ESPECIFICO_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromEspecifico.DELETE_ESPECIFICO_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(especifico => {
                    if (especifico.id != action.deleteEspecifico.id) {
                        return {
                            especifico
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromEspecifico.DELETE_ESPECIFICO_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
