export const environment = {
  production: false,
  //DEV ---------------------------
  // apiBackOffice : "http://localhost:59589/backoffice/",
  // apiBalcan: "http://localhost:62143/",
  //CDP_API: "https://localhost:7019/",
  // APIURL: 'https://localhost:7237/api/',
  // apiBalcan: "http://10.6.95.45:160/",
  // apiAlmacen: "http://10.6.95.50:168/",
  // apiMailer: "http://10.6.95.45:190/",
  // apiVacaciones: "http://10.6.95.66:160/auth/",
  //  API_URL_Vacacional : "http://localhost:55142/api/",
  // API_URL_LECTURAS: "http://localhost:5008/",
  // APICONSUMOS:"https://localhost:7011/",
  // apiFormularioTanques:"https://localhost:44303/",
  // APINOM035: "https://localhost:7004/api/guia/", 
  // APIRTN: "https://localhost:7294/",
  // PASEO_DEL_RIO: "https://localhost:7009/",

  //CALIDAD ---------------------------
  // apiBackOffice: "http://apipmyma.aguapuebla.mx/backoffice/",
  // apiBalcan: "http://pruebas-apibalcan.aguapuebla.mx/",
  // CDP_API: "http://apicontrolplazasqa.aguapuebla.mx/",
  // APIURL: 'http://apirefrendosqa.aguapuebla.mx/api/',
  // apiAlmacen: "http://10.6.95.50:168/",
  // apiMailer: "http://pruebas-apimailing.hi2o.mx/",
  // apiVacaciones: "http://pruebas-apivacacional.hi2o.mx/auth/",
  // API_URL_Vacacional: "http://APIBalcanModuloVacacional.aguapuebla.mx/api/",
  // API_URL_LECTURAS: "http://autolecturas-pruebas-api.aguapuebla.mx/",
  // APICONSUMOS: "http://pruebas-apiconsumos.aguapuebla.mx/",
  // apiFormularioTanques: "http://tanquesapi_qa.aguapuebla.mx/",
  // APINOM035: "http://apiencuestanomqa.aguapuebla.mx/api/guia/",
  // APIRTN: "http://apiregularizatunegocioqa.aguapuebla.mx/",
  // PASEO_DEL_RIO: "http://pruebas-serviciospaseodelrio.aguapuebla.mx/",
  // API_LecturaPozos: ""

  //PRODUCTIVO -------------------------
  apiBackOffice: "https://apibalcanbackoffice.aguapuebla.mx/backoffice/",
  apiBalcan: "https://apibalcan.aguapuebla.mx/",
  CDP_API: "https://controlplazas.aguapuebla.mx/",
  APIURL: 'https://apirefrendos.aguapuebla.mx/api/',
  apiAlmacen: "",
  apiMailer: "https://apimailing.aguapuebla.mx/",
  apiVacaciones: "https://apivacacional.aguapuebla.mx/auth/",
  API_URL_Vacacional: "https://proapibalcanmodulovacacional.aguapuebla.mx/api/",
  API_URL_LECTURAS: "https://apiautolecturas.aguapuebla.mx/",
  APICONSUMOS: "https://apiconsumos.aguapuebla.mx/",
  apiFormularioTanques: "https://tanquesapi.aguapuebla.mx/",
  APINOM035: "https://apiencuestanom.aguapuebla.mx/api/guia/",
  APIRTN: "https://apiregularizatunegocio.aguapuebla.mx/",
  PASEO_DEL_RIO: "https://serviciospaseodelrio.aguapuebla.mx/",
  API_LecturaPozos: ""
};