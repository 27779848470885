import * as fromDashboards from '../../../actions/modulos/indicadores/dashboards.actions';
import { LogIndicadores } from 'src/app/shared/models/Indicadores/log.model';
import { RadialBar } from 'src/app/shared/models/balcax/charts/radial-bar.model';
import { MultiBarras } from 'src/app/shared/models/balcax/charts/multi-bar.model';
import * as chartBarras from 'src/app/shared/models/balcax/charts/bar.model';
import { CardPercentage } from 'src/app/shared/models/balcax/card.model';
import { Dashboard } from 'src/app/shared/models/Indicadores/dashboard.model';

export class DashboardsState {
    dashboard: Dashboard;
    listCardsActivities: CardPercentage[];
    resultAvanceEspecifico: RadialBar;
    resultAvanceIndicadores: chartBarras.Barras;
    resultAvanceEstandares: chartBarras.Barras;
    detallesAvanceIndicadores: chartBarras.Grouped;
    listAvancePorArea: RadialBar[];
    listRecentActivities: LogIndicadores[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: DashboardsState = {
    dashboard: null,
    listCardsActivities: [],
    resultAvanceEspecifico: null,
    resultAvanceIndicadores: null,
    resultAvanceEstandares: null,
    detallesAvanceIndicadores: null,
    listAvancePorArea:[],
    listRecentActivities:[],
    loaded: false,
    loading: false,
    error: null
};

export function dashboardsReducer(state = initState, action: fromDashboards.dashboardctions): DashboardsState {
    switch (action.type) {
        //#region GET_DASHBOARD
        case fromDashboards.GET_DASHBOARD:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromDashboards.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                dashboard: action.dashboard,
            };
        case fromDashboards.GET_DASHBOARD_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region GET_CARDS_ACTIVITIES
    case fromDashboards.GET_CARD_COUNT_ACTIVITIES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_CARD_COUNT_ACTIVITIES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            listCardsActivities: [
                ...action.cardsActivities.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromDashboards.GET_CARD_COUNT_ACTIVITIES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion

        //#region GET_AVANCE_ESPECIFICO
    case fromDashboards.GET_AVANCE_ESPECIFICO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_AVANCE_ESPECIFICO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            resultAvanceEspecifico: action.avanceEspecifico
        };
    case fromDashboards.GET_AVANCE_ESPECIFICO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion

        //#region GET_AVANCE_INDICADORES
    case fromDashboards.GET_AVANCE_INDICADORES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_AVANCE_INDICADORES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            resultAvanceIndicadores: action.avancesIndicadores
        };
    case fromDashboards.GET_AVANCE_INDICADORES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion

        //#region GET_AVANCE_ESTANDARES
    case fromDashboards.GET_AVANCE_ESTANDARES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_AVANCE_ESTANDARES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            resultAvanceEstandares: action.avancesEstandares
        };
    case fromDashboards.GET_AVANCE_ESTANDARES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion

        //#region GET_DETALLE_AVANCE_INDICADORES
    case fromDashboards.GET_DETALLE_AVANCE_INDICADORES:
        return {
            ...state,
            loading: true,
            error: null,
            detallesAvanceIndicadores: null
        };
    case fromDashboards.GET_DETALLE_AVANCE_INDICADORES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            detallesAvanceIndicadores: action.detailsAvancesIndicadores
        };
    case fromDashboards.GET_DETALLE_AVANCE_INDICADORES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    
        //#region GET_AVANCE_POR_AREA
    case fromDashboards.GET_AVANCE_POR_AREA:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_AVANCE_POR_AREA_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            listAvancePorArea: [
                 ...action.avancesArea.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromDashboards.GET_AVANCE_POR_AREA_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    
        //#region GET_RECENTS_ACTIVITIES
    case fromDashboards.GET_RECENT_ACTIVITIES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromDashboards.GET_RECENT_ACTIVITIES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            listRecentActivities: [
                 ...action.recentActivities.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromDashboards.GET_RECENT_ACTIVITIES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
        default:
            return state;
    }
}
