import { Action } from "@ngrx/store";

export const GET_CONFIG = '[CONFIG] Get User Configurations';
export const GET_CONFIG_FAIL = '[CONFIG] Get User Configurations FAIL';
export const GET_CONFIG_SUCCESS = '[CONFIG] Get User Configurations SUCCESS';

export class GetPerfilIni implements Action {
    readonly type = GET_CONFIG;
    constructor(public IdUsuario: number, public IdPerfil: number) { }
}
export class GetPerfilIniSuccess implements Action {
    readonly type = GET_CONFIG_SUCCESS;
    constructor(public payload: any) { }
}

export class GetPerfilIniFailure implements Action {
    readonly type = GET_CONFIG_FAIL;
    constructor(public payload: any) { }
}

export type perfilIniAcciones =
    | GetPerfilIni
    | GetPerfilIniSuccess
    | GetPerfilIniFailure;