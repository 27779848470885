import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as especificosActions from '../../../actions/modulos/indicadoresSustentabilidad/especificosSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';
import { ObjEspecificoSusService } from 'src/app/services/indicadoresSustentabilidad/obj_espSus.service';

@Injectable()

export class especificosSusEffects {
    constructor(
        private actions$: Actions,
        private especificosService: ObjEspecificoSusService,
    ) { }

    @Effect()
    cargarEspecificos$ = this.actions$.ofType(especificosActions.GET_ESPECIFICOS_SUS)
        .pipe(
            switchMap((action: especificosActions.GetEspecificosAction_SUS) => {
                return this.especificosService.getObjetivosEspecificos(action.idArea, action.periodo)
                    .pipe(
                        map((response: any) => new especificosActions.GetEspecificosActionSuccess_SUS(response)),
                        catchError(error => of(new especificosActions.GetEspecificosActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarReporte$ = this.actions$.ofType(especificosActions.GET_REPORTE_ESPECIFICOS_SUS)
        .pipe(
            switchMap((action: especificosActions.GetReporteEspecificosAction_SUS) => {
                return this.especificosService.getReporteIndicadores(action.criteria)
                    .pipe(
                        map((response: any) => new especificosActions.GetReporteEspecificosActionSuccess_SUS(response)),
                        catchError(error => of(new especificosActions.GetReporteEspecificosActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeEditEspecifico$ = this.actions$.ofType(especificosActions.PUT_ESPECIFICO_SUS)
        .pipe(
            switchMap((action: especificosActions.PutEspecificoAction_SUS) => {
                const especifico = new ObjetivoEspecifico();
                especifico.id = action.especifico.id;
                especifico.sustancial = action.especifico.sustancial;
                especifico.nombre = action.especifico.nombre;
                especifico.descripcion = action.especifico.descripcion;
                especifico.indicador = action.especifico.indicador;
                especifico.tipoIndicador = action.especifico.tipoIndicador;
                especifico.frecuencia = action.especifico.frecuencia;
                especifico.formula = action.especifico.formula;
                especifico.lineaBase = action.especifico.lineaBase;
                especifico.metaProgramada = action.especifico.metaProgramada;
                especifico.responsable = action.especifico.responsable;
                especifico.entregable = action.especifico.entregable;
                especifico.unidadMedida = action.especifico.unidadMedida;
                especifico.observaciones = action.especifico.observaciones;
                especifico.periodo = action.especifico.periodo;
                return this.especificosService.putObjetivoEspecifico(especifico)
                    .pipe(
                        map((response: any) => new especificosActions.PutEspecificoActionSuccess_SUS(response)),
                        catchError(error => of(new especificosActions.PutEspecificoActionFail_SUS(error)))
                    )
            })
        );
    @Effect()
    changeValidadoEspecifico$ = this.actions$.ofType(especificosActions.PUT_STATE_ESPECIFICO_SUS)
        .pipe(
            switchMap((action: especificosActions.PutStateEspecificoAction_SUS) => {
                const especifico = new ObjetivoEspecifico();
                especifico.id = action.id;
                return this.especificosService.putEspecificoValidado(especifico)
                    .pipe(
                        map(() => new especificosActions.PutStateEspecificoActionSuccess_SUS(especifico.id)),
                        catchError(error => of(new especificosActions.PutStateEspecificoActionFail_SUS(error)))
                    )
            })
        );
    @Effect()
    postEspecifico$ = this.actions$.ofType(especificosActions.POST_ESPECIFICO_SUS)
        .pipe(
            switchMap((action: especificosActions.PostEspecificoAction_SUS) => {
                return this.especificosService.postObjetivoEspecifico(action.especifico)
                    .pipe(
                        map((especifico: ObjetivoEspecifico) => new especificosActions.PostEspecificoActionSUCCESS_SUS(especifico)),
                        catchError(error => of(new especificosActions.PostEspecificoActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    deleteEspecifico$ = this.actions$.ofType(especificosActions.DELETE_ESPECIFICO_SUS)
        .pipe(
            switchMap((action: especificosActions.DeleteEspecificoAction_SUS) => {
                return this.especificosService.deleteEspecifico(action.especifico)
                    .pipe(
                        map(() => new especificosActions.DeleteEspecificoActionSUCCESS_SUS(action.especifico)),
                        catchError(error => of(new especificosActions.DeleteEspecificoActionFAIL_SUS(error)))
                    )
            })
        );
};
