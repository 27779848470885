import { Action } from '@ngrx/store';
import { MesConfiguracion } from 'src/app/shared/models/Indicadores/mes_configuracion.model';

export const GET_MES_CONFIGURACION_SUS = '[MES_CONFIGURACION_SUS] Get Mes Configuracion_SUS';
export const GET_MES_CONFIGURACION_SUS_FAIL = '[MES_CONFIGURACION_SUS] Get Mes Configuracion_SUS FAIL';
export const GET_MES_CONFIGURACION_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Get Mes Configuracion_SUS SUCCESS';

export const PUT_MES_CONFIGURACION_SUS = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS';
export const PUT_MES_CONFIGURACION_SUS_FAIL = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS FAIL';
export const PUT_MES_CONFIGURACION_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS SUCCESS';

export const PUT_MES_CONFIGURACION_PROGRAMADO_SUS = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS Programado';
export const PUT_MES_CONFIGURACION_PROGRAMADO_SUS_FAIL = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS Programado FAIL';
export const PUT_MES_CONFIGURACION_PROGRAMADO_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Put Mes Configuracion_SUS Programado SUCCESS';

export const POST_MES_CONFIGURACION_SUS = '[MES_CONFIGURACION_SUS] Post Mes Configuracion_SUS';
export const POST_MES_CONFIGURACION_SUS_FAIL = '[MES_CONFIGURACION_SUS] Post Mes Configuracion_SUS FAIL';
export const POST_MES_CONFIGURACION_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Post Mes Configuracion_SUS SUCCESS';

export const DELETE_MES_CONFIGURACION_SUS = '[MES_CONFIGURACION_SUS] Delete Mes Configuracion_SUS';
export const DELETE_MES_CONFIGURACION_SUS_FAIL = '[MES_CONFIGURACION_SUS] Delete Mes Configuracion_SUS FAIL';
export const DELETE_MES_CONFIGURACION_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Delete Mes Configuracion_SUS SUCCESS';

//#region GET_MES_CONFIGURACION
export class GetMesConfiguracionAction_SUS implements Action {
    readonly type = GET_MES_CONFIGURACION_SUS;
    constructor(public idEspecifico: number) { }
};

export class GetMesConfiguracionActionFail_SUS implements Action {
    readonly type = GET_MES_CONFIGURACION_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetMesConfiguracionActionSuccess_SUS implements Action {
    readonly type = GET_MES_CONFIGURACION_SUS_SUCCESS;
    constructor(public programa_trabajos: MesConfiguracion[]) { }
};
//#endregion
//#region PUT_MES_CONFIGURACION
export class PutMesConfiguracionAction_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_SUS;
    constructor(public mesesConfig: MesConfiguracion) { }
};

export class PutMesConfiguracionActionFail_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutMesConfiguracionActionSuccess_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_SUS_SUCCESS;
    constructor(public editMesesConfig: MesConfiguracion) { }
};
//#endregion

//#region PUT_MES_CONFIGURACION_PROGRAMADO
export class PutMesConfiguracionProgramadoAction_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO_SUS;
    constructor(public mesesConfig: MesConfiguracion[]) { }
};

export class PutMesConfiguracionProgramadoActionFail_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutMesConfiguracionProgramadoActionSuccess_SUS implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO_SUS_SUCCESS;
    constructor(public editMesesConfig: MesConfiguracion[]) { }
};
//#endregion

//#region  POST_MES_CONFIGURACION
export class PostMesConfiguracionAction_SUS implements Action {
    readonly type = POST_MES_CONFIGURACION_SUS;
    constructor(public mesesConfig: MesConfiguracion[]) { }
};
export class PostMesconfiguracionActionFAIL_SUS implements Action {
    readonly type = POST_MES_CONFIGURACION_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostMesConfiguracionActionSUCCESS_SUS implements Action {
    readonly type = POST_MES_CONFIGURACION_SUS_SUCCESS;
    constructor(public newMesesConfig: MesConfiguracion[]) { }
};
//#endregion

//#region  DELETE_MES_CONFIGURACION
export class DeleteMesConfiguracionAction_SUS implements Action {
    readonly type = DELETE_MES_CONFIGURACION_SUS;
    constructor(public mesConfig: MesConfiguracion) { }
};
export class DeleteMesConfiguracionActionFAIL_SUS implements Action {
    readonly type = DELETE_MES_CONFIGURACION_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteMesConfiguracionActionSUCCESS_SUS implements Action {
    readonly type = DELETE_MES_CONFIGURACION_SUS_SUCCESS;
    constructor(public deleteMesConfig: MesConfiguracion) { }
};
//#endregion

export type mesConfiguracionActions_SUS =
    GetMesConfiguracionAction_SUS |
    GetMesConfiguracionActionFail_SUS |
    GetMesConfiguracionActionSuccess_SUS |

    PostMesConfiguracionAction_SUS |
    PostMesconfiguracionActionFAIL_SUS |
    PostMesConfiguracionActionSUCCESS_SUS |

    PutMesConfiguracionAction_SUS |
    PutMesConfiguracionActionFail_SUS |
    PutMesConfiguracionActionSuccess_SUS |

    PutMesConfiguracionProgramadoAction_SUS |
    PutMesConfiguracionProgramadoActionFail_SUS |
    PutMesConfiguracionProgramadoActionSuccess_SUS |

    DeleteMesConfiguracionAction_SUS |
    DeleteMesConfiguracionActionFAIL_SUS |
    DeleteMesConfiguracionActionSUCCESS_SUS;