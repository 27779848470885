import * as fromFrecuencia from '../../../actions/modulos/indicadoresSustentabilidad/frecuenciasSus.actions';
import { Frecuencia } from 'src/app/shared/models/Indicadores/frecuencia.model';

export class FrecuenciaState {
    list: Frecuencia[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: FrecuenciaState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function frecuenciasReducerSus(state = initState, action: fromFrecuencia.frecuenciasActions_SUS): FrecuenciaState {
    switch (action.type) {
    //#region GET_FRECUENCIAS
    case fromFrecuencia.GET_FRECUENCIAS_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFrecuencia.GET_FRECUENCIAS_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                 ...action.frecuencias.map(frecuencia => {
                    return {
                        ...frecuencia
                    }
                })
            ]
        };
    case fromFrecuencia.GET_FRECUENCIAS_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_FRECUENCIA
    case fromFrecuencia.POST_FRECUENCIA_SUS:
        return {
            ...state,
        };
    case fromFrecuencia.POST_FRECUENCIA_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [...state.list, action.newFrecuencia],
        };
    case fromFrecuencia.POST_FRECUENCIA_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_FRECUENCIA
    case fromFrecuencia.PUT_FRECUENCIA_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFrecuencia.PUT_FRECUENCIA_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(editFrecuencia => {
                    if (editFrecuencia.id === action.updateFrecuencia.id) {
                        return {
                            ...editFrecuencia,
                            descripcion: action.updateFrecuencia.descripcion
                        };
                    } else {
                        return editFrecuencia;
                    }
                })
            ]
        };
    case fromFrecuencia.PUT_FRECUENCIA_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_FRECUENCIA
    case fromFrecuencia.DELETE_FRECUENCIA_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFrecuencia.DELETE_FRECUENCIA_SUS_SUCCESS:
        return {
            ...state,
            list: [...state.list.filter(frecuencia => {
                if (frecuencia.id != action.deleteFrecuencia.id) {
                    return {
                        frecuencia
                    };
                }
            })],
            loading: false,
            loaded: true,
            error: null
        };
    case fromFrecuencia.DELETE_FRECUENCIA_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    default:
        return state;
    }
}
