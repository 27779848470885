import { Action } from '@ngrx/store';
import { CodigoMicroMedicion, CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';

export const PUT_CATALOGO = '[ALMACEN] Put Catalogo';
export const PUT_CATALOGO_FAIL = '[ALMACEN] Put Catalogo FAIL';
export const PUT_CATALOGO_SUCCESS = '[ALMACEN] Put Catalogo SUCCESS';

export const PUT_PRODUCTO = '[PUNTO VENTA] Put Producto';
export const PUT_PRODUCTO_FAIL = '[PUNTO VENTA] Put Producto FAIL';
export const PUT_PRODUCTO_SUCCESS = '[PUNTO VENTA] Put Producto SUCCESS';

export const GET_CATALOGO = '[ALMACEN] Get Catalogo';
export const GET_CATALOGO_FAIL = '[ALMACEN] Get Catalogo FAIL';
export const GET_CATALOGO_SUCCESS = '[ALMACEN] Get Catalogo SUCCESS';

export const GET_CATALOGO_VENTA = '[PUNTO VENTA] Get Catalogo Venta';
export const GET_CATALOGO_VENTA_FAIL = '[PUNTO VENTA] Get Catalogo Venta FAIL';
export const GET_CATALOGO_VENTA_SUCCESS = '[PUNTO VENTA] Get Catalogo Venta SUCCESS';

//#region GET_CATALOGO
export class GetCatalogoAction implements Action {
    readonly type = GET_CATALOGO;
};

export class GetCatalogoActionFail implements Action {
    readonly type = GET_CATALOGO_FAIL;
    constructor(public payload: any) { }
};

export class GetCatalogoActionSuccess implements Action {
    readonly type = GET_CATALOGO_SUCCESS;
    constructor(public codigos: CodigoMicroMedicion[]) { }
};
//#endregion

//#region GET_CATALOGO
export class GetCatalogoVentaAction implements Action {
    readonly type = GET_CATALOGO_VENTA;
};

export class GetCatalogoVentaActionFail implements Action {
    readonly type = GET_CATALOGO_VENTA_FAIL;
    constructor(public payload: any) { }
};

export class GetCatalogoVentaActionSuccess implements Action {
    readonly type = GET_CATALOGO_VENTA_SUCCESS;
    constructor(public codigos: CodigoPVMicroMedicion[]) { }
};
//#endregion

//#region PUT_CATALOGO
export class PutCatalogoAction implements Action {
    readonly type = PUT_CATALOGO;
};

export class PutCatalogoActionFail implements Action {
    readonly type = PUT_CATALOGO_FAIL;
    constructor(public payload: any) { }
};

export class PutCatalogoActionSuccess implements Action {
    readonly type = PUT_CATALOGO_SUCCESS;
    constructor(public codigos: CodigoMicroMedicion[]) { }
};
//#endregion

//#region PUT_PRODUCTO
export class PutProductoAction implements Action {
    readonly type = PUT_PRODUCTO;
    constructor(public producto: CodigoPVMicroMedicion) { }
};

export class PutProductoActionFail implements Action {
    readonly type = PUT_PRODUCTO_FAIL;
    constructor(public payload: any) { }
};

export class PutProductoActionSuccess implements Action {
    readonly type = PUT_PRODUCTO_SUCCESS;
    constructor(public producto: CodigoPVMicroMedicion) { }
};
//#endregion

export type catalogoAlmacenActions =
    GetCatalogoAction |
    GetCatalogoActionFail |
    GetCatalogoActionSuccess |

    GetCatalogoVentaAction |
    GetCatalogoVentaActionFail |
    GetCatalogoVentaActionSuccess |

    PutCatalogoAction |
    PutCatalogoActionFail |
    PutCatalogoActionSuccess |

    PutProductoAction |
    PutProductoActionFail |
    PutProductoActionSuccess;