import * as fromActions from '../../../actions/modulos/indicadores/index';
import { FrecuenciaState, frecuenciasReducer } from './frecuencias.reducer';
import { TiposIndicadorState, tipoIndicadoresReducer } from './tipos_indicadores.reducer';
import { PeriodosState, periodosReducer } from './periodos.reducer';
import * as fromCatalogos from '../../../actions/modulos/indicadores/catalogos.actions';
import { estandaresReducer, EstandaresState } from './estandares.reducer';

export class CatalogosState {
    frecuencias: FrecuenciaState;
    tipos_indicadores: TiposIndicadorState;
    periodos: PeriodosState;
    estandares: EstandaresState;
};

export const initState: CatalogosState = {
    frecuencias: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    tipos_indicadores: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    periodos: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    estandares: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
};

export function catalogosReducer(state = initState, action: fromCatalogos.catalogosActions): CatalogosState {
    if (action.type == fromActions.GET_FRECUENCIAS || 
        action.type == fromActions.GET_FRECUENCIAS_FAIL ||
        action.type == fromActions.GET_FRECUENCIAS_SUCCESS ||
        action.type == fromActions.POST_FRECUENCIA ||
        action.type == fromActions.POST_FRECUENCIA_FAIL ||
        action.type == fromActions.POST_FRECUENCIA_SUCCESS ||
        action.type == fromActions.PUT_FRECUENCIA ||
        action.type == fromActions.PUT_FRECUENCIA_FAIL ||
        action.type == fromActions.PUT_FRECUENCIA_SUCCESS ||
        action.type == fromActions.DELETE_FRECUENCIA ||
        action.type == fromActions.DELETE_FRECUENCIA_FAIL ||
        action.type == fromActions.DELETE_FRECUENCIA_SUCCESS) {
            return {
                ...state,
                frecuencias: frecuenciasReducer(state.frecuencias, action)
            };
    }
    else if(action.type == fromActions.GET_TIPOS_INDICADOR ||
            action.type == fromActions.GET_TIPOS_INDICADOR_FAIL ||
            action.type == fromActions.GET_TIPOS_INDICADOR_SUCCESS ||
            action.type == fromActions.POST_TIPO_INDICADOR ||
            action.type == fromActions.POST_TIPO_INDICADOR_FAIL ||
            action.type == fromActions.POST_TIPO_INDICADOR_SUCCESS ||
            action.type == fromActions.PUT_TIPO_INDICADOR ||
            action.type == fromActions.PUT_TIPO_INDICADOR_FAIL ||
            action.type == fromActions.PUT_TIPO_INDICADOR_SUCCESS ||
            action.type == fromActions.DELETE_TIPO_INDICADOR ||
            action.type == fromActions.DELETE_TIPO_INDICADOR_FAIL ||
            action.type == fromActions.DELETE_TIPO_INDICADOR_SUCCESS){
            return {
                ...state,
                tipos_indicadores: tipoIndicadoresReducer(state.tipos_indicadores, action)
            };    
    }
    else if(action.type == fromActions.GET_PERIODOS ||
            action.type == fromActions.GET_PERIODOS_FAIL ||
            action.type == fromActions.GET_PERIODOS_SUCCESS ||
            action.type == fromActions.POST_PERIODO ||
            action.type == fromActions.POST_PERIODO_FAIL ||
            action.type == fromActions.POST_PERIODO_SUCCESS ||
            action.type == fromActions.PUT_PERIODO ||
            action.type == fromActions.PUT_PERIODO_FAIL ||
            action.type == fromActions.PUT_PERIODO_SUCCESS ||
            action.type == fromActions.DELETE_PERIODO ||
            action.type == fromActions.DELETE_PERIODO_FAIL ||
            action.type == fromActions.DELETE_PERIODO_SUCCESS){
            return {
                ...state,
                periodos: periodosReducer(state.periodos, action)
            };
    }
    else if(action.type == fromActions.GET_ESTANDARES ||
            action.type == fromActions.GET_ESTANDARES_FAIL ||
            action.type == fromActions.GET_ESTANDARES_SUCCESS ||
            action.type == fromActions.POST_ESTANDAR ||
            action.type == fromActions.POST_ESTANDAR_FAIL ||
            action.type == fromActions.POST_ESTANDAR_SUCCESS ||
            action.type == fromActions.PUT_ESTANDAR ||
            action.type == fromActions.PUT_ESTANDAR_FAIL ||
            action.type == fromActions.PUT_ESTANDAR_SUCCESS || 
            action.type == fromActions.DELETE_ESTANDAR ||
            action.type == fromActions.DELETE_ESTANDAR_FAIL ||
            action.type == fromActions.DELETE_ESTANDAR_SUCCESS){
            return {
                ...state,
                estandares: estandaresReducer(state.estandares, action)
            };
    }

    return state;
}