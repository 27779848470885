import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { Frecuencia } from 'src/app/shared/models/Indicadores/frecuencia.model';
import { ExceptionsService } from '../exceptions.service';
import { CatalogoServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class FrecuenciasService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getFrecuencias() {
    return this.http.get(`${CatalogoServURL}/getFrecuencias`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postFrecuencia(frecuencia: Frecuencia) {
    return this.http.post(`${CatalogoServURL}/postFrecuencia`, { data: frecuencia })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putFrecuencia(frecuencia: Frecuencia) {
    return this.http.post(`${CatalogoServURL}/putFrecuencia`, frecuencia)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteFrecuencia(frecuencia: Frecuencia) {
    return this.http.get(`${CatalogoServURL}/deleteFrecuencia?idFrecuencia=${frecuencia.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

}