import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { of } from 'rxjs';
import { ExceptionsService } from '../exceptions.service';
import { DashServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getDashboard(idArea: number, id: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getDash?idArea=${idArea}&idEspEst=${id}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el total de Actividades Programadas, Ejecutadas y un Porcentaje de avance que se lleva de ello
  getCardsCountActivities(idArea: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getCardsActivities?idArea=${idArea}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el avance general por objetivo especifico
  getAvanceEspecifico(idEspecifico: number, periodo: string) {
    return this.http.get(`${DashServURL}/getProgressEspecify?idEspecifico=${idEspecifico}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el avance total por objetivo estrategico
  getAvanceIndicadores(id: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getProgressIndicators?id=${id}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el avance total por estandar de desempeño
  getAvanceEstandares(idArea: number, idEstEsp: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getProgressEstandars?idArea=${idArea}&idEstEsp=${idEstEsp}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //obtiene el detalle de avance desplegado por mes
  getDetalleAvanceIndicadores(id: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getDetailProgressIndicators?id=${id}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el log de las actividades recientes por area
  getAvancesPorArea(idArea: number, id: number, tipoFiltro: string, periodo: string) {
    return this.http.get(`${DashServURL}/getProgressAreas?idArea=${idArea}&id=${id}&tipoFiltro=${tipoFiltro}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }

  //Obtiene el log de las actividades recientes por area
  getRecentsActivities(idArea: number, periodo: string) {
    return this.http.get(`${DashServURL}/getRecentsActivities?idArea=${idArea}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response)
      );
  }
}