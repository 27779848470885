import * as fromGruposUserInwork from '../../../actions/modulos/inwork/grupos_user.actions';
import { GruposUsuarios } from 'src/app/shared/models/Inwork/grupos.model';

export class GruposUserInworkState {
    list: GruposUsuarios[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: GruposUserInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function gruposUserInworkReducer(state = initState, action: fromGruposUserInwork.gruposUsuarioInworkActions): GruposUserInworkState {
    switch (action.type) {
    //#region PUT_GRUPOS_USER
    case fromGruposUserInwork.PUT_GRUPOS_USER:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromGruposUserInwork.PUT_GRUPOS_USER_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_GRUPOS_USER
    case fromGruposUserInwork.POST_GRUPOS_USER:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromGruposUserInwork.POST_GRUPOS_USER_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_GRUPOS_USER
    case fromGruposUserInwork.DELETE_GRUPOS_USER:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromGruposUserInwork.DELETE_GRUPOS_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
