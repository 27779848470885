import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';
import { ExceptionsService } from '../exceptions.service';
import { CatalogoServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class TipoIndicadoresService {

  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getTipoIndicadores() {
    return this.http.get(`${CatalogoServURL}/getTipoIndicadores`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postTipoIndicador(tipo_indicador: TipoIndicador) {
    return this.http.post(`${CatalogoServURL}/postTipoIndicador`, { data: tipo_indicador })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putTipoIndicador(tipo_indicador: TipoIndicador) {
    return this.http.post(`${CatalogoServURL}/putTipoIndicador`, tipo_indicador)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteTipoIndicador(tipo_indicador: TipoIndicador) {
    return this.http.get(`${CatalogoServURL}/deleteTipoIndicador?idTipoIndicador=${tipo_indicador.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorEliminacion(error)
        ))
      );
  }

}