import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as camposPTActions from '../../../actions/modulos/inwork/camposPT.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { CamposService } from 'src/app/services/inwork/campos.service';
import { Campos } from 'src/app/shared/models/Inwork/catalogos.model';

@Injectable()

export class camposPTInworkEffects {
    constructor(
        private actions$: Actions,
        private camposPTService: CamposService,
    ) { }

    @Effect()
    getCamposPTInwork$ = this.actions$.ofType(camposPTActions.GET_CAMPOS_PT)
        .pipe(
            switchMap((action: camposPTActions.GetCamposPTAction) => {
                return this.camposPTService.getCamposPT(action.IdAtributosPT)
                    .pipe(
                        map((campos: Campos[]) => new camposPTActions.GetCamposPTActionSuccess(campos)),
                        catchError(error => of(new camposPTActions.GetCamposPTActionFail(error)))
                    )
            })
        );

    @Effect()
    postCampoPTInwork$ = this.actions$.ofType(camposPTActions.POST_CAMPO_PT)
        .pipe(
            switchMap((action: camposPTActions.PostCampoPTAction) => {
                return this.camposPTService.postCampoPT(action.campo)
                    .pipe(
                        map((newCampo: Campos) => new camposPTActions.PostCampoPTActionSUCCESS(newCampo)),
                        catchError(error => of(new camposPTActions.PostCampoPTActionFAIL(error)))
                    )
            })
        );
        
    @Effect()
    putCampoPTInwork$ = this.actions$.ofType(camposPTActions.PUT_CAMPO_PT)
        .pipe(
            switchMap((action: camposPTActions.PutCampoPTAction) => {
                return this.camposPTService.putCampoPT(action.campo)
                    .pipe(
                        map((updateCampo: Campos) => new camposPTActions.PutCampoPTActionSUCCESS(updateCampo)),
                        catchError(error => of(new camposPTActions.PutCampoPTActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteCampoPTInwork$ = this.actions$.ofType(camposPTActions.DELETE_CAMPO_PT)
        .pipe(
            switchMap((action: camposPTActions.DeleteCampoPTAction) => {
                return this.camposPTService.deleteCampoPT(action.campo)
                    .pipe(
                        map((response) => new camposPTActions.DeleteCampoPTActionSUCCESS((response) ? action.campo : new Campos(0, 0, 0))),
                        catchError(error => of(new camposPTActions.DeleteCampoPTActionFAIL(error)))
                    )
            })
        );
};
