import { Injectable } from '@angular/core';
import { HttpClientService } from '../implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { Usuario } from '../../shared/models/balcax/usuario.model';
import { Password } from 'src/app/shared/models/balcax/password.model';
import { HttpClient as Http, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Empresa } from 'src/app/shared/models/balcax/empresa.model';
import { Suscripcion } from 'src/app/shared/models/balcax/suscripcion.model';
import { ExceptionsService } from '../exceptions.service';
import { of } from 'rxjs';
import { Permisos } from 'src/app/shared/models/balcax/permisos.model';
import { AuthServURL, MailerServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private API_URL = environment.API_URL_Vacacional;
  private user: Usuario;
  private company: Empresa;
  private suscripcion: Suscripcion;

  IdUsuario: number;
  Usuario: any;

  constructor(
    private http: HttpClientService,
    private httpd: Http,
    private exceptionsService: ExceptionsService
  ) { }

  login(credential: Usuario) {
    return this.http.post(`${AuthServURL}/login`, credential)
      .pipe(
        map(response => {
          if (response) return response;
          this.exceptionsService._credencialesIncorrectas();
        }),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  forgotUser(credencial: any) {
    return this.http.get(`${AuthServURL}/getUserByData?usuario=${credencial.correo}`)
      .pipe(map((res: any) => {
        if (res && res.usuario.correo !== undefined || res.usuario.correo !== null) {
          const dataMailing = {
            sendTo: res.usuario.correo,
            sendMesage: {
              nameuser: res.usuario.nombre,
              username: res.usuario.correo,
              password: res.usuario.contrasena,
              //urlplatform: 'http://balcan.hi2o.mx/'
              urlplatform: 'https://balcax.aguapuebla.mx/login'
            },
            sendPlatform: 'BALCAX'
          };
          return dataMailing;
        }
        return true;
      }));
  }

  resetPasswordMailing(dataMailing: any) {
    return this.httpd.post(`${MailerServURL}mail/reset/balcax`, dataMailing);
  }

  //Metodo alternativo de recuperacion de contraseña
  forgotPass(credencial: any) {
    return this.http.get(`${AuthServURL}/getUserByData?usuario=${credencial.correo}`)
      .pipe(map((res: any) => {
        if (res && res.usuario.correo !== undefined || res.usuario.correo !== null) {
          const dataMailing = res.usuario.correo
          return dataMailing;
        }
        return true;
      }));
  }

  //Metodo alternativo de recuperacion de contraseña del apiModuloVacacional
  recuperaPass(correo: string){
    return this.httpd.get(`${this.API_URL}Mailing/recuperaPass/`+correo);
  }

  getUser() {
    return this.http.get(`${AuthServURL}/getUser`)
      .pipe(
        map((response: any) => {
          this.user = response.usuario;
          this.company = response.empresa;
          this.suscripcion = response.suscripcion;
          return response;
        }),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  getPermisos(pagina: string) {
    if (this.user.permisos) {
      let permisos = this.user.permisos;
      let acceso = JSON.parse(permisos).find(x => x.RouterLink == pagina);
      if (acceso)
        return acceso;
      else
        return null;
    }
    else
      return null;
  }

  getPermisosPage(pagina: string): Permisos {
    if (this.user.permisos) {
      let permisos = this.user.permisos;
      let acceso = JSON.parse(permisos).find(x => x.RouterLink == pagina);
      if (acceso) {
        return new Permisos(
          acceso.Actualizar,
          acceso.Agregar,
          acceso.Autorizar,
          acceso.Borrar,
          acceso.Consultar
        );
      }
      else
        return null;
    }
    else
      return null;
  }

  putPassword(data: Password) {
    return this.http.post(`${AuthServURL}/updatePassword`, { data: data })
      .pipe(
        map((response: any) => {
          if (response == 200)
            this.exceptionsService._toast('success', 'Completado', 'Credenciales actualizadas.');
          else
            this.exceptionsService._toast('error', 'Error', 'Contraseña Incorrecta.');
        }),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  logoff() {
    this.http.get(`${AuthServURL}/logout`)
      .subscribe({
        complete: () => {
          localStorage.clear();
          location.href = '/login';
        }
      });
  }

  get userAuth() {
    return this.user;
  }

  get empresaAuth() {
    return this.company;
  }

  get suscripcionAuth() {
    return this.suscripcion;
  }
}
