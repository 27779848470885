import { loginEffects } from './balcax/login.effects';
import { authEffects } from './balcax/auth.effects';
import { usersEffects } from './balcax/users.effects';

// INDICADORES
import { dashboardsEffects } from './modulos/indicadores/dashboards.effects';
import { areasEffects } from './modulos/indicadores/areas.effects';
import { areasUsuarioEffects } from './modulos/indicadores/areasusuario.effects';
import { especificosEffects } from './modulos/indicadores/especificos.effects';
import { estrategiasEffects } from './modulos/indicadores/estrategias.effects';
import { estrategicosEffects } from './modulos/indicadores/estrategicos.effects';
import { frecuenciasEffects } from './modulos/indicadores/frecuencias.effects';
import { tipoIndicadoresEffects } from './modulos/indicadores/tipos_indicadores.effects';
import { mesConfiguracionEffects } from './modulos/indicadores/mesconfiguracion.effects';
import { configProgramaTrabajoEffects } from './modulos/indicadores/config_programatrabajo.effects';
import { periodosEffects } from './modulos/indicadores/periodos.effects';
import { estandaresEffects } from './modulos/indicadores/estandares.effects';

//! INDICADORES SUSTENTABILIDAD
import { dashboardsSusEffects } from './modulos/indicadoresSustentabilidad/dashboardsSus.effects';
import { areasSusEffects } from './modulos/indicadoresSustentabilidad/areasSus.effects';
import { areasUsuarioSusEffects } from './modulos/indicadoresSustentabilidad/areasusuarioSus.effects';
import { especificosSusEffects } from './modulos/indicadoresSustentabilidad/especificosSus.effects';
import { estrategiasSusEffects } from './modulos/indicadoresSustentabilidad/estrategiasSus.effects';
import { estrategicosSusEffects } from './modulos/indicadoresSustentabilidad/estrategicosSus.effects';
import { frecuenciasSusEffects } from './modulos/indicadoresSustentabilidad/frecuenciasSus.effects';
import { tipoIndicadoresSusEffects } from './modulos/indicadoresSustentabilidad/tipos_indicadoresSus.effects';
import { mesConfiguracionSusEffects } from './modulos/indicadoresSustentabilidad/mesconfiguracionSus.effects';
import { configProgramaTrabajoSusEffects } from './modulos/indicadoresSustentabilidad/config_programatrabajoSus.effects';
import { periodosSusEffects } from './modulos/indicadoresSustentabilidad/periodosSus.effects';
import { estandaresSusEffects } from './modulos/indicadoresSustentabilidad/estandaresSus.effects';

// ALMACEN
import { catalogoEffects } from './modulos/microMedicion/catalogo.effects';
import { medidoresAlmacenEffects } from './modulos/microMedicion/medidoresAlmacen.effects';
import { ubicacionEffects } from './modulos/microMedicion/ubicacion.effects';
import { proteyectoEffects } from './modulos/microMedicion/proyecto.effects';
import { lotesEffects } from './modulos/microMedicion/lotes.effects';
import { estadosEffects } from './modulos/microMedicion/estado.effects';
import { proveedorEffects } from './modulos/microMedicion/proveedor.effects';
import { logMedidorEffects } from './modulos/microMedicion/logMedidor.effects';

// PUNTO DE VENTA
import { corteCaja_PV_Effects } from './modulos/punto-venta/corteCaja_PV.effects';

// INWORK
import { catalogosInworkEffects } from './modulos/inwork/catalogos.effects';
import { itemsCatalogoInworkEffects } from './modulos/inwork/items_catalogo.effects';
import { gruposInworkEffects } from './modulos/inwork/grupos.effects';
import { rolesInworkEffects } from './modulos/inwork/roles.effects';
import { rolesUsuariosInworkEffects } from './modulos/inwork/roles_usuario.effects';
import { flujoTrabajoInworkEffects } from './modulos/inwork/flujo_trabajo.effects';
import { carpetasProyectosInworkEffects } from './modulos/inwork/carpetas_proyectos.effects';
import { gruposUserInworkEffects } from './modulos/inwork/grupos_user.effects';
import { tareasInworkEffects } from './modulos/inwork/tareas.effects';
import { actualizacionesInworkEffects } from './modulos/inwork/actualizaciones.effects';

//CAMPAÑAS
import { universoEffects } from './modulos/campañas/universo.effects';
import { camposPTInworkEffects } from './modulos/inwork/camposPT.effects';
import { archivosInworkEffects } from './modulos/inwork/archivos.effects';

//ACUAPUE
import { perfilInicEffects } from './modulos/acuapue/perfilInicio.effects';

//MODULO VACACIONAL
import { incidenciasEffects } from './modulos/incidencias/incidencias.effects';

export const effectsArray: any[] = [
    loginEffects,
    authEffects,
    usersEffects,

    // INDICADORES
    dashboardsEffects,
    areasEffects,
    areasUsuarioEffects,
    especificosEffects,
    estrategiasEffects,
    estrategicosEffects,
    frecuenciasEffects,
    tipoIndicadoresEffects,
    mesConfiguracionEffects,
    configProgramaTrabajoEffects,
    periodosEffects,
    estandaresEffects,

    //! INDICADORES SUSTENTABILIDAD
    dashboardsSusEffects,
    areasSusEffects,
    areasUsuarioSusEffects,
    especificosSusEffects,
    estrategiasSusEffects,
    estrategicosSusEffects,
    frecuenciasSusEffects,
    tipoIndicadoresSusEffects,
    mesConfiguracionSusEffects,
    configProgramaTrabajoSusEffects,
    periodosSusEffects,
    estandaresSusEffects,

    // ALMACEN
    catalogoEffects,
    medidoresAlmacenEffects,
    ubicacionEffects,
    proteyectoEffects,
    lotesEffects,
    estadosEffects,
    proveedorEffects,
    logMedidorEffects,

    // PUNTO DE VENTA
    corteCaja_PV_Effects,

    //INWORK
    catalogosInworkEffects,
    itemsCatalogoInworkEffects,
    gruposInworkEffects,
    rolesInworkEffects,
    rolesUsuariosInworkEffects,
    flujoTrabajoInworkEffects,
    carpetasProyectosInworkEffects,
    gruposUserInworkEffects,
    tareasInworkEffects,
    camposPTInworkEffects,
    actualizacionesInworkEffects,
    archivosInworkEffects,
    //CAMPAÑAS
    universoEffects,

    //ACUAPUE
    perfilInicEffects,

    //Modulo Vacacional
    incidenciasEffects,
];

export * from './balcax/login.effects';
export * from './balcax/auth.effects';
export * from './balcax/users.effects';

//INDICADORES
export * from './modulos/indicadores/dashboards.effects';
export * from './modulos/indicadores/areas.effects';
export * from './modulos/indicadores/areasusuario.effects';
export * from './modulos/indicadores/especificos.effects';
export * from './modulos/indicadores/estrategias.effects';
export * from './modulos/indicadores/estrategicos.effects';
export * from './modulos/indicadores/frecuencias.effects';
export * from './modulos/indicadores/tipos_indicadores.effects';
export * from './modulos/indicadores/mesconfiguracion.effects';
export * from './modulos/indicadores/config_programatrabajo.effects';
export * from './modulos/indicadores/periodos.effects';
export * from './modulos/indicadores/estandares.effects';

//! INDICADORES SUSTENTABILIDAD
export * from './modulos/indicadoresSustentabilidad/dashboardsSus.effects';
export * from './modulos/indicadoresSustentabilidad/areasSus.effects';
export * from './modulos/indicadoresSustentabilidad/areasusuarioSus.effects';
export * from './modulos/indicadoresSustentabilidad/especificosSus.effects';
export * from './modulos/indicadoresSustentabilidad/estrategiasSus.effects';
export * from './modulos/indicadoresSustentabilidad/estrategicosSus.effects';
export * from './modulos/indicadoresSustentabilidad/frecuenciasSus.effects';
export * from './modulos/indicadoresSustentabilidad/tipos_indicadoresSus.effects';
export * from './modulos/indicadoresSustentabilidad/mesconfiguracionSus.effects';
export * from './modulos/indicadoresSustentabilidad/config_programatrabajoSus.effects';
export * from './modulos/indicadoresSustentabilidad/periodosSus.effects';
export * from './modulos/indicadoresSustentabilidad/estandaresSus.effects';

// ALMACEN
export * from './modulos/microMedicion/catalogo.effects';
export * from './modulos/microMedicion/medidoresAlmacen.effects';
export * from './modulos/microMedicion/ubicacion.effects';
export * from './modulos/microMedicion/proyecto.effects';
export * from './modulos/microMedicion/lotes.effects';
export * from './modulos/microMedicion/estado.effects';
export * from './modulos/microMedicion/proveedor.effects';
export * from './modulos/microMedicion/logMedidor.effects';

//PUNTO DE VENTA
export * from './modulos/punto-venta/corteCaja_PV.effects';

//INWORK
export * from './modulos/inwork/catalogos.effects';
export * from './modulos/inwork/items_catalogo.effects';
export * from './modulos/inwork/grupos.effects';
export * from './modulos/inwork/roles.effects';
export * from './modulos/inwork/roles_usuario.effects';
export * from './modulos/inwork/flujo_trabajo.effects';
export * from './modulos/inwork/carpetas_proyectos.effects';
export * from './modulos/inwork/grupos_user.effects';
export * from './modulos/inwork/tareas.effects';
export * from './modulos/inwork/camposPT.effects';
export * from './modulos/inwork/actualizaciones.effects';
export * from './modulos/inwork/archivos.effects';

//CAMPAÑAS
export * from './modulos/campañas/universo.effects';

//ACAPUE
export * from './modulos/acuapue/perfilInicio.effects';

//MODULO VACACIONAL
export * from './modulos/incidencias/incidencias.effects';
