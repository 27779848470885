import * as fromTareasInwork from '../../../actions/modulos/inwork/tareas.actions';
import { Tareas } from 'src/app/shared/models/Inwork/tareas.model';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';

export class TareasInworkState {
    list_by_cp: Tareas[];
    list_by_dep: Tareas[];
    list_shared_by: Usuario[];
    loaded_by_cp: boolean;
    loading_by_cp: boolean;
    loaded_by_dep: boolean;
    loading_by_dep: boolean;
    loaded_shared_by: boolean;
    loading_shared_by: boolean;
    error: any;
};

export const initState: TareasInworkState = {
    list_by_cp: [],
    list_by_dep: [],
    list_shared_by: [],
    loaded_by_cp: false,
    loading_by_cp: false,
    loaded_by_dep: false,
    loading_by_dep: false,
    loaded_shared_by: false,
    loading_shared_by: false,
    error: null
};

export function tareasInworkReducer(state = initState, action: fromTareasInwork.tareasInworkActions): TareasInworkState {
    switch (action.type) {
    //#region GET_TAREAS_BY_CP
    case fromTareasInwork.GET_TAREAS_BY_CP:
        return {
            ...state,
            loading_by_cp: true,
            error: null
        };
    case fromTareasInwork.GET_TAREAS_BY_CP_SUCCESS:
        return {
            ...state,
            loading_by_cp: false,
            loaded_by_cp: true,
            list_by_cp: [
                ...action.tareas.map(tarea => {
                    return {
                        ...tarea
                    }
                })
            ]
        };
    case fromTareasInwork.GET_TAREAS_BY_CP_FAIL:
        return {
            ...state,
            loaded_by_cp: false,
            loading_by_cp: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region GET_TAREAS_BY_DEP
    case fromTareasInwork.GET_TAREAS_BY_DEP:
        return {
            ...state,
            loading_by_dep: true,
            error: null
        };
    case fromTareasInwork.GET_TAREAS_BY_DEP_SUCCESS:
        return {
            ...state,
            loading_by_dep: false,
            loaded_by_dep: true,
            list_by_dep: [
                ...action.tareas.map(tarea => {
                    return {
                        ...tarea
                    }
                })
            ]
        };
    case fromTareasInwork.GET_TAREAS_BY_DEP_FAIL:
        return {
            ...state,
            loaded_by_dep: false,
            loading_by_dep: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region GET_TASK_SHARED_BY
    case fromTareasInwork.GET_SHARED_BY:
        return {
            ...state,
            loading_shared_by: true,
            error: null
        };
    case fromTareasInwork.GET_SHARED_BY_SUCCESS:
        return {
            ...state,
            loading_shared_by: false,
            loaded_shared_by: true,
            list_shared_by: [
                ...action.users.map(user => {
                    return {
                        ...user
                    }
                })
            ]
        };
    case fromTareasInwork.GET_SHARED_BY_FAIL:
        return {
            ...state,
            loaded_shared_by: false,
            loading_shared_by: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_TAREA
    case fromTareasInwork.PUT_TAREA:
        return {
            ...state,
            loading_by_cp: true,
            loading_by_dep: true,
            error: null
        };
    case fromTareasInwork.PUT_TAREA_SUCCESS:
        return {
            ...state,
            loading_by_cp: false,
            loading_by_dep: false,
            loaded_by_cp: true,
            loaded_by_dep: true,
            list_by_cp: [
                ...state.list_by_cp.map(tarea => {
                    if (tarea.id === action.updateTarea.id) {
                        return {
                            ...tarea,
                            nombre: action.updateTarea.nombre,
                            descripcion: action.updateTarea.descripcion,
                            dependencia: action.updateTarea.dependencia,
                            generadaPor: action.updateTarea.generadaPor,
                            registrado: action.updateTarea.registrado,
                            visible: action.updateTarea.visible,
                            archivada: action.updateTarea.archivada,
                            favorita: action.updateTarea.favorita,
                            idAtributosPT: action.updateTarea.idAtributosPT,
                            idCarpetaProyecto: action.updateTarea.idCarpetaProyecto,
                            atributosPT: action.updateTarea.atributosPT,
                            carpetaProyecto: action.updateTarea.carpetaProyecto
                        };
                    } else {
                        return tarea;
                    }
                })
            ],
            list_by_dep: [
                ...state.list_by_dep.map(tarea => {
                    if (tarea.id === action.updateTarea.id) {
                        return {
                            ...tarea,
                            nombre: action.updateTarea.nombre,
                            descripcion: action.updateTarea.descripcion,
                            dependencia: action.updateTarea.dependencia,
                            generadaPor: action.updateTarea.generadaPor,
                            registrado: action.updateTarea.registrado,
                            visible: action.updateTarea.visible,
                            archivada: action.updateTarea.archivada,
                            favorita: action.updateTarea.favorita,
                            idAtributosPT: action.updateTarea.idAtributosPT,
                            idCarpetaProyecto: action.updateTarea.idCarpetaProyecto,
                            atributosPT: action.updateTarea.atributosPT,
                            carpetaProyecto: action.updateTarea.carpetaProyecto
                        };
                    } else {
                        return tarea;
                    }
                })
            ]
        };
    case fromTareasInwork.PUT_TAREA_FAIL:
        return {
            ...state,
            loaded_by_cp: false,
            loaded_by_dep: false,
            loading_by_cp: false,
            loading_by_dep: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_TAREA
    case fromTareasInwork.POST_TAREA:
        return {
            ...state,
            loading_by_cp: true,
            loading_by_dep: true,
            error: null
        }
    case fromTareasInwork.POST_TAREA_SUCCESS:
        return {
            ...state,
            list_by_cp: [...state.list_by_cp, action.newTarea],
            list_by_dep: [...state.list_by_dep, action.newTarea],
            loading_by_cp: false,
            loading_by_dep: false,
            loaded_by_cp: true,
            loaded_by_dep: true,
            error: null            
        };
    case fromTareasInwork.POST_TAREA_FAIL:
        return {
            ...state,
            loaded_by_cp: false,
            loaded_by_dep: false,
            loading_by_cp: false,
            loading_by_dep: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_TAREA
    case fromTareasInwork.DELETE_TAREA:
            return {
                ...state,
                loading_by_cp: true,
                loading_by_dep: true,
                error: null
            }
    case fromTareasInwork.DELETE_TAREA_SUCCESS:
            return {
                ...state,
                list_by_cp: [...state.list_by_cp.filter(tarea => {
                    if (tarea.id != action.deleteTarea.id) {
                        return {
                            tarea
                        };
                    }
                })],
                list_by_dep: [...state.list_by_dep.filter(tarea => {
                    if (tarea.id != action.deleteTarea.id) {
                        return {
                            tarea
                        };
                    }
                })],
                loading_by_cp: false,
                loading_by_dep: false,
                loaded_by_cp: true,
                loaded_by_dep: true,
                error: null
            };
    case fromTareasInwork.DELETE_TAREA_FAIL:
            return {
                ...state,
                loaded_by_cp: false,
                loaded_by_dep: false,
                loading_by_cp: false,
                loading_by_dep: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
