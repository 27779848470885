import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as estrategiasActions from '../../../actions/modulos/indicadoresSustentabilidad/estrategiasSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { Estrategia } from 'src/app/shared/models/Indicadores/estrategia.model';
import { EstrategiasSusService } from 'src/app/services/indicadoresSustentabilidad/estrategiasSus.service';

@Injectable()

export class estrategiasSusEffects {
    constructor(
        private actions$: Actions,
        private estrategiasService: EstrategiasSusService,
    ) { }

        @Effect()
        cargarEstrategias$ = this.actions$.ofType(estrategiasActions.GET_ESTRATEGIAS_SUS)
            .pipe(
                switchMap((action: estrategiasActions.GetEstrategiasAction_SUS) => {
                    return this.estrategiasService.getEstrategias(action.idEspecifico)
                        .pipe(
                            map((estrategias: Estrategia[]) => new estrategiasActions.GetEstrategiasActionSuccess_SUS(estrategias)),
                            catchError(error => of(new estrategiasActions.GetEstrategiasActionFail_SUS(error)))
                        )
                })
            );
        @Effect()
        changeEditEstrategia$ = this.actions$.ofType(estrategiasActions.PUT_ESTRATEGIAS_SUS)
            .pipe(
                switchMap((action: estrategiasActions.PutEstrategiasAction_SUS) => {
                    const estrategia = new Estrategia();
                    estrategia.id = action.estrategia.id;
                    estrategia.nombre = action.estrategia.nombre;
                    estrategia.descripcion = action.estrategia.descripcion;
                    estrategia.objetivo_especifico = action.estrategia.objetivo_especifico;

                    return this.estrategiasService.putEstrategia(estrategia)
                        .pipe(
                            map(() => new estrategiasActions.PutEstrategiasActionSuccess_SUS(estrategia)),
                            catchError(error => of(new estrategiasActions.PutEstrategiasActionFail_SUS(error)))
                        )
                })
            );

        @Effect()
        postEstrategia$ = this.actions$.ofType(estrategiasActions.POST_ESTRATEGIA_SUS)
            .pipe(
                switchMap((action: estrategiasActions.PostEstrategiaAction_SUS) => {
                    return this.estrategiasService.postEstrategia(action.estrategia)
                        .pipe(
                            map((estrategia: Estrategia) => new estrategiasActions.PostEstrategiaActionSUCCESS_SUS(estrategia)),
                            catchError(error => of(new estrategiasActions.PostEstrategiaActionFAIL_SUS(error)))
                        )
                })
            );

        @Effect()
        deleteEstrategia$ = this.actions$.ofType(estrategiasActions.DELETE_ESTRATEGIA_SUS)
            .pipe(
                switchMap((action: estrategiasActions.DeleteEstrategiaAction_SUS) => {
                    return this.estrategiasService.deleteEstrategia(action.estrategia)
                        .pipe(
                            map(() => new estrategiasActions.DeleteEstrategiaActionSUCCESS_SUS(action.estrategia)),
                            catchError(error => of(new estrategiasActions.DeleteEstrategiaActionFAIL_SUS(error)))
                        )
                })
            );
};
