import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';
import { of } from 'rxjs';
import { ExceptionsService } from '../exceptions.service';
import { BusinessServURL_SUS } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class AreasSusService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getAreas() {
    return this.http.get(`${BusinessServURL_SUS}/getBusiness`)
      .pipe(
        map((response: any) => {

          return response;
        }),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putArea(area_empresa: AreaEmpresa) {
    return this.http.post(`${BusinessServURL_SUS}/putBusiness`, { data: area_empresa })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putAreaPermiso(area_empresa: AreaEmpresa) {
    return this.http.post(`${BusinessServURL_SUS}/putBusinessPerms`, area_empresa)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postArea(area_empresa: AreaEmpresa) {
    return this.http.post(`${BusinessServURL_SUS}/postBusiness`, { data: area_empresa })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteArea(area_empresa: AreaEmpresa) {
    return this.http.get(`${BusinessServURL_SUS}/deleteBusiness?idArea=${area_empresa.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}