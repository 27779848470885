import { Action } from '@ngrx/store';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';

export const GET_PERIODOS = '[PERIODOS] Get Periodos';
export const GET_PERIODOS_FAIL = '[PERIODOS] Get Periodos FAIL';
export const GET_PERIODOS_SUCCESS = '[PERIODOS] Get Periodos SUCCESS';

export const POST_PERIODO = '[PERIODOS] Post Periodo';
export const POST_PERIODO_FAIL = '[PERIODOS] Post Periodo FAIL';
export const POST_PERIODO_SUCCESS = '[PERIODOS] Post Periodo SUCCESS';

export const PUT_PERIODO = '[PERIODO] Put Periodo';
export const PUT_PERIODO_FAIL = '[PERIODO] Put Periodo FAIL';
export const PUT_PERIODO_SUCCESS = '[PERIODO] Put Periodo SUCCESS';

export const DELETE_PERIODO = '[DELETE_PERIODO] Delete Periodo';
export const DELETE_PERIODO_FAIL = '[DELETE_PERIODO] Delete Periodo FAIL';
export const DELETE_PERIODO_SUCCESS = '[DELETE_PERIODO] Delete Periodo SUCCESS';

//#region GET_PERIODOS
export class GetPeriodosAction implements Action {
    readonly type = GET_PERIODOS;
    constructor() { }
};

export class GetPeriodosActionFail implements Action {
    readonly type = GET_PERIODOS_FAIL;
    constructor(public payload: any) { }
};

export class GetPeriodosActionSuccess implements Action {
    readonly type = GET_PERIODOS_SUCCESS;
    constructor(public periodos: Periodos[]) { }
};
//#endregion

//#region  POST_PERIODO
export class PostPeriodoAction implements Action {
    readonly type = POST_PERIODO;
    constructor(public periodo: Periodos) { }
};
export class PostPeriodoActionFAIL implements Action {
    readonly type = POST_PERIODO_FAIL;
    constructor(public payload: any) { }
};
export class PostPeriodoActionSUCCESS implements Action {
    readonly type = POST_PERIODO_SUCCESS;
    constructor(public newPeriodo: Periodos) { }
};
//#endregion

//#region  PUT_PERIODO
export class PutPeriodoAction implements Action {
    readonly type = PUT_PERIODO;
    constructor(public periodo: Periodos) { }
};
export class PutPeriodoActionFAIL implements Action {
    readonly type = PUT_PERIODO_FAIL;
    constructor(public payload: any) { }
};
export class PutPeriodoActionSUCCESS implements Action {
    readonly type = PUT_PERIODO_SUCCESS;
    constructor(public updatePeriodo: Periodos) { }
};
//#endregion

//#region  DELETE_PERIODO
export class DeletePeriodoAction implements Action {
    readonly type = DELETE_PERIODO;
    constructor(public periodo: Periodos) { }
};
export class DeletePeriodoActionFAIL implements Action {
    readonly type = DELETE_PERIODO_FAIL;
    constructor(public payload: any) { }
};
export class DeletePeriodoActionSUCCESS implements Action {
    readonly type = DELETE_PERIODO_SUCCESS
    constructor(public deletePeriodo: Periodos) { }
};
//#endregion

export type periodosActions =
GetPeriodosAction |
GetPeriodosActionFail |
GetPeriodosActionSuccess |

PostPeriodoAction |
PostPeriodoActionFAIL |
PostPeriodoActionSUCCESS |

PutPeriodoAction |
PutPeriodoActionFAIL |
PutPeriodoActionSUCCESS |

DeletePeriodoAction |
DeletePeriodoActionFAIL |
DeletePeriodoActionSUCCESS;