import * as fromGruposInwork from '../../../actions/modulos/inwork/grupos.actions';
import { Grupos } from 'src/app/shared/models/Inwork/grupos.model';

export class GruposInworkState {
    list: Grupos[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: GruposInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function gruposInworkReducer(state = initState, action: fromGruposInwork.gruposInworkActions): GruposInworkState {
    switch (action.type) {
    //#region GET_GRUPOS
    case fromGruposInwork.GET_GRUPOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromGruposInwork.GET_GRUPOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.grupos.map(grupos => {
                    return {
                        ...grupos
                    }
                })
            ]
        };
    case fromGruposInwork.GET_GRUPOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_GRUPO
    case fromGruposInwork.PUT_GRUPO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromGruposInwork.PUT_GRUPO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(grupo => {
                    if (grupo.id === action.updateGrupo.id) {
                        return {
                            ...grupo,
                            nombre: action.updateGrupo.nombre,
                            descripcion: action.updateGrupo.descripcion,
                        };
                    } else {
                        return grupo;
                    }
                })
            ]
        };
    case fromGruposInwork.PUT_GRUPO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_GRUPO
    case fromGruposInwork.POST_GRUPO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromGruposInwork.POST_GRUPO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newGrupo],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromGruposInwork.POST_GRUPO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_GRUPO
    case fromGruposInwork.DELETE_GRUPO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromGruposInwork.DELETE_GRUPO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(grupo => {
                    if (grupo.id != action.deleteGrupo.id) {
                        return {
                            grupo
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromGruposInwork.DELETE_GRUPO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
