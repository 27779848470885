import { Action } from '@ngrx/store';
import { FlujosTrabajo } from 'src/app/shared/models/Inwork/flujo_trabajo.model';
import { DataFlujoTrabajo } from 'src/app/shared/models/Inwork/models_globales.model';

//FLUJO_TRABAJO
export const GET_FLUJOS_TRABAJO = '[INWORK] Get Flujo Trabajo Inwork';
export const GET_FLUJOS_TRABAJO_FAIL = '[INWORK] Get Flujo Trabajo Inwork FAIL';
export const GET_FLUJOS_TRABAJO_SUCCESS = '[INWORK] Get Flujo Trabajo Inwork SUCCESS';

export const GET_FLUJOS = '[INWORK] Get Flujos Inwork';
export const GET_FLUJOS_FAIL = '[INWORK] Get Flujos Inwork FAIL';
export const GET_FLUJOS_SUCCESS = '[INWORK] Get Flujos Inwork SUCCESS';

export const POST_FLUJO_TRABAJO = '[INWORK] Post Flujo Trabajo Inwork';
export const POST_FLUJO_TRABAJO_FAIL = '[INWORK] Post Flujo Trabajo Inwork FAIL';
export const POST_FLUJO_TRABAJO_SUCCESS = '[INWORK] Post Flujo Trabajo Inwork SUCCESS';

export const PUT_FLUJO_TRABAJO = '[INWORK] Put Flujo Trabajo Inwork';
export const PUT_FLUJO_TRABAJO_FAIL = '[INWORK] Put Flujo Trabajo Inwork FAIL';
export const PUT_FLUJO_TRABAJO_SUCCESS = '[INWORK] Put Flujo Trabajo Inwork SUCCESS';

export const DELETE_FLUJO_TRABAJO = '[INWORK] Delete Flujo Trabajo Inwork';
export const DELETE_FLUJO_TRABAJO_FAIL = '[INWORK] Delete Flujo Trabajo Inwork FAIL';
export const DELETE_FLUJO_TRABAJO_SUCCESS = '[INWORK] Delete Flujo Trabajo Inwork SUCCESS';

//#region GET_FLUJOS_TRABAJO
export class GetFlujosTrabajoAction implements Action {
    readonly type = GET_FLUJOS_TRABAJO;
};

export class GetFlujosTrabajoActionFail implements Action {
    readonly type = GET_FLUJOS_TRABAJO_FAIL;
    constructor(public payload: any) { }
};

export class GetFlujosTrabajoActionSuccess implements Action {
    readonly type = GET_FLUJOS_TRABAJO_SUCCESS;
    constructor(public flujosTrabajo: DataFlujoTrabajo[]) { }
};
//#endregion

//#region GET_FLUJOS
export class GetFlujosAction implements Action {
    readonly type = GET_FLUJOS;
};

export class GetFlujosActionFail implements Action {
    readonly type = GET_FLUJOS_FAIL;
    constructor(public payload: any) { }
};

export class GetFlujosActionSuccess implements Action {
    readonly type = GET_FLUJOS_SUCCESS;
    constructor(public flujosTrabajo: FlujosTrabajo[]) { }
};
//#endregion

//#region  POST_FLUJO_TRABAJO
export class PostFlujoTrabajoAction implements Action {
    readonly type = POST_FLUJO_TRABAJO;
    constructor(public flujoTrabajo: DataFlujoTrabajo) { }
};
export class PostFlujoTrabajoActionFAIL implements Action {
    readonly type = POST_FLUJO_TRABAJO_FAIL;
    constructor(public payload: any) { }
};
export class PostFlujoTrabajoActionSUCCESS implements Action {
    readonly type = POST_FLUJO_TRABAJO_SUCCESS;
    constructor(public newFlujoTrabajo: DataFlujoTrabajo) { }
};
//#endregion

//#region  PUT_FLUJO_TRABAJO
export class PutFlujoTrabajoAction implements Action {
    readonly type = PUT_FLUJO_TRABAJO;
    constructor(public flujoTrabajo: DataFlujoTrabajo) { }
};
export class PutFlujoTrabajoActionFAIL implements Action {
    readonly type = PUT_FLUJO_TRABAJO_FAIL;
    constructor(public payload: any) { }
};
export class PutFlujoTrabajoActionSUCCESS implements Action {
    readonly type = PUT_FLUJO_TRABAJO_SUCCESS;
    constructor(public updateFlujoTrabajo: DataFlujoTrabajo) { }
};
//#endregion

//#region  DELETE_FLUJO_TRABAJO
export class DeleteFlujoTrabajoAction implements Action {
    readonly type = DELETE_FLUJO_TRABAJO;
    constructor(public flujoTrabajo: DataFlujoTrabajo) { }
};
export class DeleteFlujoTrabajoActionFAIL implements Action {
    readonly type = DELETE_FLUJO_TRABAJO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteFlujoTrabajoActionSUCCESS implements Action {
    readonly type = DELETE_FLUJO_TRABAJO_SUCCESS;
    constructor(public deleteFlujoTrabajo: DataFlujoTrabajo) { }
};
//#endregion

export type flujoTrabajoInworkActions =
GetFlujosTrabajoAction |
GetFlujosTrabajoActionFail |
GetFlujosTrabajoActionSuccess |

GetFlujosAction |
GetFlujosActionFail |
GetFlujosActionSuccess |

PostFlujoTrabajoAction |
PostFlujoTrabajoActionFAIL |
PostFlujoTrabajoActionSUCCESS |

PutFlujoTrabajoAction |
PutFlujoTrabajoActionFAIL |
PutFlujoTrabajoActionSUCCESS |

DeleteFlujoTrabajoAction |
DeleteFlujoTrabajoActionFAIL |
DeleteFlujoTrabajoActionSUCCESS;
