import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { DashboardsService } from 'src/app/services/indicadores/dashboards.service';
import * as dashboardActions from '../../../actions/modulos/indicadores/dashboards.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()

export class dashboardsEffects {
    constructor(
        private actions$: Actions,
        private dashboardService: DashboardsService,
    ) { }

    @Effect()
    cargarDashboard$ = this.actions$.ofType(dashboardActions.GET_DASHBOARD)
        .pipe(
            switchMap((action: dashboardActions.GetDashboardAction) => {
                return this.dashboardService.getDashboard(action.idArea, action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetDashboardActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetDashboardActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarCardsActivities$ = this.actions$.ofType(dashboardActions.GET_CARD_COUNT_ACTIVITIES)
        .pipe(
            switchMap((action: dashboardActions.GetCardCountActivitiesAction) => {
                return this.dashboardService.getCardsCountActivities(action.idArea, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetCardCountActivitiesActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetCardCountActivitiesActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceEspecifico$ = this.actions$.ofType(dashboardActions.GET_AVANCE_ESPECIFICO)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceEspecificoAction) => {
                return this.dashboardService.getAvanceEspecifico(action.idEspecifico, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceEspecificoActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetAvanceEspecificoActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceIndicadores$ = this.actions$.ofType(dashboardActions.GET_AVANCE_INDICADORES)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceIndicadoresAction) => {
                return this.dashboardService.getAvanceIndicadores(action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceIndicadoresActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetAvanceIndicadoresActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceEstandares$ = this.actions$.ofType(dashboardActions.GET_AVANCE_ESTANDARES)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceEstandaresAction) => {
                return this.dashboardService.getAvanceEstandares(action.idArea, action.idEstEsp, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceEstandaresActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetAvanceEstandaresActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarDetallesAvanceIndicadores$ = this.actions$.ofType(dashboardActions.GET_DETALLE_AVANCE_INDICADORES)
        .pipe(
            switchMap((action: dashboardActions.GetDetalleAvanceIndicadoresAction) => {
                return this.dashboardService.getDetalleAvanceIndicadores(action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetDetalleAvanceIndicadoresActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetDetalleAvanceIndicadoresActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarAvancePorArea$ = this.actions$.ofType(dashboardActions.GET_AVANCE_POR_AREA)
        .pipe(
            switchMap((action: dashboardActions.GetAvancePorAreaAction) => {
                return this.dashboardService.getAvancesPorArea(action.idArea, action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvancePorAreaActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetAvancePorAreaActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarRecentActivities$ = this.actions$.ofType(dashboardActions.GET_RECENT_ACTIVITIES)
        .pipe(
            switchMap((action: dashboardActions.GetRecentActivitiesAction) => {
                return this.dashboardService.getRecentsActivities(action.idArea, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetRecentActivitiesActionSuccess(response)),
                        catchError(error => of(new dashboardActions.GetRecentActivitiesActionFail(error)))
                    )
            })
        );
};