import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';
import { ProgramWorkServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class ConfigProgramaTrabajoService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getConfiguracionPrograma(idEstrategico) {
    return this.http.get(`${ProgramWorkServURL}/getProgramWork?idEstrategico=${idEstrategico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  getConfiguracionAvance(idEstrategico) {
    return this.http.get(`${ProgramWorkServURL}/getConfigAdv?idEstrategico=${idEstrategico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putConfiguracionPrograma(configPrograma: ConfiguracionPrograma) {
    return this.http.post(`${ProgramWorkServURL}/putProgramWork`, configPrograma)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putConfiguracionAvance(configAvance: ConfiguracionAvance[]) {
    return this.http.post(`${ProgramWorkServURL}/putConfigAdv`, { data: configAvance })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postConfiguracionPrograma(configPrograma: ConfiguracionPrograma) {
    return this.http.post(`${ProgramWorkServURL}/postProgramWork`, { data: configPrograma })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postConfiguracionAvance(configAvance: ConfiguracionAvance[]) {
    return this.http.post(`${ProgramWorkServURL}/postConfigAdv`, { data: configAvance })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteConfiguracionPrograma(configPrograma: ConfiguracionPrograma) {
    return this.http.get(`${ProgramWorkServURL}/deleteProgramWork?idEstrategico=${configPrograma.idObjetivoEstrategico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteConfiguracionAvance(configAvance: ConfiguracionAvance) {
    return this.http.get(`${ProgramWorkServURL}/deleteConfigAdv?idEstrategico=${configAvance.idEstrategico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}