import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as gruposActions from '../../../actions/modulos/inwork/grupos.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { GruposService } from 'src/app/services/inwork/grupos.service';
import { Grupos } from 'src/app/shared/models/Inwork/grupos.model';

@Injectable()

export class gruposInworkEffects {
    constructor(
        private actions$: Actions,
        private gruposService: GruposService,
    ) { }

    @Effect()
    getGruposInwork$ = this.actions$.ofType(gruposActions.GET_GRUPOS)
        .pipe(
            switchMap(() => {
                return this.gruposService.getGrupos()
                    .pipe(
                        map((grupos: Grupos[]) => new gruposActions.GetGruposActionSuccess(grupos)),
                        catchError(error => of(new gruposActions.GetGruposActionFail(error)))
                    )
            })
        );

    @Effect()
    postGrupoInwork$ = this.actions$.ofType(gruposActions.POST_GRUPO)
        .pipe(
            switchMap((action: gruposActions.PostGrupoAction) => {
                return this.gruposService.postGrupo(action.grupo)
                    .pipe(
                        map((newGrupo: Grupos) => new gruposActions.PostGrupoActionSUCCESS(newGrupo)),
                        catchError(error => of(new gruposActions.PostGrupoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putGrupoInwork$ = this.actions$.ofType(gruposActions.PUT_GRUPO)
        .pipe(
            switchMap((action: gruposActions.PutGrupoAction) => {
                const group = new Grupos();
                group.id = action.grupo.id;
                group.nombre = action.grupo.nombre;
                group.descripcion = action.grupo.descripcion;
                
                return this.gruposService.putGrupo(group)
                    .pipe(
                        map((updateGrupo) => new gruposActions.PutGrupoActionSUCCESS(updateGrupo)),
                        catchError(error => of(new gruposActions.PutGrupoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteGrupoInwork$ = this.actions$.ofType(gruposActions.DELETE_GRUPO)
        .pipe(
            switchMap((action: gruposActions.DeleteGrupoAction) => {
                return this.gruposService.deleteGrupo(action.grupo)
                    .pipe(
                        map((response) => new gruposActions.DeleteGrupoActionSUCCESS((response) ? action.grupo : new Grupos(0, "", ""))),
                        catchError(error => of(new gruposActions.DeleteGrupoActionFAIL(error)))
                    )
            })
        );
};
