export class Catalogos{
    constructor(
        public id?: number,
        public nombre?: string,
        public descripcion?: string
    ){}
}

export class ItemsCatalogo{
    constructor(
        public id?: number,
        public item?: string,
        public catalogo?: string
    ){}
}

export class Campos{
    constructor(
        public id?: number,
        public idAtributosPT?: number,
        public idItemCatalogo?: number,
        public itemCatalogo?: ItemsCatalogo
    ){}
}