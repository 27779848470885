import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AltaMedidores } from 'src/app/shared/models/microMedicion/altaMedidoresAlmacen.model';
import { ExceptionsService } from '../../exceptions.service';
import { VariablesService } from '../../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { ResponseAltaMedidores } from 'src/app/shared/models/microMedicion/responseAltaMedidores.model';

@Injectable({
    providedIn: 'root'
})
export class MedidoresAlmacenService {

    medidores: any[] = [];

    constructor(
        private http: HttpClientService,
        private variablesService: VariablesService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region  GET
    getMedidoresAlmacen() {
        return this.http.get(`${environment.apiAlmacen}almacen/getWarehouseMeters`)
            .pipe(
                map((response: any) => {
                    this.medidores = response;
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion

    //#region POST
    postMedidores(data: AltaMedidores) {
        return this.http.post(`${environment.apiAlmacen}almacen/postMeters`, { data })
            .pipe(
                map((response: ResponseAltaMedidores) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion

    //#region PUT
    putDañados(_comentario: string, _medidores: string) {
        return this.http.post(`${environment.apiAlmacen}stateMeter/damaged`, { _comentario, _medidores })
            .pipe(
                map((response: any) => {
                    this.variablesService.toast.next(new Toast('success', 'Completado.', 'Cambiaron de estado a Dañados.', 2000));
                    return {
                        correctos: response.listCorrectos,
                        estado: response.estado
                    }
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    putAsignados(_medidores: string, _idProveedor: number) {
        return this.http.post(`${environment.apiAlmacen}stateMeter/assign`, { _medidores, _idProveedor })
            .pipe(
                map((response: any) => {
                    this.variablesService.toast.next(new Toast('success', 'Completado.', 'Cambiaron de estado a Asignados.', 2000));
                    return {
                        correctos: response.listCorrectos,
                        estado: response.estado
                    }
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion
}