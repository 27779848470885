import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as estandaresActions from '../../../actions/modulos/indicadoresSustentabilidad/estandaresSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';
import { EstandaresSusService } from 'src/app/services/indicadoresSustentabilidad/estandaresSus.service';

@Injectable()

export class estandaresSusEffects {
    constructor(
        private actions$: Actions,
        private estandarService: EstandaresSusService,
    ) { }

    @Effect()
    cargarEstandares$ = this.actions$.ofType(estandaresActions.GET_ESTANDARES_SUS)
        .pipe(
            switchMap(() => {
                return this.estandarService.getEstandares()
                    .pipe(
                        map((estandares: Estandares[]) => new estandaresActions.GetEstandaresActionSuccess_SUS(estandares)),
                        catchError(error => of(new estandaresActions.GetEstandaresActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postEstandar$ = this.actions$.ofType(estandaresActions.POST_ESTANDAR_SUS)
        .pipe(
            switchMap((action: estandaresActions.PostEstandarAction_SUS) => {
                return this.estandarService.postEstandar(action.estandar)
                    .pipe(
                        map((estandar: Estandares) => new estandaresActions.PostEstandarActionSUCCESS_SUS(estandar)),
                        catchError(error => of(new estandaresActions.PostEstandarActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    editEstandar$ = this.actions$.ofType(estandaresActions.PUT_ESTANDAR_SUS)
        .pipe(
            switchMap((action: estandaresActions.PutEstandarAction_SUS) => {
                const estandar = new Estandares();
                estandar.id = action.estandar.id;
                estandar.descripcion = action.estandar.descripcion;
                
                return this.estandarService.putEstandar(estandar)
                    .pipe(
                        map(() => new estandaresActions.PutEstandarActionSUCCESS_SUS(estandar)),
                        catchError(error => of(new estandaresActions.PutEstandarActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    deleteEstandar$ = this.actions$.ofType(estandaresActions.DELETE_ESTANDAR_SUS)
        .pipe(
            switchMap((action: estandaresActions.DeleteEstandarAction_SUS) => {
                return this.estandarService.deleteEstandar(action.estandar)
                    .pipe(
                        map(() => new estandaresActions.DeleteEstandarActionSUCCESS_SUS(action.estandar)),
                        catchError(error => of(new estandaresActions.DeleteEstandarActionFAIL_SUS(error)))
                    )
            })
        );
};
