import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as incidenciasActions from '../../../actions/modulos/incidencias/incidencias.actions';



import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
// import { AreasService } from 'src/app/services/indicadores/areas.service';
import { IncidenciasService } from 'src/app/services/incidencias/incidencias.service';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';
import { IncidenciaVacaciones } from 'src/app/shared/models/incidencias/incidenciaVacaciones';


@Injectable()


export class incidenciasEffects {
    constructor(
        private actions$: Actions,
        private _servicio: IncidenciasService,
    ) { }




    @Effect() postIncidenciaVacaciones$ = this.actions$.ofType(incidenciasActions.POST_INC_VAC)
        .pipe(
            switchMap((action: incidenciasActions.PostIncidenciaVacacionesAction) => {
                return this._servicio.postIncidenciaVacaciones(action.incidencia)
                    .pipe(
                        map((incidenciaVacaciones: any) =>new incidenciasActions.PostIncidenciaVacacionesActionSUCCESS(incidenciaVacaciones)
                        ),
                        catchError(error => of(new incidenciasActions.PostIncidenciaVacacionesActionFAIL(error)))
                    )
            })
        );
};
