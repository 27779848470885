import { Injectable } from '@angular/core';
import { HttpClientService } from '../implementation/http-client.service';
import { Usuario } from '../../shared/models/balcax/usuario.model';

import { Subscription, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Vista } from 'src/app/shared/models/balcax/vista.model';
import { ExceptionsService } from '../exceptions.service';
import { VariablesService } from './variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { UsersServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  putHabilitadoSubcripcion: Subscription = new Subscription();

  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService,
    private variablesService: VariablesService
  ) { }

  //#region GET
  getUsers(idEmpresa: number) {
    return this.http.get(`${UsersServURL}/getUsers?idEmpresa=${idEmpresa}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  getUserPermisions(correo: string) {
    return this.http.get(`${UsersServURL}/getUsersPerms?correo=${correo}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  //#endregion

  //#region POST
  postUser(usuario: Usuario, idEmpresa: number, idCodigoEmpleado:string) {
    return this.http.post(`${UsersServURL}/newUser`, { usuario, idEmpresa, idCodigoEmpleado})
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  //#endregion

  //#region PUT
  putUserHabilitado(user: Usuario) {
    return this.http.post(`${UsersServURL}/updateUserStatus`, user)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  putPermisos(correo: string, permisos: Vista[]) {
    return this.http.post(`${UsersServURL}/updateUserPerms`, { correo, permisos })
      .pipe(
        map((response: any) => {
          this.variablesService.toast.next(new Toast('success', 'Completado.', 'Permisos Actualizados', 2000));
          return response;
        }),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  putTelefono(usuario: Usuario) {
    return this.http.post(`${UsersServURL}/updatePhone`, usuario)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  putPassword(usuario: Usuario) {
    return this.http.post(`${UsersServURL}/updatePassword`, usuario)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  //#endregion

  //#region DELETE
  deleteUser(usuario: Usuario) {
    return this.http.get(`${UsersServURL}/deleteUser?idUsuario=${usuario.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
  //#endregion

  cancelSubsctiptions() {
    this.putHabilitadoSubcripcion.unsubscribe();
  }
}
