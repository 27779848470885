import * as fromProyectoAlmacen from '../../../actions/modulos/microMedicion/proyectosAlmacen.actions';
import { proyectoMicroMedicion } from 'src/app/shared/models/microMedicion/proyectoMicroMedicion.model';

export class ProyectoState {
    list: proyectoMicroMedicion[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: ProyectoState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function proyectoReducer(state = initState, action: fromProyectoAlmacen.proyectoAlmacenActions): ProyectoState {
    switch (action.type) {
        //#region GET_CATALOGO
        case fromProyectoAlmacen.GET_PROYECTO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromProyectoAlmacen.GET_PROYECTO_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.proyectos.map(proyecto => {
                        return {
                            ...proyecto
                        }
                    })
                ]
            };
        case fromProyectoAlmacen.GET_PROYECTO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
