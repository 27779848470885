import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import { DashboardsSusService } from 'src/app/services/indicadoresSustentabilidad/dashboardsSus.service';
import * as dashboardActions from '../../../actions/modulos/indicadoresSustentabilidad/dashboardsSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';

@Injectable()

export class dashboardsSusEffects {
    constructor(
        private actions$: Actions,
        private dashboardService: DashboardsSusService,
    ) { }

    @Effect()
    cargarDashboard$ = this.actions$.ofType(dashboardActions.GET_DASHBOARD_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetDashboardAction_SUS) => {
                return this.dashboardService.getDashboard(action.idArea, action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetDashboardActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetDashboardActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarCardsActivities$ = this.actions$.ofType(dashboardActions.GET_CARD_COUNT_ACTIVITIES_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetCardCountActivitiesAction_SUS) => {
                return this.dashboardService.getCardsCountActivities(action.idArea, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetCardCountActivitiesActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetCardCountActivitiesActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceEspecifico$ = this.actions$.ofType(dashboardActions.GET_AVANCE_ESPECIFICO_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceEspecificoAction_SUS) => {
                return this.dashboardService.getAvanceEspecifico(action.idEspecifico, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceEspecificoActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetAvanceEspecificoActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceIndicadores$ = this.actions$.ofType(dashboardActions.GET_AVANCE_INDICADORES_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceIndicadoresAction_SUS) => {
                return this.dashboardService.getAvanceIndicadores(action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceIndicadoresActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetAvanceIndicadoresActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarAvanceEstandares$ = this.actions$.ofType(dashboardActions.GET_AVANCE_ESTANDARES_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetAvanceEstandaresAction_SUS) => {
                return this.dashboardService.getAvanceEstandares(action.idArea, action.idEstEsp, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvanceEstandaresActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetAvanceEstandaresActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarDetallesAvanceIndicadores$ = this.actions$.ofType(dashboardActions.GET_DETALLE_AVANCE_INDICADORES_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetDetalleAvanceIndicadoresAction_SUS) => {
                return this.dashboardService.getDetalleAvanceIndicadores(action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetDetalleAvanceIndicadoresActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetDetalleAvanceIndicadoresActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarAvancePorArea$ = this.actions$.ofType(dashboardActions.GET_AVANCE_POR_AREA_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetAvancePorAreaAction_SUS) => {
                return this.dashboardService.getAvancesPorArea(action.idArea, action.id, action.tipoFiltro, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetAvancePorAreaActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetAvancePorAreaActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarRecentActivities$ = this.actions$.ofType(dashboardActions.GET_RECENT_ACTIVITIES_SUS)
        .pipe(
            switchMap((action: dashboardActions.GetRecentActivitiesAction_SUS) => {
                return this.dashboardService.getRecentsActivities(action.idArea, action.periodo)
                    .pipe(
                        map((response: any) => new dashboardActions.GetRecentActivitiesActionSuccess_SUS(response)),
                        catchError(error => of(new dashboardActions.GetRecentActivitiesActionFail_SUS(error)))
                    )
            })
        );
};