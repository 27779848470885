import { Action } from '@ngrx/store';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';
import { BusquedaCriteria } from 'src/app/shared/models/Indicadores/busqueda_criteria.model';
import { ReporteIndicadores } from 'src/app/shared/models/Indicadores/reporte_indicadores.model';

export const GET_ESPECIFICOS = '[ESPECIFICOS] Get Especificos';
export const GET_ESPECIFICOS_FAIL = '[ESPECIFICOS] Get Especificos FAIL';
export const GET_ESPECIFICOS_SUCCESS = '[ESPECIFICOS] Get Especificos SUCCESS';

export const GET_REPORTE_ESPECIFICOS = '[GET_REPORTE_ESPECIFICOS] Get Reporte Especificos';
export const GET_REPORTE_ESPECIFICOS_FAIL = '[GET_REPORTE_ESPECIFICOS_FAIL] Get Reporte Especificos FAIL';
export const GET_REPORTE_ESPECIFICOS_SUCCESS = '[GET_REPORTE_ESPECIFICOS_SUCCESS] Get Reporte Especificos SUCCESS';

export const PUT_ESPECIFICO = '[ESPECIFICOS] Put Especificos Usuario';
export const PUT_ESPECIFICO_FAIL = '[ESPECIFICOS] Put Especificos Usuario FAIL';
export const PUT_ESPECIFICO_SUCCESS = '[ESPECIFICOS] Put Especificos SUCCESS';

export const PUT_STATE_ESPECIFICO = '[ESPECIFICOS] Put State Especificos Usuario';
export const PUT_STATE_ESPECIFICO_FAIL = '[ESPECIFICOS] Put State Especificos Usuario FAIL';
export const PUT_STATE_ESPECIFICO_SUCCESS = '[ESPECIFICOS] Put State Especificos SUCCESS';

export const POST_ESPECIFICO = '[ESPECIFICOS] Post Especificos';
export const POST_ESPECIFICO_FAIL = '[ESPECIFICOS] Post Especificos FAIL';
export const POST_ESPECIFICO_SUCCESS = '[ESPECIFICOS] Post Especificos SUCCESS';

export const DELETE_ESPECIFICO = '[ESPECIFICOS] Delete Especifico';
export const DELETE_ESPECIFICO_FAIL = '[ESPECIFICOS] Delete Especifico FAIL';
export const DELETE_ESPECIFICO_SUCCESS = '[ESPECIFICOS] Delete Especifico SUCCESS';

//#region GET_ESPECIFICOS
export class GetEspecificosAction implements Action {
    readonly type = GET_ESPECIFICOS;
    constructor(public idArea: number, public periodo: string) { }
};

export class GetEspecificosActionFail implements Action {
    readonly type = GET_ESPECIFICOS_FAIL;
    constructor(public payload: any) { }
};

export class GetEspecificosActionSuccess implements Action {
    readonly type = GET_ESPECIFICOS_SUCCESS;
    constructor(public objetivosEspecificos: ObjetivoEspecifico[]) { }
};
//#endregion

//#region GET_REPORTE_ESPECIFICOS
export class GetReporteEspecificosAction implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS;
    constructor(public criteria: BusquedaCriteria) { }
};

export class GetReporteEspecificosActionFail implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS_FAIL;
    constructor(public payload: any) { }
};

export class GetReporteEspecificosActionSuccess implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS_SUCCESS;
    constructor(public report: ReporteIndicadores[]) { }
};
//#endregion

//#region PUT_ESPECIFICOS
export class PutEspecificoAction implements Action {
    readonly type = PUT_ESPECIFICO;
    constructor(public especifico: ObjetivoEspecifico) { }
};

export class PutEspecificoActionFail implements Action {
    readonly type = PUT_ESPECIFICO_FAIL;
    constructor(public payload: any) { }
};

export class PutEspecificoActionSuccess implements Action {
    readonly type = PUT_ESPECIFICO_SUCCESS;
    constructor(public editEspecifico: ObjetivoEspecifico) { }
};
//#endregion

//#region PUT_STATE_ESPECIFICOS
export class PutStateEspecificoAction implements Action {
    readonly type = PUT_STATE_ESPECIFICO;
    constructor(public id: number) { }
};

export class PutStateEspecificoActionFail implements Action {
    readonly type = PUT_STATE_ESPECIFICO_FAIL;
    constructor(public payload: any) { }
};

export class PutStateEspecificoActionSuccess implements Action {
    readonly type = PUT_STATE_ESPECIFICO_SUCCESS;
    constructor(public id: number) { }
};
//#endregion

//#region POST_ESPECIFICO
export class PostEspecificoAction implements Action {
    readonly type = POST_ESPECIFICO;
    constructor(public especifico: ObjetivoEspecifico) { }
};
export class PostEspecificoActionFAIL implements Action {
    readonly type = POST_ESPECIFICO_FAIL;
    constructor(public payload: any) { }
};
export class PostEspecificoActionSUCCESS implements Action {
    readonly type = POST_ESPECIFICO_SUCCESS;
    constructor(public newEspecifico: ObjetivoEspecifico) { }
};
//#endregion

//#region DELETE_ESPECIFICO
export class DeleteEspecificoAction implements Action {
    readonly type = DELETE_ESPECIFICO;
    constructor(public especifico: ObjetivoEspecifico) { }
};
export class DeleteEspecificoActionFAIL implements Action {
    readonly type = DELETE_ESPECIFICO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEspecificoActionSUCCESS implements Action {
    readonly type = DELETE_ESPECIFICO_SUCCESS;
    constructor(public deleteEspecifico: ObjetivoEspecifico) { }
};
//#endregion

export type especificosActions =
GetEspecificosAction |
GetEspecificosActionFail |
GetEspecificosActionSuccess |

GetReporteEspecificosAction |
GetReporteEspecificosActionFail |
GetReporteEspecificosActionSuccess |

PutEspecificoAction |
PutEspecificoActionFail |
PutEspecificoActionSuccess |

PutStateEspecificoAction |
PutStateEspecificoActionFail |
PutStateEspecificoActionSuccess |

PostEspecificoAction |
PostEspecificoActionFAIL |
PostEspecificoActionSUCCESS |

DeleteEspecificoAction |
DeleteEspecificoActionFAIL |
DeleteEspecificoActionSUCCESS;