import * as fromCatalogosInwork from '../../../actions/modulos/inwork/catalogos.actions';
import { Catalogos } from 'src/app/shared/models/Inwork/catalogos.model';

export class CatalogosInworkState {
    list: Catalogos[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: CatalogosInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function catalogosInworkReducer(state = initState, action: fromCatalogosInwork.catalogosInworkActions): CatalogosInworkState {
    switch (action.type) {
    //#region GET_CATALOGOS
    case fromCatalogosInwork.GET_CATALOGOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromCatalogosInwork.GET_CATALOGOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.catalogos.map(catalogo => {
                    return {
                        ...catalogo
                    }
                })
            ]
        };
    case fromCatalogosInwork.GET_CATALOGOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_CATALOGO
    case fromCatalogosInwork.PUT_CATALOGO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromCatalogosInwork.PUT_CATALOGO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(catalogo => {
                    if (catalogo.id === action.updateCatalogo.id) {
                        return {
                            ...catalogo,
                            nombre: action.updateCatalogo.nombre,
                            descripcion: action.updateCatalogo.descripcion,
                        };
                    } else {
                        return catalogo;
                    }
                })
            ]
        };
    case fromCatalogosInwork.PUT_CATALOGO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_CATALOGO
    case fromCatalogosInwork.POST_CATALOGO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromCatalogosInwork.POST_CATALOGO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newCatalogo],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromCatalogosInwork.POST_CATALOGO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_CATALOGO
    case fromCatalogosInwork.DELETE_CATALOGO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromCatalogosInwork.DELETE_CATALOGO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(catalogo => {
                    if (catalogo.id != action.deleteCatalogo.id) {
                        return {
                            catalogo
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromCatalogosInwork.DELETE_CATALOGO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
