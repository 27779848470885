import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Proveedor } from 'src/app/shared/models/microMedicion/proveedor.model';
import { ExceptionsService } from '../../exceptions.service';

@Injectable({
    providedIn: 'root'
})
export class ProveedoresService {

    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region  GET
    getProveedores() {
        return this.http.get(`${environment.apiAlmacen}situ/getSuppliers`)
            .pipe(
                map((response: Proveedor[]) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion
}