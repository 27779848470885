import { ActionReducerMap } from '@ngrx/store';

import * as fromLogin from './reducers/balcax/login.reducer';
import * as fromAuth from './reducers/balcax/auth.reducer';
import * as fromUsers from './reducers/balcax/users.reducer';
import * as fromTabs from './reducers/balcax/tabs.reducers';

import * as fromIndicadores from './reducers/modulos/indicadores/indicadores.reducer';

import * as fromIndicadoresSus from './reducers/modulos/indicadoresSustentabilidad/indicadoresSus.reducer';

import * as fromMicroMedicion from './reducers/modulos/microMedicion/micromedicion.reducer';
import * as fromPuntoVenta from './reducers/modulos/punto-venta/punto-venta.reducer';
import * as fromInwork from './reducers/modulos/inwork/inwork.reducer';
import * as fromCampañas from './reducers/modulos/campañas/campañas.reducer';
import * as fromPerfilInic from './reducers/modulos/acuapue/PerfilInicio.reducer';

import * as fromIncidencias from './reducers/modulos/incidencias/incidencias.reducer';

export interface AppState {
    login: fromLogin.LoginState;
    auth: fromAuth.AuthState;
    users: fromUsers.UsersState;
    tabs: fromTabs.TabsState;
    indicadores: fromIndicadores.IndicadoresState,
    indicadoresSus: fromIndicadoresSus.IndicadoresSusState,
    microMedicion: fromMicroMedicion.MicroMedicionState,
    punto_venta: fromPuntoVenta.Punto_Venta_State;
    inwork: fromInwork.InworkState;
    campañas: fromCampañas.CampañasState;
    perfilInic: fromPerfilInic.PefilIniState;
    incidenciasReducer: fromIncidencias.IncidenciaState;
};

export const appReducers: ActionReducerMap<AppState> = {
    login: fromLogin.loginReducer,
    auth: fromAuth.authReducer,
    users: fromUsers.usersReducer,
    tabs: fromTabs.tabsReducer,
    indicadores: fromIndicadores.indicadoresReducer,
    indicadoresSus: fromIndicadoresSus.indicadoresReducerSus,
    microMedicion: fromMicroMedicion.microMedicionReducer,
    punto_venta: fromPuntoVenta.punto_venta_Reducer,
    inwork: fromInwork.inworkReducer,
    campañas: fromCampañas.campañasReducer,
    perfilInic: fromPerfilInic.perfilInicReducer,
    incidenciasReducer: fromIncidencias.incidenciasReducer
};
