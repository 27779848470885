import { Action } from '@ngrx/store';
import { Corte_Caja_PV } from 'src/app/shared/models/punto-venta/corte-caja-pv.model';

export const GET_ESTATUS_CAJA = '[PUNTO VENTA] Get Estatus de Caja';
export const GET_ESTATUS_CAJA_FAIL = '[PUNTO VENTA] Get Estatus de Caja FAIL';
export const GET_ESTATUS_CAJA_SUCCESS = '[PUNTO VENTA] Get Estatus de Caja SUCCESS';

export const POST_CORTE_CAJA = '[PUNTO VENTA] Post Corte de Caja';
export const POST_CORTE_CAJA_FAIL = '[PUNTO VENTA] Post Corte de Caja FAIL';
export const POST_CORTE_CAJA_SUCCESS = '[PUNTO VENTA] Post Corte de Caja SUCCESS';

//#region GET_ESTATUS_CAJA
export class GetEstatusCaja_PV_Action implements Action {
    readonly type = GET_ESTATUS_CAJA;
    constructor(public idUsuario: number) { }
};

export class GetEstatusCaja_PV_ActionFail implements Action {
    readonly type = GET_ESTATUS_CAJA_FAIL;
    constructor(public payload: any) { }
};

export class GetEstatusCaja_PV_ActionSuccess implements Action {
    readonly type = GET_ESTATUS_CAJA_SUCCESS;
    constructor(public cortes: Corte_Caja_PV[]) { }
};
//#endregion

//#region POST_CORTE_CAJA
export class PostCorteCaja_PV_Action implements Action {
    readonly type = POST_CORTE_CAJA;
    constructor(public corte: Corte_Caja_PV) { }
};

export class PostCorteCaja_PV_ActionFail implements Action {
    readonly type = POST_CORTE_CAJA_FAIL;
    constructor(public payload: any) { }
};

export class PostCorteCaja_PV_ActionSuccess implements Action {
    readonly type = POST_CORTE_CAJA_SUCCESS;
    constructor(public cortes: Corte_Caja_PV[]) { }
};
//#endregion

export type corteCaja_PV_Actions =
    PostCorteCaja_PV_Action |
    PostCorteCaja_PV_ActionFail |
    PostCorteCaja_PV_ActionSuccess |

    GetEstatusCaja_PV_Action |
    GetEstatusCaja_PV_ActionFail |
    GetEstatusCaja_PV_ActionSuccess;