export class ObjetivoEstrategico {
    constructor(
        public id?: number,
        public descripcion?: string,
        public fechaCreacion?: Date,
        public periodoInicio?: Date,
        public periodoFin?: Date,
        public habilitado?: boolean,
        public comentarios?: string,
        public visible?: boolean,
        public tipo?: string,
        public estrategico?: string,
        public estandar?: string,
    ){}
}