import { Action } from '@ngrx/store';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';

export const GET_ESTANDARES = '[ESTANDARES] Get Estandares';
export const GET_ESTANDARES_FAIL = '[ESTANDARES] Get Estandares FAIL';
export const GET_ESTANDARES_SUCCESS = '[ESTANDARES] Get Estandares SUCCESS';

export const POST_ESTANDAR = '[POST_ESTANDAR] Post Estandar';
export const POST_ESTANDAR_FAIL = '[POST_ESTANDAR] Post Estandar FAIL';
export const POST_ESTANDAR_SUCCESS = '[POST_ESTANDAR] Post Estandar SUCCESS';

export const PUT_ESTANDAR = '[PUT_ESTANDAR] Put Estandar';
export const PUT_ESTANDAR_FAIL = '[PUT_ESTANDAR] Put Estandar FAIL';
export const PUT_ESTANDAR_SUCCESS = '[PUT_ESTANDAR] Put Estandar SUCCESS';

export const DELETE_ESTANDAR = '[DELETE_ESTANDAR] Delete Estandar';
export const DELETE_ESTANDAR_FAIL = '[DELETE_ESTANDAR] Delete Estandar FAIL';
export const DELETE_ESTANDAR_SUCCESS = '[DELETE_ESTANDAR] Delete Estandar SUCCESS';

//#region GET_ESTANDARES
export class GetEstandaresAction implements Action {
    readonly type = GET_ESTANDARES;
    constructor() { }
};

export class GetEstandaresActionFail implements Action {
    readonly type = GET_ESTANDARES_FAIL;
    constructor(public payload: any) { }
};

export class GetEstandaresActionSuccess implements Action {
    readonly type = GET_ESTANDARES_SUCCESS;
    constructor(public estandares: Estandares[]) { }
};
//#endregion

//#region  POST_ESTANDAR
export class PostEstandarAction implements Action {
    readonly type = POST_ESTANDAR;
    constructor(public estandar: Estandares) { }
};
export class PostEstandarActionFAIL implements Action {
    readonly type = POST_ESTANDAR_FAIL;
    constructor(public payload: any) { }
};
export class PostEstandarActionSUCCESS implements Action {
    readonly type = POST_ESTANDAR_SUCCESS;
    constructor(public newEstandar: Estandares) { }
};
//#endregion

//#region  PUT_ESTANDAR
export class PutEstandarAction implements Action {
    readonly type = PUT_ESTANDAR;
    constructor(public estandar: Estandares) { }
};
export class PutEstandarActionFAIL implements Action {
    readonly type = PUT_ESTANDAR_FAIL;
    constructor(public payload: any) { }
};
export class PutEstandarActionSUCCESS implements Action {
    readonly type = PUT_ESTANDAR_SUCCESS;
    constructor(public updateEstandar: Estandares) { }
};
//#endregion

//#region  DELETE_ESTANDAR
export class DeleteEstandarAction implements Action {
    readonly type = DELETE_ESTANDAR;
    constructor(public estandar: Estandares) { }
};
export class DeleteEstandarActionFAIL implements Action {
    readonly type = DELETE_ESTANDAR_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstandarActionSUCCESS implements Action {
    readonly type = DELETE_ESTANDAR_SUCCESS
    constructor(public deleteEstandar: Estandares) { }
};
//#endregion

export type estandaresActions =
GetEstandaresAction |
GetEstandaresActionFail |
GetEstandaresActionSuccess |

PostEstandarAction |
PostEstandarActionFAIL |
PostEstandarActionSUCCESS |

PutEstandarAction |
PutEstandarActionFAIL |
PutEstandarActionSUCCESS |

DeleteEstandarAction |
DeleteEstandarActionFAIL |
DeleteEstandarActionSUCCESS;