import { Archivos } from "./archivos.model";

export class Actualizaciones{
    constructor(
        public id?: number,
        public usuario?: string,
        public descripcion?: string,
        public registrado?: Date,
        public visible?: boolean,
        public idAtributosPT?: number,
        public files?: Archivos[]
    ){}
}