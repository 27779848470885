export class CodigoMicroMedicion {
    constructor(
        public id: number,
        public tipo: string,
        public codigo: string,
        public marca: string,
        public diametro: string,
        public milimetros: string
    ){}
}

export class CodigoPVMicroMedicion {
    constructor(
        public id: number,
        public tipo: string,
        public codigo: string,
        public marca: string,
        public diametro: string,
        public milimetros: string,
        public cantidad: number,
        public precio: number,
        public stock: number,
        public iva: number,
        public ivaActivo: boolean,
    ){}
}