import { environment } from "src/environments/environment";

// MAILING 
export const MailerServURL = environment.apiMailer;
// BALCAN 
export const AuthServURL = `${environment.apiBalcan}Auth`;
export const UsersServURL = `${environment.apiBalcan}Users`;
export const AcuapueServURL = `${environment.apiBalcan}Acuapue`;
// INDICADORES 
export const UserServURL = `${environment.apiBalcan}User`;
export const BusinessServURL = `${environment.apiBalcan}Business`;
export const CatalogoServURL = `${environment.apiBalcan}Catalogos`;
export const ProgramWorkServURL = `${environment.apiBalcan}ProgramWork`;
export const ProgramServURL = `${environment.apiBalcan}Program`;
export const IndicatorServURL = `${environment.apiBalcan}Indicator`;
export const EstrategyServURL = `${environment.apiBalcan}Estrategy`;
export const EspecifyServURL = `${environment.apiBalcan}Especify`;
export const DashServURL = `${environment.apiBalcan}Dash`;
//! INDICADORES SUSTENTABILIDAD
export const UserServURL_SUS = `${environment.apiBalcan}SusUser`;
export const BusinessServURL_SUS = `${environment.apiBalcan}SusBusiness`;
export const CatalogoServURL_SUS = `${environment.apiBalcan}SusCatalogos`;
export const ProgramWorkServURL_SUS = `${environment.apiBalcan}SusProgramWork`;
export const ProgramServURL_SUS = `${environment.apiBalcan}SusProgram`;
export const IndicatorServURL_SUS = `${environment.apiBalcan}SusIndicator`;
export const EstrategyServURL_SUS = `${environment.apiBalcan}SusEstrategy`;
export const EspecifyServURL_SUS = `${environment.apiBalcan}SusEspecify`;
export const DashServURL_SUS = `${environment.apiBalcan}SusDash`;
// CAMPANIAS 
export const CampaniasServURL = `${environment.apiBalcan}Shipments`;
// POZOS 
export const PozosServURL = `http://pozoos.com,/`;
// INWORK
export const UpdatesServURL = '';
export const FilesServURL = '';
export const FieldsServURL = '';
export const ProyectsServURL = '';
export const CatalogServURL = '';
export const WorkflowServURL = '';
export const GroupServURL = '';
export const ItemsCatalogServURL = '';
export const RolServURL = '';
export const RolUserServURL = '';
export const TaskServURL = '';
// export const UpdatesServURL = `${environment.apiInwork}Updates`;
// export const FilesServURL = `${environment.apiInwork}Files`;
// export const FieldsServURL = `${environment.apiInwork}Fields`;
// export const ProyectsServURL = `${environment.apiInwork}Proyects`;
// export const CatalogServURL = `${environment.apiInwork}Catalogs`;
// export const WorkflowServURL = `${environment.apiInwork}Workflow`;
// export const GroupServURL = `${environment.apiInwork}Groups`;
// export const ItemsCatalogServURL = `${environment.apiInwork}ItemsCatalog`;
// export const RolServURL = `${environment.apiInwork}Rols`;
// export const RolUserServURL = `${environment.apiInwork}RolUsers`;
// export const TaskServURL = `${environment.apiInwork}Tasks`;
