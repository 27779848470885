import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as actualizacionesActions from '../../../actions/modulos/inwork/actualizaciones.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ActualizacionesService } from 'src/app/services/inwork/actualizaciones.service';
import { Actualizaciones } from 'src/app/shared/models/Inwork/actualizaciones.model';

@Injectable()

export class actualizacionesInworkEffects {
    constructor(
        private actions$: Actions,
        private actualizacionesService: ActualizacionesService,
    ) { }

    @Effect()
    getUpdatesInwork$ = this.actions$.ofType(actualizacionesActions.GET_ACTUALIZACIONES)
        .pipe(
            switchMap((action: actualizacionesActions.GetActualizacionesAction) => {
                return this.actualizacionesService.getUpdates(action.idAtributosPT, action.numItems)
                    .pipe(
                        map((updates: Actualizaciones[]) => new actualizacionesActions.GetActualizacionesActionSuccess(updates)),
                        catchError(error => of(new actualizacionesActions.GetActualizacionesActionFail(error)))
                    )
            })
        );

    @Effect()
    postUpdateInwork$ = this.actions$.ofType(actualizacionesActions.POST_ACTUALIZACION)
        .pipe(
            switchMap((action: actualizacionesActions.PostActualizacionAction) => {
                return this.actualizacionesService.postUpdate(action.actualizacion)
                    .pipe(
                        map((newUpdate : Actualizaciones) => new actualizacionesActions.PostActualizacionActionSUCCESS(newUpdate)),
                        catchError(error => of(new actualizacionesActions.PostActualizacionActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putUpdateInwork$ = this.actions$.ofType(actualizacionesActions.PUT_ACTUALIZACION)
        .pipe(
            switchMap((action: actualizacionesActions.PutActualizacionAction) => {
                return this.actualizacionesService.putUpdate(action.actualizacion)
                    .pipe(
                        map((updateActualizacion: Actualizaciones) => new actualizacionesActions.PutActualizacionActionSUCCESS(updateActualizacion)),
                        catchError(error => of(new actualizacionesActions.PutActualizacionActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteUpdateInwork$ = this.actions$.ofType(actualizacionesActions.DELETE_ACTUALIZACION)
        .pipe(
            switchMap((action: actualizacionesActions.DeleteActualizacionAction) => {
                return this.actualizacionesService.deleteUpdate(action.actualizacion)
                    .pipe(
                        map((response) => new actualizacionesActions.DeleteActualizacionActionSUCCESS((response) ? action.actualizacion : new Actualizaciones(0,null, ""))),
                        catchError(error => of(new actualizacionesActions.DeleteActualizacionActionFAIL(error)))
                    )
            })
        );
};
