export class Corte_Caja_PV {
    constructor(
        public idUsuario:  number,
        public monedas:    Monedas_PV[],
        public billetes:   Monedas_PV[],
        public tarjetas:   Tarjeta_PV[],
        public total:      number,
        public idCaja:     number,
        public idTurno:      number,
        public fecha?:      Date
    ){}
}

export class Monedas_PV {
    constructor(
        public denominacion: number,
        public cantidad: number,
    ){}
}

export class Tarjeta_PV {
    constructor(
        public tipo: string,
        public depositado: number,
        public tarjetas: number,
    ){}
}

export class Caja_PV {
    constructor(
        public id: number,
        public numero: number,
    ){}
}
