import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as authActions from '../../actions/balcax/auth.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/balcax/auth.service';

@Injectable()

export class authEffects {
    constructor(
        private actions$: Actions,
        public authService: AuthService
    ) { }

    @Effect()
    cargarUser$ = this.actions$.ofType(authActions.SET_USER)
        .pipe(
            switchMap(() => {
                return this.authService.getUser()
                    .pipe(
                        map(usuario => {
                            let { usuario: usuarioini } = usuario;
                            this.authService.IdUsuario = usuarioini.id;
                            this.authService.Usuario = usuario;
                            return new authActions.SetUserActionSuccess(usuario)
                        }),
                        catchError(error => of(new authActions.SetUserActionFail(error)))
                    )
            })
        );

    @Effect()
    putPassword$ = this.actions$.ofType(authActions.PUT_PASSWORD_AUTH)
        .pipe(
            switchMap((action: authActions.PutPasswordUserAction) => {
                return this.authService.putPassword(action.credenciales)
                    .pipe(
                        map(() => new authActions.PutPasswordUserActionSuccess()),
                        catchError(error => of(new authActions.SetUserActionFail(error)))
                    )
            })
        );

};
