import * as fromPeriodos from '../../../actions/modulos/indicadoresSustentabilidad/periodosSus.actions';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';

export class PeriodosState {
    list: Periodos[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: PeriodosState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function periodosReducerSus(state = initState, action: fromPeriodos.periodosActions_SUS): PeriodosState {
    switch (action.type) {
    //#region GET_PERIODOS
    case fromPeriodos.GET_PERIODOS_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromPeriodos.GET_PERIODOS_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.periodos.map(periodos => {
                    return {
                        ...periodos
                    }
                })
            ]
        };
    case fromPeriodos.GET_PERIODOS_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_PERIODO
    case fromPeriodos.POST_PERIODO_SUS:
        return {
            ...state,
        };
    case fromPeriodos.POST_PERIODO_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [...state.list, action.newPeriodo],
        };
    case fromPeriodos.POST_PERIODO_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_PERIODO
    case fromPeriodos.PUT_PERIODO_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromPeriodos.PUT_PERIODO_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(editPeriodo => {
                    if (editPeriodo.id === action.updatePeriodo.id) {
                        return {
                            ...editPeriodo,
                            descripcion: action.updatePeriodo.descripcion,
                            fechaInicio: action.updatePeriodo.fechaInicio,
                            fechaFin: action.updatePeriodo.fechaFin
                        };
                    } else {
                        return editPeriodo;
                    }
                })
            ]
        };
    case fromPeriodos.PUT_PERIODO_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_PERIODO
    case fromPeriodos.DELETE_PERIODO_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromPeriodos.DELETE_PERIODO_SUS_SUCCESS:
        return {
            ...state,
            list: [...state.list.filter(periodo => {
                if (periodo.id != action.deletePeriodo.id) {
                    return {
                        periodo
                    };
                }
            })],
            loading: false,
            loaded: true,
            error: null
        };
    case fromPeriodos.DELETE_PERIODO_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    default:
        return state;
    }
}
