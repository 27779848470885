import { Action } from '@ngrx/store';
import { LogIndicadores } from 'src/app/shared/models/Indicadores/log.model';
import { RadialBar } from 'src/app/shared/models/balcax/charts/radial-bar.model';
import { MultiBarras } from 'src/app/shared/models/balcax/charts/multi-bar.model';
import * as chartBarras from 'src/app/shared/models/balcax/charts/bar.model';
import { CardPercentage } from 'src/app/shared/models/balcax/card.model';
import { Dashboard } from 'src/app/shared/models/Indicadores/dashboard.model';


export const GET_DASHBOARD = '[GET_DASHBOARD] Get Dashboard';
export const GET_DASHBOARD_FAIL = '[GET_DASHBOARD_FAIL] Get Dashboard FAIL';
export const GET_DASHBOARD_SUCCESS = '[GET_DASHBOARD_SUCCESS] Get Dashboard SUCCESS';

export const GET_CARD_COUNT_ACTIVITIES = '[GET_CARD_COUNT_ACTIVITIES] Get Card Count Activities';
export const GET_CARD_COUNT_ACTIVITIES_FAIL = '[GET_CARD_COUNT_ACTIVITIES_FAIL] Get Card Count Activities FAIL';
export const GET_CARD_COUNT_ACTIVITIES_SUCCESS = '[GET_CARD_COUNT_ACTIVITIES_SUCCESS] Get Card Count Activities SUCCESS';

export const GET_AVANCE_ESPECIFICO = '[GET_AVANCE_ESPECIFICO] Get Avance Especifico';
export const GET_AVANCE_ESPECIFICO_FAIL = '[GET_AVANCE_ESPECIFICO_FAIL] Get Avance Especifico FAIL';
export const GET_AVANCE_ESPECIFICO_SUCCESS = '[GET_AVANCE_ESPECIFICO_SUCCESS] Get Avance Especifico SUCCESS';

export const GET_AVANCE_INDICADORES = '[GET_AVANCE_INDICADORES] Get Avance Indicadores';
export const GET_AVANCE_INDICADORES_FAIL = '[GET_AVANCE_INDICADORES_FAIL] Get Avance Indicadores FAIL';
export const GET_AVANCE_INDICADORES_SUCCESS = '[GET_AVANCE_INDICADORES_SUCCESS] Get Avance Indicadores SUCCESS';

export const GET_AVANCE_ESTANDARES = '[GET_AVANCE_ESTANDARES] Get Avance Estandares';
export const GET_AVANCE_ESTANDARES_FAIL = '[GET_AVANCE_ESTANDARES_FAIL] Get Avance Estandares FAIL';
export const GET_AVANCE_ESTANDARES_SUCCESS = '[GET_AVANCE_ESTANDARES_SUCCESS] Get Avance Estandares SUCCESS';

export const GET_DETALLE_AVANCE_INDICADORES = '[GET_DETALLE_AVANCE_INDICADORES] Get Detalle Avance ';
export const GET_DETALLE_AVANCE_INDICADORES_FAIL = '[GET_DETALLE_AVANCE_INDICADORES_FAIL] Get Detalle Avance Indicadores FAIL';
export const GET_DETALLE_AVANCE_INDICADORES_SUCCESS = '[GET_DETALLE_AVANCE_INDICADORES_SUCCESS] Get Detalle Avance Indicadores SUCCESS';

export const GET_AVANCE_POR_AREA = '[GET_AVANCE_POR_AREA] Get Avance Por Area ';
export const GET_AVANCE_POR_AREA_FAIL = '[GET_AVANCE_POR_AREA_FAIL] Get Avance Por Area Indicadores FAIL';
export const GET_AVANCE_POR_AREA_SUCCESS = '[GET_AVANCE_POR_AREA_SUCCESS] Get Avance Por Area Indicadores SUCCESS';

export const GET_RECENT_ACTIVITIES = '[GET_RECENT_ACTIVITIES] Get Recent Activities';
export const GET_RECENT_ACTIVITIES_FAIL = '[GET_RECENT_ACTIVITIES_FAIL] Get Recent Activities FAIL';
export const GET_RECENT_ACTIVITIES_SUCCESS = '[GET_RECENT_ACTIVITIES_SUCCESS] Get Recent Activities SUCCESS';

//#region GET_DASHBOARD
export class GetDashboardAction implements Action {
    readonly type = GET_DASHBOARD;
    constructor(public idArea: number, public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetDashboardActionFail implements Action {
    readonly type = GET_DASHBOARD_FAIL;
    constructor(public payload: any) { }
};

export class GetDashboardActionSuccess implements Action {
    readonly type = GET_DASHBOARD_SUCCESS;
    constructor(public dashboard: Dashboard) { }
};
//#endregion

//#region GET_CARD_COUNT_ACTIVITIES
export class GetCardCountActivitiesAction implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES;
    constructor(public idArea: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetCardCountActivitiesActionFail implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES_FAIL;
    constructor(public payload: any) { }
};

export class GetCardCountActivitiesActionSuccess implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES_SUCCESS;
    constructor(public cardsActivities: CardPercentage[]) { }
};
//#endregion

//#region GET_AVANCE_ESPECIFICO
export class GetAvanceEspecificoAction implements Action {
    readonly type = GET_AVANCE_ESPECIFICO;
    constructor(public idEspecifico: number, public periodo: string) { }
};

export class GetAvanceEspecificoActionFail implements Action {
    readonly type = GET_AVANCE_ESPECIFICO_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceEspecificoActionSuccess implements Action {
    readonly type = GET_AVANCE_ESPECIFICO_SUCCESS;
    constructor(public avanceEspecifico: RadialBar) { }
};
//#endregion

//#region GET_AVANCE_INDICADORES
export class GetAvanceIndicadoresAction implements Action {
    readonly type = GET_AVANCE_INDICADORES;
    constructor(public id: number, public tipoFiltro: string, public periodo: string){}
};

export class GetAvanceIndicadoresActionFail implements Action {
    readonly type = GET_AVANCE_INDICADORES_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceIndicadoresActionSuccess implements Action {
    readonly type = GET_AVANCE_INDICADORES_SUCCESS;
    constructor(public avancesIndicadores: chartBarras.Barras) { }
};
//#endregion

//#region GET_AVANCE_ESTANDARES
export class GetAvanceEstandaresAction implements Action {
    readonly type = GET_AVANCE_ESTANDARES;
    constructor(public idArea: number, public idEstEsp: number, public tipoFiltro: string, public periodo: string){}
};

export class GetAvanceEstandaresActionFail implements Action {
    readonly type = GET_AVANCE_ESTANDARES_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceEstandaresActionSuccess implements Action {
    readonly type = GET_AVANCE_ESTANDARES_SUCCESS;
    constructor(public avancesEstandares: chartBarras.Barras) { }
};
//#endregion

//#region GET_DETALLE_AVANCE_INDICADORES
export class GetDetalleAvanceIndicadoresAction implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES;
    constructor(public id: number, public tipoFiltro: string, public periodo: string){}
};

export class GetDetalleAvanceIndicadoresActionFail implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES_FAIL;
    constructor(public payload: any) { }
};

export class GetDetalleAvanceIndicadoresActionSuccess implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES_SUCCESS;
    constructor(public detailsAvancesIndicadores: chartBarras.Grouped) { }
};
//#endregion

//#region GET_AVANCE_POR_AREA
export class GetAvancePorAreaAction implements Action {
    readonly type = GET_AVANCE_POR_AREA;
    constructor(public idArea: number, public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetAvancePorAreaActionFail implements Action {
    readonly type = GET_AVANCE_POR_AREA_FAIL;
    constructor(public payload: any) { }
};

export class GetAvancePorAreaActionSuccess implements Action {
    readonly type = GET_AVANCE_POR_AREA_SUCCESS;
    constructor(public avancesArea: RadialBar[]) { }
};
//#endregion

//#region GET_RECENT_ACTIVITIES
export class GetRecentActivitiesAction implements Action {
    readonly type = GET_RECENT_ACTIVITIES;
    constructor(public idArea: number, public periodo: string) { }
};

export class GetRecentActivitiesActionFail implements Action {
    readonly type = GET_RECENT_ACTIVITIES_FAIL;
    constructor(public payload: any) { }
};

export class GetRecentActivitiesActionSuccess implements Action {
    readonly type = GET_RECENT_ACTIVITIES_SUCCESS;
    constructor(public recentActivities: LogIndicadores[]) { }
};
//#endregion

export type dashboardctions =
GetDashboardAction |
GetDashboardActionFail |
GetDashboardActionSuccess |

GetCardCountActivitiesAction |
GetCardCountActivitiesActionFail |
GetCardCountActivitiesActionSuccess |

GetAvanceEspecificoAction |
GetAvanceEspecificoActionFail |
GetAvanceEspecificoActionSuccess |

GetAvanceEstandaresAction |
GetAvanceEstandaresActionFail |
GetAvanceEstandaresActionSuccess |

GetAvanceIndicadoresAction |
GetAvanceIndicadoresActionFail |
GetAvanceIndicadoresActionSuccess |

GetDetalleAvanceIndicadoresAction |
GetDetalleAvanceIndicadoresActionFail|
GetDetalleAvanceIndicadoresActionSuccess|

GetAvancePorAreaAction |
GetAvancePorAreaActionFail|
GetAvancePorAreaActionSuccess|

GetRecentActivitiesAction |
GetRecentActivitiesActionFail |
GetRecentActivitiesActionSuccess;

