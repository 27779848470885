import * as fromPuntoVentaActions from '../../../actions/modulos/punto-venta/index';

import * as fromPuntoVenta from '../../../actions/modulos/punto-venta/puntoVenta.actions';

import { CorteCaja_PV_State, corteCaja_PV_Reducer } from './corteCaja.reducer';

export interface Punto_Venta_State {
    corteCaja: CorteCaja_PV_State;
};

const initState: Punto_Venta_State = {
    corteCaja: {
        inicio: null,
        final: null,
        loaded: false,
        loading: false,
        error: null
    }
};

export function punto_venta_Reducer(state = initState, action: fromPuntoVenta.puntoVentaActions): Punto_Venta_State {
    if (action.type == fromPuntoVentaActions.POST_CORTE_CAJA ||
        action.type == fromPuntoVentaActions.POST_CORTE_CAJA_FAIL ||
        action.type == fromPuntoVentaActions.POST_CORTE_CAJA_SUCCESS ||
        
        action.type == fromPuntoVentaActions.GET_ESTATUS_CAJA ||
        action.type == fromPuntoVentaActions.GET_ESTATUS_CAJA_FAIL ||
        action.type == fromPuntoVentaActions.GET_ESTATUS_CAJA_SUCCESS)
        return {
            ...state,
            corteCaja: corteCaja_PV_Reducer(state.corteCaja, action)
        };
    
    else
        return state;
}