import { Action } from '@ngrx/store';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';

export const GET_CONFIGURACION_PROGRAMA_SUS = '[GET_CONFIGURACION_PROGRAMA_SUS] Get Configuracion Programa_SUS';
export const GET_CONFIGURACION_PROGRAMA_SUS_FAIL = '[GET_CONFIGURACION_PROGRAMA_FAIL_SUS] Get Configuracion Programa_SUS FAIL';
export const GET_CONFIGURACION_PROGRAMA_SUS_SUCCESS = '[GET_CONFIGURACION_PROGRAMA_SUCCESS_SUS] Get Configuracion Programa_SUS SUCCESS';

export const GET_CONFIGURACION_AVANCE_SUS = '[GET_CONFIGURACION_AVANCE_SUS] Get Configuracion Avance_SUS';
export const GET_CONFIGURACION_AVANCE_SUS_FAIL = '[GET_CONFIGURACION_AVANCE_FAIL_SUS] Get Configuracion Avance_SUS FAIL';
export const GET_CONFIGURACION_AVANCE_SUS_SUCCESS = '[GET_CONFIGURACION_AVANCE_SUCCESS_SUS] Get Configuracion Avance_SUS SUCCESS';

export const PUT_CONFIGURACION_PROGRAMA_SUS = '[PUT_CONFIGURACION_PROGRAMA_SUS] Put Configuracion Programa_SUS';
export const PUT_CONFIGURACION_PROGRAMA_SUS_FAIL = '[PUT_CONFIGURACION_PROGRAMA_FAIL_SUS] Put Configuracion Programa_SUS FAIL';
export const PUT_CONFIGURACION_PROGRAMA_SUS_SUCCESS = '[PUT_CONFIGURACION_PROGRAMA_SUCCESS_SUS] Put Configuracion Programa_SUS SUCCESS';

export const PUT_CONFIGURACION_AVANCE_SUS = '[PUT_CONFIGURACION_AVANCE_SUS] Put Configuracion Avance_SUS';
export const PUT_CONFIGURACION_AVANCE_SUS_FAIL = '[PUT_CONFIGURACION_AVANCE_FAIL_SUS] Put Configuracion Avance_SUS FAIL';
export const PUT_CONFIGURACION_AVANCE_SUS_SUCCESS = '[PUT_CONFIGURACION_AVANCE_SUCCESS_SUS] Put Configuracion Avance_SUS SUCCESS';

export const POST_CONFIGURACION_PROGRAMA_SUS = '[POST_CONFIGURACION_PROGRAMA_SUS] Post Configuracion Programa_SUS';
export const POST_CONFIGURACION_PROGRAMA_SUS_FAIL = '[POST_CONFIGURACION_PROGRAMA_FAIL_SUS] Post Configuracion Programa_SUS FAIL';
export const POST_CONFIGURACION_PROGRAMA_SUS_SUCCESS = '[POST_CONFIGURACION_PROGRAMA_SUCCESS_SUS] Post Configuracion Programa_SUS SUCCESS';

export const POST_CONFIGURACION_AVANCE_SUS = '[MES_CONFIGURACION_SUS] Post Configuracion Avance_SUS';
export const POST_CONFIGURACION_AVANCE_SUS_FAIL = '[MES_CONFIGURACION_SUS] Post Configuracion Avance_SUS FAIL';
export const POST_CONFIGURACION_AVANCE_SUS_SUCCESS = '[MES_CONFIGURACION_SUS] Post Configuracion Avance_SUS SUCCESS';

export const DELETE_CONFIGURACION_PROGRAMA_SUS = '[DELETE_CONFIGURACION_PROGRAMA_SUS] Delete Configuracion Programa_SUS';
export const DELETE_CONFIGURACION_PROGRAMA_SUS_FAIL = '[DELETE_CONFIGURACION_PROGRAMA_FAIL_SUS] Delete Configuracion Programa_SUS FAIL';
export const DELETE_CONFIGURACION_PROGRAMA_SUS_SUCCESS = '[DELETE_CONFIGURACION_PROGRAMA_SUCCESS_SUS] Delete Configuracion Programa_SUS SUCCESS';

export const DELETE_CONFIGURACION_AVANCE_SUS = '[DELETE_CONFIGURACION_AVANCE_SUS] Delete Configuracion Avance_SUS';
export const DELETE_CONFIGURACION_AVANCE_SUS_FAIL = '[DELETE_CONFIGURACION_AVANCE_FAIL_SUS] Delete Configuracion Avance_SUS FAIL';
export const DELETE_CONFIGURACION_AVANCE_SUS_SUCCESS = '[DELETE_CONFIGURACION_AVANCE_SUCCESS_SUS] Delete Configuracion Avance_SUS SUCCESS';

//#region GET_CONFIGURACION_PROGRAMA
export class GetConfiguracionProgramaAction_SUS implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA_SUS;
    constructor(public idEstrategico: number) { }
};

export class GetConfiguracionProgramaActionFail_SUS implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetConfiguracionProgramaActionSuccess_SUS implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA_SUS_SUCCESS;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region GET_CONFIGURACION_AVANCE
export class GetConfiguracionAvanceAction_SUS implements Action {
    readonly type = GET_CONFIGURACION_AVANCE_SUS;
    constructor(public idEstrategico: number) { }
};

export class GetConfiguracionAvanceActionFail_SUS implements Action {
    readonly type = GET_CONFIGURACION_AVANCE_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetConfiguracionAvanceActionSuccess_SUS implements Action {
    readonly type = GET_CONFIGURACION_AVANCE_SUS_SUCCESS;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region PUT_CONFIGURACION_PROGRAMA
export class PutConfiguracionProgramaAction_SUS implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA_SUS;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};

export class PutConfiguracionProgramaActionFail_SUS implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutConfiguracionProgramaActionSuccess_SUS implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA_SUS_SUCCESS;
    constructor(public editConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region PUT_CONFIGURACION_AVANCE
export class PutConfiguracionAvanceAction_SUS implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE_SUS;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};

export class PutConfiguracionAvanceActionFail_SUS implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutConfiguracionAvanceActionSuccess_SUS implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE_SUS_SUCCESS;
    constructor(public editConfigAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region POST_CONFIGURACION_PROGRAMA
export class PostConfiguracionProgramaAction_SUS implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA_SUS;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
export class PostConfiguracionProgramaActionFAIL_SUS implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostConfiguracionProgramaActionSUCCESS_SUS implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA_SUS_SUCCESS;
    constructor(public newConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region POST_CONFIGURACION_AVANCE
export class PostConfiguracionAvanceAction_SUS implements Action {
    readonly type = POST_CONFIGURACION_AVANCE_SUS;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};
export class PostConfiguracionAvanceActionFAIL_SUS implements Action {
    readonly type = POST_CONFIGURACION_AVANCE_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostConfiguracionAvanceActionSUCCESS_SUS implements Action {
    readonly type = POST_CONFIGURACION_AVANCE_SUS_SUCCESS;
    constructor(public newConfigAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region  DELETE_CONFIGURACION_PROGRAMA
export class DeleteConfiguracionProgramaAction_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA_SUS;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
export class DeleteConfiguracionProgramaActionFAIL_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteConfiguracionProgramaActionSUCCESS_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA_SUS_SUCCESS;
    constructor(public deleteConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region  DELETE_CONFIGURACION_AVANCE
export class DeleteConfiguracionAvanceAction_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE_SUS;
    constructor(public configAvance: ConfiguracionAvance) { }
};
export class DeleteConfiguracionAvanceActionFAIL_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteConfiguracionAvanceActionSUCCESS_SUS implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE_SUS_SUCCESS;
    constructor(public deleteConfigAvance: ConfiguracionAvance) { }
};
//#endregion

export type configuracionProgramaActions_SUS =
    GetConfiguracionProgramaAction_SUS |
    GetConfiguracionProgramaActionFail_SUS |
    GetConfiguracionProgramaActionSuccess_SUS |

    GetConfiguracionAvanceAction_SUS |
    GetConfiguracionAvanceActionFail_SUS |
    GetConfiguracionAvanceActionSuccess_SUS |

    PostConfiguracionProgramaAction_SUS |
    PostConfiguracionProgramaActionFAIL_SUS |
    PostConfiguracionProgramaActionSUCCESS_SUS |

    PostConfiguracionAvanceAction_SUS |
    PostConfiguracionAvanceActionFAIL_SUS |
    PostConfiguracionAvanceActionSUCCESS_SUS |

    PutConfiguracionProgramaAction_SUS |
    PutConfiguracionProgramaActionFail_SUS |
    PutConfiguracionProgramaActionSuccess_SUS |

    PutConfiguracionAvanceAction_SUS |
    PutConfiguracionAvanceActionFail_SUS |
    PutConfiguracionAvanceActionSuccess_SUS |

    DeleteConfiguracionProgramaAction_SUS |
    DeleteConfiguracionProgramaActionFAIL_SUS |
    DeleteConfiguracionProgramaActionSUCCESS_SUS |

    DeleteConfiguracionAvanceAction_SUS |
    DeleteConfiguracionAvanceActionFAIL_SUS |
    DeleteConfiguracionAvanceActionSUCCESS_SUS;