import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as tipoIndicadoresActions from '../../../actions/modulos/indicadores/tipos_indicadores.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { TipoIndicadoresService } from 'src/app/services/indicadores/tipo_indicador.service';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';

@Injectable()

export class tipoIndicadoresEffects {
    constructor(
        private actions$: Actions,
        private tipoIndicadoresService: TipoIndicadoresService,
    ) { }

    @Effect()
    cargarTiposIndicadores$ = this.actions$.ofType(tipoIndicadoresActions.GET_TIPOS_INDICADOR)
        .pipe(
            switchMap(() => {
                return this.tipoIndicadoresService.getTipoIndicadores()
                    .pipe(
                        map((tipo_indicador: TipoIndicador[]) => new tipoIndicadoresActions.GetTiposIndicadorActionSuccess(tipo_indicador)),
                        catchError(error => of(new tipoIndicadoresActions.GetTiposIndicadorActionFail(error)))
                    )
            })
        );

    @Effect()
    postTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.POST_TIPO_INDICADOR)
        .pipe(
            switchMap((action: tipoIndicadoresActions.PostTipoIndicadorAction) => {
                return this.tipoIndicadoresService.postTipoIndicador(action.tipoIndicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.PostTipoIndicadorActionSUCCESS(action.tipoIndicador)),
                        catchError(error => of(new tipoIndicadoresActions.PostTipoIndicadorActionFAIL(error)))
                    )
            })
        );
    @Effect()
    editTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.PUT_TIPO_INDICADOR)
        .pipe(
            switchMap((action: tipoIndicadoresActions.PutTipoIndicadorAction) => {
                const tipo_indicador = new TipoIndicador();
                tipo_indicador.id = action.tipoIndicador.id;
                tipo_indicador.descripcion = action.tipoIndicador.descripcion;

                return this.tipoIndicadoresService.putTipoIndicador(tipo_indicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.PutTipoIndicadorActionSUCCESS(tipo_indicador)),
                        catchError(error => of(new tipoIndicadoresActions.PutTipoIndicadorActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.DELETE_TIPO_INDICADOR)
        .pipe(
            switchMap((action: tipoIndicadoresActions.DeleteTipoIndicadorAction) => {
                return this.tipoIndicadoresService.deleteTipoIndicador(action.tipoIndicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.DeleteTipoIndicadorActionSUCCESS(action.tipoIndicador)),
                        catchError(error => of(new tipoIndicadoresActions.DeleteTipoIndicadorActionFAIL(error)))
                    )
            })
        );
};
