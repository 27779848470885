import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//MODULOS PERZONALIZADOS
//import { LoginModule } from './login/login.module';

// RUTAS
import { AppRoutingModule } from 'src/app/app-routing.module';

//REDUX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { effectsArray } from './store/effects';
import { appReducers } from './store/app.reducer';

// Environment
import { environment } from 'src/environments/environment.prod';

// Idioma Español
import { DatePipe, TitleCasePipe, registerLocaleData, CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FloginComponent } from './shared/components/forms/balcax/flogin/flogin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { RpasswordComponent } from './shared/components/forms/balcax/rpassword/rpassword.component';


@NgModule({
  declarations: [
    AppComponent,
    // LoginModule,
    LoginComponent,
    FloginComponent,
    RpasswordComponent,
    

  ],
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastModule,
    BrowserModule,

    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(effectsArray),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [DatePipe, TitleCasePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
