export class ObjetivoEspecifico {
    constructor(
        public id?: number,
        public nombre?: string,
        public descripcion?: string,
        public indicador?: string,
        public tipoIndicador?: string,
        public frecuencia?: string,
        public formula?: string,
        public lineaBase?: string,
        public metaProgramada?: string,
        public responsable?: string,
        public entregable?: string,
        public unidadMedida?: string,
        public sustancial?: string,
        public area?: string,
        public fechaCreacion?: Date,
        public usuario?: string,
        public observaciones?: string,
        public autorizado?: boolean,
        public programado?: boolean,
        public periodo?: string,
    ){}
}