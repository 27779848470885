export class Usuario {
    constructor(
        public id?: number,
        public nombre?: string,
        public apellidos?: string,
        public correo?: string,
        public telefono?: string,
        public registro?: Date,
        public habilitado?: boolean,
        public contrasena?: any,
        public token?: string,
        public avatar?: string,
        public permisos?: string,
        public nombreCompleto?: string,
        //public codigoEmpleado?: string,
    ){}
}
