import * as fromActions from '../../../actions/modulos/campañas/index';
import * as fromCampañas from '../../../actions/modulos/campañas/campañas.actions';

import { UniversoState, universoReducer } from './universo.reducer';

export interface CampañasState {
    universo: UniversoState;
};

const initState: CampañasState = {
    universo : {
        list: [],
        loaded: false,
        loading: false,
        responseCampania: false,
        loadedCampania: false,
        loadingCampania: false,
        listEncabezado: [],
        loadedEncabezado: false,
        loadingEncabezado: false,
        graphPie: null,
        loadedGraph: false,
        loadingGraph: false,
        listHistorico: [],
        loadedHistorico: false,
        loadingHistorico: false,
        error: null,
        listColonias: [],
        loadedColonia: false,
        loadingColonia: false
    }
};

export function campañasReducer(state = initState, action: fromCampañas.campañasActions): CampañasState {
    if(action.type == fromActions.GET_UNIVERSO ||
        action.type == fromActions.GET_UNIVERSO_FAIL ||
        action.type == fromActions.GET_UNIVERSO_SUCCESS ||
        action.type == fromActions.GET_ENCABEZADO ||
        action.type == fromActions.GET_ENCABEZADO_FAIL ||
        action.type == fromActions.GET_ENCABEZADO_SUCCESS ||
        action.type == fromActions.GET_HISTORICO ||
        action.type == fromActions.GET_HISTORICO_FAIL ||
        action.type == fromActions.GET_HISTORICO_SUCCESS ||
        action.type == fromActions.GET_GRAPH ||
        action.type == fromActions.GET_GRAPH_FAIL ||
        action.type == fromActions.GET_GRAPH_SUCCESS ||
        action.type == fromActions.GET_COLONIA ||
        action.type == fromActions.GET_COLONIA_FAIL ||
        action.type == fromActions.GET_COLONIA_SUCCESS ||
        action.type == fromActions.SEND_CAMPANIA ||
        action.type == fromActions.SEND_CAMPANIA_FAIL ||
        action.type == fromActions.SEND_CAMPANIA_SUCCESS){
            return{
                ...state,
                universo: universoReducer(state.universo, action)
            };
    }
    return state;
}