import { Action } from '@ngrx/store';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';

export const GET_AREAS = '[AREAS] Get Areas';
export const GET_AREAS_FAIL = '[AREAS] Get Areas FAIL';
export const GET_AREAS_SUCCESS = '[AREAS] Get Areas SUCCESS';

export const PUT_STATE_AREAS = '[AREAS] Put State Areas';
export const PUT_STATE_AREAS_FAIL = '[AREAS] Put State Areas FAIL';
export const PUT_STATE_AREAS_SUCCESS = '[AREAS] Put State Areas SUCCESS';

export const PUT_AREAS = '[AREAS] Put Areas';
export const PUT_AREAS_FAIL = '[AREAS] Put Areas FAIL';
export const PUT_AREAS_SUCCESS = '[AREAS] Put Areas SUCCESS';

export const POST_AREAS = '[AREAS] Post Areas';
export const POST_AREAS_FAIL = '[AREAS] Post Areas FAIL';
export const POST_AREAS_SUCCESS = '[AREAS] Post Areas SUCCESS';

export const DELETE_AREA = '[AREAS] Delete Area';
export const DELETE_AREA_FAIL = '[AREAS] Delete Area FAIL';
export const DELETE_AREA_SUCCESS = '[AREAS] Delete Area SUCCESS';

//#region GET_AREAS
export class GetAreasAction implements Action {
    readonly type = GET_AREAS;
    constructor() { }
};

export class GetAreasActionFail implements Action {
    readonly type = GET_AREAS_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasActionSuccess implements Action {
    readonly type = GET_AREAS_SUCCESS;
    constructor(public areas: AreaEmpresa[]) { }
};
//#endregion

//#region PUT_STATE_AREAS
export class PutStateAreasAction implements Action {
    readonly type = PUT_STATE_AREAS;
    constructor(public idArea: number) { }
};

export class PutStateAreasActionFail implements Action {
    readonly type = PUT_STATE_AREAS_FAIL;
    constructor(public payload: any) { }
};

export class PutStateAreasActionSuccess implements Action {
    readonly type = PUT_STATE_AREAS_SUCCESS;
    constructor(public idArea: number) { }
};
//#endregion

//#region PUT_AREAS
export class PutAreasAction implements Action {
    readonly type = PUT_AREAS;
    constructor(public areas: AreaEmpresa) { }
};

export class PutAreasActionFail implements Action {
    readonly type = PUT_AREAS_FAIL;
    constructor(public payload: any) { }
};

export class PutAreasActionSuccess implements Action {
    readonly type = PUT_AREAS_SUCCESS;
    constructor(public editArea: AreaEmpresa) { }
};
//#endregion

//#region  POST_AREAS
export class PostAreasAction implements Action {
    readonly type = POST_AREAS;
    constructor(public area: AreaEmpresa) { }
};
export class PostAreasActionFAIL implements Action {
    readonly type = POST_AREAS_FAIL;
    constructor(public payload: any) { }
};
export class PostAreasActionSUCCESS implements Action {
    readonly type = POST_AREAS_SUCCESS;
    constructor(public newArea: AreaEmpresa) { }
};
//#endregion

//#region  DELETE_AREA
export class DeleteAreaAction implements Action {
    readonly type = DELETE_AREA;
    constructor(public area: AreaEmpresa) { }
};
export class DeleteAreaActionFAIL implements Action {
    readonly type = DELETE_AREA_FAIL;
    constructor(public payload: any) { }
};
export class DeleteAreaActionSUCCESS implements Action {
    readonly type = DELETE_AREA_SUCCESS;
    constructor(public deleteArea: AreaEmpresa) { }
};
//#endregion

export type areasActions =
GetAreasAction |
GetAreasActionFail |
GetAreasActionSuccess |

PostAreasAction |
PostAreasActionFAIL |
PostAreasActionSUCCESS |

PutStateAreasAction |
PutStateAreasActionFail |
PutStateAreasActionSuccess |

PutAreasAction |
PutAreasActionFail |
PutAreasActionSuccess |

DeleteAreaAction |
DeleteAreaActionSUCCESS |
DeleteAreaActionFAIL;