import * as fromMesConfig from '../../../actions/modulos/indicadoresSustentabilidad/mesconfiguracionSus.actions';
import { MesConfiguracion } from 'src/app/shared/models/Indicadores/mes_configuracion.model';

export class MesConfigState {
    list: MesConfiguracion[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: MesConfigState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function mesConfiguracionReducerSus(state = initState, action: fromMesConfig.mesConfiguracionActions_SUS): MesConfigState {
    switch (action.type) {
        //#region GET_MES_CONFIGURACION
        case fromMesConfig.GET_MES_CONFIGURACION_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromMesConfig.GET_MES_CONFIGURACION_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.programa_trabajos.map(mesConfig => {
                        return {
                            ...mesConfig
                        }
                    })
                ]
            };
        case fromMesConfig.GET_MES_CONFIGURACION_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_MES_CONFIGURACION
        case fromMesConfig.PUT_MES_CONFIGURACION_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromMesConfig.PUT_MES_CONFIGURACION_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(mesConfigEditar => {
                        if (mesConfigEditar.id === action.editMesesConfig.id) {
                            return {
                                ...mesConfigEditar,
                                idEspecifico: action.editMesesConfig.idEspecifico,
                                mes: action.editMesesConfig.mes,
                                programado: action.editMesesConfig.programado,
                                ejecutado: action.editMesesConfig.ejecutado,
                                observaciones: action.editMesesConfig.observaciones
                            };
                        } else {
                            return mesConfigEditar;
                        }
                    })
                ]
            };
        case fromMesConfig.PUT_MES_CONFIGURACION_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_MES_CONFIGURACION_PROGRAMADO
        case fromMesConfig.PUT_MES_CONFIGURACION_PROGRAMADO_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromMesConfig.PUT_MES_CONFIGURACION_PROGRAMADO_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [...state.list, action.editMesesConfig[0]]
            };
        case fromMesConfig.PUT_MES_CONFIGURACION_PROGRAMADO_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region POST_MES_CONFIGURACION
        case fromMesConfig.POST_MES_CONFIGURACION_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromMesConfig.POST_MES_CONFIGURACION_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.newMesesConfig[0]],
                loading: false,
                loaded: true,
                error: null
            };
        case fromMesConfig.POST_MES_CONFIGURACION_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region DELETE_MES_CONFIGURACION
        case fromMesConfig.DELETE_MES_CONFIGURACION_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromMesConfig.DELETE_MES_CONFIGURACION_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(mesconfig => {
                    if (mesconfig.id != action.deleteMesConfig.id) {
                        return {
                            mesconfig
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromMesConfig.DELETE_MES_CONFIGURACION_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
