import { Action } from '@ngrx/store';
import { Estrategia } from 'src/app/shared/models/Indicadores/estrategia.model';

export const GET_ESTRATEGIAS = '[ESTRATEGIAS] Get Estrategias';
export const GET_ESTRATEGIAS_FAIL = '[ESTRATEGIAS] Get Estrategias FAIL';
export const GET_ESTRATEGIAS_SUCCESS = '[ESTRATEGIAS] Get Estrategias SUCCESS';

export const PUT_ESTRATEGIAS = '[ESTRATEGIAS] Put Estrategias';
export const PUT_ESTRATEGIAS_FAIL = '[ESTRATEGIAS] Put Estrategias FAIL';
export const PUT_ESTRATEGIAS_SUCCESS = '[ESTRATEGIAS] Put Estrategias SUCCESS';

export const POST_ESTRATEGIA = '[ESTRATEGIAS] Post Estrategia';
export const POST_ESTRATEGIA_FAIL = '[ESTRATEGIAS] Post Estrategia FAIL';
export const POST_ESTRATEGIA_SUCCESS = '[ESTRATEGIAS] Post Estrategia SUCCESS';

export const DELETE_ESTRATEGIA = '[ESTRATEGIAS] Delete Estrategia';
export const DELETE_ESTRATEGIA_FAIL = '[ESTRATEGIAS] Delete Estrategia FAIL';
export const DELETE_ESTRATEGIA_SUCCESS = '[ESTRATEGIAS] Delete Estrategia SUCCESS';

//#region GET_ESTRATEGIAS
export class GetEstrategiasAction implements Action {
    readonly type = GET_ESTRATEGIAS;
    constructor(public idEspecifico: number) { }
};

export class GetEstrategiasActionFail implements Action {
    readonly type = GET_ESTRATEGIAS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstrategiasActionSuccess implements Action {
    readonly type = GET_ESTRATEGIAS_SUCCESS;
    constructor(public estrategias: Estrategia[]) { }
};
//#endregion
//#region PUT_ESTRATEGIA
export class PutEstrategiasAction implements Action {
    readonly type = PUT_ESTRATEGIAS;
    constructor(public estrategia: Estrategia) { }
};

export class PutEstrategiasActionFail implements Action {
    readonly type = PUT_ESTRATEGIAS_FAIL;
    constructor(public payload: any) { }
};

export class PutEstrategiasActionSuccess implements Action {
    readonly type = PUT_ESTRATEGIAS_SUCCESS;
    constructor(public editEstrategia: Estrategia) { }
};
//#endregion

//#region  POST_ESTRATEGIA
export class PostEstrategiaAction implements Action {
    readonly type = POST_ESTRATEGIA;
    constructor(public estrategia: Estrategia) { }
};
export class PostEstrategiaActionFAIL implements Action {
    readonly type = POST_ESTRATEGIA_FAIL;
    constructor(public payload: any) { }
};
export class PostEstrategiaActionSUCCESS implements Action {
    readonly type = POST_ESTRATEGIA_SUCCESS;
    constructor(public newEstrategia: Estrategia) { }
};
//#endregion

//#region  DELETE_ESTRATEGIA
export class DeleteEstrategiaAction implements Action {
    readonly type = DELETE_ESTRATEGIA;
    constructor(public estrategia: Estrategia) { }
};
export class DeleteEstrategiaActionFAIL implements Action {
    readonly type = DELETE_ESTRATEGIA_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstrategiaActionSUCCESS implements Action {
    readonly type = DELETE_ESTRATEGIA_SUCCESS;
    constructor(public deleteEstrategia: Estrategia) { }
};
//#endregion

export type estrategiasActions =
GetEstrategiasAction |
GetEstrategiasActionFail |
GetEstrategiasActionSuccess |

PostEstrategiaAction |
PostEstrategiaActionFAIL |
PostEstrategiaActionSUCCESS |

PutEstrategiasAction |
PutEstrategiasActionFail |
PutEstrategiasActionSuccess |

DeleteEstrategiaAction |
DeleteEstrategiaActionSUCCESS |
DeleteEstrategiaActionFAIL;