import { Action } from '@ngrx/store';
import { AreaUsuario } from 'src/app/shared/models/Indicadores/area_usuario.model';
import { AreasUsuarioAux } from 'src/app/shared/models/Indicadores/areas-usuario-aux.model';

export const GET_AREAS_USUARIO_BY_CORREO = '[AREAS_USUARIO] Get Areas Usuario By Correo';
export const GET_AREAS_USUARIO_BY_CORREO_FAIL = '[AREAS_USUARIO] Get Areas Usuario By Correo FAIL';
export const GET_AREAS_USUARIO_BY_CORREO_SUCCESS = '[AREAS_USUARIO] Get Areas Usuario By Correo SUCCESS';

export const GET_AREAS_USUARIO = '[AREAS_USUARIO] Get Areas Usuario';
export const GET_AREAS_USUARIO_FAIL = '[AREAS_USUARIO] Get Areas Usuario FAIL';
export const GET_AREAS_USUARIO_SUCCESS = '[AREAS_USUARIO] Get Areas Usuario SUCCESS';

export const PUT_AREAS_USUARIO = '[AREAS_USUARIO] Put Areas Usuario';
export const PUT_AREAS_USUARIO_FAIL = '[AREAS_USUARIO] Put Areas Usuario FAIL';
export const PUT_AREAS_USUARIO_SUCCESS = '[AREAS_USUARIO] Put Areas SUCCESS';

export const POST_AREAS_USUARIO = '[AREAS_USUARIO] Post Areas Usuario';
export const POST_AREAS_USUARIO_FAIL = '[AREAS_USUARIO] Post Areas Usuario FAIL';
export const POST_AREAS_USUARIO_SUCCESS = '[AREAS_USUARIO] Post Areas Usuario SUCCESS';

export const DELETE_AREA_USUARIO = '[AREAS_USUARIO] Delete Area Usuario';
export const DELETE_AREA_USUARIO_FAIL = '[AREAS_USUARIO] Delete Area Usuario FAIL';
export const DELETE_AREA_USUARIO_SUCCESS = '[AREAS_USUARIO] Delete Area Usuario SUCCESS';

//#region GET_AREAS_USUARIO_BY_CORREO
export class GetAreasUsuarioByCorreoAction implements Action {
    readonly type = GET_AREAS_USUARIO_BY_CORREO
    constructor(public correo: string) { }
};

export class GetAreasUsuarioByCorreoActionFail implements Action {
    readonly type = GET_AREAS_USUARIO_BY_CORREO_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasUsuarioByCorreoActionSuccess implements Action {
    readonly type = GET_AREAS_USUARIO_BY_CORREO_SUCCESS;
    constructor(public area_usuario: AreaUsuario) { }
};
//#endregion

//#region GET_AREAS_USUARIO
export class GetAreasUsuarioAction implements Action {
    readonly type = GET_AREAS_USUARIO
};

export class GetAreasUsuarioActionFail implements Action {
    readonly type = GET_AREAS_USUARIO_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasUsuarioActionSuccess implements Action {
    readonly type = GET_AREAS_USUARIO_SUCCESS;
    constructor(public listAreasUsuario: AreasUsuarioAux[]) { }
};
//#endregion

//#region PUT_AREAS_USUARIO
export class PutAreasUsuarioAction implements Action {
    readonly type = PUT_AREAS_USUARIO;
    constructor(public areas_usuario: AreasUsuarioAux) { }
};

export class PutAreasUsuarioActionFail implements Action {
    readonly type = PUT_AREAS_USUARIO_FAIL;
    constructor(public payload: any) { }
};

export class PutAreasUsuarioActionSuccess implements Action {
    readonly type = PUT_AREAS_USUARIO_SUCCESS;
    constructor(public editAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

//#region  POST_AREAS_USUARIO
export class PostAreasUsuarioAction implements Action {
    readonly type = POST_AREAS_USUARIO;
    constructor(public area_usuario: AreasUsuarioAux) { }
};
export class PostAreasUsuarioActionFAIL implements Action {
    readonly type = POST_AREAS_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class PostAreasUsuarioActionSUCCESS implements Action {
    readonly type = POST_AREAS_USUARIO_SUCCESS;
    constructor(public newAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

//#region  DELETE_AREA
export class DeleteAreaUsuarioAction implements Action {
    readonly type = DELETE_AREA_USUARIO;
    constructor(public area_usuario: AreasUsuarioAux) { }
};
export class DeleteAreaUsuarioActionFAIL implements Action {
    readonly type = DELETE_AREA_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteAreaUsuarioActionSUCCESS implements Action {
    readonly type = DELETE_AREA_USUARIO_SUCCESS;
    constructor(public deleteAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

export type areasUsuarioActions =
GetAreasUsuarioByCorreoAction |
GetAreasUsuarioByCorreoActionFail |
GetAreasUsuarioByCorreoActionSuccess |

GetAreasUsuarioAction |
GetAreasUsuarioActionFail |
GetAreasUsuarioActionSuccess |

PostAreasUsuarioAction |
PostAreasUsuarioActionFAIL |
PostAreasUsuarioActionSUCCESS |

PutAreasUsuarioAction |
PutAreasUsuarioActionFail |
PutAreasUsuarioActionSuccess |

DeleteAreaUsuarioAction |
DeleteAreaUsuarioActionSUCCESS |
DeleteAreaUsuarioActionFAIL;