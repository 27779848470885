export * from './areas.actions';
export * from './areasusuario.actions';
export * from './config_programatrabajo.actions';
export * from './dashboards.actions';
export * from './especificos.actions';
export * from './estandares.actions';
export * from './estrategias.actions';
export * from './estrategicos.actions';
export * from './frecuencias.actions';
export * from './mesconfiguracion.actions';
export * from './periodos.actions';
export * from './tipos_indicadores.actions';