import * as fromCatalogoAlmacen from '../../../actions/modulos/microMedicion/catalogoAlmacen.actions';
import { CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';

export class CatalogoState {
    list: any[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: CatalogoState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function catalogoReducer(state = initState, action: fromCatalogoAlmacen.catalogoAlmacenActions): CatalogoState {
    switch (action.type) {
        //#region GET_CATALOGO
        case fromCatalogoAlmacen.GET_CATALOGO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCatalogoAlmacen.GET_CATALOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.codigos.map(user => {
                        return {
                            ...user
                        }
                    })
                ]
            };
        case fromCatalogoAlmacen.GET_CATALOGO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_CATALOGO
        case fromCatalogoAlmacen.GET_CATALOGO_VENTA:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCatalogoAlmacen.GET_CATALOGO_VENTA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.codigos.map(codigo => {
                        return {
                            ...codigo
                        }
                    })
                ]
            };
        case fromCatalogoAlmacen.GET_CATALOGO_VENTA_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_CATALOGO
        case fromCatalogoAlmacen.PUT_CATALOGO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCatalogoAlmacen.PUT_CATALOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.codigos.map(codigo => {
                        return {
                            ...codigo
                        }
                    })
                ]
            };
        case fromCatalogoAlmacen.PUT_CATALOGO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_CATALOGO
        case fromCatalogoAlmacen.PUT_PRODUCTO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCatalogoAlmacen.PUT_PRODUCTO_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map((x: CodigoPVMicroMedicion) => {
                        if (x.id == action.producto.id) {
                            return action.producto
                        } else
                            return x;
                    })
                ]
            };
        case fromCatalogoAlmacen.PUT_PRODUCTO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
