import { Action } from '@ngrx/store';
import { Estrategia } from 'src/app/shared/models/Indicadores/estrategia.model';

export const GET_ESTRATEGIAS_SUS = '[ESTRATEGIAS_SUS] Get Estrategias_SUS';
export const GET_ESTRATEGIAS_SUS_FAIL = '[ESTRATEGIAS_SUS] Get Estrategias_SUS FAIL';
export const GET_ESTRATEGIAS_SUS_SUCCESS = '[ESTRATEGIAS_SUS] Get Estrategias_SUS SUCCESS';

export const PUT_ESTRATEGIAS_SUS = '[ESTRATEGIAS_SUS] Put Estrategias_SUS';
export const PUT_ESTRATEGIAS_SUS_FAIL = '[ESTRATEGIAS_SUS] Put Estrategias_SUS FAIL';
export const PUT_ESTRATEGIAS_SUS_SUCCESS = '[ESTRATEGIAS_SUS] Put Estrategias_SUS SUCCESS';

export const POST_ESTRATEGIA_SUS = '[ESTRATEGIAS_SUS] Post Estrategia_SUS';
export const POST_ESTRATEGIA_SUS_FAIL = '[ESTRATEGIAS_SUS] Post Estrategia_SUS FAIL';
export const POST_ESTRATEGIA_SUS_SUCCESS = '[ESTRATEGIAS_SUS] Post Estrategia_SUS SUCCESS';

export const DELETE_ESTRATEGIA_SUS = '[ESTRATEGIAS_SUS] Delete Estrategia_SUS';
export const DELETE_ESTRATEGIA_SUS_FAIL = '[ESTRATEGIAS_SUS] Delete Estrategia_SUS FAIL';
export const DELETE_ESTRATEGIA_SUS_SUCCESS = '[ESTRATEGIAS_SUS] Delete Estrategia_SUS SUCCESS';

//#region GET_ESTRATEGIAS
export class GetEstrategiasAction_SUS implements Action {
    readonly type = GET_ESTRATEGIAS_SUS;
    constructor(public idEspecifico: number) { }
};

export class GetEstrategiasActionFail_SUS implements Action {
    readonly type = GET_ESTRATEGIAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstrategiasActionSuccess_SUS implements Action {
    readonly type = GET_ESTRATEGIAS_SUS_SUCCESS;
    constructor(public estrategias: Estrategia[]) { }
};
//#endregion
//#region PUT_ESTRATEGIA
export class PutEstrategiasAction_SUS implements Action {
    readonly type = PUT_ESTRATEGIAS_SUS;
    constructor(public estrategia: Estrategia) { }
};

export class PutEstrategiasActionFail_SUS implements Action {
    readonly type = PUT_ESTRATEGIAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutEstrategiasActionSuccess_SUS implements Action {
    readonly type = PUT_ESTRATEGIAS_SUS_SUCCESS;
    constructor(public editEstrategia: Estrategia) { }
};
//#endregion

//#region  POST_ESTRATEGIA
export class PostEstrategiaAction_SUS implements Action {
    readonly type = POST_ESTRATEGIA_SUS;
    constructor(public estrategia: Estrategia) { }
};
export class PostEstrategiaActionFAIL_SUS implements Action {
    readonly type = POST_ESTRATEGIA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostEstrategiaActionSUCCESS_SUS implements Action {
    readonly type = POST_ESTRATEGIA_SUS_SUCCESS;
    constructor(public newEstrategia: Estrategia) { }
};
//#endregion

//#region  DELETE_ESTRATEGIA
export class DeleteEstrategiaAction_SUS implements Action {
    readonly type = DELETE_ESTRATEGIA_SUS;
    constructor(public estrategia: Estrategia) { }
};
export class DeleteEstrategiaActionFAIL_SUS implements Action {
    readonly type = DELETE_ESTRATEGIA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstrategiaActionSUCCESS_SUS implements Action {
    readonly type = DELETE_ESTRATEGIA_SUS_SUCCESS;
    constructor(public deleteEstrategia: Estrategia) { }
};
//#endregion

export type estrategiasActions_SUS =
    GetEstrategiasAction_SUS |
    GetEstrategiasActionFail_SUS |
    GetEstrategiasActionSuccess_SUS |

    PostEstrategiaAction_SUS |
    PostEstrategiaActionFAIL_SUS |
    PostEstrategiaActionSUCCESS_SUS |

    PutEstrategiasAction_SUS |
    PutEstrategiasActionFail_SUS |
    PutEstrategiasActionSuccess_SUS |

    DeleteEstrategiaAction_SUS |
    DeleteEstrategiaActionSUCCESS_SUS |
    DeleteEstrategiaActionFAIL_SUS;