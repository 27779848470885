import { Action } from '@ngrx/store';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';

export const GET_ESTANDARES_SUS = '[ESTANDARES_SUS] Get Estandares_SUS';
export const GET_ESTANDARES_SUS_FAIL = '[ESTANDARES_SUS] Get Estandares_SUS FAIL';
export const GET_ESTANDARES_SUS_SUCCESS = '[ESTANDARES_SUS] Get Estandares_SUS SUCCESS';

export const POST_ESTANDAR_SUS = '[POST_ESTANDAR_SUS] Post Estandar_SUS';
export const POST_ESTANDAR_SUS_FAIL = '[POST_ESTANDAR_SUS] Post Estandar_SUS FAIL';
export const POST_ESTANDAR_SUS_SUCCESS = '[POST_ESTANDAR_SUS] Post Estandar_SUS SUCCESS';

export const PUT_ESTANDAR_SUS = '[PUT_ESTANDAR_SUS] Put Estandar_SUS';
export const PUT_ESTANDAR_SUS_FAIL = '[PUT_ESTANDAR_SUS] Put Estandar_SUS FAIL';
export const PUT_ESTANDAR_SUS_SUCCESS = '[PUT_ESTANDAR_SUS] Put Estandar_SUS SUCCESS';

export const DELETE_ESTANDAR_SUS = '[DELETE_ESTANDAR_SUS] Delete Estandar_SUS';
export const DELETE_ESTANDAR_SUS_FAIL = '[DELETE_ESTANDAR_SUS] Delete Estandar_SUS FAIL';
export const DELETE_ESTANDAR_SUS_SUCCESS = '[DELETE_ESTANDAR_SUS] Delete Estandar_SUS SUCCESS';

//#region GET_ESTANDARES
export class GetEstandaresAction_SUS implements Action {
    readonly type = GET_ESTANDARES_SUS;
    constructor() { }
};

export class GetEstandaresActionFail_SUS implements Action {
    readonly type = GET_ESTANDARES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstandaresActionSuccess_SUS implements Action {
    readonly type = GET_ESTANDARES_SUS_SUCCESS;
    constructor(public estandares: Estandares[]) { }
};
//#endregion

//#region  POST_ESTANDAR
export class PostEstandarAction_SUS implements Action {
    readonly type = POST_ESTANDAR_SUS;
    constructor(public estandar: Estandares) { }
};
export class PostEstandarActionFAIL_SUS implements Action {
    readonly type = POST_ESTANDAR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostEstandarActionSUCCESS_SUS implements Action {
    readonly type = POST_ESTANDAR_SUS_SUCCESS;
    constructor(public newEstandar: Estandares) { }
};
//#endregion

//#region  PUT_ESTANDAR
export class PutEstandarAction_SUS implements Action {
    readonly type = PUT_ESTANDAR_SUS;
    constructor(public estandar: Estandares) { }
};
export class PutEstandarActionFAIL_SUS implements Action {
    readonly type = PUT_ESTANDAR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PutEstandarActionSUCCESS_SUS implements Action {
    readonly type = PUT_ESTANDAR_SUS_SUCCESS;
    constructor(public updateEstandar: Estandares) { }
};
//#endregion

//#region  DELETE_ESTANDAR
export class DeleteEstandarAction_SUS implements Action {
    readonly type = DELETE_ESTANDAR_SUS;
    constructor(public estandar: Estandares) { }
};
export class DeleteEstandarActionFAIL_SUS implements Action {
    readonly type = DELETE_ESTANDAR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstandarActionSUCCESS_SUS implements Action {
    readonly type = DELETE_ESTANDAR_SUS_SUCCESS
    constructor(public deleteEstandar: Estandares) { }
};
//#endregion

export type estandaresActions_SUS =
GetEstandaresAction_SUS |
GetEstandaresActionFail_SUS |
GetEstandaresActionSuccess_SUS |

PostEstandarAction_SUS |
PostEstandarActionFAIL_SUS |
PostEstandarActionSUCCESS_SUS |

PutEstandarAction_SUS |
PutEstandarActionFAIL_SUS |
PutEstandarActionSUCCESS_SUS |

DeleteEstandarAction_SUS |
DeleteEstandarActionFAIL_SUS |
DeleteEstandarActionSUCCESS_SUS;