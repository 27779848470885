import { Action } from '@ngrx/store';
import { Actualizaciones } from 'src/app/shared/models/Inwork/actualizaciones.model';

//ACTUALIZACIONES
export const GET_ACTUALIZACIONES = '[INWORK] Get Actualizaciones Inwork';
export const GET_ACTUALIZACIONES_FAIL = '[INWORK] Get Actualizaciones Inwork FAIL';
export const GET_ACTUALIZACIONES_SUCCESS = '[INWORK] Get Actualizaciones Inwork SUCCESS';

export const POST_ACTUALIZACION = '[INWORK] Post Actualizacion Inwork';
export const POST_ACTUALIZACION_FAIL = '[INWORK] Post Actualizacion Inwork FAIL';
export const POST_ACTUALIZACION_SUCCESS = '[INWORK] Post Actualizacion Inwork SUCCESS';

export const PUT_ACTUALIZACION = '[INWORK] Put Actualizacion Inwork';
export const PUT_ACTUALIZACION_FAIL = '[INWORK] Put Actualizacion Inwork FAIL';
export const PUT_ACTUALIZACION_SUCCESS = '[INWORK] Put Actualizacion Inwork SUCCESS';

export const DELETE_ACTUALIZACION = '[INWORK] Delete Actualizacion Inwork';
export const DELETE_ACTUALIZACION_FAIL = '[INWORK] Delete Actualizacion Inwork FAIL';
export const DELETE_ACTUALIZACION_SUCCESS = '[INWORK] Delete Actualizacion Inwork SUCCESS';

//#region GET_ACTUALIZACIONES
export class GetActualizacionesAction implements Action {
    readonly type = GET_ACTUALIZACIONES;
    constructor(public idAtributosPT: number, public numItems: number){}
};

export class GetActualizacionesActionFail implements Action {
    readonly type = GET_ACTUALIZACIONES_FAIL;
    constructor(public payload: any) { }
};

export class GetActualizacionesActionSuccess implements Action {
    readonly type = GET_ACTUALIZACIONES_SUCCESS;
    constructor(public actualizaciones: Actualizaciones[]) { }
};
//#endregion

//#region  POST_ACTUALIZACION
export class PostActualizacionAction implements Action {
    readonly type = POST_ACTUALIZACION;
    constructor(public actualizacion: Actualizaciones) { }
};
export class PostActualizacionActionFAIL implements Action {
    readonly type = POST_ACTUALIZACION_FAIL;
    constructor(public payload: any) { }
};
export class PostActualizacionActionSUCCESS implements Action {
    readonly type = POST_ACTUALIZACION_SUCCESS;
    constructor(public newActualizacion: Actualizaciones) { }
};
//#endregion

//#region  PUT_ACTUALIZACION
export class PutActualizacionAction implements Action {
    readonly type = PUT_ACTUALIZACION;
    constructor(public actualizacion: Actualizaciones) { }
};
export class PutActualizacionActionFAIL implements Action {
    readonly type = PUT_ACTUALIZACION_FAIL;
    constructor(public payload: any) { }
};
export class PutActualizacionActionSUCCESS implements Action {
    readonly type = PUT_ACTUALIZACION_SUCCESS;
    constructor(public updateActualizacion: Actualizaciones) { }
};
//#endregion

//#region  DELETE_ACTUALIZACION
export class DeleteActualizacionAction implements Action {
    readonly type = DELETE_ACTUALIZACION;
    constructor(public actualizacion: Actualizaciones) { }
};
export class DeleteActualizacionActionFAIL implements Action {
    readonly type = DELETE_ACTUALIZACION_FAIL;
    constructor(public payload: any) { }
};
export class DeleteActualizacionActionSUCCESS implements Action {
    readonly type = DELETE_ACTUALIZACION_SUCCESS;
    constructor(public deleteActualizacion: Actualizaciones) { }
};
//#endregion

export type actualizacionesInworkActions =
GetActualizacionesAction |
GetActualizacionesActionFail |
GetActualizacionesActionSuccess |

PostActualizacionAction |
PostActualizacionActionFAIL |
PostActualizacionActionSUCCESS |

PutActualizacionAction |
PutActualizacionActionFAIL |
PutActualizacionActionSUCCESS |

DeleteActualizacionAction |
DeleteActualizacionActionFAIL |
DeleteActualizacionActionSUCCESS;
