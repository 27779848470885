import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as rolesUsuarioActions from '../../../actions/modulos/inwork/roles_usuarios.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { RolesUsuariosService } from 'src/app/services/inwork/roles_usuarios.service';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

@Injectable()

export class rolesUsuariosInworkEffects {
    constructor(
        private actions$: Actions,
        private rolesUsuariosService: RolesUsuariosService,
    ) { }

    @Effect()
    getRolesUsuariosInwork$ = this.actions$.ofType(rolesUsuarioActions.GET_ROLES_USUARIOS)
        .pipe(
            switchMap(() => {
                return this.rolesUsuariosService.getRolesUsuarios()
                    .pipe(
                        map((rolesUsuarios: RolesUsuarios[]) => new rolesUsuarioActions.GetRolesUsuariosActionSuccess(rolesUsuarios)),
                        catchError(error => of(new rolesUsuarioActions.GetRolesUsuariosActionFail(error)))
                    )
            })
        );

    @Effect()
    getRolUsuarioByCorreoInwork$ = this.actions$.ofType(rolesUsuarioActions.GET_ROL_USUARIO_BY_CORREO)
        .pipe(
            switchMap((action: rolesUsuarioActions.GetRolUsuarioByCorreoAction) => {
                return this.rolesUsuariosService.getRolUsuarioByCorreo(action.correo)
                    .pipe(
                        map((rolUsuario: RolesUsuarios) => new rolesUsuarioActions.GetRolUsuarioByCorreoActionSuccess(rolUsuario)),
                        catchError(error => of(new rolesUsuarioActions.GetRolUsuarioByCorreoActionFail(error)))
                    )
            })
        );

    @Effect()
    postRolUsuarioInwork$ = this.actions$.ofType(rolesUsuarioActions.POST_ROL_USUARIO)
        .pipe(
            switchMap((action: rolesUsuarioActions.PostRolUsuarioAction) => {
                return this.rolesUsuariosService.postRolUsuario(action.rolUsuario)
                    .pipe(
                        map((newRolUsuario: RolesUsuarios) => new rolesUsuarioActions.PostRolUsuarioActionSUCCESS(newRolUsuario)),
                        catchError(error => of(new rolesUsuarioActions.PostRolUsuarioActionFAIL(error)))
                    )
            })
        );
        
    @Effect()
    putRolUsuarioInwork$ = this.actions$.ofType(rolesUsuarioActions.PUT_ROL_USUARIO)
        .pipe(
            switchMap((action: rolesUsuarioActions.PutRolUsuarioAction) => {
                const rolUser = new RolesUsuarios();
                rolUser.id = action.rolUsuario.id;
                rolUser.usuario = action.rolUsuario.usuario;
                rolUser.rol = action.rolUsuario.rol;
                
                return this.rolesUsuariosService.putRolUsuario(rolUser)
                    .pipe(
                        map((updateRolUsuario: RolesUsuarios) => new rolesUsuarioActions.PutRolUsuarioActionSUCCESS(updateRolUsuario)),
                        catchError(error => of(new rolesUsuarioActions.PutRolUsuarioActionFAIL(error)))
                    )
            })
        );

        @Effect()
        putStateRolUsuarioInwork$ = this.actions$.ofType(rolesUsuarioActions.PUT_STATE_ROL_USUARIO)
            .pipe(
                switchMap((action: rolesUsuarioActions.PutStateRolUsuarioAction) => {
                    const rolUser = new RolesUsuarios();
                    rolUser.id = action.rolUsuario.id;
                    
                    return this.rolesUsuariosService.putRolUsuarioActivo(rolUser)
                        .pipe(
                            map(() => new rolesUsuarioActions.PutStateRolUsuarioActionSUCCESS(rolUser.id)),
                            catchError(error => of(new rolesUsuarioActions.PutStateRolUsuarioActionFAIL(error)))
                        )
                })
            );

    @Effect()
    deleteRolUsuarioInwork$ = this.actions$.ofType(rolesUsuarioActions.DELETE_ROL_USUARIO)
        .pipe(
            switchMap((action: rolesUsuarioActions.DeleteRolUsuarioAction) => {
                return this.rolesUsuariosService.deleteRolUsuario(action.rolUsuario)
                    .pipe(
                        map((response) => new rolesUsuarioActions.DeleteRolUsuarioActionSUCCESS((response) ? action.rolUsuario : new RolesUsuarios(0, "", "", false, null))),
                        catchError(error => of(new rolesUsuarioActions.DeleteRolUsuarioActionFAIL(error)))
                    )
            })
        );
};
