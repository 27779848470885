import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as configProgramaTrabajoActions from '../../../actions/modulos/indicadoresSustentabilidad/config_programatrabajoSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';
import { ConfigProgramaTrabajoSusService } from 'src/app/services/indicadoresSustentabilidad/config_programatrabajoSus.service';

@Injectable()

export class configProgramaTrabajoSusEffects {
    constructor(
        private actions$: Actions,
        private configProgramaTrabajoService: ConfigProgramaTrabajoSusService,
    ) { }

    @Effect()
    cargarConfiguracionPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.GET_CONFIGURACION_PROGRAMA_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.GetConfiguracionProgramaAction_SUS) => {
                return this.configProgramaTrabajoService.getConfiguracionPrograma(action.idEstrategico)
                    .pipe(
                        map((response) => new configProgramaTrabajoActions.GetConfiguracionProgramaActionSuccess_SUS(response)),
                        catchError(error => of(new configProgramaTrabajoActions.GetConfiguracionProgramaActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    cargarConfiguracionAvance$ = this.actions$.ofType(configProgramaTrabajoActions.GET_CONFIGURACION_AVANCE_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.GetConfiguracionAvanceAction_SUS) => {
                return this.configProgramaTrabajoService.getConfiguracionAvance(action.idEstrategico)
                    .pipe(
                        map((response: any) => new configProgramaTrabajoActions.GetConfiguracionAvanceActionSuccess_SUS(response)),
                        catchError(error => of(new configProgramaTrabajoActions.GetConfiguracionAvanceActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeEditConfigPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.PUT_CONFIGURACION_PROGRAMA_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PutConfiguracionProgramaAction_SUS) => {
                return this.configProgramaTrabajoService.putConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.PutConfiguracionProgramaActionSuccess_SUS(action.configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.PutConfiguracionProgramaActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeEditConfigAvance$ = this.actions$.ofType(configProgramaTrabajoActions.PUT_CONFIGURACION_AVANCE_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PutConfiguracionAvanceAction_SUS) => {
                return this.configProgramaTrabajoService.putConfiguracionAvance(action.configAvance)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.PutConfiguracionAvanceActionSuccess_SUS(action.configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.PutConfiguracionAvanceActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postConfigPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.POST_CONFIGURACION_PROGRAMA_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PostConfiguracionProgramaAction_SUS) => {
                return this.configProgramaTrabajoService.postConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map((configPrograma: ConfiguracionPrograma) => new configProgramaTrabajoActions.PostConfiguracionProgramaActionSUCCESS_SUS(configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.PostConfiguracionProgramaActionFAIL_SUS(error)))
                    )
            })
        );

    @Effect()
    postConfigAvance$ = this.actions$.ofType(configProgramaTrabajoActions.POST_CONFIGURACION_AVANCE_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PostConfiguracionAvanceAction_SUS) => {
                return this.configProgramaTrabajoService.postConfiguracionAvance(action.configAvance)
                    .pipe(
                        map((configAvance: ConfiguracionAvance[]) => new configProgramaTrabajoActions.PostConfiguracionAvanceActionSUCCESS_SUS(configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.PostConfiguracionAvanceActionFAIL_SUS(error)))
                    )
            })
        );

    @Effect()
    deleteConfiguracionPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.DELETE_CONFIGURACION_PROGRAMA_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.DeleteConfiguracionProgramaAction_SUS) => {
                return this.configProgramaTrabajoService.deleteConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.DeleteConfiguracionProgramaActionSUCCESS_SUS(action.configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.DeleteConfiguracionProgramaActionFAIL_SUS(error)))
                    )
            })
        );

    @Effect()
    deleteConfiguracionAvance$ = this.actions$.ofType(configProgramaTrabajoActions.DELETE_CONFIGURACION_AVANCE_SUS)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.DeleteConfiguracionAvanceAction_SUS) => {
                return this.configProgramaTrabajoService.deleteConfiguracionAvance(action.configAvance)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.DeleteConfiguracionAvanceActionSUCCESS_SUS(action.configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.DeleteConfiguracionAvanceActionFAIL_SUS(error)))
                    )
            })
        );
};
