import * as fromItemsCatalogosInwork from '../../../actions/modulos/inwork/items_catalogo.actions';
import { ItemsCatalogo } from 'src/app/shared/models/Inwork/catalogos.model';

export class ItemsCatalogoInworkState {
    list: ItemsCatalogo[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: ItemsCatalogoInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function itemsCatalogoInworkReducer(state = initState, action: fromItemsCatalogosInwork.itemsCatalogosInworkActions): ItemsCatalogoInworkState {
    switch (action.type) {
    //#region GET_ITEMS_CATALOGO
    case fromItemsCatalogosInwork.GET_ITEMS_CATALOGO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromItemsCatalogosInwork.GET_ITEMS_CATALOGO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.itemsCatalogo.map(rowItem => {
                    return {
                        ...rowItem
                    }
                })
            ]
        };
    case fromItemsCatalogosInwork.GET_ITEMS_CATALOGO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_ITEM_CATALOGO
    case fromItemsCatalogosInwork.PUT_ITEM_CATALOGO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromItemsCatalogosInwork.PUT_ITEM_CATALOGO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(rowItem => {
                    if (rowItem.id === action.updateItemCatalogo.id) {
                        return {
                            ...rowItem,
                            item: action.updateItemCatalogo.item,
                            catalogo: action.updateItemCatalogo.catalogo
                        };
                    } else {
                        return rowItem;
                    }
                })
            ]
        };
    case fromItemsCatalogosInwork.PUT_ITEM_CATALOGO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ITEM_CATALOGO
    case fromItemsCatalogosInwork.POST_ITEM_CATALOGO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromItemsCatalogosInwork.POST_ITEM_CATALOGO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newItemCatalogo],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromItemsCatalogosInwork.POST_ITEM_CATALOGO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ITEM_CATALOGO
    case fromItemsCatalogosInwork.DELETE_ITEM_CATALOGO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromItemsCatalogosInwork.DELETE_ITEM_CATALOGO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(item => {
                    if (item.id != action.deleteItemCatalogo.id) {
                        return {
                            item
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromItemsCatalogosInwork.DELETE_ITEM_CATALOGO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
    //#endregion
    default:
        return state;
    }
}
