import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as mesConfiguracionActions from '../../../actions/modulos/indicadoresSustentabilidad/mesconfiguracionSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { MesConfiguracion } from 'src/app/shared/models/Indicadores/mes_configuracion.model';
import { ProgramaTrabajoSusService } from 'src/app/services/indicadoresSustentabilidad/programatrabajoSus.service';

@Injectable()

export class mesConfiguracionSusEffects {
    constructor(
        private actions$: Actions,
        private mesConfigService: ProgramaTrabajoSusService,
    ) { }

    @Effect()
    cargarMesConfiguracion$ = this.actions$.ofType(mesConfiguracionActions.GET_MES_CONFIGURACION_SUS)
        .pipe(
            switchMap((action: mesConfiguracionActions.GetMesConfiguracionAction_SUS) => {
                return this.mesConfigService.getProgramaTrabajo(action.idEspecifico)
                    .pipe(
                        map((mesConfig: MesConfiguracion[]) => new mesConfiguracionActions.GetMesConfiguracionActionSuccess_SUS(mesConfig)),
                        catchError(error => of(new mesConfiguracionActions.GetMesConfiguracionActionFail_SUS(error)))
                    )
            })
        );
    @Effect()
    changeEditMesConfiguracion$ = this.actions$.ofType(mesConfiguracionActions.PUT_MES_CONFIGURACION_SUS)
        .pipe(
            switchMap((action: mesConfiguracionActions.PutMesConfiguracionAction_SUS) => {
                return this.mesConfigService.putProgramaTrabajo(action.mesesConfig)
                    .pipe(
                        map(() => new mesConfiguracionActions.PutMesConfiguracionActionSuccess_SUS(action.mesesConfig)),
                        catchError(error => of(new mesConfiguracionActions.PutMesConfiguracionActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeEditMesConfiguracionProgramado$ = this.actions$.ofType(mesConfiguracionActions.PUT_MES_CONFIGURACION_PROGRAMADO_SUS)
        .pipe(
            switchMap((action: mesConfiguracionActions.PutMesConfiguracionProgramadoAction_SUS) => {
                return this.mesConfigService.putProgramaTrabajoProgramado(action.mesesConfig)
                    .pipe(
                        map(() => new mesConfiguracionActions.PutMesConfiguracionProgramadoActionSuccess_SUS(action.mesesConfig)),
                        catchError(error => of(new mesConfiguracionActions.PutMesConfiguracionProgramadoActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postMesConfiguracion$ = this.actions$.ofType(mesConfiguracionActions.POST_MES_CONFIGURACION_SUS)
        .pipe(
            switchMap((action: mesConfiguracionActions.PostMesConfiguracionAction_SUS) => {
                return this.mesConfigService.postProgramaTrabajo(action.mesesConfig)
                    .pipe(
                        map((mesesConfig: MesConfiguracion[]) => new mesConfiguracionActions.PostMesConfiguracionActionSUCCESS_SUS(mesesConfig)),
                        catchError(error => of(new mesConfiguracionActions.PostMesconfiguracionActionFAIL_SUS(error)))
                    )
            })
        );

    @Effect()
    deleteMesConfiguracion$ = this.actions$.ofType(mesConfiguracionActions.DELETE_MES_CONFIGURACION_SUS)
        .pipe(
            switchMap((action: mesConfiguracionActions.DeleteMesConfiguracionAction_SUS) => {
                return this.mesConfigService.deleteProgramaTrabajo(action.mesConfig)
                    .pipe(
                        map(() => new mesConfiguracionActions.DeleteMesConfiguracionActionSUCCESS_SUS(action.mesConfig)),
                        catchError(error => of(new mesConfiguracionActions.DeleteMesConfiguracionActionFAIL_SUS(error)))
                    )
            })
        );
};
