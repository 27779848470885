import { Action } from '@ngrx/store';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';
import { Tareas } from 'src/app/shared/models/Inwork/tareas.model';

//TAREAS
export const GET_TAREAS_BY_CP = '[INWORK] Get Tareas By Car-Pro Inwork';
export const GET_TAREAS_BY_CP_FAIL = '[INWORK] Get Tareas By Car-Pro Inwork FAIL';
export const GET_TAREAS_BY_CP_SUCCESS = '[INWORK] Get Tareas By Car-Pro Inwork SUCCESS';

export const GET_TAREAS_BY_DEP = '[INWORK] Get Tareas By Depndncia Inwork';
export const GET_TAREAS_BY_DEP_FAIL = '[INWORK] Get Tareas By Depndncia Inwork FAIL';
export const GET_TAREAS_BY_DEP_SUCCESS = '[INWORK] Get Tareas By Depndncia Inwork SUCCESS';

export const GET_SHARED_BY = '[INWORK] Get Task Shared By Inwork';
export const GET_SHARED_BY_FAIL = '[INWORK] Get Task Shared By Inwork FAIL';
export const GET_SHARED_BY_SUCCESS = '[INWORK] Get Task Shared By Inwork SUCCESS';

export const POST_TAREA = '[INWORK] Post Tarea Inwork';
export const POST_TAREA_FAIL = '[INWORK] Post Tarea Inwork FAIL';
export const POST_TAREA_SUCCESS = '[INWORK] Post Tarea Inwork SUCCESS';

export const PUT_TAREA = '[INWORK] Put Tarea Inwork';
export const PUT_TAREA_FAIL = '[INWORK] Put Tarea Inwork FAIL';
export const PUT_TAREA_SUCCESS = '[INWORK] Put Tarea Inwork SUCCESS';

export const DELETE_TAREA = '[INWORK] Delete Tarea Inwork';
export const DELETE_TAREA_FAIL = '[INWORK] Delete Tarea Inwork FAIL';
export const DELETE_TAREA_SUCCESS = '[INWORK] Delete Tarea Inwork SUCCESS';

//#region GET_TAREAS_BY_CARPETAPROYECTO
export class GetTareasByCPAction implements Action {
    readonly type = GET_TAREAS_BY_CP;
    constructor(public idCarpetaProyecto: number) { }
};

export class GetTareasByCPActionFail implements Action {
    readonly type = GET_TAREAS_BY_CP_FAIL;
    constructor(public payload: any) { }
};

export class GetTareasByCPActionSuccess implements Action {
    readonly type = GET_TAREAS_BY_CP_SUCCESS;
    constructor(public tareas: Tareas[]) { }
};
//#endregion

//#region GET_TAREAS_BY_DEPENDENCIA
export class GetTareasByDependenciaAction implements Action {
    readonly type = GET_TAREAS_BY_DEP;
    constructor(public dependencia: number) { }
};

export class GetTareasByDependenciaActionFail implements Action {
    readonly type = GET_TAREAS_BY_DEP_FAIL;
    constructor(public payload: any) { }
};

export class GetTareasByDependenciaActionSuccess implements Action {
    readonly type = GET_TAREAS_BY_DEP_SUCCESS;
    constructor(public tareas: Tareas[]) { }
};
//#endregion

//#region GET_SHARED_BY
export class GetSharedByAction implements Action {
    readonly type = GET_SHARED_BY;
    constructor(public idTarea: number) { }
};

export class GetSharedByActionFail implements Action {
    readonly type = GET_SHARED_BY_FAIL;
    constructor(public payload: any) { }
};

export class GetSharedByActionSuccess implements Action {
    readonly type = GET_SHARED_BY_SUCCESS;
    constructor(public users: Usuario[]) { }
};
//#endregion

//#region  POST_TAREA
export class PostTareaAction implements Action {
    readonly type = POST_TAREA;
    constructor(public tarea: Tareas) { }
};
export class PostTareaActionFAIL implements Action {
    readonly type = POST_TAREA_FAIL;
    constructor(public payload: any) { }
};
export class PostTareaActionSUCCESS implements Action {
    readonly type = POST_TAREA_SUCCESS;
    constructor(public newTarea: Tareas) { }
};
//#endregion

//#region  PUT_TAREA
export class PutTareaAction implements Action {
    readonly type = PUT_TAREA;
    constructor(public tarea: Tareas) { }
};
export class PutTareaActionFAIL implements Action {
    readonly type = PUT_TAREA_FAIL;
    constructor(public payload: any) { }
};
export class PutTareaActionSUCCESS implements Action {
    readonly type = PUT_TAREA_SUCCESS;
    constructor(public updateTarea: Tareas) { }
};
//#endregion

//#region  DELETE_TAREA
export class DeleteTareaAction implements Action {
    readonly type = DELETE_TAREA;
    constructor(public tarea: Tareas) { }
};
export class DeleteTareaActionFAIL implements Action {
    readonly type = DELETE_TAREA_FAIL;
    constructor(public payload: any) { }
};
export class DeleteTareaActionSUCCESS implements Action {
    readonly type = DELETE_TAREA_SUCCESS;
    constructor(public deleteTarea: Tareas) { }
};
//#endregion

export type tareasInworkActions =
GetTareasByCPAction |
GetTareasByCPActionFail |
GetTareasByCPActionSuccess |

GetTareasByDependenciaAction |
GetTareasByDependenciaActionFail |
GetTareasByDependenciaActionSuccess |

GetSharedByAction |
GetSharedByActionFail |
GetSharedByActionSuccess |

PostTareaAction |
PostTareaActionFAIL |
PostTareaActionSUCCESS |

PutTareaAction |
PutTareaActionFAIL |
PutTareaActionSUCCESS |

DeleteTareaAction |
DeleteTareaActionFAIL |
DeleteTareaActionSUCCESS;
