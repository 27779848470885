import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { loteMicroMedicion } from 'src/app/shared/models/microMedicion/lote.model';
import { ExceptionsService } from '../../exceptions.service';

@Injectable({
    providedIn: 'root'
})
export class LotesAlmacenService {

    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region  GET
    getLotes() {
        return this.http.get(`${environment.apiAlmacen}almacen/getLots`)
            .pipe(
                map((response: loteMicroMedicion[]) => {
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion
}