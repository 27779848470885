import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { Grupos, GruposUsuarios } from 'src/app/shared/models/Inwork/grupos.model';
import { GroupServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class GruposService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getGrupos() {
        return this.http.get(`${GroupServURL}/getGroups`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putGrupo(grupo: Grupos) {
        return this.http.post(`${GroupServURL}/putGroup`, grupo)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos del grupo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putGruposUser(listGruposUsuario: GruposUsuarios[]) {
        return this.http.post(`${GroupServURL}/putGroupsUsers`, { data: listGruposUsuario })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos!', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postGrupo(grupo: Grupos) {
        return this.http.post(`${GroupServURL}/postGroup`, { data: grupo })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos del nuevo grupo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postGruposUser(listGruposUsuario: GruposUsuarios[]) {
        return this.http.post(`${GroupServURL}/postGroupsUsers`, { data: listGruposUsuario })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos!', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteGrupo(grupo: Grupos) {
        return this.http.get(`${GroupServURL}/deleteGroup?idGroup=${grupo.id}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos del grupo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteGruposUser(user: string) {
        return this.http.get(`${GroupServURL}/deleteGroupsUser?user=${user}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos!', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
}
