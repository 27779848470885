import * as fromConfigProgramaTrabajo from '../../../actions/modulos/indicadoresSustentabilidad/config_programatrabajoSus.actions';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';

export class configProgramaTrabajoState {
    configuracionPrograma: ConfiguracionPrograma;
    configuracionAvance: ConfiguracionAvance[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: configProgramaTrabajoState = {
    configuracionPrograma: null,
    configuracionAvance: [],
    loaded: false,
    loading: false,
    error: null
};

export function configProgramaTrabajoReducerSus(state = initState, action: fromConfigProgramaTrabajo.configuracionProgramaActions_SUS): configProgramaTrabajoState {
    switch (action.type) {
        //#region GET_CONFIGURACION_PROGRAMA
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_PROGRAMA_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_PROGRAMA_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                configuracionPrograma: action.configPrograma
            };
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_PROGRAMA_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region GET_CONFIGURACION_AVANCE
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_AVANCE_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_AVANCE_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                configuracionAvance: [
                    ...action.configAvance.map(config => {
                        return {
                            ...config
                        }
                    })
                ]
            };
        case fromConfigProgramaTrabajo.GET_CONFIGURACION_AVANCE_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region PUT_CONFIGURACION_PROGRAMA
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_PROGRAMA_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_PROGRAMA_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                configuracionPrograma: action.editConfigPrograma
            };
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_PROGRAMA_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region PUT_CONFIGURACION_AVANCE
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_AVANCE_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_AVANCE_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                configuracionAvance: [...state.configuracionAvance, action.editConfigAvance[0]]
            };
        case fromConfigProgramaTrabajo.PUT_CONFIGURACION_AVANCE_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        
        //#region POST_CONFIGURACION_PROGRAMA
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_PROGRAMA_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_PROGRAMA_SUS_SUCCESS:
            return {
                ...state,
                configuracionPrograma: action.newConfigPrograma,
                loading: false,
                loaded: true,
                error: null
            };
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_PROGRAMA_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region POST_CONFIGURACION_AVANCE
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_AVANCE_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_AVANCE_SUS_SUCCESS:
            return {
                ...state,
                configuracionAvance: [...state.configuracionAvance, action.newConfigAvance[0]],
                loading: false,
                loaded: true,
                error: null
            };
        case fromConfigProgramaTrabajo.POST_CONFIGURACION_AVANCE_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region DELETE_CONFIGURACION_PROGRAMA
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_PROGRAMA_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_PROGRAMA_SUS_SUCCESS:
            return {
                ...state,
                configuracionPrograma: null,
                loading: false,
                loaded: true,
                error: null
            };
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_PROGRAMA_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        //#region DELETE_MES_CONFIGURACION
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_AVANCE_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_AVANCE_SUS_SUCCESS:
            return {
                ...state,
                configuracionAvance: [...state.configuracionAvance.filter(config => {
                    if (config.idEstrategico != action.deleteConfigAvance.idEstrategico) {
                        return {
                            config
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromConfigProgramaTrabajo.DELETE_CONFIGURACION_AVANCE_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
