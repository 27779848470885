import { Action } from '@ngrx/store';
import { Grupos } from 'src/app/shared/models/Inwork/grupos.model';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

//GRUPOS
export const GET_GRUPOS = '[INWORK] Get Grupos Inwork';
export const GET_GRUPOS_FAIL = '[INWORK] Get Grupos Inwork FAIL';
export const GET_GRUPOS_SUCCESS = '[INWORK] Get Grupos Inwork SUCCESS';

export const POST_GRUPO = '[INWORK] Post Grupo Inwork';
export const POST_GRUPO_FAIL = '[INWORK] Post Grupo Inwork FAIL';
export const POST_GRUPO_SUCCESS = '[INWORK] Post Grupo Inwork SUCCESS';

export const PUT_GRUPO = '[INWORK] Put Grupo Inwork';
export const PUT_GRUPO_FAIL = '[INWORK] Put Grupo Inwork FAIL';
export const PUT_GRUPO_SUCCESS = '[INWORK] Put Grupo Inwork SUCCESS';

export const DELETE_GRUPO = '[INWORK] Delete Grupo Inwork';
export const DELETE_GRUPO_FAIL = '[INWORK] Delete Grupo Inwork FAIL';
export const DELETE_GRUPO_SUCCESS = '[INWORK] Delete Grupo Inwork SUCCESS';


//#region GET_GRUPOS
export class GetGruposAction implements Action {
    readonly type = GET_GRUPOS;
};

export class GetGruposActionFail implements Action {
    readonly type = GET_GRUPOS_FAIL;
    constructor(public payload: any) { }
};

export class GetGruposActionSuccess implements Action {
    readonly type = GET_GRUPOS_SUCCESS;
    constructor(public grupos: Grupos[]) { }
};
//#endregion

//#region  POST_GRUPO
export class PostGrupoAction implements Action {
    readonly type = POST_GRUPO;
    constructor(public grupo: Grupos) { }
};
export class PostGrupoActionFAIL implements Action {
    readonly type = POST_GRUPO_FAIL;
    constructor(public payload: any) { }
};
export class PostGrupoActionSUCCESS implements Action {
    readonly type = POST_GRUPO_SUCCESS;
    constructor(public newGrupo: Grupos) { }
};
//#endregion

//#region  PUT_GRUPO
export class PutGrupoAction implements Action {
    readonly type = PUT_GRUPO;
    constructor(public grupo: Grupos) { }
};
export class PutGrupoActionFAIL implements Action {
    readonly type = PUT_GRUPO_FAIL;
    constructor(public payload: any) { }
};
export class PutGrupoActionSUCCESS implements Action {
    readonly type = PUT_GRUPO_SUCCESS;
    constructor(public updateGrupo: Grupos) { }
};
//#endregion

//#region  DELETE_GRUPO
export class DeleteGrupoAction implements Action {
    readonly type = DELETE_GRUPO;
    constructor(public grupo: Grupos) { }
};
export class DeleteGrupoActionFAIL implements Action {
    readonly type = DELETE_GRUPO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteGrupoActionSUCCESS implements Action {
    readonly type = DELETE_GRUPO_SUCCESS;
    constructor(public deleteGrupo: Grupos) { }
};
//#endregion

export type gruposInworkActions =
GetGruposAction |
GetGruposActionFail |
GetGruposActionSuccess |

PostGrupoAction |
PostGrupoActionFAIL |
PostGrupoActionSUCCESS |

PutGrupoAction |
PutGrupoActionFAIL |
PutGrupoActionSUCCESS |

DeleteGrupoAction |
DeleteGrupoActionFAIL |
DeleteGrupoActionSUCCESS;