import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as flujoTrabajoActions from '../../../actions/modulos/inwork/flujo_trabajo.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { FlujoTrabajoService } from 'src/app/services/inwork/flujo_trabajo.service';
import { DataFlujoTrabajo } from 'src/app/shared/models/Inwork/models_globales.model';
import { FlujosTrabajo } from 'src/app/shared/models/Inwork/flujo_trabajo.model';

@Injectable()

export class flujoTrabajoInworkEffects {
    constructor(
        private actions$: Actions,
        private flujoTrabajoService: FlujoTrabajoService,
    ) { }

    @Effect()
    getFlujosTrabajoInwork$ = this.actions$.ofType(flujoTrabajoActions.GET_FLUJOS_TRABAJO)
        .pipe(
            switchMap(() => {
                return this.flujoTrabajoService.getFlujosTrabajo()
                    .pipe(
                        map((flujosTrabajo: DataFlujoTrabajo[]) => new flujoTrabajoActions.GetFlujosTrabajoActionSuccess(flujosTrabajo)),
                        catchError(error => of(new flujoTrabajoActions.GetFlujosTrabajoActionFail(error)))
                    )
            })
        );
    
    @Effect()
    getFlujosInwork$ = this.actions$.ofType(flujoTrabajoActions.GET_FLUJOS)
        .pipe(
            switchMap(() => {
                return this.flujoTrabajoService.getFlujos()
                    .pipe(
                        map((flujosTrabajo: FlujosTrabajo[]) => new flujoTrabajoActions.GetFlujosActionSuccess(flujosTrabajo)),
                        catchError(error => of(new flujoTrabajoActions.GetFlujosActionFail(error)))
                    )
            })
        );

    @Effect()
    postFlujoTrabajoInwork$ = this.actions$.ofType(flujoTrabajoActions.POST_FLUJO_TRABAJO)
        .pipe(
            switchMap((action: flujoTrabajoActions.PostFlujoTrabajoAction) => {
                return this.flujoTrabajoService.postFlujoTrabajo(action.flujoTrabajo)
                    .pipe(
                        map((newFlujoTrabajo: DataFlujoTrabajo) => new flujoTrabajoActions.PostFlujoTrabajoActionSUCCESS(newFlujoTrabajo)),
                        catchError(error => of(new flujoTrabajoActions.PostFlujoTrabajoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putFlujoTrabajoInwork$ = this.actions$.ofType(flujoTrabajoActions.PUT_FLUJO_TRABAJO)
        .pipe(
            switchMap((action: flujoTrabajoActions.PutFlujoTrabajoAction) => {
                const dataFT = new DataFlujoTrabajo();
                dataFT.id = action.flujoTrabajo.id;
                dataFT.nombre = action.flujoTrabajo.nombre;
                dataFT.creadoPor = action.flujoTrabajo.creadoPor;
                dataFT.dataEstadosPrimarios = action.flujoTrabajo.dataEstadosPrimarios;
                
                return this.flujoTrabajoService.putFlujoTrabajo(dataFT)
                    .pipe(
                        map((updateFlujoTrabajo: DataFlujoTrabajo) => new flujoTrabajoActions.PutFlujoTrabajoActionSUCCESS(updateFlujoTrabajo)),
                        catchError(error => of(new flujoTrabajoActions.PutFlujoTrabajoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteFlujoTrabajoInwork$ = this.actions$.ofType(flujoTrabajoActions.DELETE_FLUJO_TRABAJO)
        .pipe(
            switchMap((action: flujoTrabajoActions.DeleteFlujoTrabajoAction) => {
                return this.flujoTrabajoService.deleteFlujoTrabajo(action.flujoTrabajo)
                    .pipe(
                        map((response) => new flujoTrabajoActions.DeleteFlujoTrabajoActionSUCCESS((response) ? action.flujoTrabajo : new FlujosTrabajo(0,"",null))),
                        catchError(error => of(new flujoTrabajoActions.DeleteFlujoTrabajoActionFAIL(error)))
                    )
            })
        );
};
