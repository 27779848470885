import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as areasActions from '../../../actions/modulos/indicadoresSustentabilidad/areasSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';
import { AreasSusService } from 'src/app/services/indicadoresSustentabilidad/areasSus.service';

@Injectable()

export class areasSusEffects {
    constructor(
        private actions$: Actions,
        private areasService: AreasSusService,
    ) { }

    @Effect()
    cargarAreas$ = this.actions$.ofType(areasActions.GET_AREAS_SUS)
        .pipe(
            switchMap(() => {
                return this.areasService.getAreas()
                    .pipe(
                        map((response: any) => new areasActions.GetAreasActionSuccess_SUS(response)),
                        catchError(error => of(new areasActions.GetAreasActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeEditArea$ = this.actions$.ofType(areasActions.PUT_AREAS_SUS)
        .pipe(
            switchMap((action: areasActions.PutAreasAction_SUS) => {
                const areas = new AreaEmpresa();
                areas.id = action.areas.id;
                areas.descripcion = action.areas.descripcion;
                areas.dependencia = action.areas.dependencia;
                areas.nivel = action.areas.nivel;
                areas.permiso = action.areas.permiso;
                areas.idEmpresa = action.areas.idEmpresa;

                return this.areasService.putArea(areas)
                    .pipe(
                        map(() => new areasActions.PutAreasActionSuccess_SUS(areas)),
                        catchError(error => of(new areasActions.PutAreasActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeStateArea$ = this.actions$.ofType(areasActions.PUT_STATE_AREAS_SUS)
        .pipe(
            switchMap((action: areasActions.PutStateAreasAction_SUS) => {
                const area = new AreaEmpresa();
                area.id = action.idArea;
                return this.areasService.putAreaPermiso(area)
                    .pipe(
                        map(() => new areasActions.PutStateAreasActionSuccess_SUS(area.id)),
                        catchError(error => of(new areasActions.PutStateAreasActionFail_SUS(error)))
                    )
            })
        );
        
    @Effect()
    postArea$ = this.actions$.ofType(areasActions.POST_AREAS_SUS)
        .pipe(
            switchMap((action: areasActions.PostAreasAction_SUS) => {
                return this.areasService.postArea(action.area)
                    .pipe(
                        map((area: AreaEmpresa) => new areasActions.PostAreasActionSUCCESS_SUS(area)),
                        catchError(error => of(new areasActions.PostAreasActionFAIL_SUS(error)))
                    )
            })
        );

    @Effect()
    deleteArea$ = this.actions$.ofType(areasActions.DELETE_AREA_SUS)
        .pipe(
            switchMap((action: areasActions.DeleteAreaAction_SUS) => {
                return this.areasService.deleteArea(action.area)
                    .pipe(
                        map(() => new areasActions.DeleteAreaActionSUCCESS_SUS(action.area)),
                        catchError(error => of(new areasActions.DeleteAreaActionFAIL_SUS(error)))
                    )
            })
        );
};
