import { Action } from '@ngrx/store';
import { Proveedor } from 'src/app/shared/models/microMedicion/proveedor.model';

export const GET_PROVEEDORES = '[ALMACEN] Get Proveedores';
export const GET_PROVEEDORES_FAIL = '[ALMACEN] Get Proveedores FAIL';
export const GET_PROVEEDORES_SUCCESS = '[ALMACEN] Get Proveedores SUCCESS';

//#region GET_PROVEEDORES
export class GetUProveedorAction implements Action {
    readonly type = GET_PROVEEDORES;
};

export class GetUProveedorActionFail implements Action {
    readonly type = GET_PROVEEDORES_FAIL;
    constructor(public payload: any) { }
};

export class GetUProveedorActionSuccess implements Action {
    readonly type = GET_PROVEEDORES_SUCCESS;
    constructor(public proveedores: Proveedor[]) { }
};
//#endregion

export type proveedorActions =
    GetUProveedorAction |
    GetUProveedorActionFail |
    GetUProveedorActionSuccess;