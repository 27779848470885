import * as fromProveedor from '../../../actions/modulos/microMedicion/proveedor.actions';
import { Proveedor } from 'src/app/shared/models/microMedicion/proveedor.model';

export class ProveedorState {
    list: Proveedor[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: ProveedorState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function proveedorReducer(state = initState, action: fromProveedor.proveedorActions): ProveedorState {
    switch (action.type) {
        //#region GET_PROVEEDORES
        case fromProveedor.GET_PROVEEDORES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromProveedor.GET_PROVEEDORES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.proveedores.map(proveedor => {
                        return {
                            ...proveedor
                        }
                    })
                ]
            };
        case fromProveedor.GET_PROVEEDORES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
