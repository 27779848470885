import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as especificosActions from '../../../actions/modulos/indicadores/especificos.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ObjEspecificoService } from 'src/app/services/indicadores/obj_esp.service';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';

@Injectable()

export class especificosEffects {
    constructor(
        private actions$: Actions,
        private especificosService: ObjEspecificoService,
    ) { }

        @Effect()
        cargarEspecificos$ = this.actions$.ofType(especificosActions.GET_ESPECIFICOS)
            .pipe(
                switchMap((action: especificosActions.GetEspecificosAction) => {
                    return this.especificosService.getObjetivosEspecificos(action.idArea, action.periodo)
                        .pipe(
                            map((response: any) => new especificosActions.GetEspecificosActionSuccess(response)),
                            catchError(error => of(new especificosActions.GetEspecificosActionFail(error)))
                        )
                })
            );

        @Effect()
        cargarReporte$ = this.actions$.ofType(especificosActions.GET_REPORTE_ESPECIFICOS)
            .pipe(
                switchMap((action: especificosActions.GetReporteEspecificosAction) => {
                    return this.especificosService.getReporteIndicadores(action.criteria)
                        .pipe(
                            map((response: any) => new especificosActions.GetReporteEspecificosActionSuccess(response)),
                            catchError(error => of(new especificosActions.GetReporteEspecificosActionFail(error)))
                        )
                })
            );

        @Effect()
        changeEditEspecifico$ = this.actions$.ofType(especificosActions.PUT_ESPECIFICO)
            .pipe(
                switchMap((action: especificosActions.PutEspecificoAction) => {
                    const especifico= new ObjetivoEspecifico();
                    especifico.id = action.especifico.id;
                    especifico.sustancial = action.especifico.sustancial;
                    especifico.nombre = action.especifico.nombre;
                    especifico.descripcion = action.especifico.descripcion;
                    especifico.indicador = action.especifico.indicador;
                    especifico.tipoIndicador = action.especifico.tipoIndicador;
                    especifico.frecuencia = action.especifico.frecuencia;
                    especifico.formula = action.especifico.formula;
                    especifico.lineaBase = action.especifico.lineaBase;
                    especifico.metaProgramada = action.especifico.metaProgramada;
                    especifico.responsable = action.especifico.responsable;
                    especifico.entregable = action.especifico.entregable;
                    especifico.unidadMedida = action.especifico.unidadMedida;
                    especifico.observaciones = action.especifico.observaciones;
                    especifico.periodo = action.especifico.periodo;
                    return this.especificosService.putObjetivoEspecifico(especifico)
                        .pipe(
                            map((response : any) => new especificosActions.PutEspecificoActionSuccess(response)),
                            catchError(error => of(new especificosActions.PutEspecificoActionFail(error)))
                        )
                })
            ); 
        @Effect()
        changeValidadoEspecifico$ = this.actions$.ofType(especificosActions.PUT_STATE_ESPECIFICO)
            .pipe(
                switchMap((action: especificosActions.PutStateEspecificoAction) => {
                    const especifico = new ObjetivoEspecifico();
                    especifico.id = action.id;
                    return this.especificosService.putEspecificoValidado(especifico)
                        .pipe(
                            map(() => new especificosActions.PutStateEspecificoActionSuccess(especifico.id)),
                            catchError(error => of(new especificosActions.PutStateEspecificoActionFail(error)))
                        )
                })
            );       
        @Effect()
        postEspecifico$ = this.actions$.ofType(especificosActions.POST_ESPECIFICO)
            .pipe(
                switchMap((action: especificosActions.PostEspecificoAction) => {
                    return this.especificosService.postObjetivoEspecifico(action.especifico)
                        .pipe(
                            map((especifico: ObjetivoEspecifico) => new especificosActions.PostEspecificoActionSUCCESS(especifico)),
                            catchError(error => of(new especificosActions.PostEspecificoActionFAIL(error)))
                        )
                })
            );
        @Effect()
        deleteEspecifico$ = this.actions$.ofType(especificosActions.DELETE_ESPECIFICO)
            .pipe(
                switchMap((action: especificosActions.DeleteEspecificoAction) => {
                    return this.especificosService.deleteEspecifico(action.especifico)
                        .pipe(
                            map(() => new especificosActions.DeleteEspecificoActionSUCCESS(action.especifico)),
                            catchError(error => of(new especificosActions.DeleteEspecificoActionFAIL(error)))
                        )
                })
            );
};
