import { Action } from '@ngrx/store';
import { GruposUsuarios } from 'src/app/shared/models/Inwork/grupos.model';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

//GRUPOS_USUARIOS
export const POST_GRUPOS_USER = '[INWORK] Post Grupos User Inwork';
export const POST_GRUPOS_USER_FAIL = '[INWORK] Post Grupos User Inwork FAIL';
export const POST_GRUPOS_USER_SUCCESS = '[INWORK] Post Grupos User Inwork SUCCESS';

export const PUT_GRUPOS_USER = '[INWORK] Put Grupos User Inwork';
export const PUT_GRUPOS_USER_FAIL = '[INWORK] Put Grupos User Inwork FAIL';
export const PUT_GRUPOS_USER_SUCCESS = '[INWORK] Put Grupos User Inwork SUCCESS';

export const DELETE_GRUPOS_USER = '[INWORK] Delete Grupos User Inwork';
export const DELETE_GRUPOS_USER_FAIL = '[INWORK] Delete Grupos User Inwork FAIL';
export const DELETE_GRUPOS_USER_SUCCESS = '[INWORK] Delete Grupos User Inwork SUCCESS';

//#region  POST_GRUPOS_USUARIO
export class PostGruposUserAction implements Action {
    readonly type = POST_GRUPOS_USER;
    constructor(public listGruposUsuario: GruposUsuarios[]) { }
};
export class PostGruposUserActionFAIL implements Action {
    readonly type = POST_GRUPOS_USER_FAIL;
    constructor(public payload: any) { }
};
export class PostGruposUserActionSUCCESS implements Action {
    readonly type = POST_GRUPOS_USER_SUCCESS;
    constructor(public rolesUsuarios: RolesUsuarios[]) { }
};
//#endregion

//#region  PUT_GRUPOS_USUARIO
export class PutGruposUserAction implements Action {
    readonly type = PUT_GRUPOS_USER;
    constructor(public listGruposUsuario: GruposUsuarios[]) { }
};
export class PutGruposUserActionFAIL implements Action {
    readonly type = PUT_GRUPOS_USER_FAIL;
    constructor(public payload: any) { }
};
export class PutGruposUserActionSUCCESS implements Action {
    readonly type = PUT_GRUPOS_USER_SUCCESS;
    constructor(public rolesUsuarios: RolesUsuarios[]) { }
};
//#endregion

//#region  DELETE_GRUPOS_USUARIO
export class DeleteGruposUserAction implements Action {
    readonly type = DELETE_GRUPOS_USER;
    constructor(public user: string) { }
};
export class DeleteGruposUserActionFAIL implements Action {
    readonly type = DELETE_GRUPOS_USER_FAIL;
    constructor(public payload: any) { }
};
export class DeleteGruposUserActionSUCCESS implements Action {
    readonly type = DELETE_GRUPOS_USER_SUCCESS;
    constructor(public rolesUsuarios: RolesUsuarios[]) { }
};
//#endregion

export type gruposUsuarioInworkActions =
PostGruposUserAction |
PostGruposUserActionFAIL |
PostGruposUserActionSUCCESS |

PutGruposUserAction |
PutGruposUserActionFAIL |
PutGruposUserActionSUCCESS |

DeleteGruposUserAction |
DeleteGruposUserActionFAIL |
DeleteGruposUserActionSUCCESS;