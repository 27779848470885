import { Action } from '@ngrx/store';
import { proyectoMicroMedicion } from 'src/app/shared/models/microMedicion/proyectoMicroMedicion.model';

export const GET_PROYECTO = '[ALMACEN] Get Proyeco';
export const GET_PROYECTO_FAIL = '[ALMACEN] Get Proyeco FAIL';
export const GET_PROYECTO_SUCCESS = '[ALMACEN] Get Proyeco SUCCESS';

//#region GET_proyectoES
export class GetProyecoAction implements Action {
    readonly type = GET_PROYECTO;
};

export class GetProyecoActionFail implements Action {
    readonly type = GET_PROYECTO_FAIL;
    constructor(public payload: any) { }
};

export class GetProyecoActionSuccess implements Action {
    readonly type = GET_PROYECTO_SUCCESS;
    constructor(public proyectos: proyectoMicroMedicion[]) { }
};
//#endregion

export type proyectoAlmacenActions =
    GetProyecoAction |
    GetProyecoActionFail |
    GetProyecoActionSuccess;