import * as fromMedidoresAlmacen from '../../../actions/modulos/microMedicion/medidoresAlmacen.actions';
import { Medidor } from 'src/app/shared/models/microMedicion/medidor.model';
import { ResponseAltaMedidores } from 'src/app/shared/models/microMedicion/responseAltaMedidores.model';

export class MedidoresAlmacenState {
    list: Medidor[];
    altaMedidores: ResponseAltaMedidores;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: MedidoresAlmacenState = {
    list: [],
    altaMedidores: null,
    loaded: false,
    loading: false,
    error: null
};

export function medidoresAlmacenReducer(state = initState, action: fromMedidoresAlmacen.medidoresAlmacenActions): MedidoresAlmacenState {
    switch (action.type) {
        //#region GET_MEDIDORES
        case fromMedidoresAlmacen.GET_MEDIDORES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromMedidoresAlmacen.GET_MEDIDORES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.medidores.map((medidor: Medidor) => {
                        return {
                            ...medidor
                        }
                    })
                ]
            };
        case fromMedidoresAlmacen.GET_MEDIDORES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region POST_MEDIDORES
        case fromMedidoresAlmacen.POST_MEDIDORES:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: null
            };
        case fromMedidoresAlmacen.POST_MEDIDORES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                altaMedidores: action.data
            };
        case fromMedidoresAlmacen.POST_MEDIDORES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_DAÑADOS
        case fromMedidoresAlmacen.PUT_MEDIDORES_DAÑADOS:
            return {
                ...state,
                error: null
            };
        case fromMedidoresAlmacen.PUT_MEDIDORES_DAÑADOS_SUCCESS:
            return {
                ...state,
                list: state.list.map((medidor: any) => {
                    let encontrado = action.medidoresDañados.find(x => x.id == medidor.id);

                    if (encontrado != undefined) {
                        medidor.estado = action.estado.nombre;
                        medidor.idEstado = action.estado.id;
                        medidor.registro = encontrado.registro;
                    }
                    return medidor;
                })
            };
        case fromMedidoresAlmacen.PUT_MEDIDORES_DAÑADOS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_ASIGNADOS
        case fromMedidoresAlmacen.PUT_MEDIDORES_ASIGNAR:
            return {
                ...state,
                error: null
            };
        case fromMedidoresAlmacen.PUT_MEDIDORES_ASIGNAR_SUCCESS:
            return {
                ...state,
                list: state.list.map((medidor: any) => {
                    let encontrado = action.medidoresAsignados.find(x => x.id == medidor.id);

                    if (encontrado != undefined) {
                        medidor.proveedor = action.medidoresAsignados[0].nombre;
                        medidor.estado = action.estado.nombre;
                        medidor.idEstado = action.estado.id;
                        medidor.registro = encontrado.registro;
                    }
                    return medidor;
                })
            };
        case fromMedidoresAlmacen.PUT_MEDIDORES_ASIGNAR_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region EMPTY_POST_MEDIDORES
        case fromMedidoresAlmacen.EMPTY_POST_MEDIDORES:
            return {
                ...state,
                altaMedidores: null,
                loaded: false,
                loading: false,
                error: null,
            };
        //#endregion
        default:
            return state;
    }
}
