import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as rolesActions from '../../../actions/modulos/inwork/roles.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { RolesService } from 'src/app/services/inwork/roles.service';
import { Roles } from 'src/app/shared/models/Inwork/roles.model';

@Injectable()

export class rolesInworkEffects {
    constructor(
        private actions$: Actions,
        private rolesService: RolesService,
    ) { }

    @Effect()
    getRolInwork$ = this.actions$.ofType(rolesActions.GET_ROLES)
        .pipe(
            switchMap(() => {
                return this.rolesService.getRoles()
                    .pipe(
                        map((roles: Roles[]) => new rolesActions.GetRolesActionSuccess(roles)),
                        catchError(error => of(new rolesActions.GetRolesActionFail(error)))
                    )
            })
        );

    @Effect()
    postRolInwork$ = this.actions$.ofType(rolesActions.POST_ROL)
        .pipe(
            switchMap((action: rolesActions.PostRolAction) => {
                return this.rolesService.postRol(action.rol)
                    .pipe(
                        map((newRol : Roles) => new rolesActions.PostRolActionSUCCESS(newRol)),
                        catchError(error => of(new rolesActions.PostRolActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putRolInwork$ = this.actions$.ofType(rolesActions.PUT_ROL)
        .pipe(
            switchMap((action: rolesActions.PutRolAction) => {
                const rol = new Roles();
                rol.id = action.rol.id;
                rol.rol = action.rol.rol;
                rol.descripcion = action.rol.descripcion;
                
                return this.rolesService.putRol(rol)
                    .pipe(
                        map((updateRol: Roles) => new rolesActions.PutRolActionSUCCESS(updateRol)),
                        catchError(error => of(new rolesActions.PutRolActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteRolInwork$ = this.actions$.ofType(rolesActions.DELETE_ROL)
        .pipe(
            switchMap((action: rolesActions.DeleteRolAction) => {
                return this.rolesService.deleteRol(action.rol)
                    .pipe(
                        map((response) => new rolesActions.DeleteRolActionSUCCESS((response) ? action.rol : new Roles(0,"", ""))),
                        catchError(error => of(new rolesActions.DeleteRolActionFAIL(error)))
                    )
            })
        );
};
