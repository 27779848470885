import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PerfilInicio } from "src/app/shared/models/perfil-inicio.model";
import { AcuapueperfilService } from '../../../../services/acuapue/acuapueperfil.service';
import * as PerfilInicActions from '../../../actions/';

@Injectable()
export class perfilInicEffects {

    constructor(
        private actions$: Actions,
        private PerfilService: AcuapueperfilService,
        private router: Router
    ) { }

    @Effect()
    cargarConfig$ = this.actions$.ofType(PerfilInicActions.GET_CONFIG)
        .pipe(
            switchMap((action: PerfilInicActions.GetPerfilIni) => {
                return this.PerfilService.getConfigPerfil(action.IdUsuario, action.IdPerfil)
                    .pipe(
                        map((usuario: any) => {
                            return new PerfilInicActions.GetPerfilIniSuccess(usuario)
                        }),
                        catchError(error => of(new PerfilInicActions.GetPerfilIniFailure(error)))
                    )
            })
        );
}
