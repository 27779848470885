import { Component, OnInit, OnDestroy } from '@angular/core';
import { fadeAnimation } from '../shared/animations/fade';
import { Usuario } from '../shared/models/balcax/usuario.model';
import { MessageService } from 'primeng/api';

import * as loginActions from '../store/actions/balcax';

import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { Subscription } from 'rxjs';
import { VariablesService } from '../services/balcax/variableGL.service';
import { Toast } from '../shared/models/toast.model';
import { alertMessage } from '../shared/models/balcax/alertMessage.model';
import { AuthService } from '../services/balcax/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [fadeAnimation],
  providers: [MessageService],
})
export class LoginComponent implements OnInit, OnDestroy {

  toastSubcripcion: Subscription = new Subscription();
  activeScreen: number = 1;
  alert: alertMessage = new alertMessage();

  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
    private AuthService: AuthService,
    private variablesService: VariablesService
  ) {
    this.toastSubcripcion = this.variablesService.toastLogin.subscribe((toast: Toast) => {
      if (toast)
        this.messageService.add({ key: 'toast', severity: toast.estado, summary: toast.titulo, detail: toast.mensaje, life: toast.segundos, closable: false });
    });
    this.alert.alertShow = false;
  }

  ngOnInit() {
    this.alert = new alertMessage(
      null,
      false,
      null,
      null,
      null,
    );
  }

  ngOnDestroy() {
    if (this.toastSubcripcion) {
      this.toastSubcripcion.unsubscribe();
    }
  }
  activar(screen) {
    this.activeScreen = screen;
    this.alert.alertShow = false;
  }

  getDataLogin(e) {
    this.alert = new alertMessage(
      null,
      false,
      null,
      null,
      null,
    );
    if (e) {
      let user: Usuario = {
        correo: e.correo,
        contrasena: e.contrasena
      }

      this.store.dispatch(new loginActions.GetLoginAction(user))
    }
  }
  // getDataUser(e) {//Metodo apiMailer
  //   if (e) {
  //     let user = {
  //       correo: e.correo,
  //     }
  //     this.AuthService.forgotUser(user).subscribe(datauser => {
  //       console.log("login: ",datauser)
  //       if (datauser) {
  //         const datamailing = datauser;
  //         this.AuthService.resetPasswordMailing(datamailing).subscribe((datamail: any) => {
  //           if (datamail.success === true && datamail.statussend === true) {
  //             this.alert.alertClass = 'success';
  //             this.alert.alertShow = true;
  //             this.alert.alertHeader = 'Se ha enviado un correo para recuperar tu contraseña.'
  //             this.alert.alertMessage = null;
  //             this.alert.alertDetails = 'si no aparece en la bandeja de entrada consulte la carpeta de spam';
  //           } else {
  //             this.alert.alertClass = 'danger';
  //             this.alert.alertShow = true;
  //             this.alert.alertHeader = 'No se encontro correo asociado al usuario.';
  //             this.alert.alertMessage = null;
  //             this.alert.alertDetails = 'Verifique que su información este actualizada';
  //           }
  //         }, error => console.log("Error:", error));
  //       }
  //     }, error => {
  //       this.alert.alertClass = 'danger';
  //       this.alert.alertShow = true;
  //       this.alert.alertHeader = 'No se encontro el usuario.';
  //       this.alert.alertMessage = null;
  //       this.alert.alertDetails = 'Verifique que su información este actualizada';
  //     });
  //     setTimeout(() => {
  //       this.alert.alertShow = false;
  //     }, 2500);
  //   }
  // }

  getDataUser(e) {//Metodo de apiModuloVacacional
    if (e) {
      let user = {
        correo: e.correo,
      }
      this.AuthService.forgotPass(user).subscribe(datauser => {
        console.log("login: ", datauser)
        if (datauser) {
          const datamailing = datauser;
          this.AuthService.recuperaPass(datamailing).subscribe((datamail: any) => {
            if (datamail && datamail !== null) {
              console.log("Statussend: "+datamail);
              this.alert.alertClass = 'success';
              this.alert.alertShow = true;
              this.alert.alertHeader = 'Se ha enviado un correo para recuperar tu contraseña.'
              this.alert.alertMessage = null;
              this.alert.alertDetails = 'si no aparece en la bandeja de entrada consulte la carpeta de spam';
              
            } else {
              console.log("Statussend: "+datamail );
              this.alert.alertClass = 'danger';
              this.alert.alertShow = true;
              this.alert.alertHeader = 'No se encontro correo asociado al usuario.';
              this.alert.alertMessage = null;
              this.alert.alertDetails = 'Verifique que su información este actualizada';
            }
          }, error => console.log("Error:", error));
        }
      }, error => {
        this.alert.alertClass = 'danger';
        this.alert.alertShow = true;
        this.alert.alertHeader = 'No se encontro el usuario.';
        this.alert.alertMessage = null;
        this.alert.alertDetails = 'Verifique que su información este actualizada';
      });
      // setTimeout(() => {
      //   this.alert.alertShow = false;
      // }, 5000);
    }
  }//End getDataUser del metodo apiModuloVacacional
}//End class LoginComponent
