import * as fromLogMedidorAlmacen from '../../../actions/modulos/microMedicion/logMedidor.actions';
import { SelectedMedidoresSuccess } from 'src/app/shared/models/microMedicion/selectedMedidores.model';

export class LogMedidorState {
    list: string[];
    logMedidor: SelectedMedidoresSuccess;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: LogMedidorState = {
    list: [],
    logMedidor: null,
    loaded: false,
    loading: false,
    error: null
};

export function logMedidorReducer(state = initState, action: fromLogMedidorAlmacen.logSeriesAlmacenActions): LogMedidorState {
    switch (action.type) {
        //#region GET_SERIES
        case fromLogMedidorAlmacen.GET_SERIES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromLogMedidorAlmacen.GET_SERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.series
                ]
            };
        case fromLogMedidorAlmacen.GET_SERIES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_SERIES
        case fromLogMedidorAlmacen.GET_LOG_MEDIDOR:
            return {
                ...state,
                logMedidor: null,
                loading: true,
                error: null
            };
        case fromLogMedidorAlmacen.GET_LOG_MEDIDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                logMedidor: action.historico
            };
        case fromLogMedidorAlmacen.GET_LOG_MEDIDOR_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
