import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { of } from 'rxjs';
import { ExceptionsService } from '../exceptions.service';
import { AreasUsuarioAux } from 'src/app/shared/models/Indicadores/areas-usuario-aux.model';
import { UserServURL } from 'src/config/variablesGL';
@Injectable({
  providedIn: 'root'
})
export class AreasUsuarioService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getAreasUsuarioByCorreo(correo: string) {
    return this.http.get(`${UserServURL}/getUserByEmail?correo=${correo}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  getAreasUsuario() {
    return this.http.get(`${UserServURL}/getUser`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putAreaUsuario(area_usuario: AreasUsuarioAux) {
    return this.http.post(`${UserServURL}/putUser`, { data: area_usuario })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postAreaUsuario(area_usuario: AreasUsuarioAux) {
    return this.http.post(`${UserServURL}/postUser`, { data: area_usuario })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteAreaUsuario(area_usuario: AreasUsuarioAux) {
    return this.http.get(`${UserServURL}/deleteUser?idAreaUsuario=${area_usuario.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}