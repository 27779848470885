import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { MesConfiguracion } from 'src/app/shared/models/Indicadores/mes_configuracion.model';
import { ExceptionsService } from '../exceptions.service';
import { ProgramServURL_SUS } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class ProgramaTrabajoSusService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getProgramaTrabajo(idEspecifico) {
    return this.http.get(`${ProgramServURL_SUS}/getProgramStatus?idEspecifico=${idEspecifico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putProgramaTrabajo(mesesConfig: MesConfiguracion) {
    return this.http.post(`${ProgramServURL_SUS}/putMesConfig`, { data: mesesConfig })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putProgramaTrabajoProgramado(mesesConfig: MesConfiguracion[]) {
    return this.http.post(`${ProgramServURL_SUS}/putMesConfigProgramer`, { data: mesesConfig })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postProgramaTrabajo(mesesConfig: MesConfiguracion[]) {
    return this.http.post(`${ProgramServURL_SUS}/postMesConfig`, { data: mesesConfig })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteProgramaTrabajo(mesConfig: MesConfiguracion) {
    return this.http.get(`${ProgramServURL_SUS}/deleeProgramWork?idEspecifico=${mesConfig.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}