import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as periodosActions from '../../../actions/modulos/indicadores/periodos.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { PeriodosService } from 'src/app/services/indicadores/periodos.service';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';

@Injectable()

export class periodosEffects {
    constructor(
        private actions$: Actions,
        private periodosService: PeriodosService,
    ) { }

    @Effect()
    cargarPeriodos$ = this.actions$.ofType(periodosActions.GET_PERIODOS)
        .pipe(
            switchMap(() => {
                return this.periodosService.getPeriodos()
                    .pipe(
                        map((periodos: Periodos[]) => new periodosActions.GetPeriodosActionSuccess(periodos)),
                        catchError(error => of(new periodosActions.GetPeriodosActionFail(error)))
                    )
            })
        );

    @Effect()
    postPeriodo$ = this.actions$.ofType(periodosActions.POST_PERIODO)
        .pipe(
            switchMap((action: periodosActions.PostPeriodoAction) => {
                return this.periodosService.postPeriodos(action.periodo)
                    .pipe(
                        map((periodoN: Periodos) => new periodosActions.PostPeriodoActionSUCCESS(periodoN)),
                        catchError(error => of(new periodosActions.PostPeriodoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    editPeriodo$ = this.actions$.ofType(periodosActions.PUT_PERIODO)
        .pipe(
            switchMap((action: periodosActions.PutPeriodoAction) => {
                const periodo = new Periodos();
                periodo.id = action.periodo.id;
                periodo.descripcion = action.periodo.descripcion;
                periodo.fechaInicio = action.periodo.fechaInicio;
                periodo.fechaFin = action.periodo.fechaFin;

                return this.periodosService.putPeriodo(periodo)
                    .pipe(
                        map(() => new periodosActions.PutPeriodoActionSUCCESS(periodo)),
                        catchError(error => of(new periodosActions.PutPeriodoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deletePeriodo$ = this.actions$.ofType(periodosActions.DELETE_PERIODO)
        .pipe(
            switchMap((action: periodosActions.DeletePeriodoAction) => {
                return this.periodosService.deletePeriodo(action.periodo)
                    .pipe(
                        map(() => new periodosActions.DeletePeriodoActionSUCCESS(action.periodo)),
                        catchError(error => of(new periodosActions.DeletePeriodoActionFAIL(error)))
                    )
            })
        );
};
