import { Action } from '@ngrx/store';
import { Frecuencia } from 'src/app/shared/models/Indicadores/frecuencia.model';

//FRECUENCIAS
export const GET_FRECUENCIAS = '[GET_FRECUENCIAS] Get Frecuencias';
export const GET_FRECUENCIAS_FAIL = '[GET_FRECUENCIAS] Get Frecuencias FAIL';
export const GET_FRECUENCIAS_SUCCESS = '[GET_FRECUENCIAS] Get Frecuencias SUCCESS';

export const POST_FRECUENCIA = '[POST_FRECUENCIAS] Post Frecuencia';
export const POST_FRECUENCIA_FAIL = '[POST_FRECUENCIAS] Post Frecuencia FAIL';
export const POST_FRECUENCIA_SUCCESS = '[POST_FRECUENCIAS] Post Frecuencia SUCCESS';

export const PUT_FRECUENCIA = '[PUT_FRECUENCIAS] Put Frecuencia';
export const PUT_FRECUENCIA_FAIL = '[PUT_FRECUENCIAS] Put Frecuencia FAIL';
export const PUT_FRECUENCIA_SUCCESS = '[PUT_FRECUENCIAS] Put Frecuencia SUCCESS';

export const DELETE_FRECUENCIA = '[DELETE_FRECUENCIAS] Delete Frecuencia';
export const DELETE_FRECUENCIA_FAIL = '[DELETE_FRECUENCIAS] Delete Frecuencia FAIL';
export const DELETE_FRECUENCIA_SUCCESS = '[DELETE_FRECUENCIAS] Delete Frecuencia SUCCESS';

//#region GET_FRECUENCIAS
export class GetFrecuenciasAction implements Action {
    readonly type = GET_FRECUENCIAS;
};

export class GetFrecuenciasActionFail implements Action {
    readonly type = GET_FRECUENCIAS_FAIL;
    constructor(public payload: any) { }
};

export class GetFrecuenciasActionSuccess implements Action {
    readonly type = GET_FRECUENCIAS_SUCCESS;
    constructor(public frecuencias: Frecuencia[]) { }
};
//#endregion

//#region  POST_FRECUENCIA
export class PostFrecuenciaAction implements Action {
    readonly type = POST_FRECUENCIA;
    constructor(public frecuencia: Frecuencia) { }
};
export class PostFrecuenciaActionFAIL implements Action {
    readonly type = POST_FRECUENCIA_FAIL;
    constructor(public payload: any) { }
};
export class PostFrecuenciaActionSUCCESS implements Action {
    readonly type = POST_FRECUENCIA_SUCCESS;
    constructor(public newFrecuencia: Frecuencia) { }
};
//#endregion

//#region  PUT_FRECUENCIA
export class PutFrecuenciaAction implements Action {
    readonly type = PUT_FRECUENCIA;
    constructor(public updateFrecuencia: Frecuencia) { }
};
export class PutFrecuenciaActionFAIL implements Action {
    readonly type = PUT_FRECUENCIA_FAIL;
    constructor(public payload: any) { }
};
export class PutFrecuenciaActionSUCCESS implements Action {
    readonly type = PUT_FRECUENCIA_SUCCESS;
    constructor(public updateFrecuencia: Frecuencia) { }
};
//#endregion

//#region  DELETE_FRECUENCIA
export class DeleteFrecuenciaAction implements Action {
    readonly type = DELETE_FRECUENCIA;
    constructor(public frecuencia: Frecuencia) { }
};
export class DeleteFrecuenciaActionFAIL implements Action {
    readonly type = DELETE_FRECUENCIA_FAIL;
    constructor(public payload: any) { }
};
export class DeleteFrecuenciaActionSUCCESS implements Action {
    readonly type = DELETE_FRECUENCIA_SUCCESS;
    constructor(public deleteFrecuencia: Frecuencia) { }
};
//#endregion

export type frecuenciasActions =
GetFrecuenciasAction |
GetFrecuenciasActionFail |
GetFrecuenciasActionSuccess |

PostFrecuenciaAction |
PostFrecuenciaActionFAIL |
PostFrecuenciaActionSUCCESS |

PutFrecuenciaAction |
PutFrecuenciaActionFAIL |
PutFrecuenciaActionSUCCESS |

DeleteFrecuenciaAction |
DeleteFrecuenciaActionFAIL |
DeleteFrecuenciaActionSUCCESS;
