import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExceptionsService } from '../exceptions.service';
import { HttpClientService } from '../implementation/http-client.service';
import { HttpClient } from '@angular/common/http';
import { AcuapueServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class AcuapueperfilService {

  public DatosSuministro = 2;

  constructor(private http: HttpClientService,
    private exceptionsService: ExceptionsService) { }

  getConfigPerfil(idUsuario: number, idEmpresa: number) {
    return this.http.get(`${AcuapueServURL}/getConfig?iduser=${idUsuario}&idempresa=${idEmpresa}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}
