import { Action } from '@ngrx/store';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';

//TIPOS DE INDICADORES
export const GET_TIPOS_INDICADOR = '[GET_TIPOS_INDICADOR] Get Tipos_Indicador';
export const GET_TIPOS_INDICADOR_FAIL = '[GET_TIPOS_INDICADOR] Get Tipos_Indicador FAIL';
export const GET_TIPOS_INDICADOR_SUCCESS = '[GET_TIPOS_INDICADOR] Get Tipos_Indicador SUCCESS';

export const POST_TIPO_INDICADOR = '[POST_TIPO_INDICADOR] Post Tipo_Indicador';
export const POST_TIPO_INDICADOR_FAIL = '[POST_TIPO_INDICADOR] Post Tipo_Indicador FAIL';
export const POST_TIPO_INDICADOR_SUCCESS = '[POST_TIPO_INDICADOR] Post Tipo_Indicador SUCCESS';

export const PUT_TIPO_INDICADOR = '[PUT_TIPO_INDICADOR] Put Tipo_Indicador';
export const PUT_TIPO_INDICADOR_FAIL = '[PUT_TIPO_INDICADOR] Put Tipo_Indicador FAIL';
export const PUT_TIPO_INDICADOR_SUCCESS = '[PUT_TIPO_INDICADOR] Put Tipo_Indicador SUCCESS';

export const DELETE_TIPO_INDICADOR = '[DELETE_TIPOS_INDICADOR] Delete Tipo_Indicador';
export const DELETE_TIPO_INDICADOR_FAIL = '[DELETE_TIPOS_INDICADOR] Delete Tipo_Indicador FAIL';
export const DELETE_TIPO_INDICADOR_SUCCESS = '[DELETE_TIPOS_INDICADOR] Delete Tipo_Indicador SUCCESS';

//#region GET_TIPOS_INDICADOR
export class GetTiposIndicadorAction implements Action {
    readonly type = GET_TIPOS_INDICADOR;
};

export class GetTiposIndicadorActionFail implements Action {
    readonly type = GET_TIPOS_INDICADOR_FAIL;
    constructor(public payload: any) { }
};

export class GetTiposIndicadorActionSuccess implements Action {
    readonly type = GET_TIPOS_INDICADOR_SUCCESS;
    constructor(public tipos_indicador: TipoIndicador[]) { }
};
//#endregion

//#region  POST_TIPO_INDICADOR
export class PostTipoIndicadorAction implements Action {
    readonly type = POST_TIPO_INDICADOR;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class PostTipoIndicadorActionFAIL implements Action {
    readonly type = POST_TIPO_INDICADOR_FAIL;
    constructor(public payload: any) { }
};
export class PostTipoIndicadorActionSUCCESS implements Action {
    readonly type = POST_TIPO_INDICADOR_SUCCESS;
    constructor(public newTipoIndicador: TipoIndicador) { }
};
//#endregion

//#region  PUT_TIPO_INDICADOR
export class PutTipoIndicadorAction implements Action {
    readonly type = PUT_TIPO_INDICADOR;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class PutTipoIndicadorActionFAIL implements Action {
    readonly type = PUT_TIPO_INDICADOR_FAIL;
    constructor(public payload: any) { }
};
export class PutTipoIndicadorActionSUCCESS implements Action {
    readonly type = PUT_TIPO_INDICADOR_SUCCESS;
    constructor(public updateTipoIndicador: TipoIndicador) { }
};
//#endregion

//#region  DELETE_TIPO_INDICADOR
export class DeleteTipoIndicadorAction implements Action {
    readonly type = DELETE_TIPO_INDICADOR;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class DeleteTipoIndicadorActionFAIL implements Action {
    readonly type = DELETE_TIPO_INDICADOR_FAIL;
    constructor(public payload: any) { }
};
export class DeleteTipoIndicadorActionSUCCESS implements Action {
    readonly type = DELETE_TIPO_INDICADOR_SUCCESS;
    constructor(public deleteTipoIndicador: TipoIndicador) { }
};
//#endregion

export type tiposIndicadoresActions =
GetTiposIndicadorAction |
GetTiposIndicadorActionFail |
GetTiposIndicadorActionSuccess |

PostTipoIndicadorAction |
PostTipoIndicadorActionFAIL |
PostTipoIndicadorActionSUCCESS |

PutTipoIndicadorAction |
PutTipoIndicadorActionFAIL |
PutTipoIndicadorActionSUCCESS |

DeleteTipoIndicadorAction |
DeleteTipoIndicadorActionFAIL |
DeleteTipoIndicadorActionSUCCESS;