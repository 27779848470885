import { AtributosPT } from './atributos_pt.model';

export class CarpetaProyecto{
    constructor(
        public id?: number,
        public nombre?: string,
        public descripcion?: string,
        public dependencia?: number,
        public tipo?: string,
        public visible?: boolean,
        public atributosPT?: AtributosPT,
        public usersCompartir?: any[],
        public usersPropietario?: any[],
    ){}
}