import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';
import { RolUserServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class RolesUsuariosService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getRolesUsuarios() {
        return this.http.get(`${RolUserServURL}/getRolsUsers`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getRolUsuarioByCorreo(correo: string) {
        return this.http.get(`${RolUserServURL}/getRolsUsersByMail?correo=${correo}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putRolUsuario(rolUsuario: RolesUsuarios) {
        return this.http.post(`${RolUserServURL}/putRolUser`, { data: rolUsuario })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos del usuario correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putRolUsuarioActivo(rolUsuario: RolesUsuarios) {
        return this.http.post(`${RolUserServURL}/putRolUserActive`, { data: rolUsuario })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado', 'Status Actualizado Correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar el status! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postRolUsuario(rolUsuario: RolesUsuarios) {
        return this.http.post(`${RolUserServURL}/postRolUser`, { data: rolUsuario })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos del usuario correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteRolUsuario(rolUsuario: RolesUsuarios) {
        return this.http.get(`${RolUserServURL}/deleteRolUser?user=${rolUsuario.usuario}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos del usuario correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}