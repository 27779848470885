import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as itemsCatalogoActions from '../../../actions/modulos/inwork/items_catalogo.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ItemsCatalogoService } from 'src/app/services/inwork/items_catalogo.service';
import { ItemsCatalogo } from 'src/app/shared/models/Inwork/catalogos.model';

@Injectable()

export class itemsCatalogoInworkEffects {
    constructor(
        private actions$: Actions,
        private itemsCatalogoService: ItemsCatalogoService,
    ) { }

    @Effect()
    getItemsCatalogoInwork$ = this.actions$.ofType(itemsCatalogoActions.GET_ITEMS_CATALOGO)
        .pipe(
            switchMap(() => {
                return this.itemsCatalogoService.getItemsCatalogo()
                    .pipe(
                        map((itemsCatalogo: ItemsCatalogo[]) => new itemsCatalogoActions.GetItemsCatalogoActionSuccess(itemsCatalogo)),
                        catchError(error => of(new itemsCatalogoActions.GetItemsCatalogoActionFail(error)))
                    )
            })
        );

    @Effect()
    postItemCatalogoInwork$ = this.actions$.ofType(itemsCatalogoActions.POST_ITEM_CATALOGO)
        .pipe(
            switchMap((action: itemsCatalogoActions.PostItemCatalogoAction) => {
                return this.itemsCatalogoService.postItemCatalogo(action.itemCatalogo)
                    .pipe(
                        map((newItemCatalogo: ItemsCatalogo) => new itemsCatalogoActions.PostItemCatalogoActionSUCCESS(newItemCatalogo)),
                        catchError(error => of(new itemsCatalogoActions.PostItemCatalogoActionFAIL(error)))
                    )
            })
        );
        
    @Effect()
    putItemCatalogoInwork$ = this.actions$.ofType(itemsCatalogoActions.PUT_ITEM_CATALOGO)
        .pipe(
            switchMap((action: itemsCatalogoActions.PutItemCatalogoAction) => {
                const itemCatalogo = new ItemsCatalogo();
                itemCatalogo.id = action.itemCatalogo.id;
                itemCatalogo.item = action.itemCatalogo.item;
                itemCatalogo.catalogo = action.itemCatalogo.catalogo;
                
                return this.itemsCatalogoService.putItemCatalogo(itemCatalogo)
                    .pipe(
                        map((updateItemCatalogo: ItemsCatalogo) => new itemsCatalogoActions.PutItemCatalogoActionSUCCESS(updateItemCatalogo)),
                        catchError(error => of(new itemsCatalogoActions.PutItemCatalogoActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteItemCatalogoInwork$ = this.actions$.ofType(itemsCatalogoActions.DELETE_ITEM_CATALOGO)
        .pipe(
            switchMap((action: itemsCatalogoActions.DeleteItemCatalogoAction) => {
                return this.itemsCatalogoService.deleteItemCatalogo(action.itemCatalogo)
                    .pipe(
                        map((response) => new itemsCatalogoActions.DeleteItemCatalogoActionSUCCESS((response) ? action.itemCatalogo : new ItemsCatalogo(0, "", ""))),
                        catchError(error => of(new itemsCatalogoActions.DeleteItemCatalogoActionFAIL(error)))
                    )
            })
        );
};
