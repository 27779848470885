import * as fromRolesUsuariosInwork from '../../../actions/modulos/inwork/roles_usuarios.actions';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

export class RolesUsuariosInworkState {
    list: RolesUsuarios[];
    rolUsuario: RolesUsuarios;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: RolesUsuariosInworkState = {
    list: [],
    rolUsuario: null,
    loaded: false,
    loading: false,
    error: null
};

export function rolesUsuariosInworkReducer(state = initState, action: fromRolesUsuariosInwork.rolesUsuarioInworkActions): RolesUsuariosInworkState {
    switch (action.type) {
    //#region GET_ROLES_USUARIOS
    case fromRolesUsuariosInwork.GET_ROLES_USUARIOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromRolesUsuariosInwork.GET_ROLES_USUARIOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.rolesUsuarios.map(rowItem => {
                    return {
                        ...rowItem
                    }
                })
            ]
        };
    case fromRolesUsuariosInwork.GET_ROLES_USUARIOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region GET_ROL_USUARIO_BY_CORREO
    case fromRolesUsuariosInwork.GET_ROL_USUARIO_BY_CORREO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromRolesUsuariosInwork.GET_ROL_USUARIO_BY_CORREO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            rolUsuario: action.rolUsuario
        };
    case fromRolesUsuariosInwork.GET_ROL_USUARIO_BY_CORREO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_ROL_USUARIO
    case fromRolesUsuariosInwork.PUT_ROL_USUARIO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromRolesUsuariosInwork.PUT_ROL_USUARIO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(rowItem => {
                    if (rowItem.id === action.updateRolUsuario.id) {
                        return {
                            ...rowItem,
                            usuario: action.updateRolUsuario.usuario,
                            rol: action.updateRolUsuario.rol,
                        };
                    } else {
                        return rowItem;
                    }
                })
            ]
        };
    case fromRolesUsuariosInwork.PUT_ROL_USUARIO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_STATE_ROL_USUARIO
    case fromRolesUsuariosInwork.PUT_STATE_ROL_USUARIO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromRolesUsuariosInwork.PUT_STATE_ROL_USUARIO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(rowItem => {
                    if (rowItem.id === action.idRolUsuario) {
                        return {
                            ...rowItem,
                            activo: !rowItem.activo
                        };
                    } else {
                        return rowItem;
                    }
                })
            ]
        };
    case fromRolesUsuariosInwork.PUT_STATE_ROL_USUARIO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ROL_USUARIO
    case fromRolesUsuariosInwork.POST_ROL_USUARIO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromRolesUsuariosInwork.POST_ROL_USUARIO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newRolUsuario],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromRolesUsuariosInwork.POST_ROL_USUARIO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ROL_USUARIO
    case fromRolesUsuariosInwork.DELETE_ROL_USUARIO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromRolesUsuariosInwork.DELETE_ROL_USUARIO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(item => {
                    if (item.id != action.deleteRolUsuario.id) {
                        return {
                            item
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromRolesUsuariosInwork.DELETE_ROL_USUARIO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
    //#endregion
    default:
        return state;
    }
}
