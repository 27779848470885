export class Grupos{
    constructor(
        public id?: number,
        public nombre?: string,
        public descripcion?: string
    ){}
}

export class GruposUsuarios{
    constructor(
        public id?: number,
        public grupo?: string,
        public usuario?: string
    ){}
}