import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Toast } from '../../shared/models/toast.model';
import { Tab } from '../../shared/models/balcax/tab.model';
import { SwalModel } from 'src/app/shared/models/swal.model';

// EXCEL
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Excel } from 'src/app/shared/models/balcax/excel.model';

// PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { autoTable as AutoTable } from 'jspdf-autotable';
import { PDF } from 'src/app/shared/models/balcax/pdf.model';
import { CarpetaProyecto } from 'src/app/shared/models/Inwork/carpeta_proyecto.model';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { FlujosTrabajo } from 'src/app/shared/models/Inwork/flujo_trabajo.model';
import { Estados } from 'src/app/shared/models/Inwork/estados.model';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  showSideUser = new Subject<boolean>();
  showSideViews = new Subject<boolean>();
  showSideBar = new Subject<boolean>();
  changeTipoMenu = new Subject<boolean>();

  toastLogin = new BehaviorSubject<Toast>(null);
  toast = new BehaviorSubject<Toast>(null);
  swal = new BehaviorSubject<SwalModel>(null);
  pagina = new BehaviorSubject<string>("");

  periodoFiltro = new BehaviorSubject<string>(null);
  periodoFiltroSus = new BehaviorSubject<string>(null);
  
  carpetaProyectoBreadcrumb = new BehaviorSubject<number>(0);
  consultaNiss = new BehaviorSubject<string>(null);
  consultaNissLoader = new BehaviorSubject<boolean>(false);
  panelconsultaNiss = new BehaviorSubject<boolean>(false);
  darkMode = new BehaviorSubject<boolean>(false);

  tabs = new BehaviorSubject<Tab[]>([]);

  colores = ['#1ABCA5', '#34495E', '#3498DB', '#9B59B6', '#2ECC71', '#F1C40F', '#E74C3C', '#95A5A6'];

  //Calendario en Español
  es: any;

  constructor(
    private datepipe: DatePipe,
    private titlecasePipe: TitleCasePipe,
    private router: Router
  ) {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar'
    }
  }

  getStatusPantalla(pagina?: string): number {
    let width = window.screen.width;
    switch (pagina) {
      case "estrategias": {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 8;
        else return 12;
      }
      case "historico": {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 2;
        else return 8;
      }
      case "backoffice": {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 50;
        else return 50;
      }
      default: {
        if (width < 640) return 1;
        else if (width > 640 && width < 1920) return 10;
        else return 17;
      }
    }
  }

  getDate(): string {
    let fecha = new Date();
    return `${this.datepipe.transform(fecha, 'yyyy-MM-dd')}`;
  }

  transformName(txt: string): string {
    return this.titlecasePipe.transform(txt);
  }

  removeCredential() {
    this.router.navigate(['/'], { replaceUrl: true });
    localStorage.clear();
    location.reload();
  }

  getPercentage(value: number, max: number): number {
    if (value > max) {
      return 100;
    } else if (value == 0 && max == 0) {
      return 0;
    } else {
      return (+value / max) * 100;
    }
  }

  downloadBase64File(contentType, base64Data, fileName, paternDocument) {
    let padre = document.getElementById(paternDocument);

    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    padre.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

  }

  dataURItoBlob(dataURI, fileType: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: fileType });
    return blob;
  }

  returnFileSize(number) {
    if (number < 1024) {
      return number + ' bytes';
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + ' KB';
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + ' MB';
    }
  }

  getDataTable(data: any): any {
    let arregloCols: any[] = [];
    let headers = Object.keys(data[0]);

    headers.map(h => {
      if (h.substring(0, 2).toLowerCase() != "id") {
        arregloCols.push({
          field: h,
          header: h
        });
      }
    });

    let arregloRows: any[] = [];
    data.map((row) => {
      let x: any = [];
      headers.map((h) => {
        if (h == "registrado")
          x[h] = String(this.datepipe.transform(row[h], 'fullDate'))
        else if (h == "fecha")
          x[h] = String(this.datepipe.transform(row[h], 'short'))
        else if (h == "registro")

        //    x[h] = String(this.datepipe.transform(row[h], 'short'))
        //  else if (h == "fecharegistro")

          x[h] = String(this.datepipe.transform(row[h], 'dd/mm/yyyy'))
        else if (h == "fechaCreacion")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "periodoInicio" || h == "fechaInicio")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "periodoFin" || h == "fechaFin")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "habilitado" || h == "autorizado")
          x[h] = Boolean(row[h])
        else if (h == "estado")
          x[h] = Boolean(row[h])
        else
          x[h] = row[h];
      });
      arregloRows.push(x)
    });

    return { cols: arregloCols, rows: arregloRows };
  }

  poblarCombo(lista: CarpetaProyecto[], exepto: CarpetaProyecto): SelectItem[] {
    let listaRetorno: SelectItem[] = [];
    lista.forEach((item) => {
      if (exepto) {
        if (item.id != exepto.id) {
          if (item.dependencia != exepto.id) {
            let nuevo: SelectItem = {
              label: item.nombre,
              value: item.id,
              title: item.tipo,
            };
            listaRetorno.push(nuevo);
          }
        }
      } else {
        let nuevo: SelectItem = {
          label: item.nombre,
          value: item.id,
          title: item.tipo,
        };
        listaRetorno.push(nuevo);
      }
    });
    return listaRetorno;
  }

  poblarComboGrouped(lista: FlujosTrabajo[]): SelectItemGroup[] {
    let listaRetorno: SelectItemGroup[] = [];
    lista.forEach((item) => {
      let nuevo: SelectItemGroup = {
        label: item.nombre,
        value: item.id,
        items: this.setEstados(item.estados)
      };
      listaRetorno.push(nuevo);
    });
    return listaRetorno;
  }

  setEstados(estados: Estados[]): any {
    let items = [];
    estados.forEach(estado => {
      items.push({ label: estado.nombre, value: estado.id, title: estado.color });
    })
    return items;
  }

  getRowsExcel(headers: string[], rows: any): any[] {
    let rowsTemp: any[] = rows.map((x: any) => {
      let temp: string[] = [];

      headers.map((header: string) => {
        if (x[header.toLocaleLowerCase()]) {
          let _value = String(x[header.toLocaleLowerCase()]);
          temp.push((_value == 'true') ? 'Activado' : (_value == 'false') ? 'Desactivado' : _value);
        }
      });

      return temp;
    });

    return rowsTemp;
  }

  getIdRandom(): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  //Función para calcular los días transcurridos entre dos fechas
  restaFechas(f1, f2) {
    let diasRestar = 0;
    var aFecha1 = f1.split('/');
    var aFecha2 = f2.split('/');
    var fFecha1 = Date.UTC(aFecha1[2], aFecha1[1] - 1, aFecha1[0]);
    var fFecha2 = Date.UTC(aFecha2[2], aFecha2[1] - 1, aFecha2[0]);
    var dif = fFecha2 - fFecha1;
    var dias = Math.floor(dif / (1000 * 60 * 60 * 24));

    if (dias >= 4) {
      diasRestar = Math.round(dias / 7) * 2;
      dias -= (diasRestar - 1);
    }
    return dias;
  }

  getWidthExcel(row: any[]): any[] {
    let index: number = this.getIndexLongText(row);

    let sizes: any[] = [];
    row[index].map((x: any) => {
      let caracteres: number = String(x).length;

      if (caracteres <= 15) {
        sizes.push({ width: 18 });
      } else if (caracteres > 11 && caracteres <= 45) {
        sizes.push({ width: 40 });
      } else {
        sizes.push({ width: 95 });
      }
    });

    sizes.push({ width: 12 });
    sizes.push({ width: 12 });

    return sizes;
  }

  getIndexLongText(rows: any[]): number {
    let longitud: number[] = [];

    rows.map((x) => {
      longitud.push(JSON.stringify(x).length);
    });

    let max = Math.max.apply(Map, longitud);

    return longitud.findIndex(x => x == max);
  }

  getLetterAlphabet(longitud: number): string {
    switch (longitud) {
      case 5:
        return 'E';
      case 6:
        return 'F';
      case 7:
        return 'G';
      case 8:
        return 'H';
      case 9:
        return 'I';
      case 10:
        return 'J';
      case 11:
        return 'K';
      case 12:
        return 'L';
      case 13:
        return 'M';
      case 14:
        return 'N';
      case 15:
        return 'O';
      case 16:
        return 'P';
      case 17:
        return 'Q';
      case 18:
        return 'R';
      case 19:
        return 'S';
      case 20:
        return 'T';
      case 21:
        return 'U';
      case 22:
        return 'V';
      case 23:
        return 'W';
      case 24:
        return 'X';
      case 25:
        return 'Y';
      default:
        return 'Z';
    }
  }

  utf8_to_b64(palabra): string {
    return window.btoa(unescape(encodeURIComponent(palabra)));
  }

  b64_to_utf8(palabra) {
    return decodeURIComponent(escape(window.atob(palabra)));
  }

  exportExcel(datos: Excel) {

    let headers: string[] = datos.columns.map((x: any) => { return x.header; });

    const header: string[] = headers.map(x => this.transformName(x));
    const data: any[] = this.getRowsExcel(headers, datos.rows);

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(datos.nameTab);

    worksheet.columns = this.getWidthExcel(data);

    let titleRow = worksheet.addRow([datos.title]);
    titleRow.font = { name: 'Calibri Light', family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([`Fecha: ${this.getDate()}`]);
    worksheet.addRow([]);

    let logo = workbook.addImage({
      base64: datos.img,
      extension: 'png',
    });

    let letter = this.getLetterAlphabet(headers.length);
    let letter_1 = this.getLetterAlphabet(headers.length - 1);

    worksheet.addImage(logo, `${letter_1}1:${letter}2`);
    worksheet.mergeCells(`A1:${letter}2`);

    let headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'darkGrid',
        fgColor: { argb: 'CCf9fefb' },
        bgColor: { argb: 'FFFFFFFF' }
      };
      cell.alignment = { horizontal: "center" };
      cell.font = { bold: true };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });

    data.map((x, i) => {
      let bodyRow = worksheet.addRow(data[i]);

      bodyRow.eachCell((cell) => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });
    });

    if (datos.whithFirma) {
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([, , '__________________________', , '__________________________',]).alignment = { horizontal: "center" };
      worksheet.addRow([, , datos.firma.elaboro, , datos.firma.autorizo,]).alignment = { horizontal: "center" };
      worksheet.addRow([, , 'Elaboró', , 'Autorizo',]);

      worksheet.lastRow.alignment = { horizontal: "center" };
      worksheet.lastRow.font = { size: 10 };
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${datos.nameFile}(${this.getDate()}).xlsx`);
    });
  }

  exportPDF(datos: PDF) {
    const doc = new jsPDF();

    doc.addImage(datos.img, 'PNG', 170, 10, 25, 18);

    doc.setFontSize(10);
    doc.text(datos.description, 14, 22);

    if (datos.encabezado) {
      doc.autoTable({
        startY: 30,
        theme: 'grid',
        head: [datos.encabezado.header],
        body: [datos.encabezado.body]
      });
    }

    if (datos.detalle) {
      doc.autoTable({
        theme: 'grid',
        head: [datos.detalle.header],
        body: [datos.detalle.body]
      });
    }

    if (datos.body) {
      doc.autoTable({
        theme: 'grid',
        head: [datos.body.header],
        body: datos.body.body
      });
    }

    doc.autoTable({
      body: [
        [`Nombre y Firma de ${datos.firma.elaboro}`, `Nombre y Firma de ${datos.firma.autorizo}`],
        [],
        ['_______________________________________', '_______________________________________']
      ]
    });

    doc.save(`${datos.nameFile}: ${this.getDate()}.pdf`);
  }

  getDataTableNom35(data: any): any {
    let arregloCols: any[] = [];
    let headers = Object.keys(data[0]);

    headers.map(h => {
      if (h.substring(0, 2).toLowerCase() != "id") {
        arregloCols.push({
          field: h,
          header: h
        });
      }
    });

    let arregloRows: any[] = [];
    data.map((row) => {
      let x: any = [];
      headers.map((h) => {
        if (h == "registrado")
          x[h] = String(this.datepipe.transform(row[h], 'fullDate'))
        else if (h == "fecha")
          x[h] = String(this.datepipe.transform(row[h], 'short'))
        else if (h == "registro")
          x[h] = String(this.datepipe.transform(row[h], 'dd/mm/yyyy'))
        else if (h == "fechaCreacion")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "periodoInicio" || h == "fechaInicio")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "periodoFin" || h == "fechaFin")
          x[h] = String(this.datepipe.transform(row[h], 'medium'))
        else if (h == "habilitado" || h == "autorizado")
          x[h] = Boolean(row[h])
        else if (h == "estado")
          x[h] = Boolean(row[h])
        else
          x[h] = row[h];
      });
      arregloRows.push(x)
    });

    return { cols: arregloCols, rows: arregloRows };
  }
}
