import { Grupos } from "./grupos.model";

export class RolesUsuarios{
    constructor(
        public id?: number,
        public usuario?: string,
        public rol?: string,
        public activo?: boolean,
        public grupos?: Grupos[]
    ){}
}
