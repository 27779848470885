import { Action } from '@ngrx/store';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';

//TIPOS DE INDICADORES
export const GET_TIPOS_INDICADOR_SUS = '[GET_TIPOS_INDICADOR_SUS] Get Tipos_Indicador_SUS';
export const GET_TIPOS_INDICADOR_SUS_FAIL = '[GET_TIPOS_INDICADOR_SUS] Get Tipos_Indicador_SUS FAIL';
export const GET_TIPOS_INDICADOR_SUS_SUCCESS = '[GET_TIPOS_INDICADOR_SUS] Get Tipos_Indicador_SUS SUCCESS';

export const POST_TIPO_INDICADOR_SUS = '[POST_TIPO_INDICADOR_SUS] Post Tipo_Indicador_SUS';
export const POST_TIPO_INDICADOR_SUS_FAIL = '[POST_TIPO_INDICADOR_SUS] Post Tipo_Indicador_SUS FAIL';
export const POST_TIPO_INDICADOR_SUS_SUCCESS = '[POST_TIPO_INDICADOR_SUS] Post Tipo_Indicador_SUS SUCCESS';

export const PUT_TIPO_INDICADOR_SUS = '[PUT_TIPO_INDICADOR_SUS] Put Tipo_Indicador_SUS';
export const PUT_TIPO_INDICADOR_SUS_FAIL = '[PUT_TIPO_INDICADOR_SUS] Put Tipo_Indicador_SUS FAIL';
export const PUT_TIPO_INDICADOR_SUS_SUCCESS = '[PUT_TIPO_INDICADOR_SUS] Put Tipo_Indicador_SUS SUCCESS';

export const DELETE_TIPO_INDICADOR_SUS = '[DELETE_TIPOS_INDICADOR_SUS] Delete Tipo_Indicador_SUS';
export const DELETE_TIPO_INDICADOR_SUS_FAIL = '[DELETE_TIPOS_INDICADOR_SUS] Delete Tipo_Indicador_SUS FAIL';
export const DELETE_TIPO_INDICADOR_SUS_SUCCESS = '[DELETE_TIPOS_INDICADOR_SUS] Delete Tipo_Indicador_SUS SUCCESS';

//#region GET_TIPOS_INDICADOR
export class GetTiposIndicadorAction_SUS implements Action {
    readonly type = GET_TIPOS_INDICADOR_SUS;
};

export class GetTiposIndicadorActionFail_SUS implements Action {
    readonly type = GET_TIPOS_INDICADOR_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetTiposIndicadorActionSuccess_SUS implements Action {
    readonly type = GET_TIPOS_INDICADOR_SUS_SUCCESS;
    constructor(public tipos_indicador: TipoIndicador[]) { }
};
//#endregion

//#region  POST_TIPO_INDICADOR
export class PostTipoIndicadorAction_SUS implements Action {
    readonly type = POST_TIPO_INDICADOR_SUS;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class PostTipoIndicadorActionFAIL_SUS implements Action {
    readonly type = POST_TIPO_INDICADOR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostTipoIndicadorActionSUCCESS_SUS implements Action {
    readonly type = POST_TIPO_INDICADOR_SUS_SUCCESS;
    constructor(public newTipoIndicador: TipoIndicador) { }
};
//#endregion

//#region  PUT_TIPO_INDICADOR
export class PutTipoIndicadorAction_SUS implements Action {
    readonly type = PUT_TIPO_INDICADOR_SUS;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class PutTipoIndicadorActionFAIL_SUS implements Action {
    readonly type = PUT_TIPO_INDICADOR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PutTipoIndicadorActionSUCCESS_SUS implements Action {
    readonly type = PUT_TIPO_INDICADOR_SUS_SUCCESS;
    constructor(public updateTipoIndicador: TipoIndicador) { }
};
//#endregion

//#region  DELETE_TIPO_INDICADOR
export class DeleteTipoIndicadorAction_SUS implements Action {
    readonly type = DELETE_TIPO_INDICADOR_SUS;
    constructor(public tipoIndicador: TipoIndicador) { }
};
export class DeleteTipoIndicadorActionFAIL_SUS implements Action {
    readonly type = DELETE_TIPO_INDICADOR_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteTipoIndicadorActionSUCCESS_SUS implements Action {
    readonly type = DELETE_TIPO_INDICADOR_SUS_SUCCESS;
    constructor(public deleteTipoIndicador: TipoIndicador) { }
};
//#endregion

export type tiposIndicadoresActions_SUS =
GetTiposIndicadorAction_SUS |
GetTiposIndicadorActionFail_SUS |
GetTiposIndicadorActionSuccess_SUS |

PostTipoIndicadorAction_SUS |
PostTipoIndicadorActionFAIL_SUS |
PostTipoIndicadorActionSUCCESS_SUS |

PutTipoIndicadorAction_SUS |
PutTipoIndicadorActionFAIL_SUS |
PutTipoIndicadorActionSUCCESS_SUS |

DeleteTipoIndicadorAction_SUS |
DeleteTipoIndicadorActionFAIL_SUS |
DeleteTipoIndicadorActionSUCCESS_SUS;