import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as proveedorAlmacenActions from '../../../actions/modulos/microMedicion/proveedor.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProveedoresService } from 'src/app/services/microMedicion/almacen/proveedores.service';
import { Proveedor } from 'src/app/shared/models/microMedicion/proveedor.model';

@Injectable()

export class proveedorEffects {
    constructor(
        private actions$: Actions,
        private proveedoresService: ProveedoresService,
    ) { }

    @Effect()
    getProveedores$ = this.actions$.ofType(proveedorAlmacenActions.GET_PROVEEDORES)
        .pipe(
            switchMap(() => {
                return this.proveedoresService.getProveedores()
                    .pipe(
                        map((response: Proveedor[]) => new proveedorAlmacenActions.GetUProveedorActionSuccess(response)),
                        catchError(error => of(new proveedorAlmacenActions.GetUProveedorActionFail(error)))
                    )
            })
        );

};
