import { Action } from '@ngrx/store';
import { Catalogos } from 'src/app/shared/models/Inwork/catalogos.model';

//CATALOGOS
export const GET_CATALOGOS = '[INWORK] Get Catalogos Inwork';
export const GET_CATALOGOS_FAIL = '[INWORK] Get Catalogos Inwork FAIL';
export const GET_CATALOGOS_SUCCESS = '[INWORK] Get Catalogos Inwork SUCCESS';

export const POST_CATALOGO = '[INWORK] Post Catalogo Inwork';
export const POST_CATALOGO_FAIL = '[INWORK] Post Catalogo Inwork FAIL';
export const POST_CATALOGO_SUCCESS = '[INWORK] Post Catalogo Inwork SUCCESS';

export const PUT_CATALOGO = '[INWORK] Put Catalogo Inwork';
export const PUT_CATALOGO_FAIL = '[INWORK] Put Catalogo Inwork FAIL';
export const PUT_CATALOGO_SUCCESS = '[INWORK] Put Catalogo Inwork SUCCESS';

export const DELETE_CATALOGO = '[INWORK] Delete Catalogo Inwork';
export const DELETE_CATALOGO_FAIL = '[INWORK] Delete Catalogo Inwork FAIL';
export const DELETE_CATALOGO_SUCCESS = '[INWORK] Delete Catalogo Inwork SUCCESS';

//#region GET_CATALOGOS
export class GetCatalogosAction implements Action {
    readonly type = GET_CATALOGOS;
};

export class GetCatalogosActionFail implements Action {
    readonly type = GET_CATALOGOS_FAIL;
    constructor(public payload: any) { }
};

export class GetCatalogosActionSuccess implements Action {
    readonly type = GET_CATALOGOS_SUCCESS;
    constructor(public catalogos: Catalogos[]) { }
};
//#endregion

//#region  POST_CATALOGO
export class PostCatalogoAction implements Action {
    readonly type = POST_CATALOGO;
    constructor(public catalogo: Catalogos) { }
};
export class PostCatalogoActionFAIL implements Action {
    readonly type = POST_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class PostCatalogoActionSUCCESS implements Action {
    readonly type = POST_CATALOGO_SUCCESS;
    constructor(public newCatalogo: Catalogos) { }
};
//#endregion

//#region  PUT_CATALOGO
export class PutCatalogoAction implements Action {
    readonly type = PUT_CATALOGO;
    constructor(public catalogo: Catalogos) { }
};
export class PutCatalogoActionFAIL implements Action {
    readonly type = PUT_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class PutCatalogoActionSUCCESS implements Action {
    readonly type = PUT_CATALOGO_SUCCESS;
    constructor(public updateCatalogo: Catalogos) { }
};
//#endregion

//#region  DELETE_CATALOGO
export class DeleteCatalogoAction implements Action {
    readonly type = DELETE_CATALOGO;
    constructor(public catalogo: Catalogos) { }
};
export class DeleteCatalogoActionFAIL implements Action {
    readonly type = DELETE_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteCatalogoActionSUCCESS implements Action {
    readonly type = DELETE_CATALOGO_SUCCESS;
    constructor(public deleteCatalogo: Catalogos) { }
};
//#endregion

export type catalogosInworkActions =
GetCatalogosAction |
GetCatalogosActionFail |
GetCatalogosActionSuccess |

PostCatalogoAction |
PostCatalogoActionFAIL |
PostCatalogoActionSUCCESS |

PutCatalogoAction |
PutCatalogoActionFAIL |
PutCatalogoActionSUCCESS |

DeleteCatalogoAction |
DeleteCatalogoActionFAIL |
DeleteCatalogoActionSUCCESS;
