import { Injectable } from '@angular/core';
import { HttpClientService } from '../implementation/http-client.service';
import { environment } from 'src/environments/environment';
import { ObjetivoEstrategico } from 'src/app/shared/models/Indicadores/obj_estrategico.model';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { of } from 'rxjs';
import { IndicatorServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class ObjEstrategicoService {

  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService,
  ) { }

  getObjEstrategico(tipoIndicador: string) {
    return this.http.get(`${IndicatorServURL}/getIndicators?tipoIndicador=${tipoIndicador}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putOEstrategicoHabilitado(oestrategico: ObjetivoEstrategico) {
    return this.http.post(`${IndicatorServURL}/putEstrategicEnabled`, oestrategico)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putOEstrategico(oestrategico: ObjetivoEstrategico) {
    return this.http.post(`${IndicatorServURL}/putObjetiveEstrategic`, { data: oestrategico })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postOEstrategico(oestrategico: ObjetivoEstrategico) {
    return this.http.post(`${IndicatorServURL}/postEstrategic`, { data: oestrategico })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteEstrategico(estrategico: ObjetivoEstrategico) {
    return this.http.get(`${IndicatorServURL}/deleteEstrategic?idEstrategico=${estrategico.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}