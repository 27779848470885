import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as estrategicosActions from '../../../actions/modulos/indicadoresSustentabilidad/estrategicosSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ObjetivoEstrategico } from 'src/app/shared/models/Indicadores/obj_estrategico.model';
import { ObjEstrategicoSusService } from 'src/app/services/indicadoresSustentabilidad/obj_estSus.service';

@Injectable()

export class estrategicosSusEffects {
    constructor(
        private actions$: Actions,
        private estrategicosService: ObjEstrategicoSusService,
    ) { }

    @Effect()
    cargarEstrategicos$ = this.actions$.ofType(estrategicosActions.GET_ESTRATEGICOS_SUS)
        .pipe(
            switchMap((action: estrategicosActions.GetEstrategicosAction_SUS) => {
                return this.estrategicosService.getObjEstrategico(action.tipoIndicador)
                    .pipe(
                        map((listEstrategicos: ObjetivoEstrategico[]) => new estrategicosActions.GetEstrategicosActionSuccess_SUS(listEstrategicos)),
                        catchError(error => of(new estrategicosActions.GetEstrategicosActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    EditEstrategico$ = this.actions$.ofType(estrategicosActions.PUT_ESTRATEGICO_SUS)
        .pipe(
            switchMap((action: estrategicosActions.PutEstrategicoAction_SUS) => {
                const estrategico = new ObjetivoEstrategico();
                estrategico.id = action.estrategico.id;
                estrategico.descripcion = action.estrategico.descripcion;
                estrategico.periodoInicio = action.estrategico.periodoInicio;
                estrategico.periodoFin = action.estrategico.periodoFin;
                estrategico.comentarios = action.estrategico.comentarios;
                estrategico.tipo = action.estrategico.tipo;
                estrategico.estrategico = action.estrategico.estrategico;
                estrategico.estandar = action.estrategico.estandar;

                return this.estrategicosService.putOEstrategico(estrategico)
                    .pipe(
                        map(() => new estrategicosActions.PutEstrategicoActionSuccess_SUS(estrategico)),
                        catchError(error => of(new estrategicosActions.PutEstrategicoActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    changeStateEstrategico$ = this.actions$.ofType(estrategicosActions.PUT_STATE_ESTRATEGICO_SUS)
        .pipe(
            switchMap((action: estrategicosActions.PutStateEstrategicoAction_SUS) => {
                const estrategico = new ObjetivoEstrategico();
                estrategico.id = action.idEstrategico;
                return this.estrategicosService.putOEstrategicoHabilitado(estrategico)
                    .pipe(
                        map(() => new estrategicosActions.PutStateEstrategicoActionSuccess_SUS(estrategico.id)),
                        catchError(error => of(new estrategicosActions.PutStateEstrategicoActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postEstrategico$ = this.actions$.ofType(estrategicosActions.POST_ESTRATEGICO_SUS)
        .pipe(
            switchMap((action: estrategicosActions.PostEstrategicoAction_SUS) => {
                return this.estrategicosService.postOEstrategico(action.estrategico)
                    .pipe(
                        map((estrategico: ObjetivoEstrategico) => new estrategicosActions.PostEstrategicoActionSUCCESS_SUS(estrategico)),
                        catchError(error => of(new estrategicosActions.PostEstrategicoActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    deleteEstrategico$ = this.actions$.ofType(estrategicosActions.DELETE_ESTRATEGICO_SUS)
        .pipe(
            switchMap((action: estrategicosActions.DeleteEstrategicoAction_SUS) => {
                return this.estrategicosService.deleteEstrategico(action.estrategico)
                    .pipe(
                        map(() => new estrategicosActions.DeleteEstrategicoActionSUCCESS_SUS(action.estrategico)),
                        catchError(error => of(new estrategicosActions.DeleteEstrategicoActionFAIL_SUS(error)))
                    )
            })
        );
};
