import { Action } from '@ngrx/store';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';
import { Vista } from 'src/app/shared/models/balcax/vista.model';

export const GET_USERS = '[USERS] Get Users';
export const GET_USERS_FAIL = '[USERS] Get Users FAIL';
export const GET_USERS_SUCCESS = '[USERS] Get Users SUCCESS';

export const GET_USER_PERMISIONS = '[USERS] Get User Permisions';
export const GET_USER_PERMISIONS_FAIL = '[USERS] Get User Permisions FAIL';
export const GET_USER_PERMISIONS_SUCCESS = '[USERS] Get User Permisions SUCCESS';

export const PUT_STATE_USER = '[USERS] Put State User';
export const PUT_STATE_USER_FAIL = '[USERS] Put State User FAIL';
export const PUT_STATE_USER_SUCCESS = '[USERS] Put State User SUCCESS';

export const POST_USER = '[USERS] Post User';
export const POST_USER_FAIL = '[USERS] Post User FAIL';
export const POST_USER_SUCCESS = '[USERS] Post User SUCCESS';

export const DELETE_USER = '[USERS] Delete User';
export const DELETE_USER_FAIL = '[USERS] Delete User FAIL';
export const DELETE_USER_SUCCESS = '[USERS] Delete User SUCCESS';

export const PUT_TELEFONO_USER = '[USERS] Put Telefono User';
export const PUT_TELEFONO_USER_FAIL = '[USERS] Put Telefono User FAIL';
export const PUT_TELEFONO_USER_SUCCESS = '[USERS] Put Telefono User SUCCESS';

export const PUT_PASSWORD_USER = '[USERS] Put Password User';
export const PUT_PASSWORD_USER_FAIL = '[USERS] Put Password User FAIL';
export const PUT_PASSWORD_USER_SUCCESS = '[USERS] Put Password User SUCCESS';

export const PUT_PERMISOS_USER = '[USERS] Put Permisos User';
export const PUT_PERMISOS_USER_FAIL = '[USERS] Put Permisos User FAIL';
export const PUT_PERMISOS_USER_SUCCESS = '[USERS] Put Permisos User SUCCESS';

//#region GET_USERS
export class GetUsersAction implements Action {
    readonly type = GET_USERS;
    constructor(public id: number) { }
};

export class GetUsersActionFail implements Action {
    readonly type = GET_USERS_FAIL;
    constructor(public payload: any) { }
};

export class GetUsersActionSuccess implements Action {
    readonly type = GET_USERS_SUCCESS;
    constructor(public users: Usuario[]) { }
};

export class GetUserPermisionsAction implements Action {
    readonly type = GET_USER_PERMISIONS;
    constructor(public correo: string) { }
};

export class GetUserPermisionsActionFail implements Action {
    readonly type = GET_USER_PERMISIONS_FAIL;
    constructor(public payload: any) { }
};

export class GetUserPermisionsActionSuccess implements Action {
    readonly type = GET_USER_PERMISIONS_SUCCESS;
    constructor(public permisions: string) { }
};
//#endregion

//#region PUT_STATE_USER
export class PutStateUsersAction implements Action {
    readonly type = PUT_STATE_USER;
    constructor(public correo: string) { }
};

export class PutStateUserActionFail implements Action {
    readonly type = PUT_STATE_USER_FAIL;
    constructor(public payload: any) { }
};

export class PutStateUserActionSuccess implements Action {
    readonly type = PUT_STATE_USER_SUCCESS;
    constructor(public correo: string) { }
};
//#endregion

//#region  POST_USER
export class PostUserAction implements Action {
    readonly type = POST_USER;
    constructor(public user: Usuario, public idEmpresa: number, public idCodigoEmpleado: string) { }
};
export class PostUserActionFAIL implements Action {
    readonly type = POST_USER_FAIL;
    constructor(public payload: any) { }
};
export class PostUserActionSUCCESS implements Action {
    readonly type = POST_USER_SUCCESS;
    constructor(public newUser: Usuario) { }
};
//#endregion

//#region  DELETE_USER
export class DeleteUserAction implements Action {
    readonly type = DELETE_USER;
    constructor(public user: Usuario) { }
};
export class DeleteUserActionFAIL implements Action {
    readonly type = DELETE_USER_FAIL;
    constructor(public payload: any) { }
};
export class DeleteUserActionSUCCESS implements Action {
    readonly type = DELETE_USER_SUCCESS;
    constructor(public deleteUser: Usuario) { }
};
//#endregion

//#region  PUT_TELEFONO
export class PutTelefonoAction implements Action {
    readonly type = PUT_TELEFONO_USER;
    constructor(public user: Usuario) { }
};
export class PutTelefonoActionFAIL implements Action {
    readonly type = PUT_TELEFONO_USER_FAIL;
    constructor(public payload: any) { }
};
export class PutTelefonoActionSUCCESS implements Action {
    readonly type = PUT_TELEFONO_USER_SUCCESS;
    constructor(public putUser: Usuario) { }
};
//#endregion

//#region  PUT_PASSWORD
export class PutPasswordAction implements Action {
    readonly type = PUT_PASSWORD_USER;
    constructor(public user: Usuario) { }
};
export class PutPasswordActionFAIL implements Action {
    readonly type = PUT_PASSWORD_USER_FAIL;
    constructor(public payload: any) { }
};
export class PutPasswordActionSUCCESS implements Action {
    readonly type = PUT_PASSWORD_USER_SUCCESS;
};
//#endregion

//#region  PUT_PERMISOS_USER
export class PutPermisosAction implements Action {
    readonly type = PUT_PERMISOS_USER;
    constructor(public correo: string,
                public permisos: Vista[]) { }
};
export class PutPermisosActionFAIL implements Action {
    readonly type = PUT_PERMISOS_USER_FAIL;
    constructor(public payload: any) { }
};
export class PutPermisosActionSUCCESS implements Action {
    readonly type = PUT_PERMISOS_USER_SUCCESS;
};
//#endregion

export type usersActions =
    GetUsersAction |
    GetUsersActionFail |
    GetUsersActionSuccess |
    
    GetUserPermisionsAction |
    GetUserPermisionsActionFail |
    GetUserPermisionsActionSuccess |

    PutStateUsersAction |
    PutStateUserActionFail |
    PutStateUserActionSuccess |

    PostUserAction |
    PostUserActionFAIL |
    PostUserActionSUCCESS |

    DeleteUserAction |
    DeleteUserActionFAIL |
    DeleteUserActionSUCCESS |

    PutTelefonoAction |
    PutTelefonoActionFAIL |
    PutTelefonoActionSUCCESS |

    PutPasswordAction |
    PutPasswordActionFAIL |
    PutPasswordActionSUCCESS |

    PutPermisosAction |
    PutPermisosActionFAIL |
    PutPermisosActionSUCCESS;
