import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { CarpetaProyecto } from 'src/app/shared/models/Inwork/carpeta_proyecto.model';
import { ProyectsServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class CarpetaProyectoService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getCarpetasProyectos() {
        return this.http.get(`${ProyectsServURL}/getProjectsArchives`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getCarpetasProyectosById(idCarpetaProyecto: number) {
        return this.http.get(`${ProyectsServURL}/getProjectsArchivesById?idCarpetaProyecto=${idCarpetaProyecto}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putCarpetaProyecto(carpetaProyecto: CarpetaProyecto) {
        return this.http.post(`${ProyectsServURL}/putProjectArchive`, { data: carpetaProyecto })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos de manera correcta.', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error.', 'Hubo un problema al actualizar los datos...', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postCarpetaProyecto(carpetaProyecto: CarpetaProyecto) {
        return this.http.post(`${ProyectsServURL}/postProjectArchive`, { data: carpetaProyecto })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos de manera correcta.', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error.', 'Hubo un problema al guardar los datos...', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteCarpetaProyecto(carpetaProyecto: CarpetaProyecto) {
        return this.http.get(`${ProyectsServURL}/deleteProjectArchive?idCarpetaProyecto=${carpetaProyecto.id}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos de manera correcta.', 2000));
                        return response;
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error.', 'Hubo un problema al eliminar los datos...', 2000));
                    }
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}
