import { Action } from '@ngrx/store';
import { AreaUsuario } from 'src/app/shared/models/Indicadores/area_usuario.model';
import { AreasUsuarioAux } from 'src/app/shared/models/Indicadores/areas-usuario-aux.model';

export const GET_AREAS_USUARIO_SUS_BY_CORREO = '[AREAS_USUARIO_SUS] Get Areas Usuario By Correo_SUS ';
export const GET_AREAS_USUARIO_SUS_BY_CORREO_FAIL = '[AREAS_USUARIO_SUS] Get Areas Usuario By Correo_SUS FAIL';
export const GET_AREAS_USUARIO_SUS_BY_CORREO_SUCCESS = '[AREAS_USUARIO_SUS] Get Areas Usuario By Correo_SUS SUCCESS';

export const GET_AREAS_USUARIO_SUS = '[AREAS_USUARIO_SUS] Get Areas Usuario_SUS';
export const GET_AREAS_USUARIO_SUS_FAIL = '[AREAS_USUARIO_SUS] Get Areas Usuario_SUS FAIL';
export const GET_AREAS_USUARIO_SUS_SUCCESS = '[AREAS_USUARIO_SUS] Get Areas Usuario_SUS SUCCESS';

export const PUT_AREAS_USUARIO_SUS = '[AREAS_USUARIO_SUS] Put Areas Usuario_SUS';
export const PUT_AREAS_USUARIO_SUS_FAIL = '[AREAS_USUARIO_SUS] Put Areas Usuario_SUS FAIL';
export const PUT_AREAS_USUARIO_SUS_SUCCESS = '[AREAS_USUARIO_SUS] Put Areas_SUS SUCCESS';

export const POST_AREAS_USUARIO_SUS = '[AREAS_USUARIO_SUS] Post Areas Usuario_SUS';
export const POST_AREAS_USUARIO_SUS_FAIL = '[AREAS_USUARIO_SUS] Post Areas Usuario_SUS FAIL';
export const POST_AREAS_USUARIO_SUS_SUCCESS = '[AREAS_USUARIO_SUS] Post Areas Usuario_SUS SUCCESS';

export const DELETE_AREA_USUARIO_SUS = '[AREAS_USUARIO_SUS] Delete Area Usuario_SUS';
export const DELETE_AREA_USUARIO_SUS_FAIL = '[AREAS_USUARIO_SUS] Delete Area Usuario_SUS FAIL';
export const DELETE_AREA_USUARIO_SUS_SUCCESS = '[AREAS_USUARIO_SUS] Delete Area Usuario_SUS SUCCESS';

//#region GET_AREAS_USUARIO_BY_CORREO
export class GetAreasUsuarioByCorreoAction_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS_BY_CORREO
    constructor(public correo: string) { }
};

export class GetAreasUsuarioByCorreoActionFail_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS_BY_CORREO_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasUsuarioByCorreoActionSuccess_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS_BY_CORREO_SUCCESS;
    constructor(public area_usuario: AreaUsuario) { }
};
//#endregion

//#region GET_AREAS_USUARIO
export class GetAreasUsuarioAction_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS
};

export class GetAreasUsuarioActionFail_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasUsuarioActionSuccess_SUS implements Action {
    readonly type = GET_AREAS_USUARIO_SUS_SUCCESS;
    constructor(public listAreasUsuario: AreasUsuarioAux[]) { }
};
//#endregion

//#region PUT_AREAS_USUARIO
export class PutAreasUsuarioAction_SUS implements Action {
    readonly type = PUT_AREAS_USUARIO_SUS;
    constructor(public areas_usuario: AreasUsuarioAux) { }
};

export class PutAreasUsuarioActionFail_SUS implements Action {
    readonly type = PUT_AREAS_USUARIO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutAreasUsuarioActionSuccess_SUS implements Action {
    readonly type = PUT_AREAS_USUARIO_SUS_SUCCESS;
    constructor(public editAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

//#region  POST_AREAS_USUARIO
export class PostAreasUsuarioAction_SUS implements Action {
    readonly type = POST_AREAS_USUARIO_SUS;
    constructor(public area_usuario: AreasUsuarioAux) { }
};
export class PostAreasUsuarioActionFAIL_SUS implements Action {
    readonly type = POST_AREAS_USUARIO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostAreasUsuarioActionSUCCESS_SUS implements Action {
    readonly type = POST_AREAS_USUARIO_SUS_SUCCESS;
    constructor(public newAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

//#region  DELETE_AREA
export class DeleteAreaUsuarioAction_SUS implements Action {
    readonly type = DELETE_AREA_USUARIO_SUS;
    constructor(public area_usuario: AreasUsuarioAux) { }
};
export class DeleteAreaUsuarioActionFAIL_SUS implements Action {
    readonly type = DELETE_AREA_USUARIO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteAreaUsuarioActionSUCCESS_SUS implements Action {
    readonly type = DELETE_AREA_USUARIO_SUS_SUCCESS;
    constructor(public deleteAreaUsuario: AreasUsuarioAux) { }
};
//#endregion

export type areasUsuarioActions_SUS =
GetAreasUsuarioByCorreoAction_SUS |
GetAreasUsuarioByCorreoActionFail_SUS |
GetAreasUsuarioByCorreoActionSuccess_SUS |

GetAreasUsuarioAction_SUS |
GetAreasUsuarioActionFail_SUS |
GetAreasUsuarioActionSuccess_SUS |

PostAreasUsuarioAction_SUS |
PostAreasUsuarioActionFAIL_SUS |
PostAreasUsuarioActionSUCCESS_SUS |

PutAreasUsuarioAction_SUS |
PutAreasUsuarioActionFail_SUS |
PutAreasUsuarioActionSuccess_SUS |

DeleteAreaUsuarioAction_SUS |
DeleteAreaUsuarioActionSUCCESS_SUS |
DeleteAreaUsuarioActionFAIL_SUS;