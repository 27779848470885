import * as fromAlmacenActions from '../../../actions/modulos/microMedicion/index';

import * as fromMicroMedicion from '../../../actions/modulos/microMedicion/microMedicion.actions';

import { CatalogoState, catalogoReducer } from './catalogo.reducer';
import { MedidoresAlmacenState, medidoresAlmacenReducer } from './medidoresAlmacen.reducer';
import { UbicacionState, ubicacionReducer } from './ubicacion.reducer';
import { ProyectoState, proyectoReducer } from './proyecto.reducer';
import { LoteState, lotesReducer } from './lote.reducer';
import { EstadoState, estadosReducer } from './estado.reducer';
import { ProveedorState, proveedorReducer } from './proveedor.reducer';
import { LogMedidorState, logMedidorReducer } from './logMedidor.reducer';

export interface MicroMedicionState {
    catalogo: CatalogoState;
    medidorAlmacen: MedidoresAlmacenState;
    ubicacionAlmacen: UbicacionState;
    proyectoAlmacen: ProyectoState;
    lotesAlmacen: LoteState;
    estado: EstadoState;
    proveedor: ProveedorState;
    logMedidor: LogMedidorState;
};

const initState: MicroMedicionState = {
    catalogo: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    medidorAlmacen: {
        list: [],
        altaMedidores: null,
        loaded: false,
        loading: false,
        error: null
    },
    ubicacionAlmacen: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    proyectoAlmacen: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    lotesAlmacen: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    estado: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    proveedor: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    logMedidor: {
        list: [],
        logMedidor: null,
        loaded: false,
        loading: false,
        error: null
    },
};

export function microMedicionReducer(state = initState, action: fromMicroMedicion.microMedicionActions): MicroMedicionState {
    if (action.type == fromAlmacenActions.GET_CATALOGO ||
        action.type == fromAlmacenActions.GET_CATALOGO_FAIL ||
        action.type == fromAlmacenActions.GET_CATALOGO_SUCCESS ||

        action.type == fromAlmacenActions.GET_CATALOGO_VENTA ||
        action.type == fromAlmacenActions.GET_CATALOGO_VENTA_FAIL ||
        action.type == fromAlmacenActions.GET_CATALOGO_VENTA_SUCCESS ||

        action.type == fromAlmacenActions.PUT_CATALOGO ||
        action.type == fromAlmacenActions.PUT_CATALOGO_FAIL ||
        action.type == fromAlmacenActions.PUT_CATALOGO_SUCCESS ||
        
        action.type == fromAlmacenActions.PUT_PRODUCTO ||
        action.type == fromAlmacenActions.PUT_PRODUCTO_FAIL ||
        action.type == fromAlmacenActions.PUT_PRODUCTO_SUCCESS)
        return {
            ...state,
            catalogo: catalogoReducer(state.catalogo, action)
        };
    else if (action.type == fromAlmacenActions.GET_MEDIDORES ||
        action.type == fromAlmacenActions.GET_MEDIDORES_FAIL ||
        action.type == fromAlmacenActions.GET_MEDIDORES_SUCCESS ||
        action.type == fromAlmacenActions.POST_MEDIDORES ||
        action.type == fromAlmacenActions.POST_MEDIDORES_FAIL ||
        action.type == fromAlmacenActions.POST_MEDIDORES_SUCCESS ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_DAÑADOS ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_DAÑADOS_FAIL ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_DAÑADOS_SUCCESS ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_ASIGNAR ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_ASIGNAR_FAIL ||
        action.type == fromAlmacenActions.PUT_MEDIDORES_ASIGNAR_SUCCESS ||
        action.type == fromAlmacenActions.EMPTY_POST_MEDIDORES)
        return {
            ...state,
            medidorAlmacen: medidoresAlmacenReducer(state.medidorAlmacen, action)
        };
    else if (action.type == fromAlmacenActions.GET_UBICACIONES ||
        action.type == fromAlmacenActions.GET_UBICACIONES_FAIL ||
        action.type == fromAlmacenActions.GET_UBICACIONES_SUCCESS)
        return {
            ...state,
            ubicacionAlmacen: ubicacionReducer(state.ubicacionAlmacen, action)
        };
    else if (action.type == fromAlmacenActions.GET_PROYECTO ||
        action.type == fromAlmacenActions.GET_PROYECTO_FAIL ||
        action.type == fromAlmacenActions.GET_PROYECTO_SUCCESS)
        return {
            ...state,
            proyectoAlmacen: proyectoReducer(state.proyectoAlmacen, action)
        };
    else if (action.type == fromAlmacenActions.GET_LOTES ||
        action.type == fromAlmacenActions.GET_LOTES_FAIL ||
        action.type == fromAlmacenActions.GET_LOTES_SUCCESS)
        return {
            ...state,
            lotesAlmacen: lotesReducer(state.lotesAlmacen, action)
        };
    else if (action.type == fromAlmacenActions.GET_ESTADOS ||
        action.type == fromAlmacenActions.GET_ESTADOS_FAIL ||
        action.type == fromAlmacenActions.GET_ESTADOS_SUCCESS)
        return {
            ...state,
            estado: estadosReducer(state.estado, action)
        };
    else if (action.type == fromAlmacenActions.GET_PROVEEDORES ||
        action.type == fromAlmacenActions.GET_PROVEEDORES_FAIL ||
        action.type == fromAlmacenActions.GET_PROVEEDORES_SUCCESS)
        return {
            ...state,
            proveedor: proveedorReducer(state.proveedor, action)
        };
    else if (action.type == fromAlmacenActions.GET_SERIES ||
        action.type == fromAlmacenActions.GET_SERIES_FAIL ||
        action.type == fromAlmacenActions.GET_SERIES_SUCCESS ||
        action.type == fromAlmacenActions.GET_LOG_MEDIDOR ||
        action.type == fromAlmacenActions.GET_LOG_MEDIDOR_FAIL ||
        action.type == fromAlmacenActions.GET_LOG_MEDIDOR_SUCCESS)
        return {
            ...state,
            logMedidor: logMedidorReducer(state.logMedidor, action)
        };
    else
        return state;
}