import * as fromEstadoAlmacen from '../../../actions/modulos/microMedicion/estadosAlmacen.actions';
import { EstadoMicroMedicion } from 'src/app/shared/models/microMedicion/estadoMicromedicion.model';

export class EstadoState {
    list: EstadoMicroMedicion[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: EstadoState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function estadosReducer(state = initState, action: fromEstadoAlmacen.estadosAlmacenActions): EstadoState {
    switch (action.type) {
        //#region GET_ESTADOS
        case fromEstadoAlmacen.GET_ESTADOS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEstadoAlmacen.GET_ESTADOS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.estados.map(estado => {
                        return {
                            ...estado
                        }
                    })
                ]
            };
        case fromEstadoAlmacen.GET_ESTADOS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
