import { Action } from '@ngrx/store';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';
import { Password } from 'src/app/shared/models/balcax/password.model';

export const SET_USER = '[AUTH] Set User';
export const SET_USER_FAIL = '[AUTH] Set User FAIL';
export const SET_USER_SUCCESS = '[AUTH] Set User SUCCESS';

export const PUT_PASSWORD_AUTH = '[AUTH] Put Password User';
export const PUT_PASSWORD_AUTH_FAIL = '[AUTH] Put Password User FAIL';
export const PUT_PASSWORD_AUTH_SUCCESS = '[AUTH] Put Password User SUCCESS';

//#region GET_USERS
export class SetUserAction implements Action {
    readonly type = SET_USER;
};

export class SetUserActionFail implements Action {
    readonly type = SET_USER_FAIL;
    constructor(public payload: any) { }
};

export class SetUserActionSuccess implements Action {
    readonly type = SET_USER_SUCCESS;
    constructor(public user: Usuario) { }
};
//#endregion

//#region PUT_PASSWORD
export class PutPasswordUserAction implements Action {
    readonly type = PUT_PASSWORD_AUTH;
    constructor(public credenciales: Password) { }
};

export class PutPasswordUserActionFail implements Action {
    readonly type = PUT_PASSWORD_AUTH_FAIL;
    constructor(public payload: any) { }
};

export class PutPasswordUserActionSuccess implements Action {
    readonly type = PUT_PASSWORD_AUTH_SUCCESS;
};
//#endregion

export type authAcciones =
    SetUserAction |
    SetUserActionFail |
    SetUserActionSuccess |

    PutPasswordUserAction |
    PutPasswordUserActionFail |
    PutPasswordUserActionSuccess;