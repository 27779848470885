import * as fromCamposPTInwork from '../../../actions/modulos/inwork/camposPT.actions';
import { Campos } from 'src/app/shared/models/Inwork/catalogos.model';

export class CamposPTInworkState {
    list: Campos[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: CamposPTInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function camposPTInworkReducer(state = initState, action: fromCamposPTInwork.camposPTInworkActions): CamposPTInworkState {
    switch (action.type) {
    //#region GET_CAMPOS_PT
    case fromCamposPTInwork.GET_CAMPOS_PT:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromCamposPTInwork.GET_CAMPOS_PT_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.campos.map(rowItem => {
                    return {
                        ...rowItem
                    }
                })
            ]
        };
    case fromCamposPTInwork.GET_CAMPOS_PT_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_CAMPO_PT
    case fromCamposPTInwork.PUT_CAMPO_PT:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromCamposPTInwork.PUT_CAMPO_PT_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(rowItem => {
                    if (rowItem.id === action.updateCampo.id) {
                        return {
                            ...rowItem,
                            idAtributosPT: action.updateCampo.idAtributosPT,
                            idItemCatalogo: action.updateCampo.idItemCatalogo,
                            itemCatalogo: action.updateCampo.itemCatalogo
                        };
                    } else {
                        return rowItem;
                    }
                })
            ]
        };
    case fromCamposPTInwork.PUT_CAMPO_PT_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_CAMPO_PT
    case fromCamposPTInwork.POST_CAMPO_PT:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromCamposPTInwork.POST_CAMPO_PT_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newCampo],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromCamposPTInwork.POST_CAMPO_PT_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_CAMPO_PT
    case fromCamposPTInwork.DELETE_CAMPO_PT:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromCamposPTInwork.DELETE_CAMPO_PT_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(campo => {
                    if (campo.id != action.deleteCampo.id) {
                        return {
                            campo
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromCamposPTInwork.DELETE_CAMPO_PT_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
    //#endregion
    default:
        return state;
    }
}
