import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { Tareas } from 'src/app/shared/models/Inwork/tareas.model';
import { TaskServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class TareasService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getTareasByCarpetaProyecto(idCarpetaProyecto: number) {
        return this.http.get(`${TaskServURL}/getTasksProjArch?carpetaProyecto=${idCarpetaProyecto}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getTareasByDependencia(dependencia: number) {
        return this.http.get(`${TaskServURL}/getTasksByDependencia?depedencia=${dependencia}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getCompartidoCon(idTarea: number) {
        return this.http.get(`${TaskServURL}/getSharedBy?idTarea=${idTarea}`)
            .pipe(
                map((response: any) => response),
                // catchError((error) => of(
                //     this.exceptionsService._errorComunicacion(error)
                // ))
            );
    }

    putTarea(tarea: Tareas) {
        return this.http.post(`${TaskServURL}/putTask`, tarea)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos de la tarea', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postTarea(tarea: Tareas) {
        return this.http.post(`${TaskServURL}/postTask`, { data: tarea })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos de la nueva tarea', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteRol(tarea: Tareas) {
        return this.http.get(`${TaskServURL}/deleteTask?idTarea=${tarea.id}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos de la tarea', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error.', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}