import * as fromEstrategico from '../../../actions/modulos/indicadores/estrategicos.actions';
import { ObjetivoEstrategico } from 'src/app/shared/models/Indicadores/obj_estrategico.model';

export class EstrategicoState {
    list: ObjetivoEstrategico[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: EstrategicoState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function estrategicosReducer(state = initState, action: fromEstrategico.estrategicosActions): EstrategicoState {
    switch (action.type) {
        //#region GET_ESTRATEGICOS
        case fromEstrategico.GET_ESTRATEGICOS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromEstrategico.GET_ESTRATEGICOS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.objetivosEstrategicos.map(estrategico => {
                        return {
                            ...estrategico
                        }
                    })
                ]
            };
        case fromEstrategico.GET_ESTRATEGICOS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
       //#region PUT_ESTRATEGICOS
       case fromEstrategico.PUT_ESTRATEGICO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstrategico.PUT_ESTRATEGICO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(estEditar => {
                    if (estEditar.id === action.editEstrategico.id) {
                        return {
                            ...estEditar,
                            descripcion: action.editEstrategico.descripcion,
                            fechaCreación: action.editEstrategico.fechaCreacion,
                            periodoInicio: new Date(action.editEstrategico.periodoInicio),
                            periodoFin: new Date(action.editEstrategico.periodoFin),
                            habilitado: action.editEstrategico.habilitado,
                            comentarios: action.editEstrategico.comentarios,
                            tipo: action.editEstrategico.tipo,
                            estrategico: action.editEstrategico.estrategico,
                            estandar: action.editEstrategico.estandar
                        };
                    } else {
                        return estEditar;
                    }
                })
            ]
        };
    case fromEstrategico.PUT_ESTRATEGICO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_STATE_ESTRATEGICO    
    case fromEstrategico.PUT_STATE_ESTRATEGICO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstrategico.PUT_STATE_ESTRATEGICO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(estrategicoEditar => {
                    if (estrategicoEditar.id === action.idEstrategico) {
                        return {
                            ...estrategicoEditar,
                            habilitado: !estrategicoEditar.habilitado
                        };
                    } else {
                        return estrategicoEditar;
                    }
                })
            ]
        };
    case fromEstrategico.PUT_STATE_ESTRATEGICO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ESTRATEGICOS
    case fromEstrategico.POST_ESTRATEGICO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromEstrategico.POST_ESTRATEGICO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newEstrategico],
            loading: false,
            loaded: true,
            error: null
        };
    case fromEstrategico.POST_ESTRATEGICO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ESTRATEGICO
    case fromEstrategico.DELETE_ESTRATEGICO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromEstrategico.DELETE_ESTRATEGICO_SUCCESS:
        return {
            ...state,
            list: [...state.list.filter(estrategico => {
                if (estrategico.id != action.deleteEstrategico.id) {
                    return {
                        estrategico
                    };
                }
            })],
            loading: false,
            loaded: true,
            error: null
        };
    case fromEstrategico.DELETE_ESTRATEGICO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    default:
        return state;
    }
}
