import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';
import { ExceptionsService } from '../exceptions.service';
import { of } from 'rxjs';
import { BusquedaCriteria } from 'src/app/shared/models/Indicadores/busqueda_criteria.model';
import { EspecifyServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class ObjEspecificoService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getObjetivosEspecificos(idArea: number, periodo: string) {
    return this.http.get(`${EspecifyServURL}/getEspecify?idArea=${idArea}&periodo=${periodo}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  getReporteIndicadores(criteria: BusquedaCriteria) {
    return this.http.get(`${EspecifyServURL}/getReportIndicators?direccion=${criteria._direccion}&periodo=${criteria._periodo}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putObjetivoEspecifico(especifico: ObjetivoEspecifico) {
    return this.http.post(`${EspecifyServURL}/putObjetiveEspecify`, { data: especifico })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putEspecificoValidado(especifico: ObjetivoEspecifico) {
    return this.http.post(`${EspecifyServURL}/putObjetiveEspecifyValidated`, especifico)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postObjetivoEspecifico(especifico: ObjetivoEspecifico) {
    return this.http.post(`${EspecifyServURL}/postObjetiveEspecify`, { data: especifico })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteEspecifico(especifico: ObjetivoEspecifico) {
    return this.http.get(`${EspecifyServURL}/deleteEspecify?idEspecifico=${especifico.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }
}