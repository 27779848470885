import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';
import { ExceptionsService } from '../exceptions.service';
import { CatalogoServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class PeriodosService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    getPeriodos() {
        return this.http.get(`${CatalogoServURL}/getPeriodos`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postPeriodos(periodo: Periodos) {
        return this.http.post(`${CatalogoServURL}/postPeriodos`, { data: periodo })
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putPeriodo(periodo: Periodos) {
        return this.http.post(`${CatalogoServURL}/putPeriodo`, periodo)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deletePeriodo(periodo: Periodos) {
        return this.http.get(`${CatalogoServURL}/deletePeriodo?idPeriodo=${periodo.id}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}