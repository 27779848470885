import * as fromCarpetasProyectosInwork from '../../../actions/modulos/inwork/carpetas_proyectos.actions';
import { CarpetaProyecto } from 'src/app/shared/models/Inwork/carpeta_proyecto.model';

export class CarpetasProyectosInworkState {
    list: CarpetaProyecto[];
    list_by_dependencia: CarpetaProyecto[];
    loadedBD: boolean;
    loadingBD: boolean;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: CarpetasProyectosInworkState = {
    list: [],
    list_by_dependencia: [],
    loadedBD: false,
    loadingBD: false,
    loaded: false,
    loading: false,
    error: null
};

export function carpetasProyectosInworkReducer(state = initState, action: fromCarpetasProyectosInwork.carpetasProyectosInworkActions): CarpetasProyectosInworkState {
    switch (action.type) {
    //#region GET_CARPETASPROYECTOS
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.carpetasP.map(carpetasP => {
                    return {
                        ...carpetasP
                    }
                })
            ]
        };
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region GET_CARPETASPROYECTOS
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS_BY_ID:
        return {
            ...state,
            loadingBD: true,
            error: null
        };
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS_BY_ID_SUCCESS:
        return {
            ...state,
            loadingBD: false,
            loadedBD: true,
            list_by_dependencia: [
                ...action.carpetasP.map(carpetasP => {
                    return {
                        ...carpetasP
                    }
                })
            ]
        };
    case fromCarpetasProyectosInwork.GET_CARPETASPROYECTOS_BY_ID_FAIL:
        return {
            ...state,
            loadedBD: false,
            loadingBD: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_CARPETAPROYECTO
    case fromCarpetasProyectosInwork.PUT_CARPETAPROYECTO:
        return {
            ...state,
            loadingBD: true,
            error: null
        };
    case fromCarpetasProyectosInwork.PUT_CARPETAPROYECTO_SUCCESS:
        return {
            ...state,
            loadingBD: false,
            loadedBD: true,
            list: [
                ...state.list.map(cp => {
                    if (cp.id === action.updateCarpetaProyecto.id) {
                        return {
                            ...cp,
                            nombre : action.updateCarpetaProyecto.nombre,
                            descripcion : action.updateCarpetaProyecto.descripcion,
                            dependencia : action.updateCarpetaProyecto.dependencia,
                            tipo : action.updateCarpetaProyecto.tipo,
                            visible : action.updateCarpetaProyecto.visible,
                            atributosPT : action.updateCarpetaProyecto.atributosPT,
                            usersCompartir : action.updateCarpetaProyecto.usersCompartir,
                            usersPropietarios : action.updateCarpetaProyecto.usersPropietario,
                        };
                    } else {
                        return cp;
                    }
                })
            ]
        };
    case fromCarpetasProyectosInwork.PUT_CARPETAPROYECTO_FAIL:
        return {
            ...state,
            loadedBD: false,
            loadingBD: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_CARPETAPROYECTO
    case fromCarpetasProyectosInwork.POST_CARPETAPROYECTO:
        return {
            ...state,
            loadingBD: true,
            error: null
        }
    case fromCarpetasProyectosInwork.POST_CARPETAPROYECTO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newCarpetaProyecto],
            loadingBD: false,
            loadedBD: true,
            error: null            
        };
    case fromCarpetasProyectosInwork.POST_CARPETAPROYECTO_FAIL:
        return {
            ...state,
            loadedBD: false,
            loadingBD: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_CARPETAPROYECTO
    case fromCarpetasProyectosInwork.DELETE_CARPETAPROYECTO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromCarpetasProyectosInwork.DELETE_CARPETAPROYECTO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(cp => {
                    if (cp.id != action.deleteCarpetaProyecto.id) {
                        return {
                            cp
                        };
                    }
                })],
                list_by_dependencia: [...state.list_by_dependencia.filter(cp => {
                    if (cp.id != action.deleteCarpetaProyecto.id) {
                        return {
                            cp
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromCarpetasProyectosInwork.DELETE_CARPETAPROYECTO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                loadedBD: false,
                loadingBD: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
        return state;
    }
}
