import { Action } from '@ngrx/store';
import { Pie } from 'src/app/shared/models/balcax/charts/pie.model';
import { DataCampania, Encabezado, FiltrosUniverso, Historico, Universo } from 'src/app/shared/models/campañas/campañas.model';
import { Colonia } from 'src/app/shared/models/campañas/colonias.model';

export const GET_UNIVERSO= '[CAMPAÑAS] Get Universos';
export const GET_UNIVERSO_FAIL = '[CAMPAÑAS] Get Universos FAIL';
export const GET_UNIVERSO_SUCCESS = '[CAMPAÑAS] Get Universos SUCCESS';

export const GET_ENCABEZADO= '[CAMPAÑAS] Get Encabezado';
export const GET_ENCABEZADO_FAIL = '[CAMPAÑAS] Get Encabezado FAIL';
export const GET_ENCABEZADO_SUCCESS = '[CAMPAÑAS] Get Encabezado SUCCESS';

export const GET_HISTORICO= '[CAMPAÑAS] Get Historico';
export const GET_HISTORICO_FAIL = '[CAMPAÑAS] Get Historico FAIL';
export const GET_HISTORICO_SUCCESS = '[CAMPAÑAS] Get Historico SUCCESS';

export const GET_GRAPH= '[CAMPAÑAS] Get Graph';
export const GET_GRAPH_FAIL = '[CAMPAÑAS] Get Graph FAIL';
export const GET_GRAPH_SUCCESS = '[CAMPAÑAS] Get Graph SUCCESS';

export const GET_COLONIA= '[CAMPAÑAS] Get Colonia';
export const GET_COLONIA_FAIL = '[CAMPAÑAS] Get Colonia FAIL';
export const GET_COLONIA_SUCCESS = '[CAMPAÑAS] Get Colonia SUCCESS';

export const SEND_CAMPANIA = '[CAMPAÑAS] Send Campania';
export const SEND_CAMPANIA_FAIL = '[CAMPAÑAS] Send Campania FAIL';
export const SEND_CAMPANIA_SUCCESS = '[CAMPAÑAS] Send Campania SUCCESS';

//#region GET_UNIVERSO
export class GetUniversoAction implements Action {
    readonly type = GET_UNIVERSO;
    constructor(public filtros: FiltrosUniverso) { }
};

export class GetUniversoActionFail implements Action {
    readonly type = GET_UNIVERSO_FAIL;
    constructor(public payload: any) { }
};

export class GetUniversoActionSuccess implements Action {
    readonly type = GET_UNIVERSO_SUCCESS;
    constructor(public universo: Universo[]) { }
};
//#endregion

//#region GET_ENCABEZADO
export class GetEncabezadoAction implements Action {
    readonly type = GET_ENCABEZADO;
};

export class GetEncabezadoActionFail implements Action {
    readonly type = GET_ENCABEZADO_FAIL;
    constructor(public payload: any) { }
};

export class GetEncabezadoActionSuccess implements Action {
    readonly type = GET_ENCABEZADO_SUCCESS;
    constructor(public encabezados: Encabezado[]) { }
};
//#endregion

//#region GET_HISTORICO
export class GetHistoricoAction implements Action {
    readonly type = GET_HISTORICO;
    constructor(public idEncabezado: number) { }
};

export class GetHistoricoActionFail implements Action {
    readonly type = GET_HISTORICO_FAIL;
    constructor(public payload: any) { }
};

export class GetHistoricoActionSuccess implements Action {
    readonly type = GET_HISTORICO_SUCCESS;
    constructor(public historicos: Historico[]) { }
};
//#endregion

//#region GET_GRAPH
export class GetGraphAction implements Action {
    readonly type = GET_GRAPH;
    constructor(public idEncabezado: number) { }
};

export class GetGraphActionFail implements Action {
    readonly type = GET_GRAPH_FAIL;
    constructor(public payload: any) { }
};

export class GetGraphActionSuccess implements Action {
    readonly type = GET_GRAPH_SUCCESS;
    constructor(public graph: Pie) { }
};
//#endregion

//#region GET_COLONIA
export class GetColoniaAction implements Action {
    readonly type = GET_COLONIA;
};

export class GetColoniaActionFail implements Action {
    readonly type = GET_COLONIA_FAIL;
    constructor(public payload: any) { }
};

export class GetColoniaActionSuccess implements Action {
    readonly type = GET_COLONIA_SUCCESS;
    constructor(public colonias: Colonia[]) { }
};
//#endregion

//#region  SEND_CAMPANIA
export class SendCampaniaAction implements Action {
    readonly type = SEND_CAMPANIA;
    constructor(public campania: DataCampania) { }
};

export class SendCampaniaActionFail implements Action {
    readonly type = SEND_CAMPANIA_FAIL;
    constructor(public payload: any) { }
};

export class SendCampaniaActionSuccess implements Action {
    readonly type = SEND_CAMPANIA_SUCCESS;
    constructor(public response: boolean) { }
};
//#endregion

export type universoActions =
GetUniversoAction |
GetUniversoActionFail |
GetUniversoActionSuccess |

GetEncabezadoAction |
GetEncabezadoActionFail |
GetEncabezadoActionSuccess |

GetHistoricoAction |
GetHistoricoActionFail |
GetHistoricoActionSuccess |

GetGraphAction |
GetGraphActionFail |
GetGraphActionSuccess |

GetColoniaAction |
GetColoniaActionFail |
GetColoniaActionSuccess|

SendCampaniaAction |
SendCampaniaActionFail |
SendCampaniaActionSuccess;