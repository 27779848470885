import { Action } from '@ngrx/store';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';

export const GET_AREAS_SUS = '[AREAS_SUS] Get Areas_SUS';
export const GET_AREAS_SUS_FAIL = '[AREAS_SUS] Get Areas_SUS FAIL';
export const GET_AREAS_SUS_SUCCESS = '[AREAS_SUS] Get Areas_SUS SUCCESS';

export const PUT_STATE_AREAS_SUS = '[AREAS_SUS] Put State Areas_SUS';
export const PUT_STATE_AREAS_SUS_FAIL = '[AREAS_SUS] Put State Areas_SUS FAIL';
export const PUT_STATE_AREAS_SUS_SUCCESS = '[AREAS_SUS] Put State Areas_SUS SUCCESS';

export const PUT_AREAS_SUS = '[AREAS_SUS] Put Areas_SUS';
export const PUT_AREAS_SUS_FAIL = '[AREAS_SUS] Put Areas_SUS FAIL';
export const PUT_AREAS_SUS_SUCCESS = '[AREAS_SUS] Put Areas_SUS SUCCESS';

export const POST_AREAS_SUS = '[AREAS_SUS] Post Areas_SUS';
export const POST_AREAS_SUS_FAIL = '[AREAS_SUS] Post Areas_SUS FAIL';
export const POST_AREAS_SUS_SUCCESS = '[AREAS_SUS] Post Areas_SUS SUCCESS';

export const DELETE_AREA_SUS = '[AREAS_SUS] Delete Area_SUS';
export const DELETE_AREA_SUS_FAIL = '[AREAS_SUS] Delete Area_SUS FAIL';
export const DELETE_AREA_SUS_SUCCESS = '[AREAS_SUS] Delete Area_SUS SUCCESS';

//#region GET_AREAS
export class GetAreasAction_SUS implements Action {
    readonly type = GET_AREAS_SUS;
    constructor() { }
};

export class GetAreasActionFail_SUS implements Action {
    readonly type = GET_AREAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAreasActionSuccess_SUS implements Action {
    readonly type = GET_AREAS_SUS_SUCCESS;
    constructor(public areas: AreaEmpresa[]) { }
};
//#endregion

//#region PUT_STATE_AREAS
export class PutStateAreasAction_SUS implements Action {
    readonly type = PUT_STATE_AREAS_SUS;
    constructor(public idArea: number) { }
};

export class PutStateAreasActionFail_SUS implements Action {
    readonly type = PUT_STATE_AREAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutStateAreasActionSuccess_SUS implements Action {
    readonly type = PUT_STATE_AREAS_SUS_SUCCESS;
    constructor(public idArea: number) { }
};
//#endregion

//#region PUT_AREAS
export class PutAreasAction_SUS implements Action {
    readonly type = PUT_AREAS_SUS;
    constructor(public areas: AreaEmpresa) { }
};

export class PutAreasActionFail_SUS implements Action {
    readonly type = PUT_AREAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutAreasActionSuccess_SUS implements Action {
    readonly type = PUT_AREAS_SUS_SUCCESS;
    constructor(public editArea: AreaEmpresa) { }
};
//#endregion

//#region  POST_AREAS
export class PostAreasAction_SUS implements Action {
    readonly type = POST_AREAS_SUS;
    constructor(public area: AreaEmpresa) { }
};
export class PostAreasActionFAIL_SUS implements Action {
    readonly type = POST_AREAS_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostAreasActionSUCCESS_SUS implements Action {
    readonly type = POST_AREAS_SUS_SUCCESS;
    constructor(public newArea: AreaEmpresa) { }
};
//#endregion

//#region  DELETE_AREA
export class DeleteAreaAction_SUS implements Action {
    readonly type = DELETE_AREA_SUS;
    constructor(public area: AreaEmpresa) { }
};
export class DeleteAreaActionFAIL_SUS implements Action {
    readonly type = DELETE_AREA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteAreaActionSUCCESS_SUS implements Action {
    readonly type = DELETE_AREA_SUS_SUCCESS;
    constructor(public deleteArea: AreaEmpresa) { }
};
//#endregion

export type areasActions_SUS =
GetAreasAction_SUS |
GetAreasActionFail_SUS |
GetAreasActionSuccess_SUS |

PostAreasAction_SUS |
PostAreasActionFAIL_SUS |
PostAreasActionSUCCESS_SUS |

PutStateAreasAction_SUS |
PutStateAreasActionFail_SUS |
PutStateAreasActionSuccess_SUS |

PutAreasAction_SUS |
PutAreasActionFail_SUS |
PutAreasActionSuccess_SUS |

DeleteAreaAction_SUS |
DeleteAreaActionSUCCESS_SUS |
DeleteAreaActionFAIL_SUS;