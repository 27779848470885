import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as fecuenciasActions from '../../../actions/modulos/indicadores/frecuencias.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { FrecuenciasService } from 'src/app/services/indicadores/frecuencias.service';
import { Frecuencia } from 'src/app/shared/models/Indicadores/frecuencia.model';

@Injectable()

export class frecuenciasEffects {
    constructor(
        private actions$: Actions,
        private frecuenciasService: FrecuenciasService,
    ) { }

    @Effect()
    cargarFrecuencias$ = this.actions$.ofType(fecuenciasActions.GET_FRECUENCIAS)
        .pipe(
            switchMap(() => {
                return this.frecuenciasService.getFrecuencias()
                    .pipe(
                        map((frecuencias: Frecuencia[]) => new fecuenciasActions.GetFrecuenciasActionSuccess(frecuencias)),
                        catchError(error => of(new fecuenciasActions.GetFrecuenciasActionFail(error)))
                    )
            })
        );

    @Effect()
    postFrecuencia$ = this.actions$.ofType(fecuenciasActions.POST_FRECUENCIA)
        .pipe(
            switchMap((action: fecuenciasActions.PostFrecuenciaAction) => {
                return this.frecuenciasService.postFrecuencia(action.frecuencia)
                    .pipe(
                        map((frecuencia: Frecuencia) => new fecuenciasActions.PostFrecuenciaActionSUCCESS(frecuencia)),
                        catchError(error => of(new fecuenciasActions.PostFrecuenciaActionFAIL(error)))
                    )
            })
        );
    @Effect()
    editFrecuencia$ = this.actions$.ofType(fecuenciasActions.PUT_FRECUENCIA)
        .pipe(
            switchMap((action: fecuenciasActions.PutFrecuenciaAction) => {
                const frecuencia = new Frecuencia();
                frecuencia.id = action.updateFrecuencia.id;
                frecuencia.descripcion = action.updateFrecuencia.descripcion;

                return this.frecuenciasService.putFrecuencia(frecuencia)
                    .pipe(
                        map(() => new fecuenciasActions.PutFrecuenciaActionSUCCESS(frecuencia)),
                        catchError(error => of(new fecuenciasActions.PutFrecuenciaActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteFrecuencia$ = this.actions$.ofType(fecuenciasActions.DELETE_FRECUENCIA)
        .pipe(
            switchMap((action: fecuenciasActions.DeleteFrecuenciaAction) => {
                return this.frecuenciasService.deleteFrecuencia(action.frecuencia)
                    .pipe(
                        map(() => new fecuenciasActions.DeleteFrecuenciaActionSUCCESS(action.frecuencia)),
                        catchError(error => of(new fecuenciasActions.DeleteFrecuenciaActionFAIL(error)))
                    )
            })
        );
};
