import * as fromArchivosInwork from '../../../actions/modulos/inwork/archivos.actions';
import { Archivos } from 'src/app/shared/models/Inwork/archivos.model';

export class ArchivosInworkState {
    list: Archivos[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: ArchivosInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function archivosInworkReducer(state = initState, action: fromArchivosInwork.archivosInworkActions): ArchivosInworkState {
    switch (action.type) {
    //#region GET_ARCHIVOS
    case fromArchivosInwork.GET_ARCHIVOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromArchivosInwork.GET_ARCHIVOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.archivos.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromArchivosInwork.GET_ARCHIVOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_ARCHIVOS
    case fromArchivosInwork.PUT_ARCHIVOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromArchivosInwork.PUT_ARCHIVOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.updateArchivos.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromArchivosInwork.PUT_ARCHIVOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ARCHIVOS
    case fromArchivosInwork.POST_ARCHIVOS:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromArchivosInwork.POST_ARCHIVOS_SUCCESS:
        return {
            ...state,
            list: [
                ...action.newArchivos.map(item => {
                    return {
                        ...item
                    }
                })
            ],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromArchivosInwork.POST_ARCHIVOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ARCHIVOS
    case fromArchivosInwork.DELETE_ARCHIVOS:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromArchivosInwork.DELETE_ARCHIVOS_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(item => {
                    if (item.id != action.deleteActualizacion.id) {
                        return {
                            item
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromArchivosInwork.DELETE_ARCHIVOS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
