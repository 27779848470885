import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExceptionsService } from '../../exceptions.service';
import { Router } from '@angular/router';
import { SelectedMedidoresSuccess } from 'src/app/shared/models/microMedicion/selectedMedidores.model';

@Injectable({
    providedIn: 'root'
})

export class LogMedidorAlmacenService {

    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private router: Router
    ) { }

    //#region  GET
    getSeries() {
        return this.http.get(`${environment.apiAlmacen}almacen/getSeriesNumbers`)
            .pipe(
                map((response: string[]) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getLogMovimientos(serie: String) {
        return this.http.get(`${environment.apiAlmacen}almacen/getHistoryMeter?serie=${serie}`)
            .pipe(
                map((response: SelectedMedidoresSuccess) => {
                    this.router.navigate(['/microMedicion/historicoMedidor']);
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }
    //#endregion
}