import * as fromActions from '../../../actions/modulos/inwork/index';
import * as fromInwork from '../../../actions/modulos/inwork/inwork.actions';

import { carpetasProyectosInworkReducer, CarpetasProyectosInworkState } from './carpetas_proyectos.reducer';
import { catalogosInworkReducer, CatalogosInworkState } from './catalogos.reducer';
import { GruposInworkState, gruposInworkReducer } from './grupos.reducer';
import { GruposUserInworkState, gruposUserInworkReducer } from './grupos_user.reducer';
import { FlujoTrabajoInworkState, flujoTrabajoInworkReducer } from './flujo_trabajo.reducer';
import { ItemsCatalogoInworkState, itemsCatalogoInworkReducer } from './items_catalogo.reducer';
import { RolesInworkState, rolesInworkReducer } from './roles.reducer';
import { rolesUsuariosInworkReducer, RolesUsuariosInworkState } from './roles_usuario.reducer';
import { tareasInworkReducer, TareasInworkState } from './tareas.reducer';
import { camposPTInworkReducer, CamposPTInworkState } from './camposPT.reducer';
import { actualizacionesInworkReducer, ActualizacionesInworkState } from './actualizaciones.reducer';
import { archivosInworkReducer, ArchivosInworkState } from './archivos.reducer';


export interface InworkState {
    catalogos: CatalogosInworkState;
    itemsCatalogos: ItemsCatalogoInworkState;
    grupos: GruposInworkState;
    gruposUsuarios: GruposUserInworkState;
    roles: RolesInworkState;
    rolesUsuario: RolesUsuariosInworkState;
    flujosTrabajo: FlujoTrabajoInworkState;
    carpetasProyectos: CarpetasProyectosInworkState;
    tareas: TareasInworkState;
    campos: CamposPTInworkState;
    actualizaciones: ActualizacionesInworkState;
    archivos: ArchivosInworkState;
};

const initState: InworkState = {
    catalogos : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    itemsCatalogos : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    grupos : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    gruposUsuarios : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    roles : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    rolesUsuario : {
        list: [],
        rolUsuario: null,
        loaded: false,
        loading: false,
        error: null
    },
    flujosTrabajo : {
        list: [],
        flujos: [],
        loaded: false,
        loading: false,
        error: null
    },
    carpetasProyectos : {
        list: [],
        list_by_dependencia: [],
        loadedBD: false,
        loadingBD: false,
        loaded: false,
        loading: false,
        error: null
    },
    tareas : {
        list_by_cp: [],
        list_by_dep: [],
        list_shared_by: [],
        loaded_by_cp: false,
        loading_by_cp: false,
        loaded_by_dep: false,
        loading_by_dep: false,
        loaded_shared_by: false,
        loading_shared_by: false,
        error: null
    },
    campos : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    actualizaciones : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    archivos : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    }
};

export function inworkReducer(state = initState, action: fromInwork.inworkActions): InworkState {
    if(action.type == fromActions.GET_CATALOGOS || 
        action.type == fromActions.GET_CATALOGOS_FAIL ||
        action.type == fromActions.GET_CATALOGOS_SUCCESS ||
        action.type == fromActions.POST_CATALOGO ||
        action.type == fromActions.POST_CATALOGO_FAIL ||
        action.type == fromActions.POST_CATALOGO_SUCCESS ||
        action.type == fromActions.PUT_CATALOGO ||
        action.type == fromActions.PUT_CATALOGO_FAIL ||
        action.type == fromActions.PUT_CATALOGO_SUCCESS ||
        action.type == fromActions.DELETE_CATALOGO ||
        action.type == fromActions.DELETE_CATALOGO_FAIL ||
        action.type == fromActions.DELETE_CATALOGO_SUCCESS){
            return{
                ...state,
                catalogos: catalogosInworkReducer(state.catalogos, action)
            };
    } else if(action.type == fromActions.GET_ITEMS_CATALOGO || 
        action.type == fromActions.GET_ITEMS_CATALOGO_FAIL ||
        action.type == fromActions.GET_ITEMS_CATALOGO_SUCCESS ||
        action.type == fromActions.POST_ITEM_CATALOGO ||
        action.type == fromActions.POST_ITEM_CATALOGO_FAIL ||
        action.type == fromActions.POST_ITEM_CATALOGO_SUCCESS ||
        action.type == fromActions.PUT_ITEM_CATALOGO ||
        action.type == fromActions.PUT_ITEM_CATALOGO_FAIL ||
        action.type == fromActions.PUT_ITEM_CATALOGO_SUCCESS ||
        action.type == fromActions.DELETE_ITEM_CATALOGO ||
        action.type == fromActions.DELETE_ITEM_CATALOGO_FAIL ||
        action.type == fromActions.DELETE_ITEM_CATALOGO_SUCCESS){
            return{
                ...state,
                itemsCatalogos: itemsCatalogoInworkReducer(state.itemsCatalogos, action)
            };
    } else if(action.type == fromActions.GET_GRUPOS || 
        action.type == fromActions.GET_GRUPOS_FAIL ||
        action.type == fromActions.GET_GRUPOS_SUCCESS ||
        action.type == fromActions.POST_GRUPO ||
        action.type == fromActions.POST_GRUPO_FAIL ||
        action.type == fromActions.POST_GRUPO_SUCCESS ||
        action.type == fromActions.PUT_GRUPO ||
        action.type == fromActions.PUT_GRUPO_FAIL ||
        action.type == fromActions.PUT_GRUPO_SUCCESS ||
        action.type == fromActions.DELETE_GRUPO ||
        action.type == fromActions.DELETE_GRUPO_FAIL ||
        action.type == fromActions.DELETE_GRUPO_SUCCESS){
            return{
                ...state,
                grupos: gruposInworkReducer(state.grupos, action)
            };
    } else if(action.type == fromActions.POST_GRUPOS_USER ||
        action.type == fromActions.POST_GRUPOS_USER_FAIL ||
        action.type == fromActions.POST_GRUPOS_USER_SUCCESS ||
        action.type == fromActions.PUT_GRUPOS_USER ||
        action.type == fromActions.PUT_GRUPOS_USER_FAIL ||
        action.type == fromActions.PUT_GRUPOS_USER_SUCCESS ||
        action.type == fromActions.DELETE_GRUPOS_USER ||
        action.type == fromActions.DELETE_GRUPOS_USER_FAIL ||
        action.type == fromActions.DELETE_GRUPOS_USER_SUCCESS){
            return{
                ...state,
                gruposUsuarios: gruposUserInworkReducer(state.gruposUsuarios, action)
            };
    } else if(action.type == fromActions.GET_ROLES || 
        action.type == fromActions.GET_ROLES_FAIL ||
        action.type == fromActions.GET_ROLES_SUCCESS ||
        action.type == fromActions.POST_ROL ||
        action.type == fromActions.POST_ROL_FAIL ||
        action.type == fromActions.POST_ROL_SUCCESS ||
        action.type == fromActions.PUT_ROL ||
        action.type == fromActions.PUT_ROL_FAIL ||
        action.type == fromActions.PUT_ROL_SUCCESS ||
        action.type == fromActions.DELETE_ROL ||
        action.type == fromActions.DELETE_ROL_FAIL ||
        action.type == fromActions.DELETE_ROL_SUCCESS){
            return{
                ...state,
                roles: rolesInworkReducer(state.roles, action)
            };
    } else if(action.type == fromActions.GET_ROLES_USUARIOS || 
        action.type == fromActions.GET_ROLES_USUARIOS_FAIL ||
        action.type == fromActions.GET_ROLES_USUARIOS_SUCCESS ||
        action.type == fromActions.GET_ROL_USUARIO_BY_CORREO || 
        action.type == fromActions.GET_ROL_USUARIO_BY_CORREO_FAIL ||
        action.type == fromActions.GET_ROL_USUARIO_BY_CORREO_SUCCESS ||
        action.type == fromActions.POST_ROL_USUARIO ||
        action.type == fromActions.POST_ROL_USUARIO_FAIL ||
        action.type == fromActions.POST_ROL_USUARIO_SUCCESS ||
        action.type == fromActions.PUT_ROL_USUARIO ||
        action.type == fromActions.PUT_ROL_USUARIO_FAIL ||
        action.type == fromActions.PUT_ROL_USUARIO_SUCCESS ||
        action.type == fromActions.PUT_STATE_ROL_USUARIO ||
        action.type == fromActions.PUT_STATE_ROL_USUARIO_FAIL ||
        action.type == fromActions.PUT_STATE_ROL_USUARIO_SUCCESS ||
        action.type == fromActions.DELETE_ROL_USUARIO ||
        action.type == fromActions.DELETE_ROL_USUARIO_FAIL ||
        action.type == fromActions.DELETE_ROL_USUARIO_SUCCESS){
            return{
                ...state,
                rolesUsuario: rolesUsuariosInworkReducer(state.rolesUsuario, action)
            };
    } else if(action.type == fromActions.GET_FLUJOS_TRABAJO || 
        action.type == fromActions.GET_FLUJOS_TRABAJO_FAIL ||
        action.type == fromActions.GET_FLUJOS_TRABAJO_SUCCESS ||
        action.type == fromActions.GET_FLUJOS || 
        action.type == fromActions.GET_FLUJOS_FAIL ||
        action.type == fromActions.GET_FLUJOS_SUCCESS ||
        action.type == fromActions.POST_FLUJO_TRABAJO ||
        action.type == fromActions.POST_FLUJO_TRABAJO_FAIL ||
        action.type == fromActions.POST_FLUJO_TRABAJO_SUCCESS ||
        action.type == fromActions.PUT_FLUJO_TRABAJO ||
        action.type == fromActions.PUT_FLUJO_TRABAJO_FAIL ||
        action.type == fromActions.PUT_FLUJO_TRABAJO_SUCCESS ||
        action.type == fromActions.DELETE_FLUJO_TRABAJO ||
        action.type == fromActions.DELETE_FLUJO_TRABAJO_FAIL ||
        action.type == fromActions.DELETE_FLUJO_TRABAJO_SUCCESS){
            return{
                ...state,
                flujosTrabajo: flujoTrabajoInworkReducer(state.flujosTrabajo, action)
            };
    } else if(action.type == fromActions.GET_CARPETASPROYECTOS || 
        action.type == fromActions.GET_CARPETASPROYECTOS_FAIL ||
        action.type == fromActions.GET_CARPETASPROYECTOS_SUCCESS ||
        action.type == fromActions.GET_CARPETASPROYECTOS_BY_ID || 
        action.type == fromActions.GET_CARPETASPROYECTOS_BY_ID_FAIL ||
        action.type == fromActions.GET_CARPETASPROYECTOS_BY_ID_SUCCESS ||
        action.type == fromActions.POST_CARPETAPROYECTO ||
        action.type == fromActions.POST_CARPETAPROYECTO_FAIL ||
        action.type == fromActions.POST_CARPETAPROYECTO_SUCCESS ||
        action.type == fromActions.PUT_CARPETAPROYECTO ||
        action.type == fromActions.PUT_CARPETAPROYECTO_FAIL ||
        action.type == fromActions.PUT_CARPETAPROYECTO_SUCCESS ||
        action.type == fromActions.DELETE_CARPETAPROYECTO ||
        action.type == fromActions.DELETE_CARPETAPROYECTO_FAIL ||
        action.type == fromActions.DELETE_CARPETAPROYECTO_SUCCESS){
            return{
                ...state,
                carpetasProyectos: carpetasProyectosInworkReducer(state.carpetasProyectos, action)
            };
    } else if(action.type == fromActions.GET_TAREAS_BY_CP || 
        action.type == fromActions.GET_TAREAS_BY_CP_FAIL ||
        action.type == fromActions.GET_TAREAS_BY_CP_SUCCESS ||
        action.type == fromActions.GET_TAREAS_BY_DEP || 
        action.type == fromActions.GET_TAREAS_BY_DEP_FAIL ||
        action.type == fromActions.GET_TAREAS_BY_DEP_SUCCESS ||
        action.type == fromActions.GET_SHARED_BY || 
        action.type == fromActions.GET_SHARED_BY_FAIL ||
        action.type == fromActions.GET_SHARED_BY_SUCCESS ||
        action.type == fromActions.POST_TAREA ||
        action.type == fromActions.POST_TAREA_FAIL ||
        action.type == fromActions.POST_TAREA_SUCCESS ||
        action.type == fromActions.PUT_TAREA ||
        action.type == fromActions.PUT_TAREA_FAIL ||
        action.type == fromActions.PUT_TAREA_SUCCESS ||
        action.type == fromActions.DELETE_TAREA ||
        action.type == fromActions.DELETE_TAREA_FAIL ||
        action.type == fromActions.DELETE_TAREA_SUCCESS){
            return{
                ...state,
                tareas: tareasInworkReducer(state.tareas, action)
            };
    } else if(action.type == fromActions.GET_CAMPOS_PT || 
        action.type == fromActions.GET_CAMPOS_PT_FAIL ||
        action.type == fromActions.GET_CAMPOS_PT_SUCCESS ||
        action.type == fromActions.POST_CAMPO_PT ||
        action.type == fromActions.POST_CAMPO_PT_FAIL ||
        action.type == fromActions.POST_CAMPO_PT_SUCCESS ||
        action.type == fromActions.PUT_CAMPO_PT ||
        action.type == fromActions.PUT_CAMPO_PT_FAIL ||
        action.type == fromActions.PUT_CAMPO_PT_SUCCESS ||
        action.type == fromActions.DELETE_CAMPO_PT ||
        action.type == fromActions.DELETE_CAMPO_PT_FAIL ||
        action.type == fromActions.DELETE_CAMPO_PT_SUCCESS){
            return{
                ...state,
                campos: camposPTInworkReducer(state.campos, action)
            };
    } else if(action.type == fromActions.GET_ACTUALIZACIONES || 
        action.type == fromActions.GET_ACTUALIZACIONES_FAIL ||
        action.type == fromActions.GET_ACTUALIZACIONES_SUCCESS ||
        action.type == fromActions.POST_ACTUALIZACION ||
        action.type == fromActions.POST_ACTUALIZACION_FAIL ||
        action.type == fromActions.POST_ACTUALIZACION_SUCCESS ||
        action.type == fromActions.PUT_ACTUALIZACION ||
        action.type == fromActions.PUT_ACTUALIZACION_FAIL ||
        action.type == fromActions.PUT_ACTUALIZACION_SUCCESS ||
        action.type == fromActions.DELETE_ACTUALIZACION ||
        action.type == fromActions.DELETE_ACTUALIZACION_FAIL ||
        action.type == fromActions.DELETE_ACTUALIZACION_SUCCESS){
            return{
                ...state,
                actualizaciones: actualizacionesInworkReducer(state.actualizaciones, action)
            };
    } else if(action.type == fromActions.GET_ARCHIVOS || 
        action.type == fromActions.GET_ARCHIVOS_FAIL ||
        action.type == fromActions.GET_ARCHIVOS_SUCCESS ||
        action.type == fromActions.POST_ARCHIVOS ||
        action.type == fromActions.POST_ARCHIVOS_FAIL ||
        action.type == fromActions.POST_ARCHIVOS_SUCCESS ||
        action.type == fromActions.PUT_ARCHIVOS ||
        action.type == fromActions.PUT_ARCHIVOS_FAIL ||
        action.type == fromActions.PUT_ARCHIVOS_SUCCESS ||
        action.type == fromActions.DELETE_ARCHIVOS ||
        action.type == fromActions.DELETE_ARCHIVOS_FAIL ||
        action.type == fromActions.DELETE_ARCHIVOS_SUCCESS){
            return{
                ...state,
                archivos: archivosInworkReducer(state.archivos, action)
            };
    }
    return state;
}