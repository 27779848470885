import * as fromCorteCaja from '../../../actions/modulos/punto-venta/corteCaja_PV.actions';
import { Corte_Caja_PV } from 'src/app/shared/models/punto-venta/corte-caja-pv.model';

export class CorteCaja_PV_State {
    inicio: Corte_Caja_PV;
    final: Corte_Caja_PV;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: CorteCaja_PV_State = {
    inicio: null,
    final: null,
    loaded: false,
    loading: false,
    error: null
};

export function corteCaja_PV_Reducer(state = initState, action: fromCorteCaja.corteCaja_PV_Actions): CorteCaja_PV_State {
    switch (action.type) {
        //#region GET_ESTATUS_CAJA
        case fromCorteCaja.GET_ESTATUS_CAJA:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCorteCaja.GET_ESTATUS_CAJA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                inicio: action.cortes[0],
                final: (action.cortes.length>1) ? action.cortes[1] : null
            };
        case fromCorteCaja.GET_ESTATUS_CAJA_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region POST_CORTE_CAJA
        case fromCorteCaja.POST_CORTE_CAJA:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromCorteCaja.POST_CORTE_CAJA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                inicio: action.cortes[0],
                final: (action.cortes.length>1) ? action.cortes[1] : null
            };
        case fromCorteCaja.POST_CORTE_CAJA_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
