import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { Estrategia } from 'src/app/shared/models/Indicadores/estrategia.model';
import { ExceptionsService } from '../exceptions.service';
import { of } from 'rxjs';
import { EstrategyServURL } from 'src/config/variablesGL';

@Injectable({
  providedIn: 'root'
})
export class EstrategiasService {
  constructor(
    private http: HttpClientService,
    private exceptionsService: ExceptionsService
  ) { }

  getEstrategias(idEspecifico) {
    return this.http.get(`${EstrategyServURL}/getEstrategys?idEspecifico=${idEspecifico}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  putEstrategia(estrategia: Estrategia) {
    return this.http.post(`${EstrategyServURL}/putEstrategy`, estrategia)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  postEstrategia(estrategia: Estrategia) {
    return this.http.post(`${EstrategyServURL}/postEstrategy`, { data: estrategia })
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

  deleteEstrategia(estrategia: Estrategia) {
    return this.http.get(`${EstrategyServURL}/deleteEstrategy?idEstrategia=${estrategia.id}`)
      .pipe(
        map((response: any) => response),
        catchError((error) => of(
          this.exceptionsService._errorComunicacion(error)
        ))
      );
  }

}