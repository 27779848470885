import * as fromUbicacionAlmacen from '../../../actions/modulos/microMedicion/ubicacionAlmacen.actions';
import { ubicacionMicroMedicion } from 'src/app/shared/models/microMedicion/ubicacionmicromedicion';

export class UbicacionState {
    list: ubicacionMicroMedicion[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: UbicacionState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function ubicacionReducer(state = initState, action: fromUbicacionAlmacen.ubicacionAlmacenActions): UbicacionState {
    switch (action.type) {
        //#region GET_CATALOGO
        case fromUbicacionAlmacen.GET_UBICACIONES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUbicacionAlmacen.GET_UBICACIONES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.ubicaciones.map(ubicacion => {
                        return {
                            ...ubicacion
                        }
                    })
                ]
            };
        case fromUbicacionAlmacen.GET_UBICACIONES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
