import { Action } from '@ngrx/store';
import { SelectedMedidoresSuccess } from 'src/app/shared/models/microMedicion/selectedMedidores.model';

export const GET_SERIES = '[ALMACEN] Get Series';
export const GET_SERIES_FAIL = '[ALMACEN] Get Series FAIL';
export const GET_SERIES_SUCCESS = '[ALMACEN] Get Series SUCCESS';

export const GET_LOG_MEDIDOR = '[ALMACEN] Get Log Medidor';
export const GET_LOG_MEDIDOR_FAIL = '[ALMACEN] Get Log Medidor FAIL';
export const GET_LOG_MEDIDOR_SUCCESS = '[ALMACEN] Get Log Medidor SUCCESS';

//#region GET_SERIES
export class GetSeriesAction implements Action {
    readonly type = GET_SERIES;
};

export class GetSeriesActionFail implements Action {
    readonly type = GET_SERIES_FAIL;
    constructor(public payload: any) { }
};

export class GetSeriesActionSuccess implements Action {
    readonly type = GET_SERIES_SUCCESS;
    constructor(public series: string[]) { }
};
//#endregion

//#region GET_LOG_MEDIDOR
export class GetLogMeididorAction implements Action {
    readonly type = GET_LOG_MEDIDOR;
    constructor(public serie: string) { }
};

export class GetLogMeididorActionFail implements Action {
    readonly type = GET_LOG_MEDIDOR_FAIL;
    constructor(public payload: any) { }
};

export class GetLogMeididorActionSuccess implements Action {
    readonly type = GET_LOG_MEDIDOR_SUCCESS;
    constructor(public historico: SelectedMedidoresSuccess) { }
};
//#endregion

export type logSeriesAlmacenActions =
    GetSeriesAction |
    GetSeriesActionFail |
    GetSeriesActionSuccess |
    GetLogMeididorAction |
    GetLogMeididorActionFail |
    GetLogMeididorActionSuccess;