import * as fromUniverso from '../../../actions/modulos/campañas/universo.actions';
import { Encabezado, Historico, Universo } from 'src/app/shared/models/campañas/campañas.model';
import { Pie } from 'src/app/shared/models/balcax/charts/pie.model';
import { Colonia } from 'src/app/shared/models/campañas/colonias.model';

export class UniversoState {
    list: Universo[];
    loaded: boolean;
    loading: boolean;
    responseCampania: boolean;
    loadedCampania: boolean;
    loadingCampania: boolean;
    listEncabezado: Encabezado[];
    loadedEncabezado: boolean;
    loadingEncabezado: boolean;
    graphPie: Pie;
    loadedGraph: boolean;
    loadingGraph: boolean;
    listHistorico: Historico[];
    loadedHistorico: boolean;
    loadingHistorico: boolean;
    error: any;
    listColonias: Colonia[];
    loadedColonia: boolean;
    loadingColonia: boolean;
};

export const initState: UniversoState = {
    list: [],
    loaded: false,
    loading: false,
    responseCampania: false,
    loadedCampania: false,
    loadingCampania: false,
    listEncabezado: [],
    loadedEncabezado: false,
    loadingEncabezado: false,
    graphPie: null,
    loadedGraph: false,
    loadingGraph: false,
    listHistorico: [],
    loadedHistorico: false,
    loadingHistorico: false,
    error: null,
    listColonias: [],
    loadedColonia: false,
    loadingColonia: false
};

export function universoReducer(state = initState, action: fromUniverso.universoActions): UniversoState {
    switch (action.type) {
        //#region GET_UNIVERSO
        case fromUniverso.GET_UNIVERSO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUniverso.GET_UNIVERSO_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.universo.map(universo => {
                        return {
                            ...universo
                        }
                    })
                ]
            };
        case fromUniverso.GET_UNIVERSO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_ENCABEZADO
        case fromUniverso.GET_ENCABEZADO:
            return {
                ...state,
                loadingEncabezado: true,
                error: null
            };
        case fromUniverso.GET_ENCABEZADO_SUCCESS:
            return {
                ...state,
                loadingEncabezado: false,
                loadedEncabezado: true,
                listEncabezado: [
                    ...action.encabezados.map(head => {
                        return {
                            ...head
                        }
                    })
                ]
            };
        case fromUniverso.GET_ENCABEZADO_FAIL:
            return {
                ...state,
                loadedEncabezado: false,
                loadingEncabezado: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_HISTORICO
        case fromUniverso.GET_HISTORICO:
            return {
                ...state,
                loadingHistorico: true,
                error: null
            };
        case fromUniverso.GET_HISTORICO_SUCCESS:
            return {
                ...state,
                loadingHistorico: false,
                loadedHistorico: true,
                listHistorico: [
                    ...action.historicos.map(history => {
                        return {
                            ...history
                        }
                    })
                ]
            };
        case fromUniverso.GET_HISTORICO_FAIL:
            return {
                ...state,
                loadedHistorico: false,
                loadingHistorico: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_GRAPH
        case fromUniverso.GET_GRAPH:
            return {
                ...state,
                loadingGraph: true,
                error: null
            };
        case fromUniverso.GET_GRAPH_SUCCESS:
            return {
                ...state,
                loadingGraph: false,
                loadedGraph: true,
                graphPie: action.graph
            };
        case fromUniverso.GET_HISTORICO_FAIL:
            return {
                ...state,
                loadedGraph: false,
                loadingGraph: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_COLONIA
        case fromUniverso.GET_COLONIA:
            return {
                ...state,
                loadingColonia: true,
                error: null
            };
        case fromUniverso.GET_COLONIA_SUCCESS:
            return {
                ...state,
                loadingColonia: false,
                loadedColonia: true,
                listColonias: action.colonias
            };
        case fromUniverso.GET_HISTORICO_FAIL:
            return {
                ...state,
                loadedColonia: false,
                loadingColonia: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region SEND_CAMPANIA
        case fromUniverso.SEND_CAMPANIA:
            return {
                ...state,
                loadingCampania: true,
                error: null
            };
        case fromUniverso.SEND_CAMPANIA_SUCCESS:
            return {
                ...state,
                loadingCampania: false,
                loadedCampania: true,
                responseCampania: action.response
            };
        case fromUniverso.SEND_CAMPANIA_FAIL:
            return {
                ...state,
                loadedCampania: false,
                loadingCampania: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
