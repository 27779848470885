import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as universoActions from '../../../actions/modulos/campañas/universo.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { CamapañasService } from 'src/app/services/campañas/campaña.service';
import { Encabezado, Historico, Universo } from 'src/app/shared/models/campañas/campañas.model';
import { Pie } from 'src/app/shared/models/balcax/charts/pie.model';
import { Colonia } from 'src/app/shared/models/campañas/colonias.model';


@Injectable()

export class universoEffects {
    constructor(
        private actions$: Actions,
        private campañasService: CamapañasService,
    ) { }

    @Effect()
    cargarUniverso$ = this.actions$.ofType(universoActions.GET_UNIVERSO)
        .pipe(
            switchMap((action: universoActions.GetUniversoAction) => {
                return this.campañasService.getUniverso(action.filtros)
                    .pipe(
                        map((universo: Universo[]) => new universoActions.GetUniversoActionSuccess(universo)),
                        catchError(error => of(new universoActions.GetUniversoActionFail(error)))
                    );
            })
        );

    @Effect()
    cargarEncabezados$ = this.actions$.ofType(universoActions.GET_ENCABEZADO)
        .pipe(
            switchMap(() => {
                return this.campañasService.getEncabezado()
                    .pipe(
                        map((encabezados: Encabezado[]) => new universoActions.GetEncabezadoActionSuccess(encabezados)),
                        catchError(error => of(new universoActions.GetEncabezadoActionFail(error)))
                    );
            })
        );

    @Effect()
    cargarHistorico$ = this.actions$.ofType(universoActions.GET_HISTORICO)
        .pipe(
            switchMap((action: universoActions.GetHistoricoAction) => {
                return this.campañasService.getHistorico(action.idEncabezado)
                    .pipe(
                        map((historicos: Historico[]) => new universoActions.GetHistoricoActionSuccess(historicos)),
                        catchError(error => of(new universoActions.GetHistoricoActionFail(error)))
                    );
            })
        );

    @Effect()
    cargarGraficaPastel$ = this.actions$.ofType(universoActions.GET_GRAPH)
        .pipe(
            switchMap((action: universoActions.GetGraphAction) => {
                return this.campañasService.getGrafic(action.idEncabezado)
                    .pipe(
                        map((graph: Pie) => new universoActions.GetGraphActionSuccess(graph)),
                        catchError(error => of(new universoActions.GetGraphActionFail(error)))
                    );
            })
        );

    @Effect()
    cargarColonias$ = this.actions$.ofType(universoActions.GET_COLONIA)
        .pipe(
            switchMap(() => {
                return this.campañasService.getColonias()
                    .pipe(
                        map((colonias: Colonia[]) => new universoActions.GetColoniaActionSuccess(colonias)),
                        catchError(error => of(new universoActions.GetColoniaActionFail(error)))
                    );
            })
        );

    @Effect()
    sendCampania$ = this.actions$.ofType(universoActions.SEND_CAMPANIA)
        .pipe(
            switchMap((action: universoActions.SendCampaniaAction) => {
                return this.campañasService.sendCampania(action.campania)
                    .pipe(
                        map((response) => new universoActions.SendCampaniaActionSuccess(response)),
                        catchError(error => of(new universoActions.SendCampaniaActionFail(error)))
                    )
            })
        );

};
