import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as tareasActions from '../../../actions/modulos/inwork/tareas.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { TareasService } from 'src/app/services/inwork/tareas.service';
import { Tareas } from 'src/app/shared/models/Inwork/tareas.model';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';

@Injectable()

export class tareasInworkEffects {
    constructor(
        private actions$: Actions,
        private tareasService: TareasService,
    ) { }

    @Effect()
    getTareasByCPInwork$ = this.actions$.ofType(tareasActions.GET_TAREAS_BY_CP)
        .pipe(
            switchMap((action: tareasActions.GetTareasByCPAction) => {
                return this.tareasService.getTareasByCarpetaProyecto(action.idCarpetaProyecto)
                    .pipe(
                        map((tareas: Tareas[]) => new tareasActions.GetTareasByCPActionSuccess(tareas)),
                        catchError(error => of(new tareasActions.GetTareasByCPActionFail(error)))
                    )
            })
        );
    
    @Effect()
    getTareasByDependenciaInwork$ = this.actions$.ofType(tareasActions.GET_TAREAS_BY_DEP)
        .pipe(
            switchMap((action: tareasActions.GetTareasByDependenciaAction) => {
                return this.tareasService.getTareasByDependencia(action.dependencia)
                    .pipe(
                        map((tareas: Tareas[]) => new tareasActions.GetTareasByDependenciaActionSuccess(tareas)),
                        catchError(error => of(new tareasActions.GetTareasByDependenciaActionFail(error)))
                    )
            })
        );
    
    @Effect()
    getSharedByInwork$ = this.actions$.ofType(tareasActions.GET_SHARED_BY)
        .pipe(
            switchMap((action: tareasActions.GetSharedByAction) => {
                return this.tareasService.getCompartidoCon(action.idTarea)
                    .pipe(
                        map((usuarios: Usuario[]) => new tareasActions.GetSharedByActionSuccess(usuarios)),
                        catchError(error => of(new tareasActions.GetSharedByActionFail(error)))
                    )
            })
        );

    @Effect()
    postTareaInwork$ = this.actions$.ofType(tareasActions.POST_TAREA)
        .pipe(
            switchMap((action: tareasActions.PostTareaAction) => {
                return this.tareasService.postTarea(action.tarea)
                    .pipe(
                        map((newTarea : Tareas) => new tareasActions.PostTareaActionSUCCESS(newTarea)),
                        catchError(error => of(new tareasActions.PostTareaActionFAIL(error)))
                    )
            })
        );

    @Effect()
    putTareaInwork$ = this.actions$.ofType(tareasActions.PUT_TAREA)
        .pipe(
            switchMap((action: tareasActions.PutTareaAction) => {
                return this.tareasService.putTarea(action.tarea)
                    .pipe(
                        map((updateTarea: Tareas) => new tareasActions.PutTareaActionSUCCESS(updateTarea)),
                        catchError(error => of(new tareasActions.PutTareaActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteTareaInwork$ = this.actions$.ofType(tareasActions.DELETE_TAREA)
        .pipe(
            switchMap((action: tareasActions.DeleteTareaAction) => {
                return this.tareasService.deleteRol(action.tarea)
                    .pipe(
                        map((response) => new tareasActions.DeleteTareaActionSUCCESS((response) ? action.tarea : new Tareas(0,"", ""))),
                        catchError(error => of(new tareasActions.DeleteTareaActionFAIL(error)))
                    )
            })
        );
};
