import * as fromPerfilIni from '../../../actions/modulos/acuapue/PerfilInicio.actions';
import { PerfilInicio } from "../../../../shared/models/perfil-inicio.model";

export interface PefilIniState {
    perfilIni: PerfilInicio;
    estado: string;
    loaded: boolean;
    loading: boolean;
    error: any;
}

const initialStatePI: PefilIniState = {
    perfilIni: null,
    estado: '',
    loaded: false,
    loading: false,
    error: null
};
export function perfilInicReducer(state = initialStatePI, action: fromPerfilIni.perfilIniAcciones): PefilIniState {
    switch (action.type) {
        case fromPerfilIni.GET_CONFIG:
            return {
                ...state,
                estado: 'GetPerfil',
                loading: true,
                loaded: false,
                error: null
            };
        case fromPerfilIni.GET_CONFIG_SUCCESS:
            return {
                ...state,
                estado: 'GetPerfilSucces',
                loading: false,
                loaded: true,
                perfilIni: { ...action.payload }
            };
        case fromPerfilIni.GET_CONFIG_FAIL:
            return {
                ...state,
                estado: 'GetPerfilFailure',
                loaded: false,
                loading: false,
                error: {
                    message: action.payload,
                }
            };
        default: {
            return state;
        }
    }
}