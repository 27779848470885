import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { DataFlujoTrabajo } from 'src/app/shared/models/Inwork/models_globales.model';
import { WorkflowServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class FlujoTrabajoService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getFlujosTrabajo() {
        return this.http.get(`${WorkflowServURL}/getWorkFlows`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getFlujos() {
        return this.http.get(`${WorkflowServURL}/getWorksFlow`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getItemsFlujoTrabajo() {
        return this.http.get(`${WorkflowServURL}/getItemsWorkFlow`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putFlujoTrabajo(flujoTrabajo: DataFlujoTrabajo) {
        return this.http.post(`${WorkflowServURL}/putWorkFlow`, flujoTrabajo)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado', 'Flujo de Trabajo Actualizado Correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postFlujoTrabajo(flujoTrabajo: DataFlujoTrabajo) {
        return this.http.post(`${WorkflowServURL}/postWorkFlow`, flujoTrabajo)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos del flujo de trabajo correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteFlujoTrabajo(flujoTrabajo: DataFlujoTrabajo) {
        return this.http.get(`${WorkflowServURL}/deleteWorkFlow?idFlujoTrabajoConfig=${flujoTrabajo.id}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos del flujo correctamente...', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}