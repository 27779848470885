import { Action } from '@ngrx/store';
import { CarpetaProyecto } from 'src/app/shared/models/Inwork/carpeta_proyecto.model';

export const GET_CARPETASPROYECTOS = '[INWORK] Get Carpetas Proyectos Inwork';
export const GET_CARPETASPROYECTOS_FAIL = '[INWORK] Get Carpetas Proyectos Inwork FAIL';
export const GET_CARPETASPROYECTOS_SUCCESS = '[INWORK] Get Carpetas Proyectos Inwork SUCCESS';

export const GET_CARPETASPROYECTOS_BY_ID = '[INWORK] Get Carpetas Proyectos By Id Inwork';
export const GET_CARPETASPROYECTOS_BY_ID_FAIL = '[INWORK] Get Carpetas Proyectos By Id Inwork FAIL';
export const GET_CARPETASPROYECTOS_BY_ID_SUCCESS = '[INWORK] Get Carpetas Proyectos By Id Inwork SUCCESS';

export const POST_CARPETAPROYECTO = '[INWORK] Post Carpeta Proyecto Inwork';
export const POST_CARPETAPROYECTO_FAIL = '[INWORK] Post Carpeta Proyecto Inwork FAIL';
export const POST_CARPETAPROYECTO_SUCCESS = '[INWORK] Post Carpeta Proyecto Inwork SUCCESS';

export const PUT_CARPETAPROYECTO = '[INWORK] Put Carpeta Proyecto Inwork';
export const PUT_CARPETAPROYECTO_FAIL = '[INWORK] Put Carpeta Proyecto Inwork FAIL';
export const PUT_CARPETAPROYECTO_SUCCESS = '[INWORK] Put Carpeta Proyecto Inwork SUCCESS';

export const DELETE_CARPETAPROYECTO = '[INWORK] Delete Carpeta Proyecto Inwork';
export const DELETE_CARPETAPROYECTO_FAIL = '[INWORK] Delete Carpeta Proyecto Inwork FAIL';
export const DELETE_CARPETAPROYECTO_SUCCESS = '[INWORK] Delete Carpeta Proyecto Inwork SUCCESS';


//#region GET_CARPETAPROYECTO
export class GetCarpetaProyectoAction implements Action {
    readonly type = GET_CARPETASPROYECTOS;
};

export class GetCarpetaProyectoActionFail implements Action {
    readonly type = GET_CARPETASPROYECTOS_FAIL;
    constructor(public payload: any) { }
};

export class GetCarpetaProyectoActionSuccess implements Action {
    readonly type = GET_CARPETASPROYECTOS_SUCCESS;
    constructor(public carpetasP: CarpetaProyecto[]) { }
};
//#endregion

//#region GET_CARPETAPROYECTO_BY_ID
export class GetCarpetaProyectoByIdAction implements Action {
    readonly type = GET_CARPETASPROYECTOS_BY_ID;
    constructor(public idCarpetaP:number){}
};

export class GetCarpetaProyectoByIdActionFail implements Action {
    readonly type = GET_CARPETASPROYECTOS_BY_ID_FAIL;
    constructor(public payload: any) { }
};

export class GetCarpetaProyectoByIdActionSuccess implements Action {
    readonly type = GET_CARPETASPROYECTOS_BY_ID_SUCCESS;
    constructor(public carpetasP: CarpetaProyecto[]) { }
};
//#endregion

//#region  POST_CARPETAPROYECTO
export class PostCarpetaProyectoAction implements Action {
    readonly type = POST_CARPETAPROYECTO;
    constructor(public carpetaProyecto: CarpetaProyecto) { }
};
export class PostCarpetaProyectoActionFAIL implements Action {
    readonly type = POST_CARPETAPROYECTO_FAIL;
    constructor(public payload: any) { }
};
export class PostCarpetaProyectoActionSUCCESS implements Action {
    readonly type = POST_CARPETAPROYECTO_SUCCESS;
    constructor(public newCarpetaProyecto: CarpetaProyecto) { }
};
//#endregion

//#region  PUT_CARPETAPROYECTO
export class PutCarpetaProyectoAction implements Action {
    readonly type = PUT_CARPETAPROYECTO;
    constructor(public carpetaProyecto: CarpetaProyecto) { }
};
export class PutCarpetaProyectoActionFAIL implements Action {
    readonly type = PUT_CARPETAPROYECTO_FAIL;
    constructor(public payload: any) { }
};
export class PutCarpetaProyectoActionSUCCESS implements Action {
    readonly type = PUT_CARPETAPROYECTO_SUCCESS;
    constructor(public updateCarpetaProyecto: CarpetaProyecto) { }
};
//#endregion

//#region  DELETE_CARPETAPROYECTO
export class DeleteCarpetaProyectoAction implements Action {
    readonly type = DELETE_CARPETAPROYECTO;
    constructor(public carpetaProyecto: CarpetaProyecto) { }
};
export class DeleteCarpetaProyectoActionFAIL implements Action {
    readonly type = DELETE_CARPETAPROYECTO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteCarpetaProyectoActionSUCCESS implements Action {
    readonly type = DELETE_CARPETAPROYECTO_SUCCESS;
    constructor(public deleteCarpetaProyecto: CarpetaProyecto) { }
};
//#endregion

export type carpetasProyectosInworkActions =
GetCarpetaProyectoAction |
GetCarpetaProyectoActionFail |
GetCarpetaProyectoActionSuccess |

GetCarpetaProyectoByIdAction |
GetCarpetaProyectoByIdActionFail |
GetCarpetaProyectoByIdActionSuccess |

PostCarpetaProyectoAction |
PostCarpetaProyectoActionFAIL |
PostCarpetaProyectoActionSUCCESS |

PutCarpetaProyectoAction |
PutCarpetaProyectoActionFAIL |
PutCarpetaProyectoActionSUCCESS |

DeleteCarpetaProyectoAction |
DeleteCarpetaProyectoActionFAIL |
DeleteCarpetaProyectoActionSUCCESS;
