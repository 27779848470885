import { AtributosPT } from './atributos_pt.model';

export class Tareas{
    constructor(
        public id?: number,
        public nombre?: string,
        public descripcion?: string,
        public dependencia?: number,
        public generadaPor?: string,
        public registrado?: Date,
        public visible?: boolean,
        public archivada?: boolean,
        public favorita?: boolean,
        public idAtributosPT?: number,
        public idCarpetaProyecto?: number,
        public atributosPT?: AtributosPT,
        public carpetaProyecto?: string
    ){}
}