import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as catalogoAlmacenActions from '../../../actions/modulos/microMedicion/catalogoAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { CatalogoAlmacenService } from 'src/app/services/microMedicion/almacen/catalogo.service';
import { CodigoMicroMedicion, CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';
import { CatalogoPVService } from 'src/app/services/microMedicion/puntoVenta/catalogo.service';

@Injectable()

export class catalogoEffects {
    constructor(
        private actions$: Actions,
        private catalogoAlmacenService: CatalogoAlmacenService,
        private catalogopvservice: CatalogoPVService,
    ) { }

    @Effect()
    getCatalogo$ = this.actions$.ofType(catalogoAlmacenActions.GET_CATALOGO)
        .pipe(
            switchMap(() => {
                return this.catalogoAlmacenService.getCatalogo()
                    .pipe(
                        map((codigos: CodigoMicroMedicion[]) => new catalogoAlmacenActions.GetCatalogoActionSuccess(codigos)),
                        catchError(error => of(new catalogoAlmacenActions.GetCatalogoActionFail(error)))
                    )
            })
        );

    @Effect()
    getCatalogoPV$ = this.actions$.ofType(catalogoAlmacenActions.GET_CATALOGO_VENTA)
        .pipe(
            switchMap(() => {
                return this.catalogoAlmacenService.getCatalogoPV()
                    .pipe(
                        map((codigos: CodigoPVMicroMedicion[]) => new catalogoAlmacenActions.GetCatalogoVentaActionSuccess(codigos)),
                        catchError(error => of(new catalogoAlmacenActions.GetCatalogoVentaActionFail(error)))
                    )
            })
        );

    @Effect()
    putCatalogo$ = this.actions$.ofType(catalogoAlmacenActions.PUT_CATALOGO)
        .pipe(
            switchMap(() => {
                return this.catalogoAlmacenService.putCatalogo()
                    .pipe(
                        map((codigos: CodigoMicroMedicion[]) => new catalogoAlmacenActions.PutCatalogoActionSuccess(codigos)),
                        catchError(error => of(new catalogoAlmacenActions.PutCatalogoActionFail(error)))
                    )
            })
        );

    @Effect()
    putProducto$ = this.actions$.ofType(catalogoAlmacenActions.PUT_PRODUCTO)
        .pipe(
            switchMap((action: catalogoAlmacenActions.PutProductoAction) => {
                return this.catalogopvservice.putProducto(action.producto)
                    .pipe(
                        map((producto: CodigoPVMicroMedicion) => new catalogoAlmacenActions.PutProductoActionSuccess(producto)),
                        catchError(error => of(new catalogoAlmacenActions.PutProductoActionFail(error)))
                    )
            })
        );
};
