import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as ubicacionAlmacenActions from '../../../actions/modulos/microMedicion/ubicacionAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { UbicacionesAlmacenService } from 'src/app/services/microMedicion/almacen/ubicacionesAlmacen.service';

@Injectable()

export class ubicacionEffects {
    constructor(
        private actions$: Actions,
        private ubicacionesAlmacenService: UbicacionesAlmacenService,
    ) { }

    @Effect()
    getUbicaciones$ = this.actions$.ofType(ubicacionAlmacenActions.GET_UBICACIONES)
        .pipe(
            switchMap(() => {
                return this.ubicacionesAlmacenService.getUbicaciones()
                    .pipe(
                        map((response: any) => new ubicacionAlmacenActions.GetUbicacionActionSuccess(response)),
                        catchError(error => of(new ubicacionAlmacenActions.GetUbicacionActionFail(error)))
                    )
            })
        );

};
