import * as fromUsers from '../../actions/balcax/users.actions';

import { Usuario } from 'src/app/shared/models/balcax/usuario.model';

export interface UsersState {
    list: Usuario[];
    permisions: string;
    loaded: boolean;
    loading: boolean;
    error: any;
};

const initState: UsersState = {
    list: [],
    permisions: null,
    loaded: false,
    loading: false,
    error: null
};

export function usersReducer(state = initState, action: fromUsers.usersActions): UsersState {
    switch (action.type) {
        //#region GET_USERS
        case fromUsers.GET_USERS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.users.map(user => {
                        return {
                            ...user
                        }
                    })
                ]
            };
        case fromUsers.GET_USERS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        case fromUsers.GET_USER_PERMISIONS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.GET_USER_PERMISIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                permisions: action.permisions
            };
        case fromUsers.GET_USER_PERMISIONS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_STATE_USER    
        case fromUsers.PUT_STATE_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.PUT_STATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(userEditar => {
                        if (userEditar.correo === action.correo) {
                            return {
                                ...userEditar,
                                habilitado: !userEditar.habilitado
                            };
                        } else {
                            return userEditar;
                        }
                    })
                ]
            };
        case fromUsers.PUT_STATE_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region POST_USER
        case fromUsers.POST_USER:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromUsers.POST_USER_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.newUser],
                loading: false,
                loaded: true,
                error: null
            };
        case fromUsers.POST_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region DELETE_USER
        case fromUsers.DELETE_USER:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromUsers.DELETE_USER_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(user => {
                    if (user.id != action.deleteUser.id) {
                        return {
                            user
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromUsers.DELETE_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_TELEFONO_USER    
        case fromUsers.PUT_TELEFONO_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.PUT_TELEFONO_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(userEditar => {
                        if (userEditar.correo === action.putUser.correo) {
                            return {
                                ...userEditar,
                                telefono: action.putUser.telefono
                            };
                        } else {
                            return userEditar;
                        }
                    })
                ]
            };
        case fromUsers.PUT_PASSWORD_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_PASSWORD_USER    
        case fromUsers.PUT_PASSWORD_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.PUT_PASSWORD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        case fromUsers.PUT_PASSWORD_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_PERMISOS_USER    
        case fromUsers.PUT_PERMISOS_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromUsers.PUT_PERMISOS_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
            };
        case fromUsers.PUT_PERMISOS_USER_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
