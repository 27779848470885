import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as usersActions from '../../actions/balcax';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { UsersService } from 'src/app/services/balcax/users.service';
import { Usuario } from 'src/app/shared/models/balcax/usuario.model';
import { VariablesService } from 'src/app/services/balcax/variableGL.service';

@Injectable()

export class usersEffects {
    constructor(
        private actions$: Actions,
        public usersService: UsersService,
        public variablesService: VariablesService,
    ) { }

    @Effect()
    cargarUsers$ = this.actions$.ofType(usersActions.GET_USERS)
        .pipe(
            switchMap((action: usersActions.GetUsersAction) => {
                return this.usersService.getUsers(action.id)
                    .pipe(
                        map((usuarios: Usuario[]) => new usersActions.GetUsersActionSuccess(usuarios)),
                        catchError(error => of(new usersActions.GetUsersActionFail(error)))
                    )
            })
        );
    
    @Effect()
    cargarUserPermisions$ = this.actions$.ofType(usersActions.GET_USER_PERMISIONS)
        .pipe(
            switchMap((action: usersActions.GetUserPermisionsAction) => {
                return this.usersService.getUserPermisions(action.correo)
                    .pipe(
                        map((permisions: string) => new usersActions.GetUserPermisionsActionSuccess(permisions)),
                        catchError(error => of(new usersActions.GetUserPermisionsActionFail(error)))
                    )
            })
        );

    @Effect()
    changeStateUser$ = this.actions$.ofType(usersActions.PUT_STATE_USER)
        .pipe(
            switchMap((action: usersActions.PutStateUsersAction) => {
                const usuario = new Usuario();
                usuario.correo = action.correo;
                return this.usersService.putUserHabilitado(usuario)
                    .pipe(
                        map(() => new usersActions.PutStateUserActionSuccess(usuario.correo)),
                        catchError(error => of(new usersActions.PutStateUserActionFail(error)))
                    )
            })
        );

    @Effect()
    postUser$ = this.actions$.ofType(usersActions.POST_USER)
        .pipe(
            switchMap((action: usersActions.PostUserAction) => {
                return this.usersService.postUser( action.user,  action.idEmpresa,  action.idCodigoEmpleado)
                    .pipe(
                        map((user: Usuario) => new usersActions.PostUserActionSUCCESS(user)),
                        catchError(error => of(new usersActions.PostUserActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteUser$ = this.actions$.ofType(usersActions.DELETE_USER)
        .pipe(
            switchMap((action: usersActions.DeleteUserAction) => {
                return this.usersService.deleteUser(action.user)
                    .pipe(
                        map(() => new usersActions.DeleteUserActionSUCCESS(action.user)),
                        catchError(error => of(new usersActions.DeleteUserActionFAIL(error)))
                    )
            })
        );

    @Effect()
    putTelefonoUser$ = this.actions$.ofType(usersActions.PUT_TELEFONO_USER)
        .pipe(
            switchMap((action: usersActions.PutTelefonoAction) => {
                return this.usersService.putTelefono(action.user)
                    .pipe(
                        map(() => new usersActions.PutTelefonoActionSUCCESS(action.user)),
                        catchError(error => of(new usersActions.PutTelefonoActionFAIL(error)))
                    )
            })
        );

    @Effect()
    putPasswordUser$ = this.actions$.ofType(usersActions.PUT_PASSWORD_USER)
        .pipe(
            switchMap((action: usersActions.PutPasswordAction) => {
                return this.usersService.putPassword(action.user)
                    .pipe(
                        map(() => new usersActions.PutPasswordActionSUCCESS()),
                        catchError(error => of(new usersActions.PutPasswordActionFAIL(error)))
                    )
            })
        );

    @Effect()
    putPermisosUser$ = this.actions$.ofType(usersActions.PUT_PERMISOS_USER)
        .pipe(
            switchMap((action: usersActions.PutPermisosAction) => {
                return this.usersService.putPermisos(action.correo,action.permisos)
                    .pipe(
                        map(() => new usersActions.PutPermisosActionSUCCESS()),
                        catchError(error => of(new usersActions.PutPermisosActionFAIL(error)))
                    )
            })
        );

};
