import { Action } from '@ngrx/store';
import { RolesUsuarios } from 'src/app/shared/models/Inwork/roles_usuarios.model';

//ROLES USUARIOS
export const GET_ROLES_USUARIOS = '[INWORK] Get Roles Usuarios Inwork';
export const GET_ROLES_USUARIOS_FAIL = '[INWORK] Get Roles Usuarios Inwork FAIL';
export const GET_ROLES_USUARIOS_SUCCESS = '[INWORK] Get Roles Usuarios Inwork SUCCESS';

export const GET_ROL_USUARIO_BY_CORREO = '[INWORK] Get Rol Usuario By Correo Inwork';
export const GET_ROL_USUARIO_BY_CORREO_FAIL = '[INWORK] Get Rol Usuario By Correo Inwork FAIL';
export const GET_ROL_USUARIO_BY_CORREO_SUCCESS = '[INWORK] Get Rol Usuario By Correo Inwork SUCCESS';

export const POST_ROL_USUARIO = '[INWORK] Post Rol Usuario Inwork';
export const POST_ROL_USUARIO_FAIL = '[INWORK] Post Rol Usuario Inwork FAIL';
export const POST_ROL_USUARIO_SUCCESS = '[INWORK] Post Rol Usuario Inwork SUCCESS';

export const PUT_ROL_USUARIO = '[INWORK] Put Rol Usuario Inwork';
export const PUT_ROL_USUARIO_FAIL = '[INWORK] Put Rol Usuario Inwork FAIL';
export const PUT_ROL_USUARIO_SUCCESS = '[INWORK] Put Rol Usuario Inwork SUCCESS';

export const PUT_STATE_ROL_USUARIO = '[INWORK] Put State Rol Usuario Inwork';
export const PUT_STATE_ROL_USUARIO_FAIL = '[INWORK] Put State Rol Usuario Inwork FAIL';
export const PUT_STATE_ROL_USUARIO_SUCCESS = '[INWORK] Put State Rol Usuario Inwork SUCCESS';

export const DELETE_ROL_USUARIO = '[INWORK] Delete Rol Usuario Inwork';
export const DELETE_ROL_USUARIO_FAIL = '[INWORK] Delete Rol Usuario Inwork FAIL';
export const DELETE_ROL_USUARIO_SUCCESS = '[INWORK] Delete Rol Usuario Inwork SUCCESS';

//#region GET_ROL_USUARIO
export class GetRolesUsuariosAction implements Action {
    readonly type = GET_ROLES_USUARIOS;
};

export class GetRolesUsuariosActionFail implements Action {
    readonly type = GET_ROLES_USUARIOS_FAIL;
    constructor(public payload: any) { }
};

export class GetRolesUsuariosActionSuccess implements Action {
    readonly type = GET_ROLES_USUARIOS_SUCCESS;
    constructor(public rolesUsuarios: RolesUsuarios[]) { }
};
//#endregion

//#region GET_ROL_USUARIO_BY_CORREO
export class GetRolUsuarioByCorreoAction implements Action {
    readonly type = GET_ROL_USUARIO_BY_CORREO;
    constructor(public correo: string){}
};

export class GetRolUsuarioByCorreoActionFail implements Action {
    readonly type = GET_ROL_USUARIO_BY_CORREO_FAIL;
    constructor(public payload: any) { }
};

export class GetRolUsuarioByCorreoActionSuccess implements Action {
    readonly type = GET_ROL_USUARIO_BY_CORREO_SUCCESS;
    constructor(public rolUsuario: RolesUsuarios) { }
};
//#endregion

//#region  POST_ROL_USUARIO
export class PostRolUsuarioAction implements Action {
    readonly type = POST_ROL_USUARIO;
    constructor(public rolUsuario: RolesUsuarios) { }
};
export class PostRolUsuarioActionFAIL implements Action {
    readonly type = POST_ROL_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class PostRolUsuarioActionSUCCESS implements Action {
    readonly type = POST_ROL_USUARIO_SUCCESS;
    constructor(public newRolUsuario: RolesUsuarios) { }
};
//#endregion

//#region  PUT_ROL_USUARIO
export class PutRolUsuarioAction implements Action {
    readonly type = PUT_ROL_USUARIO;
    constructor(public rolUsuario: RolesUsuarios) { }
};
export class PutRolUsuarioActionFAIL implements Action {
    readonly type = PUT_ROL_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class PutRolUsuarioActionSUCCESS implements Action {
    readonly type = PUT_ROL_USUARIO_SUCCESS;
    constructor(public updateRolUsuario: RolesUsuarios) { }
};
//#endregion

//#region  PUT_STATE_ROL_USUARIO_
export class PutStateRolUsuarioAction implements Action {
    readonly type = PUT_STATE_ROL_USUARIO;
    constructor(public rolUsuario: RolesUsuarios) { }
};
export class PutStateRolUsuarioActionFAIL implements Action {
    readonly type = PUT_STATE_ROL_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class PutStateRolUsuarioActionSUCCESS implements Action {
    readonly type = PUT_STATE_ROL_USUARIO_SUCCESS;
    constructor(public idRolUsuario: number) { }
};
//#endregion

//#region  DELETE_ROL_USUARIO
export class DeleteRolUsuarioAction implements Action {
    readonly type = DELETE_ROL_USUARIO;
    constructor(public rolUsuario: RolesUsuarios) { }
};
export class DeleteRolUsuarioActionFAIL implements Action {
    readonly type = DELETE_ROL_USUARIO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteRolUsuarioActionSUCCESS implements Action {
    readonly type = DELETE_ROL_USUARIO_SUCCESS;
    constructor(public deleteRolUsuario: RolesUsuarios) { }
};
//#endregion

export type rolesUsuarioInworkActions =
GetRolesUsuariosAction |
GetRolesUsuariosActionFail |
GetRolesUsuariosActionSuccess |

GetRolUsuarioByCorreoAction |
GetRolUsuarioByCorreoActionFail |
GetRolUsuarioByCorreoActionSuccess |

PostRolUsuarioAction |
PostRolUsuarioActionFAIL |
PostRolUsuarioActionSUCCESS |

PutRolUsuarioAction |
PutRolUsuarioActionFAIL |
PutRolUsuarioActionSUCCESS |

PutStateRolUsuarioAction |
PutStateRolUsuarioActionFAIL |
PutStateRolUsuarioActionSUCCESS |

DeleteRolUsuarioAction |
DeleteRolUsuarioActionFAIL |
DeleteRolUsuarioActionSUCCESS;
