import { Action } from '@ngrx/store';
import { LogIndicadores } from 'src/app/shared/models/Indicadores/log.model';
import { RadialBar } from 'src/app/shared/models/balcax/charts/radial-bar.model';
import { MultiBarras } from 'src/app/shared/models/balcax/charts/multi-bar.model';
import * as chartBarras from 'src/app/shared/models/balcax/charts/bar.model';
import { CardPercentage } from 'src/app/shared/models/balcax/card.model';
import { Dashboard } from 'src/app/shared/models/Indicadores/dashboard.model';


export const GET_DASHBOARD_SUS = '[GET_DASHBOARD_SUS] Get Dashboard_SUS';
export const GET_DASHBOARD_SUS_FAIL = '[GET_DASHBOARD_FAIL_SUS] Get Dashboard_SUS FAIL';
export const GET_DASHBOARD_SUS_SUCCESS = '[GET_DASHBOARD_SUCCESS_SUS] Get Dashboard_SUS SUCCESS';

export const GET_CARD_COUNT_ACTIVITIES_SUS = '[GET_CARD_COUNT_ACTIVITIES_SUS] Get Card_SUS Count Activities';
export const GET_CARD_COUNT_ACTIVITIES_SUS_FAIL = '[GET_CARD_COUNT_ACTIVITIES_FAIL_SUS] Get Card_SUS Count Activities FAIL';
export const GET_CARD_COUNT_ACTIVITIES_SUS_SUCCESS = '[GET_CARD_COUNT_ACTIVITIES_SUCCESS_SUS] Get Card_SUS Count Activities SUCCESS';

export const GET_AVANCE_ESPECIFICO_SUS = '[GET_AVANCE_ESPECIFICO_SUS] Get Avance_SUS Especifico';
export const GET_AVANCE_ESPECIFICO_SUS_FAIL = '[GET_AVANCE_ESPECIFICO_FAIL_SUS] Get Avance_SUS Especifico FAIL';
export const GET_AVANCE_ESPECIFICO_SUS_SUCCESS = '[GET_AVANCE_ESPECIFICO_SUCCESS_SUS] Get Avance_SUS Especifico SUCCESS';

export const GET_AVANCE_INDICADORES_SUS = '[GET_AVANCE_INDICADORES_SUS] Get Avance_SUS Indicadores';
export const GET_AVANCE_INDICADORES_SUS_FAIL = '[GET_AVANCE_INDICADORES_FAIL_SUS] Get Avance_SUS Indicadores FAIL';
export const GET_AVANCE_INDICADORES_SUS_SUCCESS = '[GET_AVANCE_INDICADORES_SUCCESS_SUS] Get Avance_SUS Indicadores SUCCESS';

export const GET_AVANCE_ESTANDARES_SUS = '[GET_AVANCE_ESTANDARES_SUS] Get Avance_SUS Estandares';
export const GET_AVANCE_ESTANDARES_SUS_FAIL = '[GET_AVANCE_ESTANDARES_FAIL_SUS] Get Avance_SUS Estandares FAIL';
export const GET_AVANCE_ESTANDARES_SUS_SUCCESS = '[GET_AVANCE_ESTANDARES_SUCCESS_SUS] Get Avance_SUS Estandares SUCCESS';

export const GET_DETALLE_AVANCE_INDICADORES_SUS = '[GET_DETALLE_AVANCE_INDICADORES_SUS] Get Detalle_SUS Avance ';
export const GET_DETALLE_AVANCE_INDICADORES_SUS_FAIL = '[GET_DETALLE_AVANCE_INDICADORES_FAIL_SUS] Get Detalle_SUS Avance Indicadores FAIL';
export const GET_DETALLE_AVANCE_INDICADORES_SUS_SUCCESS = '[GET_DETALLE_AVANCE_INDICADORES_SUCCESS_SUS] Get Detalle_SUS Avance Indicadores SUCCESS';

export const GET_AVANCE_POR_AREA_SUS = '[GET_AVANCE_POR_AREA_SUS] Get Avance_SUS Por Area ';
export const GET_AVANCE_POR_AREA_SUS_FAIL = '[GET_AVANCE_POR_AREA_FAIL_SUS] Get Avance_SUS Por Area Indicadores FAIL';
export const GET_AVANCE_POR_AREA_SUS_SUCCESS = '[GET_AVANCE_POR_AREA_SUCCESS_SUS] Get Avance_SUS Por Area Indicadores SUCCESS';

export const GET_RECENT_ACTIVITIES_SUS = '[GET_RECENT_ACTIVITIES_SUS] Get Recent_SUS Activities';
export const GET_RECENT_ACTIVITIES_SUS_FAIL = '[GET_RECENT_ACTIVITIES_FAIL_SUS] Get Recent_SUS Activities FAIL';
export const GET_RECENT_ACTIVITIES_SUS_SUCCESS = '[GET_RECENT_ACTIVITIES_SUCCESS_SUS] Get Recent_SUS Activities SUCCESS';

//#region GET_DASHBOARD
export class GetDashboardAction_SUS implements Action {
    readonly type = GET_DASHBOARD_SUS;
    constructor(public idArea: number, public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetDashboardActionFail_SUS implements Action {
    readonly type = GET_DASHBOARD_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetDashboardActionSuccess_SUS implements Action {
    readonly type = GET_DASHBOARD_SUS_SUCCESS;
    constructor(public dashboard: Dashboard) { }
};
//#endregion

//#region GET_CARD_COUNT_ACTIVITIES
export class GetCardCountActivitiesAction_SUS implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES_SUS;
    constructor(public idArea: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetCardCountActivitiesActionFail_SUS implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetCardCountActivitiesActionSuccess_SUS implements Action {
    readonly type = GET_CARD_COUNT_ACTIVITIES_SUS_SUCCESS;
    constructor(public cardsActivities: CardPercentage[]) { }
};
//#endregion

//#region GET_AVANCE_ESPECIFICO
export class GetAvanceEspecificoAction_SUS implements Action {
    readonly type = GET_AVANCE_ESPECIFICO_SUS;
    constructor(public idEspecifico: number, public periodo: string) { }
};

export class GetAvanceEspecificoActionFail_SUS implements Action {
    readonly type = GET_AVANCE_ESPECIFICO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceEspecificoActionSuccess_SUS implements Action {
    readonly type = GET_AVANCE_ESPECIFICO_SUS_SUCCESS;
    constructor(public avanceEspecifico: RadialBar) { }
};
//#endregion

//#region GET_AVANCE_INDICADORES
export class GetAvanceIndicadoresAction_SUS implements Action {
    readonly type = GET_AVANCE_INDICADORES_SUS;
    constructor(public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetAvanceIndicadoresActionFail_SUS implements Action {
    readonly type = GET_AVANCE_INDICADORES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceIndicadoresActionSuccess_SUS implements Action {
    readonly type = GET_AVANCE_INDICADORES_SUS_SUCCESS;
    constructor(public avancesIndicadores: chartBarras.Barras) { }
};
//#endregion

//#region GET_AVANCE_ESTANDARES
export class GetAvanceEstandaresAction_SUS implements Action {
    readonly type = GET_AVANCE_ESTANDARES_SUS;
    constructor(public idArea: number, public idEstEsp: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetAvanceEstandaresActionFail_SUS implements Action {
    readonly type = GET_AVANCE_ESTANDARES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAvanceEstandaresActionSuccess_SUS implements Action {
    readonly type = GET_AVANCE_ESTANDARES_SUS_SUCCESS;
    constructor(public avancesEstandares: chartBarras.Barras) { }
};
//#endregion

//#region GET_DETALLE_AVANCE_INDICADORES
export class GetDetalleAvanceIndicadoresAction_SUS implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES_SUS;
    constructor(public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetDetalleAvanceIndicadoresActionFail_SUS implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetDetalleAvanceIndicadoresActionSuccess_SUS implements Action {
    readonly type = GET_DETALLE_AVANCE_INDICADORES_SUS_SUCCESS;
    constructor(public detailsAvancesIndicadores: chartBarras.Grouped) { }
};
//#endregion

//#region GET_AVANCE_POR_AREA
export class GetAvancePorAreaAction_SUS implements Action {
    readonly type = GET_AVANCE_POR_AREA_SUS;
    constructor(public idArea: number, public id: number, public tipoFiltro: string, public periodo: string) { }
};

export class GetAvancePorAreaActionFail_SUS implements Action {
    readonly type = GET_AVANCE_POR_AREA_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetAvancePorAreaActionSuccess_SUS implements Action {
    readonly type = GET_AVANCE_POR_AREA_SUS_SUCCESS;
    constructor(public avancesArea: RadialBar[]) { }
};
//#endregion

//#region GET_RECENT_ACTIVITIES
export class GetRecentActivitiesAction_SUS implements Action {
    readonly type = GET_RECENT_ACTIVITIES_SUS;
    constructor(public idArea: number, public periodo: string) { }
};

export class GetRecentActivitiesActionFail_SUS implements Action {
    readonly type = GET_RECENT_ACTIVITIES_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetRecentActivitiesActionSuccess_SUS implements Action {
    readonly type = GET_RECENT_ACTIVITIES_SUS_SUCCESS;
    constructor(public recentActivities: LogIndicadores[]) { }
};
//#endregion

export type dashboardctions_SUS =
    GetDashboardAction_SUS |
    GetDashboardActionFail_SUS |
    GetDashboardActionSuccess_SUS |

    GetCardCountActivitiesAction_SUS |
    GetCardCountActivitiesActionFail_SUS |
    GetCardCountActivitiesActionSuccess_SUS |

    GetAvanceEspecificoAction_SUS |
    GetAvanceEspecificoActionFail_SUS |
    GetAvanceEspecificoActionSuccess_SUS |

    GetAvanceEstandaresAction_SUS |
    GetAvanceEstandaresActionFail_SUS |
    GetAvanceEstandaresActionSuccess_SUS |

    GetAvanceIndicadoresAction_SUS |
    GetAvanceIndicadoresActionFail_SUS |
    GetAvanceIndicadoresActionSuccess_SUS |

    GetDetalleAvanceIndicadoresAction_SUS |
    GetDetalleAvanceIndicadoresActionFail_SUS |
    GetDetalleAvanceIndicadoresActionSuccess_SUS |

    GetAvancePorAreaAction_SUS |
    GetAvancePorAreaActionFail_SUS |
    GetAvancePorAreaActionSuccess_SUS |

    GetRecentActivitiesAction_SUS |
    GetRecentActivitiesActionFail_SUS |
    GetRecentActivitiesActionSuccess_SUS;

