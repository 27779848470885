import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as medidoresAlmacenActions from '../../../actions/modulos/microMedicion/medidoresAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { MedidoresAlmacenService } from 'src/app/services/microMedicion/almacen/medidores.service';
import { ResponseAltaMedidores } from 'src/app/shared/models/microMedicion/responseAltaMedidores.model';

@Injectable()

export class medidoresAlmacenEffects {
    constructor(
        private actions$: Actions,
        private medidoresAlmacenService: MedidoresAlmacenService,
    ) { }

    @Effect()
    getMedidoresAlmacen$ = this.actions$.ofType(medidoresAlmacenActions.GET_MEDIDORES)
        .pipe(
            switchMap(() => {
                return this.medidoresAlmacenService.getMedidoresAlmacen()
                    .pipe(
                        map((response: any) => new medidoresAlmacenActions.GetMedidoresAlmacenActionSuccess(response)),
                        catchError(error => of(new medidoresAlmacenActions.GetMedidoresAlmacenActionFail(error)))
                    )
            })
        );

    @Effect()
    postMedidores$ = this.actions$.ofType(medidoresAlmacenActions.POST_MEDIDORES)
        .pipe(
            switchMap((action: medidoresAlmacenActions.PostMedidoresAlmacenAction) => {
                return this.medidoresAlmacenService.postMedidores(action.data)
                    .pipe(
                        map((response: ResponseAltaMedidores) => new medidoresAlmacenActions.PostMedidoresAlmacenActionSuccess(response)),
                        catchError(error => of(new medidoresAlmacenActions.PostMedidoresAlmacenActionFail(error)))
                    )
            })
        );

    @Effect()
    putDañados$ = this.actions$.ofType(medidoresAlmacenActions.PUT_MEDIDORES_DAÑADOS)
        .pipe(
            switchMap((action: medidoresAlmacenActions.PutMedidoresDañadosAlmacenAction) => {
                return this.medidoresAlmacenService.putDañados(action.comentario,action.medidoresSeleccionados)
                    .pipe(
                        map((data: any) => new medidoresAlmacenActions.PutMedidoresDañadosAlmacenActionSuccess(data.correctos,data.estado)),
                        catchError(error => of(new medidoresAlmacenActions.PutMedidoresDañadosAlmacenActionFail(error)))
                    )
            })
        );

        @Effect()
        putAsignados$ = this.actions$.ofType(medidoresAlmacenActions.PUT_MEDIDORES_ASIGNAR)
            .pipe(
                switchMap((action: medidoresAlmacenActions.PutMedidoresAsignarAlmacenAction) => {
                    return this.medidoresAlmacenService.putAsignados(action.medidoresSeleccionados,action.idProveedor)
                        .pipe(
                            map((data: any) => new medidoresAlmacenActions.PutMedidoresAsignarAlmacenActionSuccess(data.correctos,data.estado)),
                            catchError(error => of(new medidoresAlmacenActions.PutMedidoresAsignarAlmacenActionFail(error)))
                        )
                })
            );
};
