import { of } from "rxjs";
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as archivosActions from '../../../actions/modulos/inwork/archivos.actions';
import * as actualizacionesActions from '../../../actions/modulos/inwork/actualizaciones.actions';

import { ArchivosService } from 'src/app/services/inwork/archivos.service';
import { Actualizaciones } from 'src/app/shared/models/Inwork/actualizaciones.model';
import { Archivos } from 'src/app/shared/models/Inwork/archivos.model';

@Injectable()

export class archivosInworkEffects {
    constructor(
        private actions$: Actions,
        private archivosService: ArchivosService,
    ) { }

    @Effect()
    getFilesInwork$ = this.actions$.ofType(archivosActions.GET_ARCHIVOS)
        .pipe(
            switchMap((action: archivosActions.GetArchivosAction) => {
                return this.archivosService.getFiles(action.idAtributosPT)
                    .pipe(
                        map((files: Archivos[]) => new archivosActions.GetArchivosActionSuccess(files)),
                        catchError(error => of(new archivosActions.GetArchivosActionFail(error)))
                    )
            })
        );

    @Effect()
    postFileInwork$ = this.actions$.ofType(archivosActions.POST_ARCHIVOS)
        .pipe(
            switchMap((action: archivosActions.PostArchivosAction) => {
                return this.archivosService.postFile(action.archivos)
                    .pipe(
                        map((response) => {
                            new actualizacionesActions.PostActualizacionActionSUCCESS(response.actualizacion);
                            new archivosActions.PostArchivosActionSUCCESS(response.archivos);
                        }),
                        catchError(error => of(new archivosActions.PostArchivosActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putFileInwork$ = this.actions$.ofType(archivosActions.PUT_ARCHIVOS)
        .pipe(
            switchMap((action: archivosActions.PutArchivosAction) => {
                return this.archivosService.putFile(action.archivos)
                    .pipe(
                        map((updateFiles: Archivos[]) => new archivosActions.PutArchivosActionSUCCESS(updateFiles)),
                        catchError(error => of(new archivosActions.PutArchivosActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteFileInwork$ = this.actions$.ofType(archivosActions.DELETE_ARCHIVOS)
        .pipe(
            switchMap((action: archivosActions.DeleteArchivosAction) => {
                return this.archivosService.deleteFile(action.actualizacion)
                    .pipe(
                        map((response) => new archivosActions.DeleteArchivosActionSUCCESS((response) ? action.actualizacion : new Actualizaciones(0,null, ""))),
                        catchError(error => of(new archivosActions.DeleteArchivosActionFAIL(error)))
                    )
            })
        );
};
