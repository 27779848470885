import * as fromAreas from '../../../actions/modulos/indicadoresSustentabilidad/areasSus.actions';
import { AreaEmpresa } from 'src/app/shared/models/Indicadores/area_empresa.model';

export class AreasState {
    list: AreaEmpresa[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: AreasState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function areasReducerSus(state = initState, action: fromAreas.areasActions_SUS): AreasState {
    switch (action.type) {
        //#region GET_AREAS
        case fromAreas.GET_AREAS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromAreas.GET_AREAS_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.areas.map(areas => {
                        return {
                            ...areas
                        }
                    })
                ]
            };
        case fromAreas.GET_AREAS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_AREAS
        case fromAreas.PUT_AREAS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromAreas.PUT_AREAS_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(areaEditar => {
                        if (areaEditar.id === action.editArea.id) {
                            return {
                                ...areaEditar,
                                descripcion: action.editArea.descripcion,
                                dependencia: action.editArea.dependencia,
                                nivel: action.editArea.nivel,
                                permiso: action.editArea.permiso,
                                idEmpresa: action.editArea.idEmpresa
                            };
                        } else {
                            return areaEditar;
                        }
                    })
                ]
            };
        case fromAreas.PUT_AREAS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_STATE_AREA    
        case fromAreas.PUT_STATE_AREAS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromAreas.PUT_STATE_AREAS_SUS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...state.list.map(areaEditar => {
                        if (areaEditar.id === action.idArea) {
                            return {
                                ...areaEditar,
                                permiso: areaEditar.permiso
                            };
                        } else {
                            return areaEditar;
                        }
                    })
                ]
            };
        case fromAreas.PUT_STATE_AREAS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region POST_AREAS
        case fromAreas.POST_AREAS_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromAreas.POST_AREAS_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.newArea],
                loading: false,
                loaded: true,
                error: null
            };
        case fromAreas.POST_AREAS_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region DELETE_AREA
        case fromAreas.DELETE_AREA_SUS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromAreas.DELETE_AREA_SUS_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(area => {
                    if (area.id != action.deleteArea.id) {
                        return {
                            area
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromAreas.DELETE_AREA_SUS_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
