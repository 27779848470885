import * as fromActions from '../../../actions/modulos/indicadoresSustentabilidad/indexSus';
import { FrecuenciaState, frecuenciasReducerSus } from './frecuenciasSus.reducer';
import { TiposIndicadorState, tipoIndicadoresReducerSus } from './tipos_indicadoresSus.reducer';
import { PeriodosState, periodosReducerSus } from './periodosSus.reducer';
import * as fromCatalogos from '../../../actions/modulos/indicadoresSustentabilidad/catalogosSus.actions';
import { estandaresReducerSus, EstandaresState } from './estandaresSus.reducer';

export class CatalogosState {
    frecuencias: FrecuenciaState;
    tipos_indicadores: TiposIndicadorState;
    periodos: PeriodosState;
    estandares: EstandaresState;
};

export const initState: CatalogosState = {
    frecuencias: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    tipos_indicadores: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    periodos: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    estandares: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
};

export function catalogosReducerSus(state = initState, action: fromCatalogos.catalogosActions_SUS): CatalogosState {
    if (action.type == fromActions.GET_FRECUENCIAS_SUS ||
        action.type == fromActions.GET_FRECUENCIAS_SUS_FAIL ||
        action.type == fromActions.GET_FRECUENCIAS_SUS_SUCCESS ||
        action.type == fromActions.POST_FRECUENCIA_SUS ||
        action.type == fromActions.POST_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.POST_FRECUENCIA_SUS_SUCCESS ||
        action.type == fromActions.PUT_FRECUENCIA_SUS ||
        action.type == fromActions.PUT_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.PUT_FRECUENCIA_SUS_SUCCESS ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS_FAIL ||
        action.type == fromActions.DELETE_FRECUENCIA_SUS_SUCCESS) {
        return {
            ...state,
            frecuencias: frecuenciasReducerSus(state.frecuencias, action)
        };
    }
    else if (action.type == fromActions.GET_TIPOS_INDICADOR_SUS ||
        action.type == fromActions.GET_TIPOS_INDICADOR_SUS_FAIL ||
        action.type == fromActions.GET_TIPOS_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUS_SUCCESS ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS_FAIL ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUS_SUCCESS) {
        return {
            ...state,
            tipos_indicadores: tipoIndicadoresReducerSus(state.tipos_indicadores, action)
        };
    }
    else if (action.type == fromActions.GET_PERIODOS_SUS ||
        action.type == fromActions.GET_PERIODOS_SUS_FAIL ||
        action.type == fromActions.GET_PERIODOS_SUS_SUCCESS ||
        action.type == fromActions.POST_PERIODO_SUS ||
        action.type == fromActions.POST_PERIODO_SUS_FAIL ||
        action.type == fromActions.POST_PERIODO_SUS_SUCCESS ||
        action.type == fromActions.PUT_PERIODO_SUS ||
        action.type == fromActions.PUT_PERIODO_SUS_FAIL ||
        action.type == fromActions.PUT_PERIODO_SUS_SUCCESS ||
        action.type == fromActions.DELETE_PERIODO_SUS ||
        action.type == fromActions.DELETE_PERIODO_SUS_FAIL ||
        action.type == fromActions.DELETE_PERIODO_SUS_SUCCESS) {
        return {
            ...state,
            periodos: periodosReducerSus(state.periodos, action)
        };
    }
    else if (action.type == fromActions.GET_ESTANDARES_SUS ||
        action.type == fromActions.GET_ESTANDARES_SUS_FAIL ||
        action.type == fromActions.GET_ESTANDARES_SUS_SUCCESS ||
        action.type == fromActions.POST_ESTANDAR_SUS ||
        action.type == fromActions.POST_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.POST_ESTANDAR_SUS_SUCCESS ||
        action.type == fromActions.PUT_ESTANDAR_SUS ||
        action.type == fromActions.PUT_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.PUT_ESTANDAR_SUS_SUCCESS ||
        action.type == fromActions.DELETE_ESTANDAR_SUS ||
        action.type == fromActions.DELETE_ESTANDAR_SUS_FAIL ||
        action.type == fromActions.DELETE_ESTANDAR_SUS_SUCCESS) {
        return {
            ...state,
            estandares: estandaresReducerSus(state.estandares, action)
        };
    }

    return state;
}