import { Action } from '@ngrx/store';
import { ObjetivoEspecifico } from 'src/app/shared/models/Indicadores/obj_especifico.model';
import { BusquedaCriteria } from 'src/app/shared/models/Indicadores/busqueda_criteria.model';
import { ReporteIndicadores } from 'src/app/shared/models/Indicadores/reporte_indicadores.model';

export const GET_ESPECIFICOS_SUS = '[ESPECIFICOS_SUS] Get Especificos_SUS';
export const GET_ESPECIFICOS_SUS_FAIL = '[ESPECIFICOS_SUS] Get Especificos_SUS FAIL';
export const GET_ESPECIFICOS_SUS_SUCCESS = '[ESPECIFICOS_SUS] Get Especificos_SUS SUCCESS';

export const GET_REPORTE_ESPECIFICOS_SUS = '[GET_REPORTE_ESPECIFICOS_SUS] Get Reporte_SUS Especificos';
export const GET_REPORTE_ESPECIFICOS_SUS_FAIL = '[GET_REPORTE_ESPECIFICOS_FAIL_SUS] Get Reporte_SUS Especificos FAIL';
export const GET_REPORTE_ESPECIFICOS_SUS_SUCCESS = '[GET_REPORTE_ESPECIFICOS_SUCCESS_SUS] Get Reporte_SUS Especificos SUCCESS';

export const PUT_ESPECIFICO_SUS = '[ESPECIFICOS_SUS] Put Especificos_SUS Usuario';
export const PUT_ESPECIFICO_SUS_FAIL = '[ESPECIFICOS_SUS] Put Especificos_SUS Usuario FAIL';
export const PUT_ESPECIFICO_SUS_SUCCESS = '[ESPECIFICOS_SUS] Put Especificos_SUS SUCCESS';

export const PUT_STATE_ESPECIFICO_SUS = '[ESPECIFICOS_SUS] Put State_SUS Especificos Usuario';
export const PUT_STATE_ESPECIFICO_SUS_FAIL = '[ESPECIFICOS_SUS] Put State_SUS Especificos Usuario FAIL';
export const PUT_STATE_ESPECIFICO_SUS_SUCCESS = '[ESPECIFICOS_SUS] Put State_SUS Especificos SUCCESS';

export const POST_ESPECIFICO_SUS = '[ESPECIFICOS_SUS] Post Especificos_SUS';
export const POST_ESPECIFICO_SUS_FAIL = '[ESPECIFICOS_SUS] Post Especificos_SUS FAIL';
export const POST_ESPECIFICO_SUS_SUCCESS = '[ESPECIFICOS_SUS] Post Especificos_SUS SUCCESS';

export const DELETE_ESPECIFICO_SUS = '[ESPECIFICOS_SUS] Delete Especifico_SUS';
export const DELETE_ESPECIFICO_SUS_FAIL = '[ESPECIFICOS_SUS] Delete Especifico_SUS FAIL';
export const DELETE_ESPECIFICO_SUS_SUCCESS = '[ESPECIFICOS_SUS] Delete Especifico_SUS SUCCESS';

//#region GET_ESPECIFICOS
export class GetEspecificosAction_SUS implements Action {
    readonly type = GET_ESPECIFICOS_SUS;
    constructor(public idArea: number, public periodo: string) { }
};

export class GetEspecificosActionFail_SUS implements Action {
    readonly type = GET_ESPECIFICOS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetEspecificosActionSuccess_SUS implements Action {
    readonly type = GET_ESPECIFICOS_SUS_SUCCESS;
    constructor(public objetivosEspecificos: ObjetivoEspecifico[]) { }
};
//#endregion

//#region GET_REPORTE_ESPECIFICOS
export class GetReporteEspecificosAction_SUS implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS_SUS;
    constructor(public criteria: BusquedaCriteria) { }
};

export class GetReporteEspecificosActionFail_SUS implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetReporteEspecificosActionSuccess_SUS implements Action {
    readonly type = GET_REPORTE_ESPECIFICOS_SUS_SUCCESS;
    constructor(public report: ReporteIndicadores[]) { }
};
//#endregion

//#region PUT_ESPECIFICOS
export class PutEspecificoAction_SUS implements Action {
    readonly type = PUT_ESPECIFICO_SUS;
    constructor(public especifico: ObjetivoEspecifico) { }
};

export class PutEspecificoActionFail_SUS implements Action {
    readonly type = PUT_ESPECIFICO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutEspecificoActionSuccess_SUS implements Action {
    readonly type = PUT_ESPECIFICO_SUS_SUCCESS;
    constructor(public editEspecifico: ObjetivoEspecifico) { }
};
//#endregion

//#region PUT_STATE_ESPECIFICOS
export class PutStateEspecificoAction_SUS implements Action {
    readonly type = PUT_STATE_ESPECIFICO_SUS;
    constructor(public id: number) { }
};

export class PutStateEspecificoActionFail_SUS implements Action {
    readonly type = PUT_STATE_ESPECIFICO_SUS_FAIL;
    constructor(public payload: any) { }
};

export class PutStateEspecificoActionSuccess_SUS implements Action {
    readonly type = PUT_STATE_ESPECIFICO_SUS_SUCCESS;
    constructor(public id: number) { }
};
//#endregion

//#region POST_ESPECIFICO
export class PostEspecificoAction_SUS implements Action {
    readonly type = POST_ESPECIFICO_SUS;
    constructor(public especifico: ObjetivoEspecifico) { }
};
export class PostEspecificoActionFAIL_SUS implements Action {
    readonly type = POST_ESPECIFICO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostEspecificoActionSUCCESS_SUS implements Action {
    readonly type = POST_ESPECIFICO_SUS_SUCCESS;
    constructor(public newEspecifico: ObjetivoEspecifico) { }
};
//#endregion

//#region DELETE_ESPECIFICO
export class DeleteEspecificoAction_SUS implements Action {
    readonly type = DELETE_ESPECIFICO_SUS;
    constructor(public especifico: ObjetivoEspecifico) { }
};
export class DeleteEspecificoActionFAIL_SUS implements Action {
    readonly type = DELETE_ESPECIFICO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEspecificoActionSUCCESS_SUS implements Action {
    readonly type = DELETE_ESPECIFICO_SUS_SUCCESS;
    constructor(public deleteEspecifico: ObjetivoEspecifico) { }
};
//#endregion

export type especificosActions_SUS =
    GetEspecificosAction_SUS |
    GetEspecificosActionFail_SUS |
    GetEspecificosActionSuccess_SUS |

    GetReporteEspecificosAction_SUS |
    GetReporteEspecificosActionFail_SUS |
    GetReporteEspecificosActionSuccess_SUS |

    PutEspecificoAction_SUS |
    PutEspecificoActionFail_SUS |
    PutEspecificoActionSuccess_SUS |

    PutStateEspecificoAction_SUS |
    PutStateEspecificoActionFail_SUS |
    PutStateEspecificoActionSuccess_SUS |

    PostEspecificoAction_SUS |
    PostEspecificoActionFAIL_SUS |
    PostEspecificoActionSUCCESS_SUS |

    DeleteEspecificoAction_SUS |
    DeleteEspecificoActionFAIL_SUS |
    DeleteEspecificoActionSUCCESS_SUS;