import * as fromAreasUsuario from '../../../actions/modulos/indicadores/areasusuario.actions';
import { AreaUsuario } from 'src/app/shared/models/Indicadores/area_usuario.model';
import { AreasUsuarioAux } from 'src/app/shared/models/Indicadores/areas-usuario-aux.model';

export class AreasUsuarioState {
    list: AreasUsuarioAux[]; 
    areaUsuario: AreaUsuario;
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: AreasUsuarioState = {
    list: [],
    areaUsuario: null,
    loaded: false,
    loading: false,
    error: null
};

export function areasUsuarioReducer(state = initState, action: fromAreasUsuario.areasUsuarioActions): AreasUsuarioState {
    switch (action.type) {
        //#region GET_AREAS_USUARIO_BY_CORREO
        case fromAreasUsuario.GET_AREAS_USUARIO_BY_CORREO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromAreasUsuario.GET_AREAS_USUARIO_BY_CORREO_SUCCESS:
        return {
                ...state,
                loading: false,
                loaded: true,
                areaUsuario: action.area_usuario
            };
        case fromAreasUsuario.GET_AREAS_USUARIO_BY_CORREO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region GET_AREAS_USUARIO
        case fromAreasUsuario.GET_AREAS_USUARIO:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromAreasUsuario.GET_AREAS_USUARIO_SUCCESS:
        return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.listAreasUsuario.map(user => {
                        return {
                            ...user
                        }
                    })
                ]
            };
        case fromAreasUsuario.GET_AREAS_USUARIO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        //#region PUT_AREAS_USUARIO
       case fromAreasUsuario.PUT_AREAS_USUARIO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromAreasUsuario.PUT_AREAS_USUARIO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(areaEditar => {
                    if (areaEditar.id === action.editAreaUsuario.id) {
                        return {
                            ...areaEditar,
                            usuario: action.editAreaUsuario.usuario,
                            area: action.editAreaUsuario.area
                        };
                    } else {
                        return areaEditar;
                    }
                })
            ]
        };
    case fromAreasUsuario.PUT_AREAS_USUARIO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
        //#region POST_AREAS_USUARIO
    case fromAreasUsuario.POST_AREAS_USUARIO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromAreasUsuario.POST_AREAS_USUARIO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newAreaUsuario],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromAreasUsuario.POST_AREAS_USUARIO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
        //#region DELETE_AREA_USUARIO
        case fromAreasUsuario.DELETE_AREA_USUARIO:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromAreasUsuario.DELETE_AREA_USUARIO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(area => {
                    if (area.id != action.deleteAreaUsuario.id) {
                        return {
                            area
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromAreasUsuario.DELETE_AREA_USUARIO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
