import * as fromLoteAlmacen from '../../../actions/modulos/microMedicion/lotesAlmacen.actions';
import { loteMicroMedicion } from 'src/app/shared/models/microMedicion/lote.model';

export class LoteState {
    list: loteMicroMedicion[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: LoteState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function lotesReducer(state = initState, action: fromLoteAlmacen.lotesAlmacenActions): LoteState {
    switch (action.type) {
        //#region GET_CATALOGO
        case fromLoteAlmacen.GET_LOTES:
            return {
                ...state,
                loading: true,
                error: null
            };
        case fromLoteAlmacen.GET_LOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: [
                    ...action.lotes.map(lote => {
                        return {
                            ...lote
                        }
                    })
                ]
            };
        case fromLoteAlmacen.GET_LOTES_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
        default:
            return state;
    }
}
