import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as logMedidorAlmacenActions from '../../../actions/modulos/microMedicion/logMedidor.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { LogMedidorAlmacenService } from 'src/app/services/microMedicion/almacen/logMedidor.service';
import { SelectedMedidoresSuccess } from 'src/app/shared/models/microMedicion/selectedMedidores.model';

@Injectable()

export class logMedidorEffects {
    constructor(
        private actions$: Actions,
        private logMedidorAlmacenService: LogMedidorAlmacenService,
    ) { }

    @Effect()
    getSeries$ = this.actions$.ofType(logMedidorAlmacenActions.GET_SERIES)
        .pipe(
            switchMap(() => {
                return this.logMedidorAlmacenService.getSeries()
                    .pipe(
                        map((series: string[]) => new logMedidorAlmacenActions.GetSeriesActionSuccess(series)),
                        catchError(error => of(new logMedidorAlmacenActions.GetSeriesActionFail(error)))
                    )
            })
        );


    @Effect()
    getLogMovimientos$ = this.actions$.ofType(logMedidorAlmacenActions.GET_LOG_MEDIDOR)
        .pipe(
            switchMap((action: logMedidorAlmacenActions.GetLogMeididorAction) => {
                return this.logMedidorAlmacenService.getLogMovimientos(action.serie)
                    .pipe(
                        map((hisorico: SelectedMedidoresSuccess) => new logMedidorAlmacenActions.GetLogMeididorActionSuccess(hisorico)),
                        catchError(error => of(new logMedidorAlmacenActions.GetLogMeididorActionFail(error)))
                    )
            })
        );

};
