import { Action } from '@ngrx/store';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

import { IncidenciaVacaciones } from 'src/app/shared/models/incidencias/incidenciaVacaciones';



export const POST_INC_VAC = '[INCIDENCIAS_VAC] Post IncidenciasVac';
export const POST_INC_VAC_FAIL = '[INCIDENCIAS_VAC] Post IncidenciasVac FAIL';
export const POST_INC_VAC_SUCCESS = '[INCIDENCIAS_VAC] Post IncidenciasVac SUCCESS';

//#region  POST_INCIDENCIAS_VAC
export class PostIncidenciaVacacionesAction implements Action {
    readonly type = POST_INC_VAC;
    constructor(public incidencia: IncidenciaVacaciones) { }
};
export class PostIncidenciaVacacionesActionFAIL implements Action {
    readonly type = POST_INC_VAC_FAIL;
    constructor(public payload: any) { }
};
export class PostIncidenciaVacacionesActionSUCCESS implements Action {
    readonly type = POST_INC_VAC_SUCCESS;
    constructor(public newIncidencia: any) {}
};
//#endregion



export type incidenciaVacacionesActions =

PostIncidenciaVacacionesAction|PostIncidenciaVacacionesActionFAIL|PostIncidenciaVacacionesActionSUCCESS;