import * as fromEstrategias from '../../../actions/modulos/indicadores/estrategias.actions';
import { Estrategia } from 'src/app/shared/models/Indicadores/estrategia.model';

export class EstrategiasState {
    list: Estrategia[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: EstrategiasState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function estrategiasReducer(state = initState, action: fromEstrategias.estrategiasActions): EstrategiasState {
    switch (action.type) {
    //#region GET_ESTRATEGIAS
    case fromEstrategias.GET_ESTRATEGIAS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstrategias.GET_ESTRATEGIAS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                 ...action.estrategias.map(estrategia => {
                    return {
                        ...estrategia
                    }
                })
            ]
        };
    case fromEstrategias.GET_ESTRATEGIAS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_ESTRATEGIAS
    case fromEstrategias.PUT_ESTRATEGIAS:
        return {
            ...state,
            loading: true,
            error: null
        };
        case fromEstrategias.PUT_ESTRATEGIAS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(estrategiaEditar => {
                    if (estrategiaEditar.id === action.editEstrategia.id) {
                        return {
                            ...estrategiaEditar,
                            nombre: action.editEstrategia.nombre,
                            descripcion: action.editEstrategia.descripcion,
                            objetivo_especifico: action.editEstrategia.objetivo_especifico
                        };
                    } else {
                        return estrategiaEditar;
                    }
                })
            ]
        };
        case fromEstrategias.PUT_ESTRATEGIAS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ESTRATEGIA
    case fromEstrategias.POST_ESTRATEGIA:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromEstrategias.POST_ESTRATEGIA_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newEstrategia],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromEstrategias.POST_ESTRATEGIA_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
        //#region DELETE_ESTRATEGIA
        case fromEstrategias.DELETE_ESTRATEGIA:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromEstrategias.DELETE_ESTRATEGIA_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(estrategia => {
                    if (estrategia.id != action.deleteEstrategia.id) {
                        return {
                            estrategia
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
        case fromEstrategias.DELETE_ESTRATEGIA_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
