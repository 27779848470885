import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as periodosActions from '../../../actions/modulos/indicadoresSustentabilidad/periodosSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { PeriodosSusService } from 'src/app/services/indicadoresSustentabilidad/periodosSus.service';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';

@Injectable()

export class periodosSusEffects {
    constructor(
        private actions$: Actions,
        private periodosService: PeriodosSusService,
    ) { }

    @Effect()
    cargarPeriodos$ = this.actions$.ofType(periodosActions.GET_PERIODOS_SUS)
        .pipe(
            switchMap(() => {
                return this.periodosService.getPeriodos()
                    .pipe(
                        map((periodos: Periodos[]) => new periodosActions.GetPeriodosActionSuccess_SUS(periodos)),
                        catchError(error => of(new periodosActions.GetPeriodosActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postPeriodo$ = this.actions$.ofType(periodosActions.POST_PERIODO_SUS)
        .pipe(
            switchMap((action: periodosActions.PostPeriodoAction_SUS) => {
                return this.periodosService.postPeriodos(action.periodo)
                    .pipe(
                        map((periodoN: Periodos) => new periodosActions.PostPeriodoActionSUCCESS_SUS(periodoN)),
                        catchError(error => of(new periodosActions.PostPeriodoActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    editPeriodo$ = this.actions$.ofType(periodosActions.PUT_PERIODO_SUS)
        .pipe(
            switchMap((action: periodosActions.PutPeriodoAction_SUS) => {
                const periodo = new Periodos();
                periodo.id = action.periodo.id;
                periodo.descripcion = action.periodo.descripcion;
                periodo.fechaInicio = action.periodo.fechaInicio;
                periodo.fechaFin = action.periodo.fechaFin;

                return this.periodosService.putPeriodo(periodo)
                    .pipe(
                        map(() => new periodosActions.PutPeriodoActionSUCCESS_SUS(periodo)),
                        catchError(error => of(new periodosActions.PutPeriodoActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    deletePeriodo$ = this.actions$.ofType(periodosActions.DELETE_PERIODO_SUS)
        .pipe(
            switchMap((action: periodosActions.DeletePeriodoAction_SUS) => {
                return this.periodosService.deletePeriodo(action.periodo)
                    .pipe(
                        map(() => new periodosActions.DeletePeriodoActionSUCCESS_SUS(action.periodo)),
                        catchError(error => of(new periodosActions.DeletePeriodoActionFAIL_SUS(error)))
                    )
            })
        );
};
