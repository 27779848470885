import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as estadoAlmacenActions from '../../../actions/modulos/microMedicion/estadosAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { EstadosService } from 'src/app/services/microMedicion/almacen/estados.service';

@Injectable()

export class estadosEffects {
    constructor(
        private actions$: Actions,
        private estadosService: EstadosService,
    ) { }

    @Effect()
    getEstados$ = this.actions$.ofType(estadoAlmacenActions.GET_ESTADOS)
        .pipe(
            switchMap(() => {
                return this.estadosService.getEstados()
                    .pipe(
                        map((response: any) => new estadoAlmacenActions.GetEstadosActionSuccess(response)),
                        catchError(error => of(new estadoAlmacenActions.GetEstadosActionFail(error)))
                    )
            })
        );

};
