import { Action } from '@ngrx/store';
import { ObjetivoEstrategico } from 'src/app/shared/models/Indicadores/obj_estrategico.model';

export const GET_ESTRATEGICOS = '[ESTRATEGICOS] Get Estrategicos';
export const GET_ESTRATEGICOS_FAIL = '[ESTRATEGICOS] Get Estrategicos FAIL';
export const GET_ESTRATEGICOS_SUCCESS = '[ESTRATEGICOS] Get Estrategicos SUCCESS';

export const PUT_STATE_ESTRATEGICO = '[ESTRATEGICOS] Put State Estrategicos';
export const PUT_STATE_ESTRATEGICO_FAIL = '[ESTRATEGICOS] Put State Estrategicos FAIL';
export const PUT_STATE_ESTRATEGICO_SUCCESS = '[ESTRATEGICOS] Put State Estrategicos SUCCESS';

export const PUT_ESTRATEGICO = '[ESTRATEGICOS] Put Estrategico';
export const PUT_ESTRATEGICO_FAIL = '[ESTRATEGICOS] Put Estrategico FAIL';
export const PUT_ESTRATEGICO_SUCCESS = '[ESTRATEGICOS] Put Estrategico SUCCESS';

export const POST_ESTRATEGICO = '[ESTRATEGICOS] Post Estrategicos';
export const POST_ESTRATEGICO_FAIL = '[ESTRATEGICOS] Post Estrategicos FAIL';
export const POST_ESTRATEGICO_SUCCESS = '[ESTRATEGICOS] Post Estrategicos SUCCESS';

export const DELETE_ESTRATEGICO = '[ESTRATEGICOS] Delete Estrategicos';
export const DELETE_ESTRATEGICO_FAIL = '[ESTRATEGICOS] Delete Estrategicos FAIL';
export const DELETE_ESTRATEGICO_SUCCESS = '[ESTRATEGICOS] Delete Estrategicos SUCCESS';
//#region GET_ESTRATEGICOS
export class GetEstrategicosAction implements Action {
    readonly type = GET_ESTRATEGICOS;
    constructor(public tipoIndicador: string){}
};

export class GetEstrategicosActionFail implements Action {
    readonly type = GET_ESTRATEGICOS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstrategicosActionSuccess implements Action {
    readonly type = GET_ESTRATEGICOS_SUCCESS;
    constructor(public objetivosEstrategicos: ObjetivoEstrategico[]) { }
};
//#endregion

//#region PUT_STATE_ESTRATEGICO
export class PutStateEstrategicoAction implements Action {
    readonly type = PUT_STATE_ESTRATEGICO;
    constructor(public idEstrategico: number) { }
};

export class PutStateEstrategicoActionFail implements Action {
    readonly type = PUT_STATE_ESTRATEGICO_FAIL;
    constructor(public payload: any) { }
};

export class PutStateEstrategicoActionSuccess implements Action {
    readonly type = PUT_STATE_ESTRATEGICO_SUCCESS;
    constructor(public idEstrategico: number) { }
};
//#endregion

//#region PUT_ESTRATEGICO
export class PutEstrategicoAction implements Action {
    readonly type = PUT_ESTRATEGICO;
    constructor(public estrategico: ObjetivoEstrategico) { }
};

export class PutEstrategicoActionFail implements Action {
    readonly type = PUT_ESTRATEGICO_FAIL;
    constructor(public payload: any) { }
};

export class PutEstrategicoActionSuccess implements Action {
    readonly type = PUT_ESTRATEGICO_SUCCESS;
    constructor(public editEstrategico: ObjetivoEstrategico) { }
};
//#endregion

//#region  POST_ESTRATEGICO
export class PostEstrategicoAction implements Action {
    readonly type = POST_ESTRATEGICO;
    constructor(public estrategico: ObjetivoEstrategico) { }
};
export class PostEstrategicoActionFAIL implements Action {
    readonly type = POST_ESTRATEGICO_FAIL;
    constructor(public payload: any) { }
};
export class PostEstrategicoActionSUCCESS implements Action {
    readonly type = POST_ESTRATEGICO_SUCCESS;
    constructor(public newEstrategico: ObjetivoEstrategico) { }
};
//#endregion

//#region  DELETE_ESTRATEGICO
export class DeleteEstrategicoAction implements Action {
    readonly type = DELETE_ESTRATEGICO;
    constructor(public estrategico: ObjetivoEstrategico) { }
};
export class DeleteEstrategicoActionFAIL implements Action {
    readonly type = DELETE_ESTRATEGICO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteEstrategicoActionSUCCESS implements Action {
    readonly type = DELETE_ESTRATEGICO_SUCCESS;
    constructor(public deleteEstrategico: ObjetivoEstrategico) { }
};
//#endregion


export type estrategicosActions =
GetEstrategicosAction |
GetEstrategicosActionFail |
GetEstrategicosActionSuccess |

PutStateEstrategicoAction |
PutStateEstrategicoActionFail |
PutStateEstrategicoActionSuccess |

PutEstrategicoAction |
PutEstrategicoActionFail |
PutEstrategicoActionSuccess |

PostEstrategicoAction |
PostEstrategicoActionFAIL |
PostEstrategicoActionSUCCESS |

DeleteEstrategicoAction |
DeleteEstrategicoActionFAIL |
DeleteEstrategicoActionSUCCESS;