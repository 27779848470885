import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as lotesAlmacenActions from '../../../actions/modulos/microMedicion/lotesAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { LotesAlmacenService } from 'src/app/services/microMedicion/almacen/lotes.service';
import { loteMicroMedicion } from 'src/app/shared/models/microMedicion/lote.model';

@Injectable()

export class lotesEffects {
    constructor(
        private actions$: Actions,
        private lotesAlmacenService: LotesAlmacenService,
    ) { }

    @Effect()
    getLotes$ = this.actions$.ofType(lotesAlmacenActions.GET_LOTES)
        .pipe(
            switchMap(() => {
                return this.lotesAlmacenService.getLotes()
                    .pipe(
                        map((lotes: loteMicroMedicion[]) => new lotesAlmacenActions.GetLoteActionSuccess(lotes)),
                        catchError(error => of(new lotesAlmacenActions.GetLoteActionFail(error)))
                    )
            })
        );

};
