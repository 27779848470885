import { Action } from '@ngrx/store';
import { EstadoMicroMedicion } from 'src/app/shared/models/microMedicion/estadoMicromedicion.model';

export const GET_ESTADOS = '[ALMACEN] Get Estados';
export const GET_ESTADOS_FAIL = '[ALMACEN] Get Estados FAIL';
export const GET_ESTADOS_SUCCESS = '[ALMACEN] Get Estados SUCCESS';

//#region GET_ESTADOS
export class GetEstadosAction implements Action {
    readonly type = GET_ESTADOS;
};

export class GetEstadosActionFail implements Action {
    readonly type = GET_ESTADOS_FAIL;
    constructor(public payload: any) { }
};

export class GetEstadosActionSuccess implements Action {
    readonly type = GET_ESTADOS_SUCCESS;
    constructor(public estados: EstadoMicroMedicion[]) { }
};
//#endregion

export type estadosAlmacenActions =
    GetEstadosAction |
    GetEstadosActionFail |
    GetEstadosActionSuccess;