import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { proyectoMicroMedicion } from 'src/app/shared/models/microMedicion/proyectoMicroMedicion.model';
import { ExceptionsService } from '../../exceptions.service';

@Injectable({
    providedIn: 'root'
})
export class ProyectosAlmacenService {

    private proyectos: proyectoMicroMedicion[] = [];

    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region  GET
    getProyectos() {
        this.proyectos = [];
        return this.http.get(`${environment.apiAlmacen}almacen/getProjects`)
            .pipe(
                map((response: any) => {
                    response.map(x => {
                        this.proyectos.push(new proyectoMicroMedicion(
                            x.id,
                            x.nombre
                        ));
                    })
                    return this.proyectos;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    get _getProyectos(): proyectoMicroMedicion[] { return this.proyectos }
    //#endregion
}