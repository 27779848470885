import { Action } from '@ngrx/store';
import { Frecuencia } from 'src/app/shared/models/Indicadores/frecuencia.model';

//FRECUENCIAS
export const GET_FRECUENCIAS_SUS = '[GET_FRECUENCIAS_SUS] Get Frecuencias_SUS';
export const GET_FRECUENCIAS_SUS_FAIL = '[GET_FRECUENCIAS_SUS] Get Frecuencias_SUS FAIL';
export const GET_FRECUENCIAS_SUS_SUCCESS = '[GET_FRECUENCIAS_SUS] Get Frecuencias_SUS SUCCESS';

export const POST_FRECUENCIA_SUS = '[POST_FRECUENCIAS_SUS] Post Frecuencia_SUS';
export const POST_FRECUENCIA_SUS_FAIL = '[POST_FRECUENCIAS_SUS] Post Frecuencia_SUS FAIL';
export const POST_FRECUENCIA_SUS_SUCCESS = '[POST_FRECUENCIAS_SUS] Post Frecuencia_SUS SUCCESS';

export const PUT_FRECUENCIA_SUS = '[PUT_FRECUENCIAS_SUS] Put Frecuencia_SUS';
export const PUT_FRECUENCIA_SUS_FAIL = '[PUT_FRECUENCIAS_SUS] Put Frecuencia_SUS FAIL';
export const PUT_FRECUENCIA_SUS_SUCCESS = '[PUT_FRECUENCIAS_SUS] Put Frecuencia_SUS SUCCESS';

export const DELETE_FRECUENCIA_SUS = '[DELETE_FRECUENCIAS_SUS] Delete Frecuencia_SUS';
export const DELETE_FRECUENCIA_SUS_FAIL = '[DELETE_FRECUENCIAS_SUS] Delete Frecuencia_SUS FAIL';
export const DELETE_FRECUENCIA_SUS_SUCCESS = '[DELETE_FRECUENCIAS_SUS] Delete Frecuencia_SUS SUCCESS';

//#region GET_FRECUENCIAS
export class GetFrecuenciasAction_SUS implements Action {
    readonly type = GET_FRECUENCIAS_SUS;
};

export class GetFrecuenciasActionFail_SUS implements Action {
    readonly type = GET_FRECUENCIAS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetFrecuenciasActionSuccess_SUS implements Action {
    readonly type = GET_FRECUENCIAS_SUS_SUCCESS;
    constructor(public frecuencias: Frecuencia[]) { }
};
//#endregion

//#region  POST_FRECUENCIA
export class PostFrecuenciaAction_SUS implements Action {
    readonly type = POST_FRECUENCIA_SUS;
    constructor(public frecuencia: Frecuencia) { }
};
export class PostFrecuenciaActionFAIL_SUS implements Action {
    readonly type = POST_FRECUENCIA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostFrecuenciaActionSUCCESS_SUS implements Action {
    readonly type = POST_FRECUENCIA_SUS_SUCCESS;
    constructor(public newFrecuencia: Frecuencia) { }
};
//#endregion

//#region  PUT_FRECUENCIA
export class PutFrecuenciaAction_SUS implements Action {
    readonly type = PUT_FRECUENCIA_SUS;
    constructor(public updateFrecuencia: Frecuencia) { }
};
export class PutFrecuenciaActionFAIL_SUS implements Action {
    readonly type = PUT_FRECUENCIA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PutFrecuenciaActionSUCCESS_SUS implements Action {
    readonly type = PUT_FRECUENCIA_SUS_SUCCESS;
    constructor(public updateFrecuencia: Frecuencia) { }
};
//#endregion

//#region  DELETE_FRECUENCIA
export class DeleteFrecuenciaAction_SUS implements Action {
    readonly type = DELETE_FRECUENCIA_SUS;
    constructor(public frecuencia: Frecuencia) { }
};
export class DeleteFrecuenciaActionFAIL_SUS implements Action {
    readonly type = DELETE_FRECUENCIA_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteFrecuenciaActionSUCCESS_SUS implements Action {
    readonly type = DELETE_FRECUENCIA_SUS_SUCCESS;
    constructor(public deleteFrecuencia: Frecuencia) { }
};
//#endregion

export type frecuenciasActions_SUS =
GetFrecuenciasAction_SUS |
GetFrecuenciasActionFail_SUS |
GetFrecuenciasActionSuccess_SUS |

PostFrecuenciaAction_SUS |
PostFrecuenciaActionFAIL_SUS |
PostFrecuenciaActionSUCCESS_SUS |

PutFrecuenciaAction_SUS |
PutFrecuenciaActionFAIL_SUS |
PutFrecuenciaActionSUCCESS_SUS |

DeleteFrecuenciaAction_SUS |
DeleteFrecuenciaActionFAIL_SUS |
DeleteFrecuenciaActionSUCCESS_SUS;
