import * as fromEstandares from '../../../actions/modulos/indicadores/estandares.actions';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';

export class EstandaresState {
    list: Estandares[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: EstandaresState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function estandaresReducer(state = initState, action: fromEstandares.estandaresActions): EstandaresState {
    switch (action.type) {
    //#region GET_PERIODOS
    case fromEstandares.GET_ESTANDARES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstandares.GET_ESTANDARES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.estandares.map(estandares => {
                    return {
                        ...estandares
                    }
                })
            ]
        };
    case fromEstandares.GET_ESTANDARES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_PERIODO
    case fromEstandares.POST_ESTANDAR:
        return {
            ...state,
        };
    case fromEstandares.POST_ESTANDAR_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [...state.list, action.newEstandar],
        };
    case fromEstandares.POST_ESTANDAR_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_PERIODO
    case fromEstandares.PUT_ESTANDAR:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstandares.PUT_ESTANDAR_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(editEstandar => {
                    if (editEstandar.id === action.updateEstandar.id) {
                        return {
                            ...editEstandar,
                            descripcion: action.updateEstandar.descripcion
                        };
                    } else {
                        return editEstandar;
                    }
                })
            ]
        };
    case fromEstandares.PUT_ESTANDAR_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_PERIODO
    case fromEstandares.DELETE_ESTANDAR:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromEstandares.DELETE_ESTANDAR_SUCCESS:
        return {
            ...state,
            list: [...state.list.filter(estandar => {
                if (estandar.id != action.deleteEstandar.id) {
                    return {
                        estandar
                    };
                }
            })],
            loading: false,
            loaded: true,
            error: null
        };
    case fromEstandares.DELETE_ESTANDAR_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    default:
        return state;
    }
}
