import { Action } from '@ngrx/store';
import { Roles } from 'src/app/shared/models/Inwork/roles.model';

//ROLES
export const GET_ROLES = '[INWORK] Get Roles Inwork';
export const GET_ROLES_FAIL = '[INWORK] Get Roles Inwork FAIL';
export const GET_ROLES_SUCCESS = '[INWORK] Get Roles Inwork SUCCESS';

export const POST_ROL = '[INWORK] Post Rol Inwork';
export const POST_ROL_FAIL = '[INWORK] Post Rol Inwork FAIL';
export const POST_ROL_SUCCESS = '[INWORK] Post Rol Inwork SUCCESS';

export const PUT_ROL = '[INWORK] Put Rol Inwork';
export const PUT_ROL_FAIL = '[INWORK] Put Rol Inwork FAIL';
export const PUT_ROL_SUCCESS = '[INWORK] Put Rol Inwork SUCCESS';

export const DELETE_ROL = '[INWORK] Delete Rol Inwork';
export const DELETE_ROL_FAIL = '[INWORK] Delete Rol Inwork FAIL';
export const DELETE_ROL_SUCCESS = '[INWORK] Delete Rol Inwork SUCCESS';

//#region GET_ROLES
export class GetRolesAction implements Action {
    readonly type = GET_ROLES;
};

export class GetRolesActionFail implements Action {
    readonly type = GET_ROLES_FAIL;
    constructor(public payload: any) { }
};

export class GetRolesActionSuccess implements Action {
    readonly type = GET_ROLES_SUCCESS;
    constructor(public roles: Roles[]) { }
};
//#endregion

//#region  POST_ROL
export class PostRolAction implements Action {
    readonly type = POST_ROL;
    constructor(public rol: Roles) { }
};
export class PostRolActionFAIL implements Action {
    readonly type = POST_ROL_FAIL;
    constructor(public payload: any) { }
};
export class PostRolActionSUCCESS implements Action {
    readonly type = POST_ROL_SUCCESS;
    constructor(public newRol: Roles) { }
};
//#endregion

//#region  PUT_ROL
export class PutRolAction implements Action {
    readonly type = PUT_ROL;
    constructor(public rol: Roles) { }
};
export class PutRolActionFAIL implements Action {
    readonly type = PUT_ROL_FAIL;
    constructor(public payload: any) { }
};
export class PutRolActionSUCCESS implements Action {
    readonly type = PUT_ROL_SUCCESS;
    constructor(public updateRol: Roles) { }
};
//#endregion

//#region  DELETE_ROL
export class DeleteRolAction implements Action {
    readonly type = DELETE_ROL;
    constructor(public rol: Roles) { }
};
export class DeleteRolActionFAIL implements Action {
    readonly type = DELETE_ROL_FAIL;
    constructor(public payload: any) { }
};
export class DeleteRolActionSUCCESS implements Action {
    readonly type = DELETE_ROL_SUCCESS;
    constructor(public deleteRol: Roles) { }
};
//#endregion

export type rolesInworkActions =
GetRolesAction |
GetRolesActionFail |
GetRolesActionSuccess |

PostRolAction |
PostRolActionFAIL |
PostRolActionSUCCESS |

PutRolAction |
PutRolActionFAIL |
PutRolActionSUCCESS |

DeleteRolAction |
DeleteRolActionFAIL |
DeleteRolActionSUCCESS;
