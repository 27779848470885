import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../../implementation/http-client.service';
import { map, catchError } from 'rxjs/operators';
import { VariablesService } from '../../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { of } from 'rxjs';
import { CodigoMicroMedicion, CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';
import { ExceptionsService } from '../../exceptions.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogoAlmacenService {


    constructor(
        private http: HttpClientService,
        private variablesService: VariablesService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region  GET
    getCatalogo() {
        let codigos: CodigoMicroMedicion[] = [];
        return this.http.get(`${environment.apiAlmacen}almacen/getCatalog`)
            .pipe(
                map((response: any) => {
                    response.map(x => {
                        codigos.push(new CodigoMicroMedicion(
                            x.id,
                            x.tipo,
                            x.codigo,
                            x.marca,
                            x.diametro,
                            x.milimetros
                        ));
                    });
                    return codigos;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )))
    }
    getCatalogoPV() {
        let codigos: CodigoPVMicroMedicion[] = [];
        return this.http.get(`${environment.apiAlmacen}almacen/getCatalog`)
            .pipe(
                map((response: any) => {
                    response.map(x => {
                        codigos.push(new CodigoPVMicroMedicion(
                            x.id,
                            x.tipo,
                            x.codigo,
                            x.marca,
                            x.diametro,
                            x.milimetros,
                            x.cantidad,
                            x.precio,
                            x.stock,
                            x.iva,
                            x.ivaActivo
                        ));
                    });
                    return codigos;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )))
    }
    //#endregion

    //#region PUT
    putCatalogo() {
        let codigos: CodigoMicroMedicion[] = [];
        return this.http.post(`${environment.apiAlmacen}open/putCatalog`, {})
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast(
                            'success',
                            'Catalogo Actualizado.',
                            `
                             Nuevos: ${response.nuevos}
                             Actualizados: ${response.actualizados}
                            `,
                            3000));

                        response.lista.map(x => {
                            codigos.push(new CodigoMicroMedicion(
                                x.id,
                                x.tipo,
                                x.codigo,
                                x.marca,
                                x.diametro,
                                x.milimetros
                            ));
                        });

                        return codigos;
                    }
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )));
    }
    //#endregion
}