import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { ExceptionsService } from '../exceptions.service';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';
import { CatalogoServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class EstandaresService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    getEstandares() {
        return this.http.get(`${CatalogoServURL}/getEstandares`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postEstandar(estandar: Estandares) {
        return this.http.post(`${CatalogoServURL}/postEstandar`, { data: estandar })
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putEstandar(estandar: Estandares) {
        return this.http.post(`${CatalogoServURL}/putEstandar`, estandar)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteEstandar(estandar: Estandares) {
        return this.http.get(`${CatalogoServURL}/deleteEstandar?idEstandar=${estandar.id}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}