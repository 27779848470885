import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { Campos } from 'src/app/shared/models/Inwork/catalogos.model';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { FieldsServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class CamposService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getCamposPT(idAtributosPT: number) {
        return this.http.get(`${FieldsServURL}/getFields?idAtributosPT=${idAtributosPT}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putCampoPT(campo: Campos) {
        return this.http.post(`${FieldsServURL}/putField`, { data: campo })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos del campo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postCampoPT(campo: Campos) {
        return this.http.post(`${FieldsServURL}/postField`, { data: campo })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos del campo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteCampoPT(campo: Campos) {
        return this.http.get(`${FieldsServURL}/deleteField?idAtributosPT=${campo.idAtributosPT}&idItemCatalogo=${campo.idItemCatalogo}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos del campo', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}