import { Injectable } from '@angular/core';
import { HttpClient as Http, HttpParams } from '@angular/common/http';
import { HttpClientService } from '../implementation/http-client.service';
import { Observable } from 'rxjs';

import {TiposIncidencias} from 'src/app/shared/models/incidencias/tiposIncidencias';
import {InfoTrabajador} from 'src/app/shared/models/incidencias/infoTrabajador';
import {IncidenciasPendientesTrabajador} from 'src/app/shared/models/incidencias/incidenciasPendientesTrabajador';
import { Catalogos } from 'src/app/shared/models/incidencias/catalogos';

import { Toast } from 'src/app/shared/models/toast.model';

import { InfoDetalleGeneral } from 'src/app/shared/models/incidencias/InfoDetalle'

import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExceptionsService } from '../exceptions.service';

//Modelos
import { DiasPendientesTrabajador } from 'src/app/shared/models/incidencias/diasPendientesTrabajador';
import { IncidenciaVacaciones , IncidenciasHE, IncidenciasOmision, IncidenciasPrimaDominical,IncidenciaVac, IncidenciasFaltaInjustificada, IncidenciasDefHosp, IncidenciasLactancia, IncidenciasFestivoLab, IncidenciaPaternidad } from 'src/app/shared/models/incidencias/incidenciaVacaciones';
//import { IncidenciaVacaciones,IncidenciaVac } from 'src/app/shared/models/incidencias/incidenciaVacaciones';
import { VariablesService } from '../balcax/variableGL.service';
import{DetalleIncidenciaVacaciones, DetalleIncidenciaHorasExtras, DetalleIncidenciaOmision,DetalleIncidenciaPrimaDominical, DetalleIncidenciaFaltaInjustificada, DetalleIncidenciaDefHosp, DetalleIncidenciaFestivoLab, DetalleIncidenciaLactancia, DetalleIncidenciaPaternidad,}from 'src/app/shared/models/incidencias/DetalleIncidenciaVacaciones';
import{CambioEstadoIncidencia}from 'src/app/shared/models/incidencias/CambioEstadoIncidencia';
import { ComentarioIncidencia } from 'src/app/shared/models/incidencias/ComentarioIncidencia';
import { environment } from 'src/environments/environment';
import { Campo } from 'src/app/shared/models/incidencias/Estados';
import { DiasporDisfrutar } from 'src/app/shared/models/incidencias/DiasporDisfrutar';
import {IncidenciasAprovadas} from 'src/app/shared/models/incidencias/IncidenciasAprovadas';
import { Enlaces } from 'src/app/shared/models/incidencias/Estados';
import { UsuariorAutorizador } from 'src/app/shared/models/incidencias/Estados';
import { ReporteVac } from '../../shared/models/incidencias/ReporteVac';
import { RSA_NO_PADDING } from 'constants';
import { modeloCarga } from 'src/app/shared/models/carnet/programasCursados.model';
import swal from 'prettyalert';

@Injectable({
  providedIn: 'root'
})
export class IncidenciasService {

  private tiposIncidencias: TiposIncidencias[];
  private infoTrabajador: InfoTrabajador[];
  private responseIncidenciasPendientes: IncidenciasPendientesTrabajador[];
  private catalogos:Catalogos[];
  private detalleincidenciaVacaciones:DetalleIncidenciaVacaciones[];
  private API_URL = environment.API_URL_Vacacional;
  constructor(
    private http: HttpClientService,
    private httpd: Http,
    private exceptionsService: ExceptionsService,
    private variablesService: VariablesService
    ) { }

    public getCatalogoIncidencias(): Observable<TiposIncidencias[]>{
      return this.httpd.get<TiposIncidencias[]>(this.API_URL + 'Catalogos/incidenciasActivas');
    }
    public getBuscarTrabajadores(idUsuarioBalca:number): Observable<InfoTrabajador[]>{
      console.log("USUARIO_VAV => ",idUsuarioBalca);
      return this.httpd.get<InfoTrabajador[]>(this.API_URL + 'Catalogos/trabajadores/'+idUsuarioBalca);
    }
    public getBuscarIncidenciasPorTrabajador(codigoTrabajador:string, tipoIncidencia:string): Observable<IncidenciasPendientesTrabajador>{
      return this.httpd.get<IncidenciasPendientesTrabajador>(this.API_URL + 'Servicios/incidenciasPrevias/' + codigoTrabajador +"/" + tipoIncidencia);
    }
    public getContarDiasVacacionesDisponibles(codigoTrabajador:string): Observable<DiasPendientesTrabajador>{
      return this.httpd.get<DiasPendientesTrabajador>(this.API_URL + 'Servicios/vacacionesDisponibles/' + codigoTrabajador);
    }
    public getRevisarPeriodoVigenteIncidencias(): Observable<number>{
      return this.httpd.get<number>(this.API_URL + 'Servicios/periodoIncidenciasCalendario/validarFechaActual');
    }
    public postIncidenciaVacaciones(incidencia_vac: IncidenciaVacaciones) {
      // console.info(this.API_URL+'Servicios/nuevaIncidenciaVacacional');
      return this.httpd.post(this.API_URL+'Servicios/nuevaIncidenciaVacacional', { data: incidencia_vac })
        .pipe(
          map((response: any) =>{
            console.log(response)
            response.includes('VAC')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
          }
            )
        );
    }

    //
    public postIncidenciaVac(incidencia_vac: IncidenciaVac) {
      // console.info(this.API_URL+'Servicios/nuevaIncidenciaVacacional');
      return this.httpd.post(this.API_URL+'Servicios/IncidenciaVacacional/',  incidencia_vac )
        .pipe(
          map((response: any) =>{
            console.log(response)
            response.includes('VAC')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
          }
            )
        );
    }

  //Listado-de_inicidencias
  //GET[ ] Obtiene el listado de Vacaciones http://localhost:51141/api/Catalogos
  public getListadoIncidencias():Observable<any>{
    return this.httpd.get<Catalogos[]>(this.API_URL + 'Catalogos');
  }
  //Listado-de_inicidencias
   //GET[ ] Obtiene el listado de Vacaciones http://localhost:51141/api/Catalogos/VerTodasLasSolicitudesPendientes?Estado=pendiente&numeroUsuario=1048
   public getListadoIncidenciasPendientes(Estado:string, numerotrabajador:number):Observable<any>{
    return this.httpd.get<Catalogos[]>(this.API_URL + 'Catalogos/VerTodasLasSolicitudesPendientes?Estado='+Estado+'&numeroUsuario='+numerotrabajador);
  }

  //Lista_de_inicidencias/Detalle_de_inicidencia
  //  GET[ ] Obtiene el detalle de incidencia http://localhost:51141/api/Servicios/DetalleGeneralIncidencia
   public getDetalleGeneralIncidencia(idIncidencia: number): Observable<any> {
    return this.httpd.get<InfoDetalleGeneral>(this.API_URL + 'Servicios/DetalleGeneralIncidencia/'+idIncidencia);
  }
   //  GET[ ] Obtiene el detalle de incidencia http://localhost:51141/api/Servicios/DetalleIncidencia
  public getDetalleIncidenciaVacaciones(idIncidencia: number): Observable<any>{
    return this.httpd.get<DetalleIncidenciaVacaciones>(this.API_URL + 'Servicios/DetalleIncidencia/'+idIncidencia);
  }

  // PUT[] Cambia el estado de la incidencia de acuerdo a si fue (2)AUTORIZADA ,(3) RECHAZADA Y (4) CANCELADA
  // http://localhost:51141/api/Servicios/CambiarEstadoIncidencia
  public postCambioEstadoIncidencia(Cambioestado:CambioEstadoIncidencia){
    console.info(this.API_URL+'Servicios/CambiarEstadoIncidencia');
      return this.httpd.post(this.API_URL+'Servicios/CambiarEstadoIncidencia/', Cambioestado)
        .pipe(
          map((response: any) =>{

            console.log(response)
            this.variablesService.toast.next(new Toast('success', 'Notificación',"La petición ha sido "+response , 2000));
          }
            )
        );
  }
  // POST[] AGREGAR INCIDENCIAS DE HORAS EXTRAS
  // http://localhost:51141/api/Servicios/CambiarEstadoIncidencias
  public postNuevaIncidenciaHE(incidenciasHE: IncidenciasHE){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaHE');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaHE/', incidenciasHE)
        .pipe(
          map((response: string) =>{
            response.includes('HE')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            // this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }
  // POST[] AGREGAR INCIDENCIAS DE HORAS EXTRAS
  // http://localhost:51141/api/Servicios/CambiarEstadoIncidencias
  public postNuevaIncidenciaHEDom(incidenciasHE: IncidenciasHE){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaHEDom');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaHEDom/', incidenciasHE)
        .pipe(
          map((response: string) =>{
            response.includes('TED')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
          }));
  }
 // http://localhost:51141/api/Servicios/AgregarComentario
  public postComentarioIncidencia(Comentario:ComentarioIncidencia){
    console.info(this.API_URL+'Servicios/AgregarComentario');
      return this.httpd.post(this.API_URL+'Servicios/AgregarComentario', Comentario)
        .pipe(
          map((response: any) =>{
            response;
          }
            ),
          catchError((error) => of(
            this.exceptionsService._errorComunicacion(error)
          ))
        );
  }

  //http://localhost:51141/api/Catalogos/VerTodosLosEstadosSolicitudes
  public getEstadosIncidencia(): Observable<Campo[]> {
    return this.httpd.get<Campo[]>(this.API_URL + 'Catalogos/VerTodosLosEstadosSolicitudes');
  }

  //http://localhost:51141/api/Servicios/VacacionesTrabajador/
  public getVacacionesTrabajador(numerotrabajador:number):Observable<DiasporDisfrutar[]>{
    return this.httpd.get<DiasporDisfrutar[]>(this.API_URL + 'Servicios/VacacionesTrabajador/' + numerotrabajador);
  }

  //http://localhost:51141/api/Servicios/VacacionesTrabajador/
  public getVacacionesTrabajadorUsuario(numerotrabajador:number):Observable<DiasporDisfrutar[]>{
    return this.httpd.get<DiasporDisfrutar[]>(this.API_URL + 'Servicios/VacacionesTrabajadorUsuario/' + numerotrabajador);
  }

  //http://localhost:51141/api/Servicios/ReportePorEstado?Estado=p&numeroUsuario=1048
  public getReportePorEstado(numerotrabajador:number,estado:string):Observable<IncidenciasAprovadas[]>{
    return this.httpd.get<IncidenciasAprovadas[]>(this.API_URL + 'Servicios/ReportePorEstado?Estado=' + estado+"&numeroUsuario="+numerotrabajador);
  }

  //http://localhost:51141/api/Catalogos/EnlacesAdministrativos
  public getEnlacesAdministrativos(idUsuarioBalcax: number):Observable<Enlaces[]>{
    return this.httpd.get<Enlaces[]>(this.API_URL + 'Catalogos/EnlacesAdministrativos?usuarioBalcan='+idUsuarioBalcax);
  }
  //http://localhost:51141/api/Catalogos/UsuariorAutorizador
  public getUsuariorAutorizador(idUsuarioBalcax: number):Observable<UsuariorAutorizador[]>{
    return this.httpd.get<UsuariorAutorizador[]>(this.API_URL + 'Catalogos/UsuariorAutorizador?usuarioBalcan='+idUsuarioBalcax);
  }

  //http://localhost:51141/api/Servicios/ReportePorEstado?Estado=AUReportePorEstado?Estado=1048
  public getReporteVacacionesCOMPAQ(fechaInicio:String, fechaFin:String):Observable<ReporteVac[]>{
    return this.httpd.get<ReporteVac[]>(this.API_URL + 'Servicios/reporte/'+fechaInicio + '/'+fechaFin);
  }
  //http://localhost:51141/api/Catalogos/ValidarTrabajador?numeroUsuario=12345678
  public getValidarTrabajador(numerotrabajador:number):Observable<any>{
    return this.httpd.get(this.API_URL + 'Catalogos/ValidarTrabajador?numeroUsuario='+numerotrabajador );
  }

     //  GET[ ] Obtiene el detalle de incidencia http://localhost:51141/api/Servicios/DetalleIncidenciaHE?idIncidencia=162
     public getDetalleIncidenciaHE(idIncidencia: number): Observable<any>{
      return this.httpd.get< DetalleIncidenciaHorasExtras>(this.API_URL + 'Servicios/DetalleIncidenciaHE?idIncidencia='+idIncidencia);
    }



// POST[] AGREGAR INCIDENCIAS DE OMISION DE CHECHADO
  // http://localhost:51141/api/Servicios/CambiarEstadoIncidencias
  public postNuevaIncidenciaOmision(incidenciaOmision: IncidenciasOmision){
    // console.info(this.API_URL+'Servicios/NuevaIncidenciaHE');
    console.info(this.API_URL+'Servicios/NuevaIncidenciaOmision');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaOmision/', incidenciaOmision)
        .pipe(
          map((response: string) =>{
            console.log("entro al service",response)
            // console.log(response)
            response.includes('JOC')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }
  ////7
  public postNuevaIncidenciaLactancia(incidenciaLactancia: IncidenciasLactancia){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaLactancia');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaLactancia/', incidenciaLactancia)
        .pipe(
          map((response: string) =>{
            console.log("entro al service",response)
            response.includes('PL')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }

  ///7
  public postNuevaIncidenciaPaternidad(incidenciaPaternidad: IncidenciaPaternidad){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaPaternidad');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaPaternidad/', incidenciaPaternidad)
        .pipe(
          map((response: string) =>{
            console.log("entro al service",response)
            response.includes('PP')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }

  //http://localhost:51141/api/Servicios/DetalleIncidenciaOmision?idIncidencia=184
  public getDetalleIncidenciaOmision(idIncidencia:number):Observable<any>{
    return this.httpd.get< DetalleIncidenciaOmision>(this.API_URL + 'Servicios/DetalleIncidenciaOmision?idIncidencia='+idIncidencia);
  }
// POST[] AGREGAR INCIDENCIAS DE Primadominical
  // http://localhost:51141/api/Servicios/NuevaIncidenciaPrimaDominical/
  public postNuevaIncidenciaPrimaDominical(incidenciaPrimaDominical: IncidenciasPrimaDominical){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaPrimaDominical');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaPrimaDominical/', incidenciaPrimaDominical)
        .pipe(
          map((response: string) =>{
            console.log(response)
            response.includes('PD')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }

 //////////7
  public postNuevaIncidenciaFestivoLab(incidenciaFestivo: IncidenciasFestivoLab){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaFestivoLab');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaFestivoLab/', incidenciaFestivo)
        .pipe(
          map((response: string) =>{
            console.log(response)
            response.includes('DFL')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }

    //http://localhost:51141/api/Servicios/DetalleIncidenciaPrimaDominical?idIncidencia=184
    public getDetalleIncidenciaPrimaDominical(idIncidencia:number):Observable<any>{
      return this.httpd.get< DetalleIncidenciaPrimaDominical>(this.API_URL + 'Servicios/DetalleIncidenciaPrimaDominical?idIncidencia='+idIncidencia);
    }

    public getDetalleIncidenciaFestivoLab(idIncidencia:number):Observable<any>{
      return this.httpd.get< DetalleIncidenciaFestivoLab>(this.API_URL + 'Servicios/DetalleIncidenciaFestivoLab?idIncidencia='+idIncidencia);
    }

    public getDetalleIncidenciaLactancia(idIncidencia:number):Observable<any>{
      return this.httpd.get< DetalleIncidenciaLactancia>(this.API_URL + 'Servicios/DetalleIncidenciaLactancia?idIncidencia='+idIncidencia);
    }

    public getDetalleIncidenciaPaternidad(idIncidencia:number):Observable<any>{
      return this.httpd.get< DetalleIncidenciaPaternidad>(this.API_URL + 'Servicios/DetalleIncidenciaPaternidad?idIncidencia='+idIncidencia);
    }


    // POST[] AGREGAR INCIDENCIAS DE RETARDO
  // http://localhost:51141/api/Servicios/NuevaIncidenciaPrimaDominical/
  public postNuevaIncidenciaRetardo(incidenciaRetardo: IncidenciasOmision){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaRetardo');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaRetardo/', incidenciaRetardo)
        .pipe(
          map((response: string) =>{
            console.log("entro al service",response)
            response.includes('RET')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', '',  response , 4000))
          }));
  }

  //POST INCIDENCIA DEFUNSION HOSPITALIZACION
  public postNuevaIncidenciaDefHosp(incidenciaDefHosp: IncidenciasDefHosp){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaDefHosp');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaDefHosp/', incidenciaDefHosp)
        .pipe(
          map((response: string) =>{
            response.includes('P')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
          }));
  }
  public getDetalleIncidenciaDefHosp(idIncidencia:number):Observable<any>{
    return this.httpd.get< DetalleIncidenciaDefHosp>(this.API_URL + 'Servicios/DetalleIncidenciaDefHosp?idIncidencia='+idIncidencia);
  }
    //http://localhost:51141/api/Servicios/DetalleIncidenciaPrimaDominical?idIncidencia=184
    public getDetalleIncidenciaRetardo(idIncidencia:number):Observable<any>{
      return this.httpd.get< DetalleIncidenciaOmision>(this.API_URL + 'Servicios/DetalleIncidenciaRetardo?idIncidencia='+idIncidencia);
    }
    //Cargas Masivas
    public postCargaMasi(postCargaMas: modeloCarga) {
      console.log(postCargaMas);
      return this.httpd.post(this.API_URL + 'Servicios/CargaMasiva/', postCargaMas)
        .pipe(
          map((response: string) => {
            // response.includes('Se crearon ') ?
            // this.variablesService.toast.next(new Toast('success', '', response, 5000)) :
            // this.variablesService.toast.next(new Toast('error', '', response, 7500))

            response.includes('Se crearon ') ?
            // swal({
            //   title: 'SIIIII',
            //   icon: "info",
            //   text: response,
              
            //   buttons: ["Aceptar"],
            //   dangerMode: false,
            // })
            swal(response, "", "success")
             :
            swal(response, "", "warning")
          }));
    }

    // POST[] AGREGAR INCIDENCIA FALTA INJUSTIFICADA
  // http://localhost:51141/api/Servicios/NuevaIncidenciaPrimaDominical/
  public postNuevaIncidenciaFaltaInjustificada(IncidenciaFaltaInjustificada: IncidenciasFaltaInjustificada){
    console.info(this.API_URL+'Servicios/NuevaIncidenciaFaltaInjustificada');
      return this.httpd.post(this.API_URL+'Servicios/NuevaIncidenciaFaltaInjustificada/', IncidenciaFaltaInjustificada)
        .pipe(
          map((response: string) =>{
            console.log("entro al service",response)
            response.includes('FINJ')?
             this.variablesService.toast.next(new Toast('success', 'Notificación',  'El folio de tu incidencia es: '+response , 4000)):
            this.variablesService.toast.next(new Toast('error', 'Alerta',  response , 4000))
          }));
  }
    //http://localhost:51141/api/Servicios/DetalleIncidenciaPrimaDominical?idIncidencia=184
    public getDetalleIncidenciaFaltaInjustificada(idIncidencia:number):Observable<any>{
      return this.httpd.get<DetalleIncidenciaFaltaInjustificada>(this.API_URL + 'Servicios/DetalleIncidenciaFaltaInjustificada?idIncidencia='+idIncidencia);
    }


         //  GET[ ] Obtiene el detalle de incidencia http://localhost:51141/api/Servicios/DetalleIncidenciaHE?idIncidencia=162
         public getDetalleIncidenciaHEDom(idIncidencia: number): Observable<any>{
          return this.httpd.get< DetalleIncidenciaHorasExtras>(this.API_URL + 'Servicios/DetalleIncidenciaHEDom?idIncidencia='+idIncidencia);
        }

 // http://localhost:51141/api/Servicios/ValidarFechas?fechaInicio=2022-12-23&fechaFin=2022-12-23&numero=51016
 // http://APIBalcanModuloVacacional.aguapuebla.mx/api/Servicios/ValidarFechas?fechaInicio=2022-12-23&fechaFin=2022-12-23&numero=51016

  public getValidarFechaIncidencia( numeroTrabajador: number){
return this.httpd.get(this.API_URL + 'Servicios/ValidarFechas?numeroTrabajador='+numeroTrabajador);

  }
  //http://localhost:51141/api/Servicios/ReportePorEstado?Estado=AUReportePorEstado?Estado=1048
  public getReporteCOMPAQ(fechaInicio:String, fechaFin:String, tipoInci:string, idUsuarioBalcan: number):Observable<ReporteVac[]>{
    return this.httpd.get<ReporteVac[]>(this.API_URL + 'Servicios/ReporteQA?fechaInicio='+fechaInicio + '&fechaFin='+fechaFin + '&tipoInci='+tipoInci + '&idUsuarioBalcan='+idUsuarioBalcan);
  }
}
