import { Action } from '@ngrx/store';
import { Actualizaciones } from 'src/app/shared/models/Inwork/actualizaciones.model';
import { Archivos } from 'src/app/shared/models/Inwork/archivos.model';

//ARCHIVOS
export const GET_ARCHIVOS = '[INWORK] Get Archivos Inwork';
export const GET_ARCHIVOS_FAIL = '[INWORK] Get Archivos Inwork FAIL';
export const GET_ARCHIVOS_SUCCESS = '[INWORK] Get Archivos Inwork SUCCESS';

export const POST_ARCHIVOS = '[INWORK] Post Archivos Inwork';
export const POST_ARCHIVOS_FAIL = '[INWORK] Post Archivos Inwork FAIL';
export const POST_ARCHIVOS_SUCCESS = '[INWORK] Post Archivos Inwork SUCCESS';

export const PUT_ARCHIVOS = '[INWORK] Put Archivos Inwork';
export const PUT_ARCHIVOS_FAIL = '[INWORK] Put Archivos Inwork FAIL';
export const PUT_ARCHIVOS_SUCCESS = '[INWORK] Put Archivos Inwork SUCCESS';

export const DELETE_ARCHIVOS = '[INWORK] Delete Archivos Inwork';
export const DELETE_ARCHIVOS_FAIL = '[INWORK] Delete Archivos Inwork FAIL';
export const DELETE_ARCHIVOS_SUCCESS = '[INWORK] Delete Archivos Inwork SUCCESS';

//#region GET_ARCHIVOS
export class GetArchivosAction implements Action {
    readonly type = GET_ARCHIVOS;
    constructor(public idAtributosPT: number){}
};

export class GetArchivosActionFail implements Action {
    readonly type = GET_ARCHIVOS_FAIL;
    constructor(public payload: any) { }
};

export class GetArchivosActionSuccess implements Action {
    readonly type = GET_ARCHIVOS_SUCCESS;
    constructor(public archivos: Archivos[]) { }
};
//#endregion

//#region  POST_ARCHIVOS
export class PostArchivosAction implements Action {
    readonly type = POST_ARCHIVOS;
    constructor(public archivos: Archivos[]) { }
};
export class PostArchivosActionFAIL implements Action {
    readonly type = POST_ARCHIVOS_FAIL;
    constructor(public payload: any) { }
};
export class PostArchivosActionSUCCESS implements Action {
    readonly type = POST_ARCHIVOS_SUCCESS;
    constructor(public newArchivos: Archivos[]) { }
};
//#endregion

//#region  PUT_ARCHIVOS
export class PutArchivosAction implements Action {
    readonly type = PUT_ARCHIVOS;
    constructor(public archivos: Archivos[]) { }
};
export class PutArchivosActionFAIL implements Action {
    readonly type = PUT_ARCHIVOS_FAIL;
    constructor(public payload: any) { }
};
export class PutArchivosActionSUCCESS implements Action {
    readonly type = PUT_ARCHIVOS_SUCCESS;
    constructor(public updateArchivos: Archivos[]) { }
};
//#endregion

//#region  DELETE_ARCHIVOS
export class DeleteArchivosAction implements Action {
    readonly type = DELETE_ARCHIVOS;
    constructor(public actualizacion: Actualizaciones) { }
};
export class DeleteArchivosActionFAIL implements Action {
    readonly type = DELETE_ARCHIVOS_FAIL;
    constructor(public payload: any) { }
};
export class DeleteArchivosActionSUCCESS implements Action {
    readonly type = DELETE_ARCHIVOS_SUCCESS;
    constructor(public deleteActualizacion: Actualizaciones) { }
};
//#endregion

export type archivosInworkActions =
GetArchivosAction |
GetArchivosActionFail |
GetArchivosActionSuccess |

PostArchivosAction |
PostArchivosActionFAIL |
PostArchivosActionSUCCESS |

PutArchivosAction |
PutArchivosActionFAIL |
PutArchivosActionSUCCESS |

DeleteArchivosAction |
DeleteArchivosActionFAIL |
DeleteArchivosActionSUCCESS;
