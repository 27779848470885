import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ExceptionsService } from '../exceptions.service';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { VariablesService } from '../balcax/variableGL.service';
import { Toast } from 'src/app/shared/models/toast.model';
import { Roles } from 'src/app/shared/models/Inwork/roles.model';
import { RolServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService,
        private variablesService: VariablesService,
    ) { }

    getRoles() {
        return this.http.get(`${RolServURL}/getRols`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    putRol(rol: Roles) {
        return this.http.post(`${RolServURL}/putRol`, rol)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se actualizaron los datos del rol', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo actualizar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    postRol(rol: Roles) {
        return this.http.post(`${RolServURL}/postRol`, { data: rol })
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se agregaron los datos del nuevo rol', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error', 'Ah ocurrido un error, no se pudo agregar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    deleteRol(rol: Roles) {
        return this.http.get(`${RolServURL}/deleteRol?idRol=${rol.id}`)
            .pipe(
                map((response: any) => {
                    if (response) {
                        this.variablesService.toast.next(new Toast('success', 'Completado.', 'Se eliminaron los datos del rol', 2000));
                    } else {
                        this.variablesService.toast.next(new Toast('error', 'Error.', 'Ah ocurrido un error, no se pudo eliminar la información! :(', 2000));
                    }
                    return response;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}