import { Action } from '@ngrx/store';
import { MesConfiguracion } from 'src/app/shared/models/Indicadores/mes_configuracion.model';

export const GET_MES_CONFIGURACION = '[MES_CONFIGURACION] Get Mes Configuracion';
export const GET_MES_CONFIGURACION_FAIL = '[MES_CONFIGURACION] Get Mes Configuracion FAIL';
export const GET_MES_CONFIGURACION_SUCCESS = '[MES_CONFIGURACION] Get Mes Configuracion SUCCESS';

export const PUT_MES_CONFIGURACION = '[MES_CONFIGURACION] Put Mes Configuracion';
export const PUT_MES_CONFIGURACION_FAIL = '[MES_CONFIGURACION] Put Mes Configuracion FAIL';
export const PUT_MES_CONFIGURACION_SUCCESS = '[MES_CONFIGURACION] Put Mes Configuracion SUCCESS';

export const PUT_MES_CONFIGURACION_PROGRAMADO = '[MES_CONFIGURACION] Put Mes Configuracion Programado';
export const PUT_MES_CONFIGURACION_PROGRAMADO_FAIL = '[MES_CONFIGURACION] Put Mes Configuracion Programado FAIL';
export const PUT_MES_CONFIGURACION_PROGRAMADO_SUCCESS = '[MES_CONFIGURACION] Put Mes Configuracion Programado SUCCESS';

export const POST_MES_CONFIGURACION = '[MES_CONFIGURACION] Post Mes Configuracion';
export const POST_MES_CONFIGURACION_FAIL = '[MES_CONFIGURACION] Post Mes Configuracion FAIL';
export const POST_MES_CONFIGURACION_SUCCESS = '[MES_CONFIGURACION] Post Mes Configuracion SUCCESS';

export const DELETE_MES_CONFIGURACION = '[MES_CONFIGURACION] Delete Mes Configuracion';
export const DELETE_MES_CONFIGURACION_FAIL = '[MES_CONFIGURACION] Delete Mes Configuracion FAIL';
export const DELETE_MES_CONFIGURACION_SUCCESS = '[MES_CONFIGURACION] Delete Mes Configuracion SUCCESS';

//#region GET_MES_CONFIGURACION
export class GetMesConfiguracionAction implements Action {
    readonly type = GET_MES_CONFIGURACION;
    constructor(public idEspecifico: number) { }
};

export class GetMesConfiguracionActionFail implements Action {
    readonly type = GET_MES_CONFIGURACION_FAIL;
    constructor(public payload: any) { }
};

export class GetMesConfiguracionActionSuccess implements Action {
    readonly type = GET_MES_CONFIGURACION_SUCCESS;
    constructor(public programa_trabajos: MesConfiguracion[]) { }
};
//#endregion
//#region PUT_MES_CONFIGURACION
export class PutMesConfiguracionAction implements Action {
    readonly type = PUT_MES_CONFIGURACION;
    constructor(public mesesConfig: MesConfiguracion) { }
};

export class PutMesConfiguracionActionFail implements Action {
    readonly type = PUT_MES_CONFIGURACION_FAIL;
    constructor(public payload: any) { }
};

export class PutMesConfiguracionActionSuccess implements Action {
    readonly type = PUT_MES_CONFIGURACION_SUCCESS;
    constructor(public editMesesConfig: MesConfiguracion) { }
};
//#endregion

//#region PUT_MES_CONFIGURACION_PROGRAMADO
export class PutMesConfiguracionProgramadoAction implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO;
    constructor(public mesesConfig: MesConfiguracion[]) { }
};

export class PutMesConfiguracionProgramadoActionFail implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO_FAIL;
    constructor(public payload: any) { }
};

export class PutMesConfiguracionProgramadoActionSuccess implements Action {
    readonly type = PUT_MES_CONFIGURACION_PROGRAMADO_SUCCESS;
    constructor(public editMesesConfig: MesConfiguracion[]) { }
};
//#endregion

//#region  POST_MES_CONFIGURACION
export class PostMesConfiguracionAction implements Action {
    readonly type = POST_MES_CONFIGURACION;
    constructor(public mesesConfig: MesConfiguracion[]) { }
};
export class PostMesconfiguracionActionFAIL implements Action {
    readonly type = POST_MES_CONFIGURACION_FAIL;
    constructor(public payload: any) { }
};
export class PostMesConfiguracionActionSUCCESS implements Action {
    readonly type = POST_MES_CONFIGURACION_SUCCESS;
    constructor(public newMesesConfig: MesConfiguracion[]) { }
};
//#endregion

//#region  DELETE_MES_CONFIGURACION
export class DeleteMesConfiguracionAction implements Action {
    readonly type = DELETE_MES_CONFIGURACION;
    constructor(public mesConfig: MesConfiguracion) { }
};
export class DeleteMesConfiguracionActionFAIL implements Action {
    readonly type = DELETE_MES_CONFIGURACION_FAIL;
    constructor(public payload: any) { }
};
export class DeleteMesConfiguracionActionSUCCESS implements Action {
    readonly type = DELETE_MES_CONFIGURACION_SUCCESS;
    constructor(public deleteMesConfig: MesConfiguracion) { }
};
//#endregion

export type mesConfiguracionActions =
GetMesConfiguracionAction |
GetMesConfiguracionActionFail |
GetMesConfiguracionActionSuccess |

PostMesConfiguracionAction |
PostMesconfiguracionActionFAIL |
PostMesConfiguracionActionSUCCESS |

PutMesConfiguracionAction |
PutMesConfiguracionActionFail |
PutMesConfiguracionActionSuccess |

PutMesConfiguracionProgramadoAction |
PutMesConfiguracionProgramadoActionFail |
PutMesConfiguracionProgramadoActionSuccess |

DeleteMesConfiguracionAction |
DeleteMesConfiguracionActionFAIL |
DeleteMesConfiguracionActionSUCCESS;