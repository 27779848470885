import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../implementation/http-client.service';
import { ExceptionsService } from '../exceptions.service';
import { DataCampania, FiltrosUniverso } from 'src/app/shared/models/campañas/campañas.model';
import { CampaniasServURL } from 'src/config/variablesGL';

@Injectable({
    providedIn: 'root'
})
export class CamapañasService {
    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    getUniverso(filtros: FiltrosUniverso) {
        return this.http.post(`${CampaniasServURL}/supply-data`, { data: filtros })
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getColonias() {
        return this.http.get(`${CampaniasServURL}/suburb`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getEncabezado() {
        return this.http.get(`${CampaniasServURL}/headers`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getHistorico(idEncabezado: number) {
        return this.http.get(`${CampaniasServURL}/historical?idEncabezado=${idEncabezado}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    getGrafic(idEncabezado: number) {
        return this.http.get(`${CampaniasServURL}/graph?idEncabezado=${idEncabezado}`)
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

    sendCampania(datos: DataCampania) {
        return this.http.post(`${CampaniasServURL}/send-campaign`, { data: datos })
            .pipe(
                map((response: any) => response),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                ))
            );
    }

}