import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as proyectoAlmacenActions from '../../../actions/modulos/microMedicion/proyectosAlmacen.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProyectosAlmacenService } from 'src/app/services/microMedicion/almacen/proyectosAlmacen.service';

@Injectable()

export class proteyectoEffects {
    constructor(
        private actions$: Actions,
        private proyectosAlmacenService: ProyectosAlmacenService,
    ) { }

    @Effect()
    getProyectos$ = this.actions$.ofType(proyectoAlmacenActions.GET_PROYECTO)
        .pipe(
            switchMap(() => {
                return this.proyectosAlmacenService.getProyectos()
                    .pipe(
                        map((response: any) => new proyectoAlmacenActions.GetProyecoActionSuccess(response)),
                        catchError(error => of(new proyectoAlmacenActions.GetProyecoActionFail(error)))
                    )
            })
        );

};
