import { Action } from '@ngrx/store';
import { loteMicroMedicion } from 'src/app/shared/models/microMedicion/lote.model';

export const GET_LOTES = '[ALMACEN] Get Lotes';
export const GET_LOTES_FAIL = '[ALMACEN] Get Lotes FAIL';
export const GET_LOTES_SUCCESS = '[ALMACEN] Get Lotes SUCCESS';

//#region GET_LOTES
export class GetLoteAction implements Action {
    readonly type = GET_LOTES;
};

export class GetLoteActionFail implements Action {
    readonly type = GET_LOTES_FAIL;
    constructor(public payload: any) { }
};

export class GetLoteActionSuccess implements Action {
    readonly type = GET_LOTES_SUCCESS;
    constructor(public lotes: loteMicroMedicion[]) { }
};
//#endregion

export type lotesAlmacenActions =
    GetLoteAction |
    GetLoteActionFail |
    GetLoteActionSuccess;