import * as fromActualizacionesInwork from '../../../actions/modulos/inwork/actualizaciones.actions';
import { Actualizaciones } from 'src/app/shared/models/Inwork/actualizaciones.model';

export class ActualizacionesInworkState {
    list: Actualizaciones[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: ActualizacionesInworkState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function actualizacionesInworkReducer(state = initState, action: fromActualizacionesInwork.actualizacionesInworkActions): ActualizacionesInworkState {
    switch (action.type) {
    //#region GET_ACTUALIZACIONES
    case fromActualizacionesInwork.GET_ACTUALIZACIONES:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromActualizacionesInwork.GET_ACTUALIZACIONES_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.actualizaciones.map(item => {
                    return {
                        ...item
                    }
                })
            ]
        };
    case fromActualizacionesInwork.GET_ACTUALIZACIONES_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_ACTUALIZACION
    case fromActualizacionesInwork.PUT_ACTUALIZACION:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromActualizacionesInwork.PUT_ACTUALIZACION_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(item => {
                    if (item.id === action.updateActualizacion.id) {
                        return {
                            ...item,
                            usuario : action.updateActualizacion.usuario,
                            descripcion : action.updateActualizacion.descripcion,
                            registrado : action.updateActualizacion.registrado,
                            visible : action.updateActualizacion.visible, 
                            idAtributosPT : action.updateActualizacion.idAtributosPT
                        };
                    } else {
                        return item;
                    }
                })
            ]
        };
    case fromActualizacionesInwork.PUT_ACTUALIZACION_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_ACTUALIZACION
    case fromActualizacionesInwork.POST_ACTUALIZACION:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromActualizacionesInwork.POST_ACTUALIZACION_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newActualizacion],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromActualizacionesInwork.POST_ACTUALIZACION_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ACTUALIZACION
    case fromActualizacionesInwork.DELETE_ACTUALIZACION:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromActualizacionesInwork.DELETE_ACTUALIZACION_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(item => {
                    if (item.id != action.deleteActualizacion.id) {
                        return {
                            item
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromActualizacionesInwork.DELETE_ACTUALIZACION_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
