import { Action } from '@ngrx/store';
import { Medidor } from 'src/app/shared/models/microMedicion/medidor.model';
import { AltaMedidores } from 'src/app/shared/models/microMedicion/altaMedidoresAlmacen.model';
import { EstadoMedidor } from 'src/app/shared/models/microMedicion/estadoMeidor.model';
import { ResponseAltaMedidores } from 'src/app/shared/models/microMedicion/responseAltaMedidores.model';

export const GET_MEDIDORES = '[ALMACEN] Get Medidores';
export const GET_MEDIDORES_FAIL = '[ALMACEN] Get Medidores FAIL';
export const GET_MEDIDORES_SUCCESS = '[ALMACEN] Get Medidores SUCCESS';

export const POST_MEDIDORES = '[ALMACEN] Post Medidores';
export const POST_MEDIDORES_FAIL = '[ALMACEN] Post Medidores FAIL';
export const POST_MEDIDORES_SUCCESS = '[ALMACEN] Post Medidores SUCCESS';

export const PUT_MEDIDORES_DAÑADOS = '[ALMACEN] Put Medidores Dañados';
export const PUT_MEDIDORES_DAÑADOS_FAIL = '[ALMACEN] Put Medidores Dañados FAIL';
export const PUT_MEDIDORES_DAÑADOS_SUCCESS = '[ALMACEN] Put Medidores Dañados SUCCESS';

export const PUT_MEDIDORES_ASIGNAR = '[ALMACEN] Put Medidores Asignar';
export const PUT_MEDIDORES_ASIGNAR_FAIL = '[ALMACEN] Put Medidores Asignar FAIL';
export const PUT_MEDIDORES_ASIGNAR_SUCCESS = '[ALMACEN] Put Medidores Asignar SUCCESS';

export const EMPTY_POST_MEDIDORES = '[ALMACEN] Empty Post Medidores';

//#region GET_MEDIDORES
export class GetMedidoresAlmacenAction implements Action {
    readonly type = GET_MEDIDORES;
};

export class GetMedidoresAlmacenActionFail implements Action {
    readonly type = GET_MEDIDORES_FAIL;
    constructor(public payload: any) { }
};

export class GetMedidoresAlmacenActionSuccess implements Action {
    readonly type = GET_MEDIDORES_SUCCESS;
    constructor(public medidores: Medidor[]) { }
};
//#endregion

//#region POST_MEDIDORES
export class PostMedidoresAlmacenAction implements Action {
    readonly type = POST_MEDIDORES;
    constructor(public data: AltaMedidores) { }
};

export class PostMedidoresAlmacenActionFail implements Action {
    readonly type = POST_MEDIDORES_FAIL;
    constructor(public payload: any) { }
};

export class PostMedidoresAlmacenActionSuccess implements Action {
    readonly type = POST_MEDIDORES_SUCCESS;
    constructor(public data: ResponseAltaMedidores) { }
};
//#endregion

//#region PUT_MEDIDORES_DAÑADOS
export class PutMedidoresDañadosAlmacenAction implements Action {
    readonly type = PUT_MEDIDORES_DAÑADOS;
    constructor(
        public comentario: string,
        public medidoresSeleccionados: string
    ) { }
};

export class PutMedidoresDañadosAlmacenActionFail implements Action {
    readonly type = PUT_MEDIDORES_DAÑADOS_FAIL;
    constructor(public payload: any) { }
};

export class PutMedidoresDañadosAlmacenActionSuccess implements Action {
    readonly type = PUT_MEDIDORES_DAÑADOS_SUCCESS;
    constructor(public medidoresDañados: any,
        public estado: EstadoMedidor) { }
};
//#endregion

//#region PUT_MEDIDORES_ASIGNAR
export class PutMedidoresAsignarAlmacenAction implements Action {
    readonly type = PUT_MEDIDORES_ASIGNAR;
    constructor(
        public idProveedor: number,
        public medidoresSeleccionados: string
    ) { }
};

export class PutMedidoresAsignarAlmacenActionFail implements Action {
    readonly type = PUT_MEDIDORES_ASIGNAR_FAIL;
    constructor(public payload: any) { }
};

export class PutMedidoresAsignarAlmacenActionSuccess implements Action {
    readonly type = PUT_MEDIDORES_ASIGNAR_SUCCESS;
    constructor(
        public medidoresAsignados: any,
        public estado: EstadoMedidor) { }
};
//#endregion

//#region EMPTY_POST_MEDIDORES
export class EmptyPostMedidoresAlmacenAction implements Action {
    readonly type = EMPTY_POST_MEDIDORES;
};
//#endregion

export type medidoresAlmacenActions =
    GetMedidoresAlmacenAction |
    GetMedidoresAlmacenActionFail |
    GetMedidoresAlmacenActionSuccess |

    PostMedidoresAlmacenAction |
    PostMedidoresAlmacenActionFail |
    PostMedidoresAlmacenActionSuccess |

    EmptyPostMedidoresAlmacenAction |

    PutMedidoresDañadosAlmacenAction |
    PutMedidoresDañadosAlmacenActionFail |
    PutMedidoresDañadosAlmacenActionSuccess |

    PutMedidoresAsignarAlmacenAction |
    PutMedidoresAsignarAlmacenActionFail |
    PutMedidoresAsignarAlmacenActionSuccess;