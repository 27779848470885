import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as areasUsuarioActions from '../../../actions/modulos/indicadores/areasusuario.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { AreasUsuarioService } from 'src/app/services/indicadores/areausuario.service';
import { AreaUsuario } from 'src/app/shared/models/Indicadores/area_usuario.model';
import { AreasUsuarioAux } from 'src/app/shared/models/Indicadores/areas-usuario-aux.model';

@Injectable()

export class areasUsuarioEffects {
    constructor(
        private actions$: Actions,
        private areasUsuarioService: AreasUsuarioService,
    ) { }

    @Effect()
    cargarAreaUsuarioByCorreo$ = this.actions$.ofType(areasUsuarioActions.GET_AREAS_USUARIO_BY_CORREO)
        .pipe(
            switchMap((action: areasUsuarioActions.GetAreasUsuarioByCorreoAction) => {
                return this.areasUsuarioService.getAreasUsuarioByCorreo(action.correo)
                    .pipe(
                        map((response: any) => new areasUsuarioActions.GetAreasUsuarioByCorreoActionSuccess(response)),
                        catchError(error => of(new areasUsuarioActions.GetAreasUsuarioByCorreoActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarAreasUsuario$ = this.actions$.ofType(areasUsuarioActions.GET_AREAS_USUARIO)
        .pipe(
            switchMap(() => {
                return this.areasUsuarioService.getAreasUsuario()
                    .pipe(
                        map((response: any) => new areasUsuarioActions.GetAreasUsuarioActionSuccess(response)),
                        catchError(error => of(new areasUsuarioActions.GetAreasUsuarioActionFail(error)))
                    )
            })
        );

    @Effect()
    changeEditAreaUsuario$ = this.actions$.ofType(areasUsuarioActions.PUT_AREAS_USUARIO)
        .pipe(
            switchMap((action: areasUsuarioActions.PutAreasUsuarioAction) => {
                const areas_usuario = new AreasUsuarioAux();
                areas_usuario.id = action.areas_usuario.id;
                areas_usuario.usuario = action.areas_usuario.usuario;
                areas_usuario.area = action.areas_usuario.area;

                return this.areasUsuarioService.putAreaUsuario(areas_usuario)
                    .pipe(
                        map(() => new areasUsuarioActions.PutAreasUsuarioActionSuccess(areas_usuario)),
                        catchError(error => of(new areasUsuarioActions.PutAreasUsuarioActionFail(error)))
                    )
            })
        );

    @Effect()
    postAreaUsuario$ = this.actions$.ofType(areasUsuarioActions.POST_AREAS_USUARIO)
        .pipe(
            switchMap((action: areasUsuarioActions.PostAreasUsuarioAction) => {
                return this.areasUsuarioService.postAreaUsuario(action.area_usuario)
                    .pipe(
                        map((area_usuario: AreasUsuarioAux) => new areasUsuarioActions.PostAreasUsuarioActionSUCCESS(area_usuario)),
                        catchError(error => of(new areasUsuarioActions.PostAreasUsuarioActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteAreaUsuario$ = this.actions$.ofType(areasUsuarioActions.DELETE_AREA_USUARIO)
        .pipe(
            switchMap((action: areasUsuarioActions.DeleteAreaUsuarioAction) => {
                return this.areasUsuarioService.deleteAreaUsuario(action.area_usuario)
                    .pipe(
                        map(() => new areasUsuarioActions.DeleteAreaUsuarioActionSUCCESS(action.area_usuario)),
                        catchError(error => of(new areasUsuarioActions.DeleteAreaUsuarioActionFAIL(error)))
                    )
            })
        );
};
