import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as estandaresActions from '../../../actions/modulos/indicadores/estandares.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { EstandaresService } from 'src/app/services/indicadores/estandares.service';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';
import { Estandares } from 'src/app/shared/models/Indicadores/estandares.model';

@Injectable()

export class estandaresEffects {
    constructor(
        private actions$: Actions,
        private estandarService: EstandaresService,
    ) { }

    @Effect()
    cargarEstandares$ = this.actions$.ofType(estandaresActions.GET_ESTANDARES)
        .pipe(
            switchMap(() => {
                return this.estandarService.getEstandares()
                    .pipe(
                        map((estandares: Estandares[]) => new estandaresActions.GetEstandaresActionSuccess(estandares)),
                        catchError(error => of(new estandaresActions.GetEstandaresActionFail(error)))
                    )
            })
        );

    @Effect()
    postEstandar$ = this.actions$.ofType(estandaresActions.POST_ESTANDAR)
        .pipe(
            switchMap((action: estandaresActions.PostEstandarAction) => {
                return this.estandarService.postEstandar(action.estandar)
                    .pipe(
                        map((estandar: Estandares) => new estandaresActions.PostEstandarActionSUCCESS(estandar)),
                        catchError(error => of(new estandaresActions.PostEstandarActionFAIL(error)))
                    )
            })
        );
    @Effect()
    editEstandar$ = this.actions$.ofType(estandaresActions.PUT_ESTANDAR)
        .pipe(
            switchMap((action: estandaresActions.PutEstandarAction) => {
                const estandar = new Estandares();
                estandar.id = action.estandar.id;
                estandar.descripcion = action.estandar.descripcion;
                
                return this.estandarService.putEstandar(estandar)
                    .pipe(
                        map(() => new estandaresActions.PutEstandarActionSUCCESS(estandar)),
                        catchError(error => of(new estandaresActions.PutEstandarActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteEstandar$ = this.actions$.ofType(estandaresActions.DELETE_ESTANDAR)
        .pipe(
            switchMap((action: estandaresActions.DeleteEstandarAction) => {
                return this.estandarService.deleteEstandar(action.estandar)
                    .pipe(
                        map(() => new estandaresActions.DeleteEstandarActionSUCCESS(action.estandar)),
                        catchError(error => of(new estandaresActions.DeleteEstandarActionFAIL(error)))
                    )
            })
        );
};
