import * as fromActions from '../../../actions/modulos/indicadores/index';
import * as fromIndicadores from '../../../actions/modulos/indicadores/indicadores.actions';

import { EstrategicoState, estrategicosReducer} from './estrategicos.reducer';
import { EspecificoState, especificosReducer } from './especificos.reducer';
import { CatalogosState, catalogosReducer } from './catalogos.reducer';
import { EstrategiasState, estrategiasReducer } from './estrategias.reducer';
import { AreasState, areasReducer } from './areas.reducer';
import { AreasUsuarioState, areasUsuarioReducer } from './areasusuario.reducer';
import { MesConfigState, mesConfiguracionReducer } from './mesconfiguracion.reducer';
import { DashboardsState, dashboardsReducer } from './dashboards.reducer';
import { configProgramaTrabajoState, configProgramaTrabajoReducer } from './config_programatrabajo.reducer';

export interface IndicadoresState {
    dashboards: DashboardsState;
    areas: AreasState;
    areas_usuario: AreasUsuarioState
    estrategicos: EstrategicoState;
    especificos: EspecificoState;
    catalogos: CatalogosState;
    estrategias: EstrategiasState;
    mesConfiguracion: MesConfigState;
    configProgramaTrabajo: configProgramaTrabajoState
};

const initState: IndicadoresState = {
    dashboards:{
        dashboard: null,
        listCardsActivities: [],
        resultAvanceEspecifico: null,
        resultAvanceIndicadores: null,
        resultAvanceEstandares: null,
        detallesAvanceIndicadores: null,
        listAvancePorArea:[],
        listRecentActivities:[],
        loaded: false,
        loading: false,
        error: null
    },
    areas : {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    areas_usuario :{
        list: [],
        areaUsuario: null,
        loaded: false,
        loading: false,
        error: null
    },
    estrategicos: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    especificos: {
        list: [],
        reportIndicadores: [],
        loaded: false,
        loading: false,
        error: null
    },
    catalogos: {
        frecuencias: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        tipos_indicadores: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        periodos: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
        estandares: {
            list: [],
            loaded: false,
            loading: false,
            error: null
        },
    },
    estrategias: {
        list: [],
        loaded:false,
        loading: false,
        error: null
    },
    mesConfiguracion: {
        list: [],
        loaded: false,
        loading: false,
        error: null
    },
    configProgramaTrabajo: {
        configuracionPrograma: null,
        configuracionAvance: [],
        loaded: false,
        loading: false,
        error: null
    }
};

export function indicadoresReducer(state = initState, action: fromIndicadores.indicadoresActions): IndicadoresState {
    if(action.type == fromActions.GET_DASHBOARD ||
        action.type == fromActions.GET_DASHBOARD_FAIL ||
        action.type == fromActions.GET_DASHBOARD_SUCCESS ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES_FAIL ||
        action.type == fromActions.GET_CARD_COUNT_ACTIVITIES_SUCCESS ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO_FAIL ||
        action.type == fromActions.GET_AVANCE_ESPECIFICO_SUCCESS ||
        action.type == fromActions.GET_AVANCE_INDICADORES ||
        action.type == fromActions.GET_AVANCE_INDICADORES_FAIL ||
        action.type == fromActions.GET_AVANCE_INDICADORES_SUCCESS ||
        action.type == fromActions.GET_AVANCE_ESTANDARES ||
        action.type == fromActions.GET_AVANCE_ESTANDARES_FAIL ||
        action.type == fromActions.GET_AVANCE_ESTANDARES_SUCCESS ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES_FAIL ||
        action.type == fromActions.GET_DETALLE_AVANCE_INDICADORES_SUCCESS ||
        action.type == fromActions.GET_AVANCE_POR_AREA ||
        action.type == fromActions.GET_AVANCE_POR_AREA_FAIL ||
        action.type == fromActions.GET_AVANCE_POR_AREA_SUCCESS ||
        action.type == fromActions.GET_RECENT_ACTIVITIES ||
        action.type == fromActions.GET_RECENT_ACTIVITIES_FAIL ||
        action.type == fromActions.GET_RECENT_ACTIVITIES_SUCCESS){
            return{
                ...state,
                dashboards: dashboardsReducer(state.dashboards, action)
            };

    }else if(action.type == fromActions.GET_AREAS || 
        action.type == fromActions.GET_AREAS_FAIL ||
        action.type == fromActions.GET_AREAS_SUCCESS ||
        action.type == fromActions.PUT_AREAS ||
        action.type == fromActions.PUT_AREAS_FAIL ||
        action.type == fromActions.PUT_AREAS_SUCCESS ||
        action.type == fromActions.PUT_STATE_AREAS ||
        action.type == fromActions.PUT_STATE_AREAS_FAIL ||
        action.type == fromActions.PUT_STATE_AREAS_SUCCESS ||
        action.type == fromActions.POST_AREAS ||
        action.type == fromActions.POST_AREAS_FAIL ||
        action.type == fromActions.POST_AREAS_SUCCESS ||
        action.type == fromActions.DELETE_AREA ||
        action.type == fromActions.DELETE_AREA_FAIL ||
        action.type == fromActions.DELETE_AREA_SUCCESS){
            return{
                ...state,
                areas: areasReducer(state.areas, action)
            };

    }else if(action.type == fromActions.GET_AREAS_USUARIO_BY_CORREO || 
        action.type == fromActions.GET_AREAS_USUARIO_BY_CORREO_FAIL ||
        action.type == fromActions.GET_AREAS_USUARIO_BY_CORREO_SUCCESS ||
        action.type == fromActions.GET_AREAS_USUARIO || 
        action.type == fromActions.GET_AREAS_USUARIO_FAIL ||
        action.type == fromActions.GET_AREAS_USUARIO_SUCCESS ||
        action.type == fromActions.PUT_AREAS_USUARIO ||
        action.type == fromActions.PUT_AREAS_USUARIO_FAIL ||
        action.type == fromActions.PUT_AREAS_USUARIO_SUCCESS ||
        action.type == fromActions.POST_AREAS_USUARIO ||
        action.type == fromActions.POST_AREAS_USUARIO_FAIL ||
        action.type == fromActions.POST_AREAS_USUARIO_SUCCESS ||
        action.type == fromActions.DELETE_AREA_USUARIO ||
        action.type == fromActions.DELETE_AREA_USUARIO_FAIL ||
        action.type == fromActions.DELETE_AREA_USUARIO_SUCCESS){
            return{
                ...state, 
                areas_usuario : areasUsuarioReducer(state.areas_usuario, action)
            };
    
    }else if (action.type == fromActions.GET_ESTRATEGICOS ||
                action.type == fromActions.GET_ESTRATEGICOS_FAIL ||
                action.type == fromActions.GET_ESTRATEGICOS_SUCCESS ||
                action.type == fromActions.PUT_STATE_ESTRATEGICO ||
                action.type == fromActions.PUT_STATE_ESTRATEGICO_FAIL ||
                action.type == fromActions.PUT_STATE_ESTRATEGICO_SUCCESS ||
                action.type == fromActions.PUT_ESTRATEGICO ||
                action.type == fromActions.PUT_ESTRATEGICO_FAIL ||
                action.type == fromActions.PUT_ESTRATEGICO_SUCCESS ||
                action.type == fromActions.POST_ESTRATEGICO ||
                action.type == fromActions.POST_ESTRATEGICO_FAIL ||
                action.type == fromActions.POST_ESTRATEGICO_SUCCESS ||
                action.type == fromActions.DELETE_ESTRATEGICO ||
                action.type == fromActions.DELETE_ESTRATEGICO_FAIL ||
                action.type == fromActions.DELETE_ESTRATEGICO_SUCCESS) {
            return {
                ...state,
                estrategicos: estrategicosReducer(state.estrategicos, action)
            };
    }
    else if(action.type == fromActions.GET_ESPECIFICOS ||
            action.type == fromActions.GET_ESPECIFICOS_FAIL ||
            action.type == fromActions.GET_ESPECIFICOS_SUCCESS ||
            action.type == fromActions.GET_REPORTE_ESPECIFICOS ||
            action.type == fromActions.GET_REPORTE_ESPECIFICOS_FAIL ||
            action.type == fromActions.GET_REPORTE_ESPECIFICOS_SUCCESS ||
            action.type == fromActions.PUT_ESPECIFICO ||
            action.type == fromActions.PUT_ESPECIFICO_FAIL ||
            action.type == fromActions.PUT_ESPECIFICO_SUCCESS ||
            action.type == fromActions.PUT_STATE_ESPECIFICO ||
            action.type == fromActions.PUT_STATE_ESPECIFICO_FAIL ||
            action.type == fromActions.PUT_STATE_ESPECIFICO_SUCCESS ||
            action.type == fromActions.POST_ESPECIFICO ||
            action.type == fromActions.POST_ESPECIFICO_FAIL ||
            action.type == fromActions.POST_ESPECIFICO_SUCCESS ||
            action.type == fromActions.DELETE_ESPECIFICO ||
            action.type == fromActions.DELETE_ESPECIFICO_FAIL ||
            action.type == fromActions.DELETE_ESPECIFICO_SUCCESS){
            return {
                ...state,
                especificos: especificosReducer(state.especificos, action)
            };    
    }
    else if(action.type == fromActions.GET_FRECUENCIAS || 
        action.type == fromActions.GET_FRECUENCIAS_FAIL ||
        action.type == fromActions.GET_FRECUENCIAS_SUCCESS ||
        action.type == fromActions.POST_FRECUENCIA ||
        action.type == fromActions.POST_FRECUENCIA_FAIL ||
        action.type == fromActions.POST_FRECUENCIA_SUCCESS ||
        action.type == fromActions.PUT_FRECUENCIA ||
        action.type == fromActions.PUT_FRECUENCIA_FAIL ||
        action.type == fromActions.PUT_FRECUENCIA_SUCCESS ||
        action.type == fromActions.DELETE_FRECUENCIA ||
        action.type == fromActions.DELETE_FRECUENCIA_FAIL ||
        action.type == fromActions.DELETE_FRECUENCIA_SUCCESS ||

        action.type == fromActions.GET_TIPOS_INDICADOR || 
        action.type == fromActions.GET_TIPOS_INDICADOR_FAIL ||
        action.type == fromActions.GET_TIPOS_INDICADOR_SUCCESS ||
        action.type == fromActions.POST_TIPO_INDICADOR ||
        action.type == fromActions.POST_TIPO_INDICADOR_FAIL ||
        action.type == fromActions.POST_TIPO_INDICADOR_SUCCESS ||
        action.type == fromActions.PUT_TIPO_INDICADOR ||
        action.type == fromActions.PUT_TIPO_INDICADOR_FAIL ||
        action.type == fromActions.PUT_TIPO_INDICADOR_SUCCESS ||
        action.type == fromActions.DELETE_TIPO_INDICADOR ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_FAIL ||
        action.type == fromActions.DELETE_TIPO_INDICADOR_SUCCESS ||
        
        action.type == fromActions.GET_PERIODOS ||
        action.type == fromActions.GET_PERIODOS_FAIL ||
        action.type == fromActions.GET_PERIODOS_SUCCESS ||
        action.type == fromActions.POST_PERIODO ||
        action.type == fromActions.POST_PERIODO_FAIL ||
        action.type == fromActions.POST_PERIODO_SUCCESS ||
        action.type == fromActions.PUT_PERIODO ||
        action.type == fromActions.PUT_PERIODO_FAIL ||
        action.type == fromActions.PUT_PERIODO_SUCCESS ||
        action.type == fromActions.DELETE_PERIODO ||
        action.type == fromActions.DELETE_PERIODO_FAIL ||
        action.type == fromActions.DELETE_PERIODO_SUCCESS ||
        
        action.type == fromActions.GET_ESTANDARES ||
        action.type == fromActions.GET_ESTANDARES_FAIL ||
        action.type == fromActions.GET_ESTANDARES_SUCCESS ||
        action.type == fromActions.POST_ESTANDAR ||
        action.type == fromActions.POST_ESTANDAR_FAIL ||
        action.type == fromActions.POST_ESTANDAR_SUCCESS ||
        action.type == fromActions.PUT_ESTANDAR ||
        action.type == fromActions.PUT_ESTANDAR_FAIL ||
        action.type == fromActions.PUT_ESTANDAR_SUCCESS || 
        action.type == fromActions.DELETE_ESTANDAR ||
        action.type == fromActions.DELETE_ESTANDAR_FAIL ||
        action.type == fromActions.DELETE_ESTANDAR_SUCCESS){
            return{
                ...state,
                catalogos: catalogosReducer(state.catalogos, action)
            };
    }
    else if(action.type == fromActions.GET_ESTRATEGIAS || 
        action.type == fromActions.GET_ESTRATEGIAS_FAIL ||
        action.type == fromActions.GET_ESTRATEGIAS_SUCCESS ||
        action.type == fromActions.POST_ESTRATEGIA ||
        action.type == fromActions.POST_ESTRATEGIA_FAIL ||
        action.type == fromActions.POST_ESTRATEGIA_SUCCESS ||
        action.type == fromActions.PUT_ESTRATEGIAS ||
        action.type == fromActions.PUT_ESTRATEGIAS_FAIL ||
        action.type == fromActions.PUT_ESTRATEGIAS_SUCCESS ||
        action.type == fromActions.DELETE_ESTRATEGIA ||
        action.type == fromActions.DELETE_ESTRATEGIA_FAIL ||
        action.type == fromActions.DELETE_ESTRATEGIA_SUCCESS){
            return{
                ...state,
                estrategias: estrategiasReducer(state.estrategias, action)
            };
    }
    else if(action.type == fromActions.GET_MES_CONFIGURACION || 
        action.type == fromActions.GET_MES_CONFIGURACION_FAIL ||
        action.type == fromActions.GET_MES_CONFIGURACION_SUCCESS ||
        action.type == fromActions.POST_MES_CONFIGURACION ||
        action.type == fromActions.POST_MES_CONFIGURACION_FAIL ||
        action.type == fromActions.POST_MES_CONFIGURACION_SUCCESS ||
        action.type == fromActions.PUT_MES_CONFIGURACION ||
        action.type == fromActions.PUT_MES_CONFIGURACION_FAIL ||
        action.type == fromActions.PUT_MES_CONFIGURACION_SUCCESS ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO_FAIL ||
        action.type == fromActions.PUT_MES_CONFIGURACION_PROGRAMADO_SUCCESS ||
        action.type == fromActions.DELETE_MES_CONFIGURACION ||
        action.type == fromActions.DELETE_MES_CONFIGURACION_FAIL ||
        action.type == fromActions.DELETE_MES_CONFIGURACION_SUCCESS){
            return{
                ...state,
                mesConfiguracion: mesConfiguracionReducer(state.mesConfiguracion, action)
            };
    }else if(action.type == fromActions.GET_CONFIGURACION_PROGRAMA ||
        action.type == fromActions.GET_CONFIGURACION_PROGRAMA_FAIL ||
        action.type == fromActions.GET_CONFIGURACION_PROGRAMA_SUCCESS ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE_FAIL ||
        action.type == fromActions.GET_CONFIGURACION_AVANCE_SUCCESS ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA_FAIL ||
        action.type == fromActions.POST_CONFIGURACION_PROGRAMA_SUCCESS ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE_FAIL ||
        action.type == fromActions.POST_CONFIGURACION_AVANCE_SUCCESS ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA_FAIL ||
        action.type == fromActions.PUT_CONFIGURACION_PROGRAMA_SUCCESS ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE_FAIL ||
        action.type == fromActions.PUT_CONFIGURACION_AVANCE_SUCCESS ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA_FAIL ||
        action.type == fromActions.DELETE_CONFIGURACION_PROGRAMA_SUCCESS ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE_FAIL ||
        action.type == fromActions.DELETE_CONFIGURACION_AVANCE_SUCCESS){
            return{
                ...state,
                configProgramaTrabajo: configProgramaTrabajoReducer(state.configProgramaTrabajo, action)
            };
    }
    return state;
}