import { Action } from '@ngrx/store';
import { ubicacionMicroMedicion } from 'src/app/shared/models/microMedicion/ubicacionmicromedicion';

export const GET_UBICACIONES = '[ALMACEN] Get Ubicaciones';
export const GET_UBICACIONES_FAIL = '[ALMACEN] Get Ubicaciones FAIL';
export const GET_UBICACIONES_SUCCESS = '[ALMACEN] Get Ubicaciones SUCCESS';

//#region GET_UBICACIONES
export class GetUbicacionAction implements Action {
    readonly type = GET_UBICACIONES;
};

export class GetUbicacionActionFail implements Action {
    readonly type = GET_UBICACIONES_FAIL;
    constructor(public payload: any) { }
};

export class GetUbicacionActionSuccess implements Action {
    readonly type = GET_UBICACIONES_SUCCESS;
    constructor(public ubicaciones: ubicacionMicroMedicion[]) { }
};
//#endregion

export type ubicacionAlmacenActions =
    GetUbicacionAction |
    GetUbicacionActionFail |
    GetUbicacionActionSuccess;