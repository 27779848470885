import * as fromFlujoTrabajoInwork from '../../../actions/modulos/inwork/flujo_trabajo.actions';
import { DataFlujoTrabajo } from 'src/app/shared/models/Inwork/models_globales.model';
import { FlujosTrabajo } from 'src/app/shared/models/Inwork/flujo_trabajo.model';

export class FlujoTrabajoInworkState {
    list: DataFlujoTrabajo[];
    flujos: FlujosTrabajo[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: FlujoTrabajoInworkState = {
    list: [],
    flujos: [],
    loaded: false,
    loading: false,
    error: null
};

export function flujoTrabajoInworkReducer(state = initState, action: fromFlujoTrabajoInwork.flujoTrabajoInworkActions): FlujoTrabajoInworkState {
    switch (action.type) {
    //#region GET_FLUJOS_TRABAJO
    case fromFlujoTrabajoInwork.GET_FLUJOS_TRABAJO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFlujoTrabajoInwork.GET_FLUJOS_TRABAJO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...action.flujosTrabajo.map(ftrabajo => {
                    return {
                        ...ftrabajo
                    }
                })
            ]
        };
    case fromFlujoTrabajoInwork.GET_FLUJOS_TRABAJO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region GET_FLUJOS_TRABAJO
    case fromFlujoTrabajoInwork.GET_FLUJOS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFlujoTrabajoInwork.GET_FLUJOS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            flujos: [
                ...action.flujosTrabajo.map(ftrabajo => {
                    return {
                        ...ftrabajo
                    }
                })
            ]
        };
    case fromFlujoTrabajoInwork.GET_FLUJOS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_FLUJO_TRABAJO
    case fromFlujoTrabajoInwork.PUT_FLUJO_TRABAJO:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromFlujoTrabajoInwork.PUT_FLUJO_TRABAJO_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(ftrabajo => {
                    if (ftrabajo.id === action.updateFlujoTrabajo.id) {
                        return {
                            ...ftrabajo,
                            nombre: action.updateFlujoTrabajo.nombre,
                            creadoPor: action.updateFlujoTrabajo.creadoPor,
                            dataEstadosPrimarios: action.updateFlujoTrabajo.dataEstadosPrimarios
                        };
                    } else {
                        return ftrabajo;
                    }
                })
            ]
        };
    case fromFlujoTrabajoInwork.PUT_FLUJO_TRABAJO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_CATALOGO
    case fromFlujoTrabajoInwork.POST_FLUJO_TRABAJO:
        return {
            ...state,
            loading: true,
            error: null
        }
    case fromFlujoTrabajoInwork.POST_FLUJO_TRABAJO_SUCCESS:
        return {
            ...state,
            list: [...state.list, action.newFlujoTrabajo],
            loading: false,
            loaded: true,
            error: null            
        };
    case fromFlujoTrabajoInwork.POST_FLUJO_TRABAJO_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_ESTRATEGIA
    case fromFlujoTrabajoInwork.DELETE_FLUJO_TRABAJO:
            return {
                ...state,
                loading: true,
                error: null
            }
    case fromFlujoTrabajoInwork.DELETE_FLUJO_TRABAJO_SUCCESS:
            return {
                ...state,
                list: [...state.list.filter(ftrabajo => {
                    if (ftrabajo.id != action.deleteFlujoTrabajo.id) {
                        return {
                            ftrabajo
                        };
                    }
                })],
                loading: false,
                loaded: true,
                error: null
            };
    case fromFlujoTrabajoInwork.DELETE_FLUJO_TRABAJO_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion
    default:
        return state;
    }
}
