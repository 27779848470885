import { Action } from '@ngrx/store';
import { Periodos } from 'src/app/shared/models/Indicadores/periodos.model';

export const GET_PERIODOS_SUS = '[PERIODOS_SUS] Get Periodos_SUS';
export const GET_PERIODOS_SUS_FAIL = '[PERIODOS_SUS] Get Periodos_SUS FAIL';
export const GET_PERIODOS_SUS_SUCCESS = '[PERIODOS_SUS] Get Periodos_SUS SUCCESS';

export const POST_PERIODO_SUS = '[PERIODOS_SUS] Post Periodo_SUS';
export const POST_PERIODO_SUS_FAIL = '[PERIODOS_SUS] Post Periodo_SUS FAIL';
export const POST_PERIODO_SUS_SUCCESS = '[PERIODOS_SUS] Post Periodo_SUS SUCCESS';

export const PUT_PERIODO_SUS = '[PERIODO_SUS] Put Periodo_SUS';
export const PUT_PERIODO_SUS_FAIL = '[PERIODO_SUS] Put Periodo_SUS FAIL';
export const PUT_PERIODO_SUS_SUCCESS = '[PERIODO_SUS] Put Periodo_SUS SUCCESS';

export const DELETE_PERIODO_SUS = '[DELETE_PERIODO_SUS] Delete Periodo_SUS';
export const DELETE_PERIODO_SUS_FAIL = '[DELETE_PERIODO_SUS] Delete Periodo_SUS FAIL';
export const DELETE_PERIODO_SUS_SUCCESS = '[DELETE_PERIODO_SUS] Delete Periodo_SUS SUCCESS';

//#region GET_PERIODOS
export class GetPeriodosAction_SUS implements Action {
    readonly type = GET_PERIODOS_SUS;
    constructor() { }
};

export class GetPeriodosActionFail_SUS implements Action {
    readonly type = GET_PERIODOS_SUS_FAIL;
    constructor(public payload: any) { }
};

export class GetPeriodosActionSuccess_SUS implements Action {
    readonly type = GET_PERIODOS_SUS_SUCCESS;
    constructor(public periodos: Periodos[]) { }
};
//#endregion

//#region  POST_PERIODO
export class PostPeriodoAction_SUS implements Action {
    readonly type = POST_PERIODO_SUS;
    constructor(public periodo: Periodos) { }
};
export class PostPeriodoActionFAIL_SUS implements Action {
    readonly type = POST_PERIODO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PostPeriodoActionSUCCESS_SUS implements Action {
    readonly type = POST_PERIODO_SUS_SUCCESS;
    constructor(public newPeriodo: Periodos) { }
};
//#endregion

//#region  PUT_PERIODO
export class PutPeriodoAction_SUS implements Action {
    readonly type = PUT_PERIODO_SUS;
    constructor(public periodo: Periodos) { }
};
export class PutPeriodoActionFAIL_SUS implements Action {
    readonly type = PUT_PERIODO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class PutPeriodoActionSUCCESS_SUS implements Action {
    readonly type = PUT_PERIODO_SUS_SUCCESS;
    constructor(public updatePeriodo: Periodos) { }
};
//#endregion

//#region  DELETE_PERIODO
export class DeletePeriodoAction_SUS implements Action {
    readonly type = DELETE_PERIODO_SUS;
    constructor(public periodo: Periodos) { }
};
export class DeletePeriodoActionFAIL_SUS implements Action {
    readonly type = DELETE_PERIODO_SUS_FAIL;
    constructor(public payload: any) { }
};
export class DeletePeriodoActionSUCCESS_SUS implements Action {
    readonly type = DELETE_PERIODO_SUS_SUCCESS
    constructor(public deletePeriodo: Periodos) { }
};
//#endregion

export type periodosActions_SUS =
GetPeriodosAction_SUS |
GetPeriodosActionFail_SUS |
GetPeriodosActionSuccess_SUS |

PostPeriodoAction_SUS |
PostPeriodoActionFAIL_SUS |
PostPeriodoActionSUCCESS_SUS |

PutPeriodoAction_SUS |
PutPeriodoActionFAIL_SUS |
PutPeriodoActionSUCCESS_SUS |

DeletePeriodoAction_SUS |
DeletePeriodoActionFAIL_SUS |
DeletePeriodoActionSUCCESS_SUS;