import * as fromIncidencias from '../../../actions/modulos/incidencias/incidencias.actions';
import { IncidenciaVacaciones } from 'src/app/shared/models/incidencias/incidenciaVacaciones';

export class IncidenciaState {
    list: IncidenciaVacaciones[];
    // incidencia:IncidenciaDetail;
    loaded: boolean;
    loading: boolean;
    error: any;
};

// export class IncidenciaDetail{
//     usuarioModificacion: string;
//     folioIncidencia: string;
//     fkIdTipoIncidenia: number;
//     fkIdEstadoIncidencia: number;
//     numeroTrabajador: number;
//     nombreTrabajador: string;
//     dias_solicitados: number;
//     fecha_inicio: Date;
//     fecha_fin: Date;
//     motivo: string;
// }

export const initState = {
    list: [],
    // incidencia:null,
    loaded: false,
    loading: false,
    error: null
};

export function incidenciasReducer(state = initState, action: fromIncidencias.incidenciaVacacionesActions): IncidenciaState {
    switch (action.type) {

        //#region POST_AREAS
        case fromIncidencias.POST_INC_VAC:
            return {
                ...state,
                loading: true,
                error: null
            }
        case fromIncidencias.POST_INC_VAC_SUCCESS:
            return {
                ...state,
                // list: [...state.list, action.newIncidencia],
                list: [...state.list],
                loading: false,
                loaded: true,
                error: null
            };
        case fromIncidencias.POST_INC_VAC_FAIL:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: {
                    status: action.payload.status,
                    message: action.payload.message,
                    url: action.payload.url
                }
            };
        //#endregion

        default:
            return state;
    }
}
