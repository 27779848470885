import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as catalogosActions from '../../../actions/modulos/inwork/catalogos.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { CatalogosService } from 'src/app/services/inwork/catalogos.service';
import { Catalogos } from 'src/app/shared/models/Inwork/catalogos.model';

@Injectable()

export class catalogosInworkEffects {
    constructor(
        private actions$: Actions,
        private catalogosService: CatalogosService,
    ) { }

    @Effect()
    getCatalogosInwork$ = this.actions$.ofType(catalogosActions.GET_CATALOGOS)
        .pipe(
            switchMap(() => {
                return this.catalogosService.getCatalogos()
                    .pipe(
                        map((catalogos: Catalogos[]) => new catalogosActions.GetCatalogosActionSuccess(catalogos)),
                        catchError(error => of(new catalogosActions.GetCatalogosActionFail(error)))
                    )
            })
        );

    @Effect()
    postCatalogoInwork$ = this.actions$.ofType(catalogosActions.POST_CATALOGO)
        .pipe(
            switchMap((action: catalogosActions.PostCatalogoAction) => {
                return this.catalogosService.postCatalogo(action.catalogo)
                    .pipe(
                        map((newCatalogo: Catalogos) => new catalogosActions.PostCatalogoActionSUCCESS(newCatalogo)),
                        catchError(error => of(new catalogosActions.PostCatalogoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    putCatalogoInwork$ = this.actions$.ofType(catalogosActions.PUT_CATALOGO)
        .pipe(
            switchMap((action: catalogosActions.PutCatalogoAction) => {
                const cat = new Catalogos();
                cat.id = action.catalogo.id;
                cat.nombre = action.catalogo.nombre;
                cat.descripcion = action.catalogo.descripcion;
                
                return this.catalogosService.putCatalogo(cat)
                    .pipe(
                        map((updateCatalogo: Catalogos) => new catalogosActions.PutCatalogoActionSUCCESS(updateCatalogo)),
                        catchError(error => of(new catalogosActions.PutCatalogoActionFAIL(error)))
                    )
            })
        );
    @Effect()
    deleteCatalogoInwork$ = this.actions$.ofType(catalogosActions.DELETE_CATALOGO)
        .pipe(
            switchMap((action: catalogosActions.DeleteCatalogoAction) => {
                return this.catalogosService.deleteCatalogo(action.catalogo)
                    .pipe(
                        map((response) => new catalogosActions.DeleteCatalogoActionSUCCESS((response) ? action.catalogo : new Catalogos(0,"",""))),
                        catchError(error => of(new catalogosActions.DeleteCatalogoActionFAIL(error)))
                    )
            })
        );
};
