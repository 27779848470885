import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as tipoIndicadoresActions from '../../../actions/modulos/indicadoresSustentabilidad/tipos_indicadoresSus.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { TipoIndicadoresSusService } from 'src/app/services/indicadoresSustentabilidad/tipo_indicadorSus.service';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';

@Injectable()

export class tipoIndicadoresSusEffects {
    constructor(
        private actions$: Actions,
        private tipoIndicadoresService: TipoIndicadoresSusService,
    ) { }

    @Effect()
    cargarTiposIndicadores$ = this.actions$.ofType(tipoIndicadoresActions.GET_TIPOS_INDICADOR_SUS)
        .pipe(
            switchMap(() => {
                return this.tipoIndicadoresService.getTipoIndicadores()
                    .pipe(
                        map((tipo_indicador: TipoIndicador[]) => new tipoIndicadoresActions.GetTiposIndicadorActionSuccess_SUS(tipo_indicador)),
                        catchError(error => of(new tipoIndicadoresActions.GetTiposIndicadorActionFail_SUS(error)))
                    )
            })
        );

    @Effect()
    postTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.POST_TIPO_INDICADOR_SUS)
        .pipe(
            switchMap((action: tipoIndicadoresActions.PostTipoIndicadorAction_SUS) => {
                return this.tipoIndicadoresService.postTipoIndicador(action.tipoIndicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.PostTipoIndicadorActionSUCCESS_SUS(action.tipoIndicador)),
                        catchError(error => of(new tipoIndicadoresActions.PostTipoIndicadorActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    editTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.PUT_TIPO_INDICADOR_SUS)
        .pipe(
            switchMap((action: tipoIndicadoresActions.PutTipoIndicadorAction_SUS) => {
                const tipo_indicador = new TipoIndicador();
                tipo_indicador.id = action.tipoIndicador.id;
                tipo_indicador.descripcion = action.tipoIndicador.descripcion;

                return this.tipoIndicadoresService.putTipoIndicador(tipo_indicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.PutTipoIndicadorActionSUCCESS_SUS(tipo_indicador)),
                        catchError(error => of(new tipoIndicadoresActions.PutTipoIndicadorActionFAIL_SUS(error)))
                    )
            })
        );
    @Effect()
    deleteTipoIndicador$ = this.actions$.ofType(tipoIndicadoresActions.DELETE_TIPO_INDICADOR_SUS)
        .pipe(
            switchMap((action: tipoIndicadoresActions.DeleteTipoIndicadorAction_SUS) => {
                return this.tipoIndicadoresService.deleteTipoIndicador(action.tipoIndicador)
                    .pipe(
                        map(() => new tipoIndicadoresActions.DeleteTipoIndicadorActionSUCCESS_SUS(action.tipoIndicador)),
                        catchError(error => of(new tipoIndicadoresActions.DeleteTipoIndicadorActionFAIL_SUS(error)))
                    )
            })
        );
};
