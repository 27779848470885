import { Action } from '@ngrx/store';
import { ItemsCatalogo } from 'src/app/shared/models/Inwork/catalogos.model';

//CATALOGOS
export const GET_ITEMS_CATALOGO = '[INWORK] Get Items Catalogo Inwork';
export const GET_ITEMS_CATALOGO_FAIL = '[INWORK] Get Items Catalogo Inwork FAIL';
export const GET_ITEMS_CATALOGO_SUCCESS = '[INWORK] Get Items Catalogo Inwork SUCCESS';

export const POST_ITEM_CATALOGO = '[INWORK] Post Item Catalogo Inwork';
export const POST_ITEM_CATALOGO_FAIL = '[INWORK] Post Item Catalogo Inwork FAIL';
export const POST_ITEM_CATALOGO_SUCCESS = '[INWORK] Post Item Catalogo Inwork SUCCESS';

export const PUT_ITEM_CATALOGO = '[INWORK] Put Item Catalogo Inwork';
export const PUT_ITEM_CATALOGO_FAIL = '[INWORK] Put Item Catalogo Inwork FAIL';
export const PUT_ITEM_CATALOGO_SUCCESS = '[INWORK] Put Item Catalogo Inwork SUCCESS';

export const DELETE_ITEM_CATALOGO = '[INWORK] Delete Item Catalogo Inwork';
export const DELETE_ITEM_CATALOGO_FAIL = '[INWORK] Delete Item Catalogo Inwork FAIL';
export const DELETE_ITEM_CATALOGO_SUCCESS = '[INWORK] Delete Item Catalogo Inwork SUCCESS';

//#region GET_ITEMS_CATALOGO
export class GetItemsCatalogoAction implements Action {
    readonly type = GET_ITEMS_CATALOGO;
};

export class GetItemsCatalogoActionFail implements Action {
    readonly type = GET_ITEMS_CATALOGO_FAIL;
    constructor(public payload: any) { }
};

export class GetItemsCatalogoActionSuccess implements Action {
    readonly type = GET_ITEMS_CATALOGO_SUCCESS;
    constructor(public itemsCatalogo: ItemsCatalogo[]) { }
};
//#endregion

//#region  POST_ITEM_CATALOGO
export class PostItemCatalogoAction implements Action {
    readonly type = POST_ITEM_CATALOGO;
    constructor(public itemCatalogo: ItemsCatalogo) { }
};
export class PostItemCatalogoActionFAIL implements Action {
    readonly type = POST_ITEM_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class PostItemCatalogoActionSUCCESS implements Action {
    readonly type = POST_ITEM_CATALOGO_SUCCESS;
    constructor(public newItemCatalogo: ItemsCatalogo) { }
};
//#endregion

//#region  PUT_ITEM_CATALOGO
export class PutItemCatalogoAction implements Action {
    readonly type = PUT_ITEM_CATALOGO;
    constructor(public itemCatalogo: ItemsCatalogo) { }
};
export class PutItemCatalogoActionFAIL implements Action {
    readonly type = PUT_ITEM_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class PutItemCatalogoActionSUCCESS implements Action {
    readonly type = PUT_ITEM_CATALOGO_SUCCESS;
    constructor(public updateItemCatalogo: ItemsCatalogo) { }
};
//#endregion

//#region  DELETE_ITEM_CATALOGO
export class DeleteItemCatalogoAction implements Action {
    readonly type = DELETE_ITEM_CATALOGO;
    constructor(public itemCatalogo: ItemsCatalogo) { }
};
export class DeleteItemCatalogoActionFAIL implements Action {
    readonly type = DELETE_ITEM_CATALOGO_FAIL;
    constructor(public payload: any) { }
};
export class DeleteItemCatalogoActionSUCCESS implements Action {
    readonly type = DELETE_ITEM_CATALOGO_SUCCESS;
    constructor(public deleteItemCatalogo: ItemsCatalogo) { }
};
//#endregion

export type itemsCatalogosInworkActions =
GetItemsCatalogoAction |
GetItemsCatalogoActionFail |
GetItemsCatalogoActionSuccess |

PostItemCatalogoAction |
PostItemCatalogoActionFAIL |
PostItemCatalogoActionSUCCESS |

PutItemCatalogoAction |
PutItemCatalogoActionFAIL |
PutItemCatalogoActionSUCCESS |

DeleteItemCatalogoAction |
DeleteItemCatalogoActionFAIL |
DeleteItemCatalogoActionSUCCESS;
