import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as corteCaja_PV_Actions from '../../../actions/modulos/punto-venta/corteCaja_PV.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';

import { Corte_Caja_PV } from 'src/app/shared/models/punto-venta/corte-caja-pv.model';
import { CorteCaja_PV_Service } from 'src/app/services/microMedicion/puntoVenta/corteCaja.service';

@Injectable()

export class corteCaja_PV_Effects {
    constructor(
        private actions$: Actions,
        private cortecaja_pv_service: CorteCaja_PV_Service,
    ) { }

    @Effect()
    postCorteCaja_PV$ = this.actions$.ofType(corteCaja_PV_Actions.POST_CORTE_CAJA)
        .pipe(
            switchMap((action: corteCaja_PV_Actions.PostCorteCaja_PV_Action) => {
                return this.cortecaja_pv_service.postCorteCaja(action.corte)
                    .pipe(
                        map((cortes: Corte_Caja_PV[]) => new corteCaja_PV_Actions.PostCorteCaja_PV_ActionSuccess(cortes)),
                        catchError(error => of(new corteCaja_PV_Actions.PostCorteCaja_PV_ActionFail(error)))
                    )
            })
        );

    @Effect()
    getEstatusCaja_PV$ = this.actions$.ofType(corteCaja_PV_Actions.GET_ESTATUS_CAJA)
        .pipe(
            switchMap((action: corteCaja_PV_Actions.GetEstatusCaja_PV_Action) => {
                return this.cortecaja_pv_service.getEstatusCaja(action.idUsuario)
                    .pipe(
                        map((cortes: Corte_Caja_PV[]) => new corteCaja_PV_Actions.GetEstatusCaja_PV_ActionSuccess(cortes)),
                        catchError(error => of(new corteCaja_PV_Actions.GetEstatusCaja_PV_ActionFail(error)))
                    )
            })
        );
};
