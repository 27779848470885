import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CodigoPVMicroMedicion } from 'src/app/shared/models/microMedicion/codigoMicroMedicion.model';
import { HttpClientService } from '../../implementation/http-client.service';
import { ExceptionsService } from '../../exceptions.service';
import { Corte_Caja_PV } from 'src/app/shared/models/punto-venta/corte-caja-pv.model';

@Injectable({
    providedIn: 'root'
})
export class CorteCaja_PV_Service {


    constructor(
        private http: HttpClientService,
        private exceptionsService: ExceptionsService
    ) { }

    //#region GET
    getEstatusCaja(idUsuario: number) {
        return this.http.get(`${environment.apiAlmacen}shop/boxCut?idUsuario=${idUsuario}`)
            .pipe(
                map((response: any) => {
                    let cortes: Corte_Caja_PV[] = [];

                    if (response.inicio) {
                        cortes.push(new Corte_Caja_PV(
                            response.inicio.id,
                            response.inicio.moneda,
                            response.inicio.billetes,
                            response.inicio.tarjetas,
                            response.inicio.total,
                            response.inicio.idCaja,
                            response.inicio.idTurno,
                            response.inicio.registro
                        ));

                        if (response.final) {
                            cortes.push(new Corte_Caja_PV(
                                response.final.id,
                                response.final.moneda,
                                response.final.billetes,
                                response.final.tarjetas,
                                response.final.total,
                                response.final.idCaja,
                                response.final.idTurno,
                                response.final.registro
                            ));
                        }
                        else { cortes.push(null); }

                    } else {
                        cortes.push(null),
                            cortes.push(null)
                    }
                    return cortes;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )));
    }
    //#endregion
    //#region POST
    postCorteCaja(corte: Corte_Caja_PV) {
        return this.http.post(`${environment.apiAlmacen}shop/boxCut`, { corte })
            .pipe(
                map((response: any) => {
                    let cortes: Corte_Caja_PV[] = [];

                    if (response.inicio) {
                        cortes.push(new Corte_Caja_PV(
                            response.inicio.id,
                            response.inicio.moneda,
                            response.inicio.billetes,
                            response.inicio.tarjetas,
                            response.inicio.total,
                            response.inicio.idCaja,
                            response.inicio.idTurno,
                            response.inicio.registro
                        ));

                        if (response.final) {
                            cortes.push(new Corte_Caja_PV(
                                response.final.id,
                                response.final.moneda,
                                response.final.billetes,
                                response.final.tarjetas,
                                response.final.total,
                                response.final.idCaja,
                                response.final.idTurno,
                                response.final.registro
                            ));
                        }
                        else { cortes.push(null); }

                    } else {
                        cortes.push(null),
                            cortes.push(null)
                    }
                    return cortes;
                }),
                catchError((error) => of(
                    this.exceptionsService._errorComunicacion(error)
                )));
    }
    //#endregion
}