import { Action } from '@ngrx/store';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';

export const GET_CONFIGURACION_PROGRAMA = '[GET_CONFIGURACION_PROGRAMA] Get Configuracion Programa';
export const GET_CONFIGURACION_PROGRAMA_FAIL = '[GET_CONFIGURACION_PROGRAMA_FAIL] Get Configuracion Programa FAIL';
export const GET_CONFIGURACION_PROGRAMA_SUCCESS = '[GET_CONFIGURACION_PROGRAMA_SUCCESS] Get Configuracion Programa SUCCESS';

export const GET_CONFIGURACION_AVANCE = '[GET_CONFIGURACION_AVANCE] Get Configuracion Avance';
export const GET_CONFIGURACION_AVANCE_FAIL = '[GET_CONFIGURACION_AVANCE_FAIL] Get Configuracion Avance FAIL';
export const GET_CONFIGURACION_AVANCE_SUCCESS = '[GET_CONFIGURACION_AVANCE_SUCCESS] Get Configuracion Avance SUCCESS';

export const PUT_CONFIGURACION_PROGRAMA = '[PUT_CONFIGURACION_PROGRAMA] Put Configuracion Programa';
export const PUT_CONFIGURACION_PROGRAMA_FAIL = '[PUT_CONFIGURACION_PROGRAMA_FAIL] Put Configuracion Programa FAIL';
export const PUT_CONFIGURACION_PROGRAMA_SUCCESS = '[PUT_CONFIGURACION_PROGRAMA_SUCCESS] Put Configuracion Programa SUCCESS';

export const PUT_CONFIGURACION_AVANCE = '[PUT_CONFIGURACION_AVANCE] Put Configuracion Avance';
export const PUT_CONFIGURACION_AVANCE_FAIL = '[PUT_CONFIGURACION_AVANCE_FAIL] Put Configuracion Avance FAIL';
export const PUT_CONFIGURACION_AVANCE_SUCCESS = '[PUT_CONFIGURACION_AVANCE_SUCCESS] Put Configuracion Avance SUCCESS';

export const POST_CONFIGURACION_PROGRAMA = '[POST_CONFIGURACION_PROGRAMA] Post Configuracion Programa';
export const POST_CONFIGURACION_PROGRAMA_FAIL = '[POST_CONFIGURACION_PROGRAMA_FAIL] Post Configuracion Programa FAIL';
export const POST_CONFIGURACION_PROGRAMA_SUCCESS = '[POST_CONFIGURACION_PROGRAMA_SUCCESS] Post Configuracion Programa SUCCESS';

export const POST_CONFIGURACION_AVANCE = '[MES_CONFIGURACION] Post Configuracion Avance';
export const POST_CONFIGURACION_AVANCE_FAIL = '[MES_CONFIGURACION] Post Configuracion Avance FAIL';
export const POST_CONFIGURACION_AVANCE_SUCCESS = '[MES_CONFIGURACION] Post Configuracion Avance SUCCESS';

export const DELETE_CONFIGURACION_PROGRAMA = '[DELETE_CONFIGURACION_PROGRAMA] Delete Configuracion Programa';
export const DELETE_CONFIGURACION_PROGRAMA_FAIL = '[DELETE_CONFIGURACION_PROGRAMA_FAIL] Delete Configuracion Programa FAIL';
export const DELETE_CONFIGURACION_PROGRAMA_SUCCESS = '[DELETE_CONFIGURACION_PROGRAMA_SUCCESS] Delete Configuracion Programa SUCCESS';

export const DELETE_CONFIGURACION_AVANCE = '[DELETE_CONFIGURACION_AVANCE] Delete Configuracion Avance';
export const DELETE_CONFIGURACION_AVANCE_FAIL = '[DELETE_CONFIGURACION_AVANCE_FAIL] Delete Configuracion Avance FAIL';
export const DELETE_CONFIGURACION_AVANCE_SUCCESS = '[DELETE_CONFIGURACION_AVANCE_SUCCESS] Delete Configuracion Avance SUCCESS';

//#region GET_CONFIGURACION_PROGRAMA
export class GetConfiguracionProgramaAction implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA;
    constructor(public idEstrategico: number) { }
};

export class GetConfiguracionProgramaActionFail implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA_FAIL;
    constructor(public payload: any) { }
};

export class GetConfiguracionProgramaActionSuccess implements Action {
    readonly type = GET_CONFIGURACION_PROGRAMA_SUCCESS;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region GET_CONFIGURACION_AVANCE
export class GetConfiguracionAvanceAction implements Action {
    readonly type = GET_CONFIGURACION_AVANCE;
    constructor(public idEstrategico: number) { }
};

export class GetConfiguracionAvanceActionFail implements Action {
    readonly type = GET_CONFIGURACION_AVANCE_FAIL;
    constructor(public payload: any) { }
};

export class GetConfiguracionAvanceActionSuccess implements Action {
    readonly type = GET_CONFIGURACION_AVANCE_SUCCESS;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region PUT_CONFIGURACION_PROGRAMA
export class PutConfiguracionProgramaAction implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};

export class PutConfiguracionProgramaActionFail implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA_FAIL;
    constructor(public payload: any) { }
};

export class PutConfiguracionProgramaActionSuccess implements Action {
    readonly type = PUT_CONFIGURACION_PROGRAMA_SUCCESS;
    constructor(public editConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region PUT_CONFIGURACION_AVANCE
export class PutConfiguracionAvanceAction implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};

export class PutConfiguracionAvanceActionFail implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE_FAIL;
    constructor(public payload: any) { }
};

export class PutConfiguracionAvanceActionSuccess implements Action {
    readonly type = PUT_CONFIGURACION_AVANCE_SUCCESS;
    constructor(public editConfigAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region POST_CONFIGURACION_PROGRAMA
export class PostConfiguracionProgramaAction implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
export class PostConfiguracionProgramaActionFAIL implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA_FAIL;
    constructor(public payload: any) { }
};
export class PostConfiguracionProgramaActionSUCCESS implements Action {
    readonly type = POST_CONFIGURACION_PROGRAMA_SUCCESS;
    constructor(public newConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region POST_CONFIGURACION_AVANCE
export class PostConfiguracionAvanceAction implements Action {
    readonly type = POST_CONFIGURACION_AVANCE;
    constructor(public configAvance: ConfiguracionAvance[]) { }
};
export class PostConfiguracionAvanceActionFAIL implements Action {
    readonly type = POST_CONFIGURACION_AVANCE_FAIL;
    constructor(public payload: any) { }
};
export class PostConfiguracionAvanceActionSUCCESS implements Action {
    readonly type = POST_CONFIGURACION_AVANCE_SUCCESS;
    constructor(public newConfigAvance: ConfiguracionAvance[]) { }
};
//#endregion

//#region  DELETE_CONFIGURACION_PROGRAMA
export class DeleteConfiguracionProgramaAction implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA;
    constructor(public configPrograma: ConfiguracionPrograma) { }
};
export class DeleteConfiguracionProgramaActionFAIL implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA_FAIL;
    constructor(public payload: any) { }
};
export class DeleteConfiguracionProgramaActionSUCCESS implements Action {
    readonly type = DELETE_CONFIGURACION_PROGRAMA_SUCCESS;
    constructor(public deleteConfigPrograma: ConfiguracionPrograma) { }
};
//#endregion

//#region  DELETE_CONFIGURACION_AVANCE
export class DeleteConfiguracionAvanceAction implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE;
    constructor(public configAvance: ConfiguracionAvance) { }
};
export class DeleteConfiguracionAvanceActionFAIL implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE_FAIL;
    constructor(public payload: any) { }
};
export class DeleteConfiguracionAvanceActionSUCCESS implements Action {
    readonly type = DELETE_CONFIGURACION_AVANCE_SUCCESS;
    constructor(public deleteConfigAvance: ConfiguracionAvance) { }
};
//#endregion

export type configuracionProgramaActions =
GetConfiguracionProgramaAction |
GetConfiguracionProgramaActionFail |
GetConfiguracionProgramaActionSuccess |

GetConfiguracionAvanceAction |
GetConfiguracionAvanceActionFail |
GetConfiguracionAvanceActionSuccess |

PostConfiguracionProgramaAction |
PostConfiguracionProgramaActionFAIL |
PostConfiguracionProgramaActionSUCCESS |

PostConfiguracionAvanceAction |
PostConfiguracionAvanceActionFAIL |
PostConfiguracionAvanceActionSUCCESS |

PutConfiguracionProgramaAction |
PutConfiguracionProgramaActionFail |
PutConfiguracionProgramaActionSuccess |

PutConfiguracionAvanceAction |
PutConfiguracionAvanceActionFail |
PutConfiguracionAvanceActionSuccess |

DeleteConfiguracionProgramaAction |
DeleteConfiguracionProgramaActionFAIL |
DeleteConfiguracionProgramaActionSUCCESS |

DeleteConfiguracionAvanceAction |
DeleteConfiguracionAvanceActionFAIL |
DeleteConfiguracionAvanceActionSUCCESS;