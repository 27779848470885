import * as fromTiposIndicadores from '../../../actions/modulos/indicadoresSustentabilidad/tipos_indicadoresSus.actions';
import { TipoIndicador } from 'src/app/shared/models/Indicadores/tipo_indicador.model';

export class TiposIndicadorState {
    list: TipoIndicador[];
    loaded: boolean;
    loading: boolean;
    error: any;
};

export const initState: TiposIndicadorState = {
    list: [],
    loaded: false,
    loading: false,
    error: null
};

export function tipoIndicadoresReducerSus(state = initState, action: fromTiposIndicadores.tiposIndicadoresActions_SUS): TiposIndicadorState {
    switch (action.type) {
    //#region GET_TIPOS_INDICADORES
    case fromTiposIndicadores.GET_TIPOS_INDICADOR_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromTiposIndicadores.GET_TIPOS_INDICADOR_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                 ...action.tipos_indicador.map(tiposIndicador => {
                    return {
                        ...tiposIndicador
                    }
                })
            ]
        };
    case fromTiposIndicadores.GET_TIPOS_INDICADOR_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region POST_TIPO_INDICADOR
    case fromTiposIndicadores.POST_TIPO_INDICADOR_SUS:
        return {
            ...state,
        }
    case fromTiposIndicadores.POST_TIPO_INDICADOR_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [...state.list, action.newTipoIndicador]
        };
    case fromTiposIndicadores.POST_TIPO_INDICADOR_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region PUT_TIPO_INDICADOR
    case fromTiposIndicadores.PUT_TIPO_INDICADOR_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromTiposIndicadores.PUT_TIPO_INDICADOR_SUS_SUCCESS:
        return {
            ...state,
            loading: false,
            loaded: true,
            list: [
                ...state.list.map(editTipo_Indicador => {
                    if (editTipo_Indicador.id === action.updateTipoIndicador.id) {
                        return {
                            ...editTipo_Indicador,
                            descripcion: action.updateTipoIndicador.descripcion
                        };
                    } else {
                        return editTipo_Indicador;
                    }
                })
            ]
        };
    case fromTiposIndicadores.PUT_TIPO_INDICADOR_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    //#region DELETE_FRECUENCIA
    case fromTiposIndicadores.DELETE_TIPO_INDICADOR_SUS:
        return {
            ...state,
            loading: true,
            error: null
        };
    case fromTiposIndicadores.DELETE_TIPO_INDICADOR_SUS_SUCCESS:
        return {
            ...state,
            list: [...state.list.filter(tipo_indicador => {
                if (tipo_indicador.id != action.deleteTipoIndicador.id) {
                    return {
                        tipo_indicador
                    };
                }
            })],
            loading: false,
            loaded: true,
            error: null
        };
    case fromTiposIndicadores.DELETE_TIPO_INDICADOR_SUS_FAIL:
        return {
            ...state,
            loaded: false,
            loading: false,
            error: {
                status: action.payload.status,
                message: action.payload.message,
                url: action.payload.url
            }
        };
    //#endregion
    default:
        return state;
    }
}
