import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VariablesService } from './balcax/variableGL.service';
import { Toast } from '../shared/models/toast.model';

@Injectable({
    providedIn: 'root'
})
export class ExceptionsService {

    constructor(
        private router: Router,
        private variablesService: VariablesService,
    ) { }

    _error(error) {
        localStorage.clear();
        localStorage.error = JSON.stringify(error);
        this.router.navigate(['/login'], { replaceUrl: true });
        setTimeout(() => {
            location.reload();
        }, 100);
    }
    _errorComunicacion(error) {
        localStorage.error = JSON.stringify(error);
        this.variablesService.toast.next(new Toast('error', 'Error.', 'No se pudo realizar la acción, intentalo de nuevo', 1000));
    }
    _errorEliminacion(error) {
        localStorage.error = JSON.stringify(error);
        this.variablesService.toast.next(new Toast('error', 'Error.', 'No se pudo eliminar, intentalo de nuevo', 1000));
    }
    _credencialesIncorrectas() {
        localStorage.clear();
        this.variablesService.toastLogin.next(new Toast('error', 'Error.', 'Credenciales Incorrectas.', 1000));
    }

    _toast(state: string, title: string, message: string) {
        this.variablesService.toast.next(new Toast(state, title, message, 1000));
    }
}
