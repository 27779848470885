import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';

import * as configProgramaTrabajoActions from '../../../actions/modulos/indicadores/config_programatrabajo.actions';

import { of } from "rxjs";
import { map, switchMap, catchError } from 'rxjs/operators';
import { ConfigProgramaTrabajoService } from 'src/app/services/indicadores/config_programatrabajo.service';
import { ConfiguracionPrograma } from 'src/app/shared/models/Indicadores/configuracion_programa.model';
import { ConfiguracionAvance } from 'src/app/shared/models/Indicadores/configuracion_avance.model';

@Injectable()

export class configProgramaTrabajoEffects {
    constructor(
        private actions$: Actions,
        private configProgramaTrabajoService: ConfigProgramaTrabajoService,
    ) { }

    @Effect()
    cargarConfiguracionPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.GET_CONFIGURACION_PROGRAMA)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.GetConfiguracionProgramaAction) => {
                return this.configProgramaTrabajoService.getConfiguracionPrograma(action.idEstrategico)
                    .pipe(
                        map((response) => new configProgramaTrabajoActions.GetConfiguracionProgramaActionSuccess(response)),
                        catchError(error => of(new configProgramaTrabajoActions.GetConfiguracionProgramaActionFail(error)))
                    )
            })
        );

    @Effect()
    cargarConfiguracionAvance$ = this.actions$.ofType(configProgramaTrabajoActions.GET_CONFIGURACION_AVANCE)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.GetConfiguracionAvanceAction) => {
                return this.configProgramaTrabajoService.getConfiguracionAvance(action.idEstrategico)
                    .pipe(
                        map((response: any) => new configProgramaTrabajoActions.GetConfiguracionAvanceActionSuccess(response)),
                        catchError(error => of(new configProgramaTrabajoActions.GetConfiguracionAvanceActionFail(error)))
                    )
            })
        );

    @Effect()
    changeEditConfigPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.PUT_CONFIGURACION_PROGRAMA)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PutConfiguracionProgramaAction) => {
                return this.configProgramaTrabajoService.putConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.PutConfiguracionProgramaActionSuccess(action.configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.PutConfiguracionProgramaActionFail(error)))
                    )
            })
        );

    @Effect()
    changeEditConfigAvance$ = this.actions$.ofType(configProgramaTrabajoActions.PUT_CONFIGURACION_AVANCE)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PutConfiguracionAvanceAction) => {
                return this.configProgramaTrabajoService.putConfiguracionAvance(action.configAvance)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.PutConfiguracionAvanceActionSuccess(action.configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.PutConfiguracionAvanceActionFail(error)))
                    )
            })
        );

    @Effect()
    postConfigPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.POST_CONFIGURACION_PROGRAMA)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PostConfiguracionProgramaAction) => {
                return this.configProgramaTrabajoService.postConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map((configPrograma: ConfiguracionPrograma) => new configProgramaTrabajoActions.PostConfiguracionProgramaActionSUCCESS(configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.PostConfiguracionProgramaActionFAIL(error)))
                    )
            })
        );

    @Effect()
    postConfigAvance$ = this.actions$.ofType(configProgramaTrabajoActions.POST_CONFIGURACION_AVANCE)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.PostConfiguracionAvanceAction) => {
                return this.configProgramaTrabajoService.postConfiguracionAvance(action.configAvance)
                    .pipe(
                        map((configAvance: ConfiguracionAvance[]) => new configProgramaTrabajoActions.PostConfiguracionAvanceActionSUCCESS(configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.PostConfiguracionAvanceActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteConfiguracionPrograma$ = this.actions$.ofType(configProgramaTrabajoActions.DELETE_CONFIGURACION_PROGRAMA)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.DeleteConfiguracionProgramaAction) => {
                return this.configProgramaTrabajoService.deleteConfiguracionPrograma(action.configPrograma)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.DeleteConfiguracionProgramaActionSUCCESS(action.configPrograma)),
                        catchError(error => of(new configProgramaTrabajoActions.DeleteConfiguracionProgramaActionFAIL(error)))
                    )
            })
        );

    @Effect()
    deleteConfiguracionAvance$ = this.actions$.ofType(configProgramaTrabajoActions.DELETE_CONFIGURACION_AVANCE)
        .pipe(
            switchMap((action: configProgramaTrabajoActions.DeleteConfiguracionAvanceAction) => {
                return this.configProgramaTrabajoService.deleteConfiguracionAvance(action.configAvance)
                    .pipe(
                        map(() => new configProgramaTrabajoActions.DeleteConfiguracionAvanceActionSUCCESS(action.configAvance)),
                        catchError(error => of(new configProgramaTrabajoActions.DeleteConfiguracionAvanceActionFAIL(error)))
                    )
            })
        );
};
